/* tslint:disable */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { TransactionService } from '../loginpage/Transaction.service';
import { cntrandsudetailspost, selec_prt_pro_details, submit_proposal_tab4_post, tab4details,selected_parts_submit,selected_components_submit, userdetails, submit_proposal_tab4_post_ee, proposalSubmissionChangeTrack, compmaterialpost, containerDetailsCntrAdmin, fromdiplayvalues_all, partDetailForPost, primarypartdetails, storecomponentforpost, RevHistFromAPi } from '../loginpage/transactions';
import { PackagingproposalComponent } from '../packagingproposal/packagingproposal.component';
import { EepartsandatributesComponent } from '../eepartsandatributes/eepartsandatributes.component';
import { DatePipe } from '@angular/common';


import {ImageService } from '../packproposaltabfour/image.Services';
import { PackagingprocessComponent } from '../packagingprocess/packagingprocess.component';
import { CompileShallowModuleMetadata } from '@angular/compiler';
import { threadId } from 'worker_threads';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-eepackproposaltabfour',
  templateUrl: './eepackproposaltabfour.component.html',
  styleUrls: ['./eepackproposaltabfour.component.css'],
  providers:[MessageService, DatePipe]
})
export class EepackproposaltabfourComponent implements OnInit {


  data_ee: string;
  region_ee:string;
  myfiles_ee: any = [];
  uploadedFiles_ee: any[] = [];
  uplo_ee: File;


  image_folder_path_ee=''


  respdata_ee='';





  part_image_ee=''
  interior_image_ee='';
  exterior_image_ee='';
  label_image_ee='';
  shipping_image_ee='';


  current_region_code_ee='';


  selectedpart_submitter_image_ee:selec_prt_pro_details={simplifiedpartno:'-',proposalid:0,bompartmasterid:0,plantgsdbcode:'',proposallastsaved:0,part_status:'',supplier_code:'',proposallastsaved_ee:1,is_ee_proposal:true,currentview_ee:true,from_draft:false,propsl_type:'',imp_part_status:''};

  id_proposal_image_ee:number;
  noSupDialog_image_ee=false;
  content_supp_image_ee:string;
  noPrtDialog_image_ee=false;
  content_selectedprt_image_ee:string;
 contandcos_cdsid_ee:string;
  supp_code_image_ee:string;
  roleid_image_ee:number;
  designsource_code_image_ee:string;


  noDesignsourceDialog_image_ee=false;
content_Designsource_image_ee='';


noroleselectedDialog_ee=false;
  norolecontent_ee='';
  tab4alldetails_ee:tab4details={id_proposal:0,f_extcntrdsgnphoto:'',f_intcntrdsgnphoto:'',f_metelidplatephoto:'',f_partphoto:'',f_shpunitphoto:'',gen_propslcommnts:'',int_cntrdesgncommnts:'',createmgcyexpndpckgprop:'',agrmntexpndpackreqmnts:'',subpresloaddoc:''};


  baseurl_get_ee:any;
  imgsave_path_ee:any='';


  store_proposal_values_ee:submit_proposal_tab4_post_ee;
  // tab2data_ee:cntrandsudetailspost;
  store_proposal_selectedparts_ee:selected_parts_submit[]=[];
  store_proposal_componenets_ee:selected_components_submit[]=[];


    store_proposal_values:submit_proposal_tab4_post;
    // tab2data:cntrandsudetailspost;
    store_proposal_selectedparts:selected_parts_submit[]=[];
    store_proposal_componenets:selected_components_submit[]=[];



  PartPhoto_image_upload_dialog_ee=false;
InteriorContainer_upload_dialog_ee=false;
ExteriorContainer_upload_dialog_ee=false;
Label_MetalID_upload_dialog_ee=false;
ShippingUnit_upload_dialog_ee=false;


intr_dsgn_comments_ee='';
general_comments_ee='';
crt_edt_empro_ee='';
agre_for_empro_ee='';


curr_cdsid_ee='';
  name_ee: any;


  upload_sbupl_display_ee=false;
  upload_sbupl_req_ee=false;


  sbupl_uploaded_ee=false;


  sbu_file_ee='';


  sbu_file_path_ee='';
  baseurl_get_sbupl_ee=''


  sbupl_upload_dialog_ee=false;


  enable_submit_proposal_button=false


  test_var_ee='';


  user_details_ee:userdetails={gppuserid:'',name:'',title:'',company:'',email:'',city:'',cntrycodeiso:'',createdby:'',createddt:'',designation:'',isforduser:'',isactive:'',phone:'',postalcode:'',address1:'',address2:'',state:'',lastlogin:'',country:'',fax:'',usertype:''};


  disable_browse_button_part_ee = false; // currently diabled in future if required to implement same as interior photo
  disable_browse_button_int_cntr_ee = false;
  disable_browse_button_ext_cntr_ee = false;
  disable_browse_button_shp_unt_ee = false;
  disable_browse_button_label_ee = false; // currently diabled in future if required to implement same as interior photo
  disable_browse_button_sbupl_ee = false;

  primarypartsattributes:primarypartdetails={
    gppuserid:this.transactionService.getcdsid(),
    id_proposal:0,
    corr_protc:'',
    lst_saved_tab:0,
    seasnlreq:'',
    shelf_life:0,
    sp_part_no:'',
    submit_status:'not submitted',
    temp_sensitivity:'No',
    temp_stor_spec:'',
    timestamp:'',
    plantgsdbcode:'',
    isstampblank:'',
    supgsdbcode: '',
    supfullname: '',
     supaddress1: '',
     supcity: '',
     supstateprov: '',
      supcountrycode: '',
     supcontactnamefirst: '',
   supcontactname: '',
  supcontactfunctn: '',
    supcontactemail: '',
    supcontactphone: '',
    supcontactfax: '',
    defaultcurr:'',
    lst_saved_tab_ee:1,
    propsl_type:''


  }


  containerDetails:containerDetailsCntrAdmin;
  tab1DataGet:primarypartdetails;
  selectedPartsGet:partDetailForPost[];

  containerDetails_ee:containerDetailsCntrAdmin;
  tab1DataGet_ee:primarypartdetails;
  selectedPartsGet_ee:partDetailForPost[];

  tab2data:cntrandsudetailspost;
  tab2DataForPost:cntrandsudetailspost;

  tab2data_ee:cntrandsudetailspost;
  tab2DataForPost_ee:cntrandsudetailspost;

  compDataGet:storecomponentforpost[]
  tab3detailsGet:compmaterialpost;
  tab3DataForPost:compmaterialpost;

  compDataGet_ee:storecomponentforpost[]
  tab3detailsGet_ee:compmaterialpost;
  tab3DataForPost_ee:compmaterialpost;

  tab4Get:tab4details
  trascMastrGet:fromdiplayvalues_all;

  tab4Get_ee:tab4details
  trascMastrGet_ee:fromdiplayvalues_all;

  enableChnageDialog=false;
  enableNotificationDialog=false;
  notificationText='';

  enableChnageDialog_ee=false;
  enableNotificationDialog_ee=false;
  enablePartDimensionNotification=false;
  notificationText_ee='';

  oldProposalDetailsForChnageTrack:proposalSubmissionChangeTrack={
    cntrTareWt:0,
    cntrNestedFldedHt:0,
    cntrExtLength:0,
    cntrExtwidth:0,
    cntrExtHeight:0,
    // maxLayersPerSu:0,
    // cntrPerLayer:0,
    // partsPerSu:0,
    maxCntrsPerSu:0,
    shippingUnitBase:'',
    shippingUnitSuffix:'',
    // shippingExtLen:0,
    // shippingExtWidth:0,
   // shippingExtHeight:0,
    // shippingUnitNestedFoldedht:0,
    shippingUnitReturnType:'',
    shiipingUnitTareWeight:0,
    cntrGrossWeight:0,
    shippingUnitGrossWeight:0,

  }
  newProposalDetailsForChangeTrack:proposalSubmissionChangeTrack={
    cntrTareWt:0,
    cntrNestedFldedHt:0,
    cntrExtLength:0,
    cntrExtwidth:0,
    cntrExtHeight:0,
    // maxLayersPerSu:0,
    // cntrPerLayer:0,
    // partsPerSu:0,
    maxCntrsPerSu:0,
    shippingUnitBase:'',
    shippingUnitSuffix:'',
    // shippingExtLen:0,
    // shippingExtWidth:0,
   // shippingExtHeight:0,
    // shippingUnitNestedFoldedht:0,
    shippingUnitReturnType:'',
    shiipingUnitTareWeight:0,
    cntrGrossWeight:0,
    shippingUnitGrossWeight:0,

  }

  oldProposalDetailsForChnageTrack_ee:proposalSubmissionChangeTrack={
    cntrTareWt:0,
    cntrNestedFldedHt:0,
    cntrExtLength:0,
    cntrExtwidth:0,
    cntrExtHeight:0,
    // maxLayersPerSu:0,
    // cntrPerLayer:0,
    // partsPerSu:0,
    maxCntrsPerSu:0,
    shippingUnitBase:'',
    shippingUnitSuffix:'',
    // shippingExtLen:0,
    // shippingExtWidth:0,
   // shippingExtHeight:0,
    // shippingUnitNestedFoldedht:0,
    shippingUnitReturnType:'',
    shiipingUnitTareWeight:0,
    cntrGrossWeight:0,
    shippingUnitGrossWeight:0,

  }
  newProposalDetailsForChangeTrack_ee:proposalSubmissionChangeTrack={
    cntrTareWt:0,
    cntrNestedFldedHt:0,
    cntrExtLength:0,
    cntrExtwidth:0,
    cntrExtHeight:0,
    // maxLayersPerSu:0,
    // cntrPerLayer:0,
    // partsPerSu:0,
    maxCntrsPerSu:0,
    shippingUnitBase:'',
    shippingUnitSuffix:'',
    // shippingExtLen:0,
    // shippingExtWidth:0,
   // shippingExtHeight:0,
    // shippingUnitNestedFoldedht:0,
    shippingUnitReturnType:'',
    shiipingUnitTareWeight:0,
    cntrGrossWeight:0,
    shippingUnitGrossWeight:0,

  }

  sugrosswtInvalid=false;
  suGrossWtInvalidReason:string=null;

  reasonForNotSubmitting='';

  cntrgrosswtInvalid=false;
  cntrgrosswtInvalidReason:string=null;

  partDimensionsInvalid=false;
  partDimensionsInvalidReason:string=null;
  partDimensionsInvalid_ee=false;
  partDimensionInvalidReason_ee:string=null;

  tempCntrCompWeight=0;
  tempSUCompWeight=0;
  no_of_container=0;

  sugrosswtInvalid_ee=false;
  suGrossWtInvalidReason_ee:string=null;

  reasonForNotSubmitting_ee='';

  cntrgrosswtInvalid_ee=false;
  cntrgrosswtInvalidReason_ee:string=null;

  tempCntrCompWeight_ee=0;
  tempSUCompWeight_ee=0;
  no_of_container_ee=0;

  revisionHistoryForPost_ee:RevHistFromAPi={
    seqno:null,
    userid:'',
    usrname:'',
    email:'',
    id_proposal:0,
    proposalstatuscurr:'',
    comments:'',
    apprvractn:'',
    evnttime:''
  }

  loaderimgee=false;

  expendable_wgts_InValid = false;
  expendable_wgts_InValidReason: string = null;
  expendable_wgts_InValid_ee = false;
  expendable_wgts_InValidReason_ee: string = null;

  cntr_comp_wt = 0;
  shp_comp_wt = 0;
  temp_shp_compwt = 0;
  cntr_comp_wt_ee = 0;
  shp_comp_wt_ee = 0;
  temp_shp_compwt_ee = 0;
   sutarewtInvalid:boolean=false;
  sutarewtInvalidReason:string="";
  sutarewtInvalid_ee:boolean=false;
  sutarewtInvalidReason_ee:string="";

  suExtHgtInvalid:boolean=false;
  suExtHgtInvalidReason:string="";
  suExtHgtInvalid_ee:boolean=false;
  suExtHgtInvalidReason_ee:string="";

  suNstFldHgtInvalid:boolean=false;
  suNstFldHgtInvalidReason:string="";
  suNstFldHgtInvalid_ee:boolean=false;
  suNstFldHgtInvalidReason_ee:string="";

  notificationTextSuHgt:string="";
  notificationTextSuNstFldHgt:string="";
  notificationTextSuHgt_ee:string="";
  notificationTextSuNstFldHgt_ee:string="";

  updateCntrDetsReturnable: boolean = false;
  updateCntrDetsReturnableReason: string = "";

  /* Added fr Supplier Profile Validation*/
  inSubmitFlowForSupplierProfileValidation:boolean=false;
  canProceedWithPropSubmission:boolean=true;// Field to enable dialog that will block user from submitting proposal if mandatry suplr details are not present
  showSupplierDetailsEmptyWarning:boolean=false; //field to show warnings in dialog that will list all non mandatory suplr profile details that are missing

  supcontactemailInvalid:boolean=false;
  supcontactemailInvalidReason=""
  defaultcurrInvalid:boolean=false;
  defaultcuuInvalidReason="";
  supcontactphoneInvalid:boolean=false;
  supcontactphoneInvalidReason="";
  supcontactfunctnInvalid:boolean=false;
  supcontactfunctnInvalidReason="";
  supcontactnamefirstInvalid:boolean=false;
  supcontactnamefirstInvalidReason=""; 
  supcontactnameInvalid:boolean=false;
  supcontactnameInvalidReason="";
  supstateprovInvalid:boolean=false;
  supstateprovInvalidReason="";
  supcountrycodeInvalid:boolean=false;
  supcountrycodeInvalidReason="";
  supfullnameInvalid:boolean=false;
  supfullnameInvalidReason="";
  supaddress1Invalid:boolean=false;
  supaddress1InvalidReason="";
  supcityInvalid:boolean=false;
  supcityInvalidReason="";
  supgsdbcodeInvalid:boolean=false;
  supgsdbcodeInvalidReason="";
  supFaxInvalid:boolean=false;
  supFaxInvalidReason="";
   /* Added fr Supplier Profile Validation*/

  constructor(private transactionService:TransactionService,private packagingproposal:PackagingproposalComponent, private partsandattri:EepartsandatributesComponent,private router:Router,   private imageService: ImageService,   private messageService:MessageService,private packagingprocess:PackagingprocessComponent,private date:DatePipe) { }

  ngOnInit(): void {
    this.region_ee=this.transactionService.getregion();
    this.curr_cdsid_ee=this.transactionService.getcdsid();
    this.current_region_code_ee=this.transactionService.getregioncode(this.region_ee);
    this.selectedpart_submitter_image_ee = this.transactionService.getpartno_packpro(this.region_ee);
    this.part_image_ee='';
    this.interior_image_ee='';
    this.exterior_image_ee='';
    this.label_image_ee='';
    this.shipping_image_ee='';
    this.baseurl_get_ee=environment.nfsBaseAPIUrl+"/downloadFile?fPath=";
    this.baseurl_get_sbupl_ee=environment.nfsBaseAPIUrl+"/downloadNonImgFile?fPath="
    this.imgsave_path_ee=environment.nfsBasePath;
    this.roleid_image_ee=this.transactionService.getcurrentrole(this.region_ee)


    this.reasonForNotSubmitting='';
    this.cntrgrosswtInvalid=false;
    this.sugrosswtInvalid=false;
    this.expendable_wgts_InValid = false;
    this.sutarewtInvalid=false;
    this.suExtHgtInvalid=false;
    this.suNstFldHgtInvalid=false;

    
    this.inSubmitFlowForSupplierProfileValidation=false;
    this.canProceedWithPropSubmission=true;
   // this.showEnterSupplierDetailsBeforeSubmit=false;
    this.supplierProfileInit();

    this.reasonForNotSubmitting_ee='';
    this.cntrgrosswtInvalid_ee=false;
    this.sugrosswtInvalid_ee=false;
    this.expendable_wgts_InValid_ee = false;
    this.sutarewtInvalid_ee=false;
    this.suExtHgtInvalid_ee=false;
    this.suNstFldHgtInvalid_ee=false;

    this.transactionService.user_details(this.curr_cdsid_ee).subscribe(user_det=>{
      console.log(user_det);
      this.user_details_ee=user_det;
      console.log(this.user_details_ee);

    })

    if (this.selectedpart_submitter_image_ee != null && this.selectedpart_submitter_image_ee.simplifiedpartno!='-' && this.selectedpart_submitter_image_ee.proposalid!=0 )
    {
    this.transactionService.get_draft_Tab1_data(this.selectedpart_submitter_image_ee.proposalid).subscribe(primary_details=>{
      console.log(primary_details);
      console.log(primary_details.isstampblank)


      if(primary_details.isstampblank.toLowerCase()=='yes')
      {
        this.upload_sbupl_display_ee=true;


        this.transactionService.loadtab2detailsifproposalexistalready_ee(this.selectedpart_submitter_image_ee.proposalid).subscribe(tab2_details=>{
          console.log(tab2_details)
          console.log(tab2_details)


          if(tab2_details.cd_ownrshp_strat!=null && tab2_details.cd_ownrshp_strat!=0 && tab2_details.cntr_ownrshp_strat_code!=null && tab2_details.cntr_ownrshp_strat_code!='')
              {

                this.transactionService.getcosdetails(tab2_details.cntr_ownrshp_strat_code,tab2_details.cd_ownrshp_strat).subscribe(cosdetails_tab2=>{
                  console.log(cosdetails_tab2);
                  if(cosdetails_tab2.sub_press_load_doc.toLowerCase()=='required')
                  {
                    this.upload_sbupl_req_ee=true;
                  }


                  else
                  {
                    this.upload_sbupl_req_ee=false;
                  }

                })

              }


              else
              {
                this.upload_sbupl_req_ee=false;
              }
        })
      }


      else{
        this.upload_sbupl_display_ee=false;
        this.upload_sbupl_req_ee=false;
      }


      console.log(this.upload_sbupl_display_ee);
      console.log(this.upload_sbupl_req_ee);
    })

    this.tab4alldetails_init_ee();
    this.submit_propsal_init_ee();
    this.submit_propsal_init();

      // this.packagingproposal.savedtab=this.transactionService.getlastsavedtab(this.region);
      /*if(this.packagingproposal.savedtab==1)
        {
          for(let i of this.packagingproposal.tabpackagingproposalmenuitems.values())
          {
                console.log(i);
                if(i.label!='COS, Container & Shipping Unit')
                {
                    this.packagingproposal.flag1=true;
                }
                else{
                    this.packagingproposal.flag1=false;
                    break;
                }
          }

          if(this.packagingproposal.flag1)
          {
              this.packagingproposal.tabpackagingproposalmenuitems.push({label: 'COS, Container & Shipping Unit', routerLink: this.router.url, icon: 'fas fa-person-booth'});
          }
        }

        else if(this.packagingproposal.savedtab==2 )
        {
          for(let i of this.packagingproposal.tabpackagingproposalmenuitems.values())
          {
                console.log(i);
                if(i.label!='Component & Material')
                {
                    this.packagingproposal.flag2=true;
                }
                else{
                    this.packagingproposal.flag2=false;
                    break;
                }
          }

          if(this.packagingproposal.flag2)
          {
              this.packagingproposal.tabpackagingproposalmenuitems.push({label: 'COS, Container & Shipping Unit', routerLink:'/northamerica/proposalcreation/packproposaltabtwo', icon: 'fas fa-person-booth'});
              this.packagingproposal.tabpackagingproposalmenuitems.push({label:'Component & Material',routerLink:this.router.url, icon: 'fas fa-person-booth'});
          }
        }

        else if(this.packagingproposal.savedtab==3 )
        {
          for(let i of this.packagingproposal.tabpackagingproposalmenuitems.values())
          {
                console.log(i);
                if(i.label!='Images/Photos & Comments ')
                {
                    this.packagingproposal.flag3=true;
                }
                else{
                    this.packagingproposal.flag3=false;
                    break;
                }
          }

          if(this.packagingproposal.flag3)
          {
            this.packagingproposal.tabpackagingproposalmenuitems.push({label: 'COS, Container & Shipping Unit', routerLink:'/northamerica/proposalcreation/packproposaltabtwo', icon: 'fas fa-person-booth'});
            this.packagingproposal.tabpackagingproposalmenuitems.push({label:'Component & Material',routerLink:'/northamerica/proposalcreation/packproposaltabthree', icon: 'fas fa-person-booth'});
            this.packagingproposal.tabpackagingproposalmenuitems.push({label:'Images/Photos & Comments',routerLink:'/northamerica/proposalcreation/packproposaltabfour', icon: 'fas fa-person-booth'});
          }
        }*/









        if(this.roleid_image_ee==null||(this.roleid_image_ee != 1 && this.roleid_image_ee != 2))
        {


          this.noroleselectedDialog_ee=true;
          this.norolecontent_ee='No role selected '
        }
        if(this.roleid_image_ee==1)
        {
        this.supp_code_image_ee = this.transactionService.get_supplier(this.region_ee);
        console.log(this.supp_code_image_ee);
        }
        else if( this.roleid_image_ee==2)
        {
          this.designsource_code_image_ee = this.transactionService.get_designsource(this.region_ee);
          console.log(this.designsource_code_image_ee)
        }







        if(this.roleid_image_ee==1)
        {

        if (this.supp_code_image_ee != null && this.selectedpart_submitter_image_ee != null && this.selectedpart_submitter_image_ee.simplifiedpartno!='-' && this.selectedpart_submitter_image_ee.proposalid!=0 ) {

          this.id_proposal_image_ee=this.selectedpart_submitter_image_ee.proposalid;
          this.tab4alldetails_init_ee();
          this.tab4alldetails_ee.id_proposal=this.id_proposal_image_ee;
          console.log(this.tab4alldetails_ee.id_proposal);


        // this.InitgetImagePart(this.tab4alldetails.id_proposal.toString());
         // this.InitgetInteriorContainer(this.tab4alldetails.id_proposal.toString());
          // this.InitgetExtrCntnrPhotoImage(this.tab4alldetails.id_proposal.toString());
          // this.InitgetLabelMetalIDPhotoImage(this.tab4alldetails.id_proposal.toString());
          // this.InitgetShippingUnitPhotoImage(this.tab4alldetails.id_proposal.toString());


          if(this.selectedpart_submitter_image_ee.proposallastsaved_ee==3)
          {
          this.transactionService.loadtab4details(this.selectedpart_submitter_image_ee.proposalid).subscribe(tab4_api=>{


            console.log(tab4_api)
            this.tab4alldetails_ee.f_partphoto=tab4_api.f_partphoto;
            this.tab4alldetails_ee.f_metelidplatephoto=tab4_api.f_metelidplatephoto;


            this.get_part_image_ee();
            this.get_label_image_ee();


          })
        }

        if(this.selectedpart_submitter_image_ee.proposallastsaved_ee>=4)
        {
          console.log('this.selectedpart_submitter_contandcos',this.selectedpart_submitter_image_ee.proposalid);
        this.loadtab4detlsifexist_ee();
        }

        }

        else if (this.supp_code_image_ee == null) {
          this.noSupDialog_image_ee = true;
          this.content_supp_image_ee = 'Please select supplier';
        }


        else if (this.supp_code_image_ee != null && (this.selectedpart_submitter_image_ee == null || this.selectedpart_submitter_image_ee.simplifiedpartno=='-' || this.selectedpart_submitter_image_ee.proposalid==0)) {
          this.noPrtDialog_image_ee = true;
          this.content_selectedprt_image_ee = 'Please select part from submitter dashboard';

        }
        }
        else if (this.roleid_image_ee==2)
        {



        if (this.designsource_code_image_ee != null && this.selectedpart_submitter_image_ee != null && this.selectedpart_submitter_image_ee.simplifiedpartno!='-' && this.selectedpart_submitter_image_ee.proposalid!=0 ) {

          this.id_proposal_image_ee=this.selectedpart_submitter_image_ee.proposalid;
          this.tab4alldetails_init_ee();
          this.tab4alldetails_ee.id_proposal=this.id_proposal_image_ee;


          if(this.selectedpart_submitter_image_ee.proposallastsaved_ee==3)
          {
          this.transactionService.loadtab4details(this.selectedpart_submitter_image_ee.proposalid).subscribe(tab4_api=>{


            console.log(tab4_api)
            this.tab4alldetails_ee.f_partphoto=tab4_api.f_partphoto;
            this.tab4alldetails_ee.f_metelidplatephoto=tab4_api.f_metelidplatephoto;


            this.get_part_image_ee();
            this.get_label_image_ee();


          })
        }



         // this.InitgetImagePart(this.tab4alldetails.id_proposal.toString());
          // this.InitgetInteriorContainer(this.tab4alldetails.id_proposal.toString());
          // this.InitgetExtrCntnrPhotoImage(this.tab4alldetails.id_proposal.toString());
          // this.InitgetLabelMetalIDPhotoImage(this.tab4alldetails.id_proposal.toString());
          // this.InitgetShippingUnitPhotoImage(this.tab4alldetails.id_proposal.toString());


          if(this.selectedpart_submitter_image_ee.proposallastsaved_ee>=4)
          {
            console.log('this.selectedpart_submitter_image',this.selectedpart_submitter_image_ee.proposalid);
          this.loadtab4detlsifexist_ee();


          }


        }

        else if (this.designsource_code_image_ee == null) {
          this.noDesignsourceDialog_image_ee = true;
          this.content_Designsource_image_ee = 'Please select Design source';
        }


        else if (this.designsource_code_image_ee != null && (this.selectedpart_submitter_image_ee == null || this.selectedpart_submitter_image_ee.simplifiedpartno=='-' || this.selectedpart_submitter_image_ee.proposalid==0)) {
          this.noPrtDialog_image_ee = true;
          this.content_selectedprt_image_ee = 'Please select part from submitter dashboard';
        }

        }

      }
      else
      {
        if(this.selectedpart_submitter_image_ee == null || this.selectedpart_submitter_image_ee.simplifiedpartno=='-' || this.selectedpart_submitter_image_ee.proposalid==0)
        {
          this.noPrtDialog_image_ee = true;
          this.content_selectedprt_image_ee = 'Please select part from submitter dashboard';
        }
      }
    }



  ok_noroledialog_ee()
  {
    let url
    url=this.transactionService.getregionurlvalue(this.region_ee);
    url+='packagingprocessdashboard'
    this.router.navigate([url]);

  }




    // if no supplier present

    ok_nosupdialog_ee() {
      console.log(this.region_ee)
      let reg_url: string
      reg_url = this.transactionService.getregionurlvalue(this.region_ee);
      reg_url += 'packagingprocess/packsupplieradmin/packsupplier';

      this.router.navigate([reg_url]);

    }


    ok_noDesignsourcedialog_ee() {
      console.log(this.region_ee)
      let reg_url: string
      reg_url = this.transactionService.getregionurlvalue(this.region_ee);
      reg_url += 'packagingprocess/ppackdesignsourceadmin/packdesignsource';

      this.router.navigate([reg_url]);

    }



    go_to_supp_main_page_ee() {
      let reg_supp_url: string;
      reg_supp_url = this.transactionService.getregionurlvalue(this.region_ee);
      if(this.roleid_image_ee==1)
      {
        reg_supp_url += 'packagingprocess/packsupplieradmin/packsupplier';
      }
      else if ( this.roleid_image_ee==2)
      {
        reg_supp_url += 'packagingprocess/packdesignsourceadmin/packdesignsource';
      }
      this.router.navigate([reg_supp_url]);

    }

    // if no part present
    ok_noprtdialog_ee() {
      console.log(this.region_ee)

      let reg_url_prt: string
      reg_url_prt = this.transactionService.getregionurlvalue(this.region_ee);
      this.name_ee=this.transactionService.getregionNameInSmallLetters();
      const proposalFor=sessionStorage.getItem('proposaltypefor');
      if(proposalFor!=null && proposalFor != 'null' && proposalFor.toLowerCase()=='migration')
      {
        if(this.roleid_image_ee==1)
        {
          this.router.navigate(['/'+this.name_ee+'/packagingprocess/packsupplieradmin/migrationsubmitter'])
        }
        else if(this.roleid_image_ee==2)
        {
          this.router.navigate(['/'+this.name_ee+'/packagingprocess/packdesignsourceadmin/migrationsubmitter'])
        }
      }
      else // if(this.selectedpart_submitter.propsl_type!=null && this.selectedpart_submitter.propsl_type.toLowerCase()=="normal")
      {
      if(this.roleid_image_ee==1)
      {
      reg_url_prt += 'packagingprocess/packsupplieradmin/submiterdashboard';
      }
      else if ( this.roleid_image_ee==2)
      {
        reg_url_prt += 'packagingprocess/packdesignsourceadmin/submiterdashboard';
      }

      this.router.navigate([reg_url_prt]);
    }

    }


    tab4alldetails_init_ee()
    {
      this.tab4alldetails_ee={
        id_proposal:0,
        f_extcntrdsgnphoto:'',
        f_intcntrdsgnphoto:'',
        f_metelidplatephoto:'',
        f_partphoto:'',
        f_shpunitphoto:'',
        gen_propslcommnts:'',
        int_cntrdesgncommnts:'',
        createmgcyexpndpckgprop:'',
        agrmntexpndpackreqmnts:'',
        subpresloaddoc:'',
      };



    }

    revisionHistoryForPostInit_ee()
    {
      this.revisionHistoryForPost_ee={
        seqno:null,
        userid:'',
        usrname:'',
        email:'',
        id_proposal:0,
        proposalstatuscurr:'',
        comments:'',
        apprvractn:'',
        evnttime:''
      }
    }



    supplierProfileInit()
    {
      this.supcontactemailInvalid=false;
      this.supcontactemailInvalidReason=""
      this.defaultcurrInvalid=false;
      this.defaultcuuInvalidReason="";
      this.supcontactphoneInvalid=false;
      this.supcontactphoneInvalidReason="";
      this.supcontactfunctnInvalid=false;
      this.supcontactfunctnInvalidReason="";
      this.supcontactnamefirstInvalid=false;
      this.supcontactnamefirstInvalidReason=""; 
      this.supcontactnameInvalid=false;
      this.supcontactnameInvalidReason="";
      this.supstateprovInvalid=false;
      this.supstateprovInvalidReason="";
      this.supcountrycodeInvalid=false;
      this.supcountrycodeInvalidReason="";
      this.supfullnameInvalid=false;
      this.supfullnameInvalidReason="";
      this.supaddress1Invalid=false;
      this.supaddress1InvalidReason="";
      this.supcityInvalid=false;
      this.supcityInvalidReason="";
      this.supgsdbcodeInvalid=false;
      this.supgsdbcodeInvalidReason="";
      this.supFaxInvalid=false;
      this.supFaxInvalidReason="";
    }




          loadtab4detlsifexist_ee()
          {
            // api to get tab 4 details
            // call each image
          // by assigningurl to image


          this.transactionService.loadtab4details_ee(this.selectedpart_submitter_image_ee.proposalid).subscribe(tab4_api=>{


            // var output;
           this.tab4alldetails_ee=tab4_api;


           console.log(tab4_api);
           console.log(this.tab4alldetails_ee);
           this.intr_dsgn_comments_ee=tab4_api.int_cntrdesgncommnts;
           this.general_comments_ee=tab4_api.gen_propslcommnts;
           this.crt_edt_empro_ee=tab4_api.createmgcyexpndpckgprop;
           this.agre_for_empro_ee=tab4_api.agrmntexpndpackreqmnts;

             this.get_part_image_ee();
             this.get_exterior_image_ee();
             this.get_interior_image_ee();
             this.get_label_image_ee();
             this.get_shipping_image_ee();
             this.get_sbupl_ee();



             if(this.agre_for_empro_ee!=null && this.agre_for_empro_ee!='' && this.agre_for_empro_ee.toLowerCase()=='yes')
             {
               this.enable_submit_proposal_button=true;
             }


             else if(this.agre_for_empro_ee!=null && this.agre_for_empro_ee!='' && this.agre_for_empro_ee.toLowerCase()=='no')
             {
               this.enable_submit_proposal_button=false;
             }

          })



          }


          get_part_image_ee()
          {



            if(this.tab4alldetails_ee.f_partphoto!='' && this.tab4alldetails_ee.f_partphoto!=null)
            {
              let url=this.baseurl_get_ee+this.tab4alldetails_ee.f_partphoto;
              this.transactionService.getImageFile(url).subscribe((response: any) => {
                this.part_image_ee = URL.createObjectURL(response);
                 setTimeout(()=> {
                  let url = URL.createObjectURL(response);
                const img: any = document.querySelector('#part_image_ee');
                img.src = url;
                }, 500);
              });
            }
            else{
              this.part_image_ee='';
            }
          }

          get_interior_image_ee()
          {


            if(this.tab4alldetails_ee.f_intcntrdsgnphoto!='' && this.tab4alldetails_ee.f_intcntrdsgnphoto!=null)
            {
            let url =this.baseurl_get_ee+this.tab4alldetails_ee.f_intcntrdsgnphoto;
            this.transactionService.getImageFile(url).subscribe((response: any) => {
              this.interior_image_ee=URL.createObjectURL(response);
               setTimeout(()=> {
                  let url = URL.createObjectURL(response);
              const img: any = document.querySelector('#interior_image_ee');
              img.src = url;
                }, 500);
            });
            } else{
              this.interior_image_ee="";
            }

          }
          get_exterior_image_ee() {
            if(this.tab4alldetails_ee.f_extcntrdsgnphoto!="" && this.tab4alldetails_ee.f_extcntrdsgnphoto!=null) {
              let url = this.baseurl_get_ee+this.tab4alldetails_ee.f_extcntrdsgnphoto;
              this.transactionService.getImageFile(url).subscribe((response: any) => {
                this.exterior_image_ee= URL.createObjectURL(response);
                 setTimeout(()=> {
                  let url = URL.createObjectURL(response);
                const img: any = document.querySelector('#exterior_image_ee');
                img.src = url;
                }, 500);
              });
            } else {
              this.exterior_image_ee="";
            }

          }
          get_label_image_ee()
          {
            if(this.tab4alldetails_ee.f_metelidplatephoto!="" && this.tab4alldetails_ee.f_metelidplatephoto!=null) {
            let url = this.baseurl_get_ee+this.tab4alldetails_ee.f_metelidplatephoto;
            this.transactionService.getImageFile(url).subscribe((response: any) => {
            this.label_image_ee=URL.createObjectURL(response);
             setTimeout(()=> {
                  let url = URL.createObjectURL(response);
            const img: any = document.querySelector('#label_image_ee');
            img.src = url;
                }, 500);
            });
            } else{
              this.label_image_ee="";
            }


            console.log(this.label_image_ee);

          }
          get_shipping_image_ee()
          {
            if(this.tab4alldetails_ee.f_shpunitphoto!="" && this.tab4alldetails_ee.f_shpunitphoto!=null) {
            let url = this.baseurl_get_ee+this.tab4alldetails_ee.f_shpunitphoto;
            this.transactionService.getImageFile(url).subscribe((response: any) => {
                  this.shipping_image_ee= URL.createObjectURL(response);
                   setTimeout(()=> {
                  let url = URL.createObjectURL(response);
                  const img: any = document.querySelector('#shipping_image_ee');
                  img.src = url;
                }, 500);
            });
            } else{
              this.shipping_image_ee="";
            }

          }


          get_sbupl_ee()
          {


            if(this.tab4alldetails_ee.subpresloaddoc!='' && this.tab4alldetails_ee.subpresloaddoc!=null)
            {
              console.log(this.tab4alldetails_ee.subpresloaddoc)
              let sbu_arr=[];
              sbu_arr=this.tab4alldetails_ee.subpresloaddoc.split('/');
              let len:number
              len=sbu_arr.length;
              let url = this.baseurl_get_sbupl_ee+this.tab4alldetails_ee.subpresloaddoc;
              this.transactionService.getPdfFile(url).subscribe((res) => {
                this.sbu_file_path_ee = URL.createObjectURL(res);
              });
            this.sbu_file_ee=sbu_arr[len-1];
            this.sbupl_uploaded_ee=true;
            }
            else{
              this.sbu_file_ee='';
              this.sbu_file_path_ee='';
              this.sbupl_uploaded_ee=false;
            }

          }




          browse_part_image_ee()
          {


            this.PartPhoto_image_upload_dialog_ee=true;



          }

          browse_interior_image_ee()
          {

            this.InteriorContainer_upload_dialog_ee=true;


          }
          browse_exterior_image_ee()
          {

            this.ExteriorContainer_upload_dialog_ee=true;
          }
          browse_label_image_ee()
          {
            this.Label_MetalID_upload_dialog_ee=true;

          }


          browse_shipping_image_ee()
          {


            this.ShippingUnit_upload_dialog_ee=true;

          }


          browse_sbu_pl_ee()
          {


            this.sbupl_upload_dialog_ee=true;

          }


          manadate_image_disable_ee():boolean
          {


            if(this.tab4alldetails_ee.f_extcntrdsgnphoto==null|| this.tab4alldetails_ee.f_extcntrdsgnphoto==''||
            this.tab4alldetails_ee.f_intcntrdsgnphoto==null|| this.tab4alldetails_ee.f_intcntrdsgnphoto==''||
            this.tab4alldetails_ee.f_partphoto==null|| this.tab4alldetails_ee.f_partphoto==''||
            this.tab4alldetails_ee.f_metelidplatephoto==null|| this.tab4alldetails_ee.f_metelidplatephoto==''||
            this.tab4alldetails_ee.f_shpunitphoto==null|| this.tab4alldetails_ee.f_shpunitphoto=='' || this.agre_for_empro_ee==null || this.agre_for_empro_ee=='')
            {
              return true;
            }

            else{
              return false;
            }

          }





          on_accept_exp_change()
          {
            console.log(this.agre_for_empro_ee);
            if(this.agre_for_empro_ee.toLowerCase()=='yes')
            {
              this.enable_submit_proposal_button=true;
            }
            else
            {
              this.enable_submit_proposal_button=false;
            }
          }




  store_primary_part_exist_image_ee()
  {

    if(this.current_region_code_ee=='NA')
    {

      sessionStorage.setItem('napackpropart', JSON.stringify(this.selectedpart_submitter_image_ee));
    }


    if(this.current_region_code_ee=='EU')
    {
      sessionStorage.setItem('eupackpropart', JSON.stringify(this.selectedpart_submitter_image_ee));
    }



    if(this.current_region_code_ee=='AP')
    {
      sessionStorage.setItem('appackpropart', JSON.stringify(this.selectedpart_submitter_image_ee));
    }



    if(this.current_region_code_ee=='SA')
    {
      sessionStorage.setItem('sapackpropart', JSON.stringify(this.selectedpart_submitter_image_ee));
    }
  }


  save_tab4_ee()

{

this.name_ee=this.transactionService.getregionNameInSmallLetters();
  console.log(this.tab4alldetails_ee);

  // service to call tab4 api
// on success
// store last saved tab

          this.tab4alldetails_ee.int_cntrdesgncommnts=this.intr_dsgn_comments_ee;
          this.tab4alldetails_ee.gen_propslcommnts= this.general_comments_ee;
           this.tab4alldetails_ee.createmgcyexpndpckgprop=this.crt_edt_empro_ee;
           this.tab4alldetails_ee.agrmntexpndpackreqmnts=this.agre_for_empro_ee;
this.transactionService.savetab4details_ee(this.tab4alldetails_ee).subscribe(response=>
  {
      let msgs:any[];
      if(response=='Success')
      {
        this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary: 'success', detail: 'EE-Image details saved successfully'});
        const check=false;

  this.region_ee=this.transactionService.getregion();
  const currentab=4;
  if(currentab>this.selectedpart_submitter_image_ee.proposallastsaved_ee)
                {

                this.selectedpart_submitter_image_ee.proposallastsaved_ee=4;
                this.store_primary_part_exist_image_ee();
                console.log(this.selectedpart_submitter_image_ee);
                }

                if(this.selectedpart_submitter_image_ee.propsl_type!=null && this.selectedpart_submitter_image_ee.propsl_type.toLowerCase()=='migration')
            {
              if(this.roleid_image_ee==1)
              {
                this.router.navigate(['/'+this.name_ee+'/packagingprocess/packsupplieradmin/migrationsubmitter'])
              }
              else if(this.roleid_image_ee==2)
              {
                this.router.navigate(['/'+this.name_ee+'/packagingprocess/packdesignsourceadmin/migrationsubmitter'])
              }
            }
            else // if(this.selectedpart_submitter.propsl_type!=null && this.selectedpart_submitter.propsl_type.toLowerCase()=="normal")
            {
                if(this.roleid_image_ee==1)
                {
                  this.router.navigate(['/'+this.name_ee+'/packagingprocess/packsupplieradmin/submiterdashboard'])
                }
                else if(this.roleid_image_ee==2)
                {
                  this.router.navigate(['/'+this.name_ee+'/packagingprocess/packdesignsourceadmin/submiterdashboard'])
                }
              }

              }

              else{


                this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'EE-Image details is not saved! try again'});

              }

              })


}



submit_propsal_init()
{






  this.store_proposal_values={

    gppuserid:'',
        id_proposal:0,
        corr_protc:'',
        // lst_saved_tab:0,
        seasnlreq:'',
        shelf_life:0,
        sp_part_no:'',
       // submit_status:"",
        temp_sensitivity:'',
        temp_stor_spec:'',
        time_stamp:'',
      //  plantgsdbcode:"",
        isstampblank:'',



        cntr_ret_type:'',
      used_carry_ovr_pck:'',
      cntr_base:'',
      cntr_suff:'',
      cntr_desc:'',
      parts_per_cntr:0,
      cntr_ext_len:0,
      cntr_width:0,
      cntr_height:0,
      cntr_nsted_fld_height:0,
      cntr_material:'',
      cntr_tar_weight:0,
      cntr_per_layer:0,
      max_layrs_shp_unt:0,
      empty_trays_cntr_layrs:0,
      max_cntr_per_shp_unt:0,
      cntr_rck_colr:'',
      shp_unt_base:'',
      shp_unt_suff:'',
      shp_unt_desc:'',
      part_per_shp_unit:0,
      shp_unt_ret_type:'',
      shp_unit_ext_len:0,
      shp_unt_ext_width:0,
      shp_unt_ext_height:0,
      shp_unt_nestd_fld_height:0,
      card_call:'',
      cntr_grp_id:'',
      cntr_ownrshp_strat_code:'',
      cd_cntr:0,
      cd_ownrshp_strat:0,
      pkgsignoff:'',
      subpresloaddoc:'',


      cntrOwner:'',


      chngdbase:'',


      bct:0,
      ect:0,











         cntr_shp_unit:'',
          shp_unt_tar_wt:0.000,
          shp_unt_gross_wt:0.000,
          cntr_gross_wt:0.000,
          ld_unload_inst:'',
          cntr_automtn_intf:'',
          transp_tested:'',
          tst_reprt_num:'',
          mot:'',
          dangrs_good:'',
          dangrs_good_ship_detls:'',
          rel_level:'',


          f_extcntrdsgnphoto:'',
        f_intcntrdsgnphoto:'',
        f_metelidplatephoto:'',
        f_partphoto:'',
        f_shpunitphoto:'',
        gen_propslcommnts:'',
        int_cntrdesgncommnts:'',
        createmgcyexpndpckgprop:'',
        agrmntexpndpackreqmnts:'',








    proposal_status_curr:'',
    proposal_status_prev:'',
    apprvr_cdsid:'',
    apprvr_asgn_date:this.date.transform(new Date(),"yyyy-MM-dd"),
    revision_hstry:'',
    apprvr_email_sent:'',
    manualenteredconainerbase:' ',
    pallettype:'',
    woodispmcmplnt:' ',
    offstmpmartprsnt:' ',
    supgsdbcode:'',
    supfullname:'',
    supaddress1:'',
    supcity:'',
    supstateprov:'',
    supcountrycode:'',
    supcontactnamefirst:'',
    supcontactname:'',
    supcontactfunctn:' ',
    supcontactemail:' ',
    supcontactphone:' ',
    supcontactfax:' ',
    submitdate:this.date.transform(new Date(),"yyyy-MM-dd"),
    iscortonimcspec:' ',
    cortonburststrnth:0,
    imcwtdisc:' ',
    blanksperstack:0,
    stacksperpallet:0,
    palletpintyp:'',


    isTstPckReqstd: 'No',
    isTstPckRecvd: 'NV',
    isTstPckEvaltd: 'NV',


    pressline: '',
    stmpdstackmthd: '',
    stmpwarehseloc: '',
    defaultcurr: '',
    cmmsuploadstat: '',
    hotstampcntrid: '',
    hotstampcntrcolor: '',
    hotstamppalletboxcolor: '',
    hotstamppalletbaselidcolor: '',
    hotstamidplatecolor: '',
    hotstampcolor: '',
    hotstampfontcolor: '',
    hotstampcomments: '',


    propsl_type:'',
    effectdate:this.date.transform(new Date(),"yyyy-MM-dd"),


    offstprtaprvd:'',
	is_bailmnt_prt_prsnt:''








  }

}


submit_propsal_init_ee()
{






  this.store_proposal_values_ee={

    gppuserid:'',
        id_proposal:0,
        corr_protc:'',
        // lst_saved_tab:0,
        seasnlreq:'',
        shelf_life:0,
        sp_part_no:'',
       // submit_status:"",
        temp_sensitivity:'',
        temp_stor_spec:'',
        time_stamp:'',
      //  plantgsdbcode:"",
        isstampblank:'',



        cntr_ret_type:'',
      used_carry_ovr_pck:'',
      cntr_base:'',
      cntr_suff:'',
      cntr_desc:'',
      parts_per_cntr:0,
      cntr_ext_len:0,
      cntr_width:0,
      cntr_height:0,
      cntr_nsted_fld_height:0,
      cntr_material:'',
      cntr_tar_weight:0,
      cntr_per_layer:0,
      max_layrs_shp_unt:0,
      empty_trays_cntr_layrs:0,
      max_cntr_per_shp_unt:0,
      cntr_rck_colr:'',
      shp_unt_base:'',
      shp_unt_suff:'',
      shp_unt_desc:'',
      part_per_shp_unit:0,
      shp_unt_ret_type:'',
      shp_unit_ext_len:0,
      shp_unt_ext_width:0,
      shp_unt_ext_height:0,
      shp_unt_nestd_fld_height:0,
      card_call:'',
      cntr_grp_id:'',
      cntr_ownrshp_strat_code:'',
      cd_cntr:0,
      cd_ownrshp_strat:0,
      pkgsignoff:'',
      subpresloaddoc:'',

      bct:0,
      ect:0,

      cntrOwner:'',


      chngdbase:'',













         cntr_shp_unit:'',
          shp_unt_tar_wt:0.000,
          shp_unt_gross_wt:0.000,
          cntr_gross_wt:0.000,
          ld_unload_inst:'',
          cntr_automtn_intf:'',
          transp_tested:'',
          tst_reprt_num:'',
          mot:'',
          dangrs_good:'',
          dangrs_good_ship_detls:'',
          rel_level:'',


          f_extcntrdsgnphoto:'',
        f_intcntrdsgnphoto:'',
        f_metelidplatephoto:'',
        f_partphoto:'',
        f_shpunitphoto:'',
        gen_propslcommnts:'',
        int_cntrdesgncommnts:'',
        createmgcyexpndpckgprop:'',
        agrmntexpndpackreqmnts:'',








    proposal_status_curr:'',
    proposal_status_prev:'',
    apprvr_cdsid:'',
    apprvr_asgn_date:this.date.transform(new Date(),"yyyy-MM-dd"),
    revision_hstry:'',
    apprvr_email_sent:'',
    manualenteredconainerbase:' ',
    pallettype:'',
    woodispmcmplnt:' ',
    offstmpmartprsnt:' ',
    supgsdbcode:'',
    supfullname:'',
    supaddress1:'',
    supcity:'',
    supstateprov:'',
    supcountrycode:'',
    supcontactnamefirst:'',
    supcontactname:'',
    supcontactfunctn:' ',
    supcontactemail:' ',
    supcontactphone:' ',
    supcontactfax:' ',
    submitdate:this.date.transform(new Date(),"yyyy-MM-dd"),
    iscortonimcspec:' ',
    cortonburststrnth:0,
    imcwtdisc:' ',
    blanksperstack:0,
    stacksperpallet:0,
    palletpintyp:'',


    isTstPckReqstd: 'No',
    isTstPckRecvd: 'NV',
    isTstPckEvaltd: 'NV',


    pressline: '',
    stmpdstackmthd: '',
    stmpwarehseloc: '',
    defaultcurr: '',
    cmmsuploadstat: '',
    hotstampcntrid: '',
    hotstampcntrcolor: '',
    hotstamppalletboxcolor: '',
    hotstamppalletbaselidcolor: '',
    hotstamidplatecolor: '',
    hotstampcolor: '',
    hotstampfontcolor: '',
    hotstampcomments: ''








  }

}

goToSupProfilePage()
{
  let reg_url: string
  reg_url = this.transactionService.getregionurlvalue(this.region_ee);
  reg_url += 'packagingprocess/packsupplieradmin/editsupplierprofile';
  this.router.navigate([reg_url])
}

submitAfterSupplierProfileValidation()
{
  this.inSubmitFlowForSupplierProfileValidation=true;
  this.transactionService.getSupInfo(this.selectedpart_submitter_image_ee.supplier_code).subscribe(supDetls=>{
    console.log("SupplierInfo",supDetls);
    if(supDetls!=null)
    {  
      if(supDetls.contactemail==null || supDetls.contactemail.trim()=='')
        {
          this.supcontactemailInvalid=true;
          this.supcontactemailInvalidReason="Contact Email"
        }
        if(supDetls.defaultcurr==null || supDetls.defaultcurr.trim()=='')
        {
          this.defaultcurrInvalid=true;
          this.defaultcuuInvalidReason="Default Currency";
        }
        if(supDetls.contactphone==null || supDetls.contactphone.trim()=='')
        {
          this.supcontactphoneInvalid=true;
          this.supcontactphoneInvalidReason="Contact Phone";
        }
        if(supDetls.contactfunctn==null || supDetls.contactfunctn.trim()=='')
        {
          this.supcontactfunctnInvalid=true;
          this.supcontactfunctnInvalidReason="Contact Function";
        }
        if(supDetls.contactnamefirst==null || supDetls.contactnamefirst.trim()=='')
        {
          this.supcontactnamefirstInvalid=true;
          this.supcontactnamefirstInvalidReason="Name Prefix"; 
        }
        if(supDetls.contactname==null || supDetls.contactname.trim()=='')
        {
          this.supcontactnameInvalid=true;
          this.supcontactnameInvalidReason="Contact Name";
  
        }
        if(supDetls.stateprovince==null || supDetls.stateprovince.trim()=='')
        {
          this.supstateprovInvalid=true;
          this.supstateprovInvalidReason="State Province";
        }
        if(supDetls.countrycode==null || supDetls.countrycode.trim()=='')
        {
          this.supcountrycodeInvalid=true;
          this.supcountrycodeInvalidReason="Country Code";
        }
        if(supDetls.fullname==null || supDetls.fullname.trim()=='')
        {
          this.supfullnameInvalid=true;
          this.supfullnameInvalidReason="Name";
        }
        if(supDetls.address1==null || supDetls.address1.trim()=='')
        {
          this.supaddress1Invalid=true;
          this.supaddress1InvalidReason="Address";
        }
        if(supDetls.city==null || supDetls.city.trim()=='')
        {
          this.supcityInvalid=true;
          this.supcityInvalidReason="City";
        }
        if(supDetls.suppliergsdbcode==null || supDetls.suppliergsdbcode.trim()=='' )
        {
          this.supgsdbcodeInvalid=true;
          this.supgsdbcodeInvalidReason="GSDB Code";
        }
        if(supDetls.contactfax==null || supDetls.contactfax.trim()=='' )
          {
            this.supFaxInvalid=true;
            this.supFaxInvalidReason="Fax";
          }

      if(!this.supcontactnameInvalid && !this.supfullnameInvalid && !this.supcontactphoneInvalid)
      {

        // If mandatory fields like contactname , name and phone number is valid, show warning in dialog if presnt but allow them to proceed
        this.canProceedWithPropSubmission=true;

        if(this.supstateprovInvalid || this.supcontactnamefirstInvalid || this.supcontactfunctnInvalid || this.defaultcurrInvalid || this.supcontactemailInvalid || 
          this.supgsdbcodeInvalid || this.supcityInvalid || this.supaddress1Invalid || this.supcountrycodeInvalid || this.supFaxInvalid)
          
        {
          this.showSupplierDetailsEmptyWarning=true;
        }
        else
        {
          this.showSupplierDetailsEmptyWarning=false;
         // this.submitproposal_ee(); // since no warnings and no mandatory fields missing

            // Check if draft tab1 is having same supplier details
            this.transactionService.get_draft_Tab1_data(this.selectedpart_submitter_image_ee.proposalid).subscribe(tab1detls=>{
              console.log("Tab1 Details",tab1detls);
              console.log("Suppler Profile",supDetls);
              this.primarypartsattributes=tab1detls;
  
              this.primarypartsattributes.supgsdbcode=supDetls.suppliergsdbcode;
              this.primarypartsattributes.supfullname=supDetls.fullname;
              this.primarypartsattributes.supaddress1=supDetls.address1;
              this.primarypartsattributes.supcity=supDetls.city;
              this.primarypartsattributes.supstateprov=supDetls.stateprovince;
              this.primarypartsattributes.supcountrycode=supDetls.countrycode;
              this.primarypartsattributes.supcontactnamefirst=supDetls.contactnamefirst;
              this.primarypartsattributes.supcontactname=supDetls.contactname;
              this.primarypartsattributes.supcontactfunctn=supDetls.contactfunctn;
              this.primarypartsattributes.supcontactemail=supDetls.contactemail;
              this.primarypartsattributes.supcontactphone=supDetls.contactphone;
              this.primarypartsattributes.supcontactfax=supDetls.contactfax;
              this.primarypartsattributes.defaultcurr=supDetls.defaultcurr;

              this.primarypartsattributes.timestamp=new Date().toISOString().split('.')[0];
  
              this.transactionService.savePrimaryPartDetails(this.primarypartsattributes).subscribe(saveattributes=>
                {
                  const op=saveattributes;
                  console.log('successful',op);
                  if(saveattributes=='Success')
                  {
                    this.submitproposal_ee(); // since no warnings and no mandatory fields missing
                  }
                  else
                  {
                    this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Supplier Details Not updated in Parts And Attributes Page'});
                  }
                });
  
  
            });
        }
        //this.supplierProfileInit();
      }

      else
      {
         // If mandatory fields like contactname , name and phone number is not valid, dont allow them proceed with submission and show warnings also in same dialog
        this.canProceedWithPropSubmission=false;
      }
    }
    else
    {
      this.canProceedWithPropSubmission=false;
    }

  });
}

closeMandatorySupValidationDialog()
{
  this.canProceedWithPropSubmission=true;
}

 submitproposal_ee()
    {
      console.log('sumbmitee')
      this.loaderimgee=true;
      this.inSubmitFlowForSupplierProfileValidation=false;
      this.supplierProfileInit();
      this.region_ee=this.transactionService.getregion();
        const currentab=4;
        // service to call tab4 api


        this.tab4alldetails_ee.int_cntrdesgncommnts=this.intr_dsgn_comments_ee;
          this.tab4alldetails_ee.gen_propslcommnts= this.general_comments_ee;
           this.tab4alldetails_ee.createmgcyexpndpckgprop=this.crt_edt_empro_ee;
           this.tab4alldetails_ee.agrmntexpndpackreqmnts=this.agre_for_empro_ee;

        this.transactionService.savetab4details_ee(this.tab4alldetails_ee).subscribe(response=>
          {
              let msgs:any[];
              if(response=='Success')
              {
                this.packagingproposal.addtoast({severity:'success', summary: 'success', detail: 'EE-Image details saved successfully'});
                const check=false;

          this.region_ee=this.transactionService.getregion();
          const currentab=4;
          if(currentab>this.selectedpart_submitter_image_ee.proposallastsaved_ee)
                        {

                        this.selectedpart_submitter_image_ee.proposallastsaved_ee=4;
                        this.store_primary_part_exist_image_ee();
                        console.log(this.selectedpart_submitter_image_ee);
                        }





          // service to gett 1234 tab details api


                  this.submit_non_ee_proposal();















        }

        else{

          this.loaderimgee=false;
          this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'EE-Image details is not saved! try again'});

        }

        })



        // service to call post transaction master api

    }



    submit_non_ee_proposal()
    {
      this.transactionService.get_draft_Tab1_data(this.selectedpart_submitter_image_ee.proposalid).subscribe(tab1_data=>{


        console.log('tab1t,',tab1_data);
        this.tab1DataGet=tab1_data;

        this.store_proposal_values.id_proposal=this.selectedpart_submitter_image_ee.proposalid;
        this.store_proposal_values.corr_protc=tab1_data.corr_protc;
       // this.store_proposal_values.lst_saved_tab=tab1_data.lst_saved_tab,
        this.store_proposal_values.seasnlreq=tab1_data.seasnlreq,
        this.store_proposal_values.shelf_life=tab1_data.shelf_life,
        this.store_proposal_values.sp_part_no=tab1_data.sp_part_no,
        // this.store_proposal_values.submit_status=tab1_data.submit_status,
        this.store_proposal_values.temp_sensitivity=tab1_data.temp_sensitivity,
        this.store_proposal_values.temp_stor_spec=tab1_data.temp_stor_spec,
           //changes to update with current time stamp;
            //this.store_proposal_values.time_stamp=tab1_data.timestamp,
            this.store_proposal_values.time_stamp=new Date().toISOString().split('.')[0]
        //this.store_proposal_values.plantgsdbcode=tab1_data.plantgsdbcode,
        this.store_proposal_values.isstampblank=tab1_data.isstampblank,


        this.store_proposal_values.supgsdbcode=tab1_data.supgsdbcode;

        this.store_proposal_values.supfullname=tab1_data.supfullname;
        this.store_proposal_values.supaddress1=tab1_data.supaddress1;
        this.store_proposal_values.supcity=tab1_data.supcity;
        this.store_proposal_values.supstateprov=tab1_data.supstateprov;
        this.store_proposal_values.supcountrycode=tab1_data.supcountrycode;
        this.store_proposal_values.supcontactnamefirst=tab1_data.supcontactnamefirst;
        this.store_proposal_values.supcontactname=tab1_data.supcontactname;
        this.store_proposal_values.supcontactfunctn=tab1_data.supcontactfunctn;
        this.store_proposal_values.supcontactemail=tab1_data.supcontactemail;
        this.store_proposal_values.supcontactphone=tab1_data.supcontactphone;
        this.store_proposal_values.supcontactfax=tab1_data.supcontactfax;
        this.store_proposal_values.defaultcurr=tab1_data.defaultcurr;







        this.transactionService.get_draft_selectedparts_data(this.selectedpart_submitter_image_ee.proposalid).subscribe(selectedparts_data=>{

          console.log(selectedparts_data);

          this.selectedPartsGet=selectedparts_data;
          this.selectedPartsGet_ee=selectedparts_data;

          this.store_proposal_selectedparts=selectedparts_data;








            this.transactionService.loadtab2detailsifproposalexistalready(this.selectedpart_submitter_image_ee.proposalid).subscribe(cont_cos_data=>
              {
                console.log(cont_cos_data);
                this.tab2data=cont_cos_data


                console.log(this.tab2data,'tab2222')


                this.store_proposal_values.cntr_ret_type=this.tab2data.cntr_ret_type
                this.store_proposal_values.used_carry_ovr_pck=this.tab2data.used_carry_ovr_pck,
                this.store_proposal_values.cntr_base=this.tab2data.cntr_base,
                this.store_proposal_values.cntr_suff=this.tab2data.cntr_suff,
                this.store_proposal_values.cntr_desc=this.tab2data.cntr_desc,
                this.store_proposal_values.parts_per_cntr=this.tab2data.parts_per_cntr,
                this.store_proposal_values.cntr_ext_len=this.tab2data.cntr_ext_len,
                this.store_proposal_values.cntr_width=this.tab2data.cntr_width,
                this.store_proposal_values.cntr_height=this.tab2data.cntr_height,
                this.store_proposal_values.cntr_nsted_fld_height=this.tab2data.cntr_nsted_fld_height
                this.store_proposal_values.cntr_material=this.tab2data.cntr_material,
                this.store_proposal_values.cntr_tar_weight=this.tab2data.cntr_tar_weight,
                this.store_proposal_values.cntr_per_layer=this.tab2data.cntr_per_layer,
                this.store_proposal_values.max_layrs_shp_unt=this.tab2data.max_layrs_shp_unt,
                this.store_proposal_values.empty_trays_cntr_layrs=this.tab2data.empty_trays_cntr_layrs,
                this.store_proposal_values.max_cntr_per_shp_unt=this.tab2data.max_cntr_per_shp_unt,
                this.store_proposal_values.cntr_rck_colr=this.tab2data.cntr_rck_colr,
                this.store_proposal_values.shp_unt_base=this.tab2data.shp_unt_base,
                this.store_proposal_values.shp_unt_suff=this.tab2data.shp_unt_suff,
                this.store_proposal_values.shp_unt_desc=this.tab2data.shp_unt_desc,
                this.store_proposal_values.part_per_shp_unit=this.tab2data.part_per_shp_unit,
                this.store_proposal_values.shp_unt_ret_type=this.tab2data.shp_unt_ret_type,
                this.store_proposal_values.shp_unit_ext_len=this.tab2data.shp_unit_ext_len,
                this.store_proposal_values.shp_unt_ext_width=this.tab2data.shp_unt_ext_width,
                this.store_proposal_values.shp_unt_ext_height=this.tab2data.shp_unt_ext_height,
                this.store_proposal_values.shp_unt_nestd_fld_height=this.tab2data.shp_unt_nestd_fld_height,
                this.store_proposal_values.card_call=this.tab2data.card_call,
                this.store_proposal_values.cntr_grp_id=this.tab2data.cntr_grp_id,
                this.store_proposal_values.cntr_ownrshp_strat_code=this.tab2data.cntr_ownrshp_strat_code,
                this.store_proposal_values.cd_cntr=this.tab2data.cd_cntr,
                this.store_proposal_values.cd_ownrshp_strat=this.tab2data.cd_ownrshp_strat;

                this.store_proposal_values.ect=this.tab2data.ect;
                this.store_proposal_values.bct=this.tab2data.bct;

                this.store_proposal_values.cntrOwner=this.tab2data.cntrowner;


                this.store_proposal_values.pallettype=this.tab2data.pallettype;
                this.store_proposal_values.woodispmcmplnt=this.tab2data.woodispmcmplnt;
                this.store_proposal_values.offstmpmartprsnt=this.tab2data.offstmpmartprsnt;
                this.store_proposal_values.blanksperstack=this.tab2data.blanksperstack;
                this.store_proposal_values.stacksperpallet=this.tab2data.stacksperpallet;
                this.store_proposal_values.palletpintyp=this.tab2data.palletpintyp;
                this.store_proposal_values.iscortonimcspec=this.tab2data.iscartonimcspec;
                this.store_proposal_values.cortonburststrnth=this.tab2data.cartonburststrnth;
                this.store_proposal_values.imcwtdisc=this.tab2data.imcwtdisc;


              if(this.tab2data.cd_ownrshp_strat!=null && this.tab2data.cd_ownrshp_strat!=0 && this.tab2data.cntr_ownrshp_strat_code!=null && this.tab2data.cntr_ownrshp_strat_code!='')
          {

          /*  this.transactionService.getcosdetails(this.tab2data.cntr_ownrshp_strat_code,this.tab2data.cd_ownrshp_strat).subscribe(cosdetails=>{
              console.log(cosdetails);
              if(cosdetails.pkg_sign_off.toLowerCase()=="required")
              {
                //condition to add after approver done to classify as Yes or no
                this.store_proposal_values.pkgsignoff="N";
              }
              if(cosdetails.sub_press_load_doc.toLowerCase()=="required")
              {
                //check condition


                this.store_proposal_values.subpresloaddoc="N";
              }





              console.log("newform",cosdetails.ownrshp_desc);



            })*/

            this.get_tab3_tab4_details_post();


          }



          else{


            this.get_tab3_tab4_details_post();

          }



                // check for shipping unit ret type exp or retun
                // if expend display pallet value from api
                // else dipaly pallet also returnable
                // wood complaint and oficial stamp identificaton mark value comes only for na others null

                  // service to call tab 3 get details





              })






        })




      })
    }



    get_tab3_tab4_details_post()
    {
      this.transactionService.getaddedcomponentdetailsfortab3(this.selectedpart_submitter_image_ee.proposalid).subscribe(complist=>{


        console.log(complist);
        this.compDataGet=complist;
        let temp;
        temp=JSON.parse(JSON.stringify(complist))

        console.log(temp);
        if(temp.length!=0)
        {
          let ownershp='';
          for(let j=0;j<temp.length;j++)
          {
              console.log(temp[j].comp_ownershp);
           if(temp[j].comp_ownershp.toLowerCase()=='ford')
           {
             ownershp='F';
           }
           else if(temp[j].comp_ownershp.toLowerCase()=='supplier')
           {
            ownershp='S';
           }
           else if(temp[j].comp_ownershp.toLowerCase()=='third party')
           {
            ownershp='T';

           }

            this.store_proposal_componenets.push(
              {
               id_proposal:this.selectedpart_submitter_image_ee.proposalid,
               id_comp:temp[j].id_comp,
               comp_base:temp[j].comp_base,
               comp_suffix:temp[j].comp_suffix,
               comp_desc:temp[j].comp_desc,
               comp_l:temp[j].comp_l,
               comp_w:temp[j].comp_w,
               comp_h:temp[j].comp_h,
               comp_type:temp[j].comp_ret_type,
               qt_per_min:temp[j].qt_per_min_su,
               updated_by:temp[j].upated_by,
               updated_dt:temp[j].updated_dt,
               comp_tar_wt:temp[j].comp_tare_wt,
               nestedfoldedht:temp[j].nestedfolderht,
               comp_code:temp[j].comp_code,
               comp_ownershp:temp[j].comp_ownershp,
               comp_material:temp[j].comp_material,
               tooledcomp:temp[j].tooledcomp,
               is_compulsary:temp[j].is_compulsary,
               compselctnfor:temp[j].compselctnfor,
               material_flag:temp[j].material_flag,
               typ_no:temp[j].typ_no
               });

          }

        }
        else{
          this.store_proposal_componenets=[];
        }





     this.transactionService.loadtab3detailsfromAPI(this.selectedpart_submitter_image_ee.proposalid).subscribe(tab3_data=>{

        console.log(tab3_data);

      this.tab3detailsGet=tab3_data;
        this.store_proposal_values.cntr_shp_unit=tab3_data.cntr_shp_unit;
        this.store_proposal_values.shp_unt_tar_wt=tab3_data.shp_unt_tar_wt;
        this.store_proposal_values.shp_unt_gross_wt=tab3_data.shp_unt_gross_wt;
        this.store_proposal_values.cntr_gross_wt=tab3_data.cntr_gross_wt;
        this.store_proposal_values.ld_unload_inst=tab3_data.ld_unload_inst;
        this.store_proposal_values.cntr_automtn_intf=tab3_data.cntr_automtn_intf;
        this.store_proposal_values.transp_tested=tab3_data.transp_tested;
        this.store_proposal_values.tst_reprt_num=tab3_data.tst_reprt_num;
        this.store_proposal_values.mot=tab3_data.mot;
        this.store_proposal_values.dangrs_good=tab3_data.dangrs_good;
        this.store_proposal_values.dangrs_good_ship_detls=tab3_data.dangrs_good_ship_detls;
        this.store_proposal_values.rel_level=tab3_data.rel_level;





      // inside that service

        // service to get tab4 details
        // for images append the values with the base url if value is not null or ""
        // in html add a senario when the value is so  to display empty without img tag


     this.transactionService.loadtab4details(this.selectedpart_submitter_image_ee.proposalid).subscribe(tab4_data=>{


          console.log(tab4_data)
          this.tab4Get=tab4_data;
          this.store_proposal_values.f_extcntrdsgnphoto=tab4_data.f_extcntrdsgnphoto,
          this.store_proposal_values.f_intcntrdsgnphoto=tab4_data.f_intcntrdsgnphoto;
          this.store_proposal_values.f_metelidplatephoto=tab4_data.f_metelidplatephoto;
          this.store_proposal_values.f_partphoto=tab4_data.f_partphoto;
          this.store_proposal_values.f_shpunitphoto=tab4_data.f_shpunitphoto;
          this.store_proposal_values.gen_propslcommnts=tab4_data.gen_propslcommnts;
          this.store_proposal_values.int_cntrdesgncommnts=tab4_data.int_cntrdesgncommnts;
          this.store_proposal_values.createmgcyexpndpckgprop=tab4_data.createmgcyexpndpckgprop;
          this.store_proposal_values.agrmntexpndpackreqmnts=tab4_data.agrmntexpndpackreqmnts;
          this.store_proposal_values.subpresloaddoc=tab4_data.subpresloaddoc;


          if(this.selectedpart_submitter_image_ee.part_status.toLowerCase()=='rejected' || this.selectedpart_submitter_image_ee.part_status.toLowerCase()=='recalled')
          {
            this.transactionService.loadtransacmasterdetails(this.selectedpart_submitter_image_ee.proposalid).subscribe(transdet_img=>{

              this.trascMastrGet=transdet_img;

              console.log(transdet_img);


              this.store_proposal_values.chngdbase=transdet_img.chngdbase;
              this.store_proposal_values.revision_hstry=transdet_img.revision_hstry;
              this.store_proposal_values.revision_hstry+='|'


              this.get_tab2_details_ee();

            })
          }


          else{
            this.store_proposal_values.revision_hstry='';
            this.get_tab2_details_ee()
          }






        })





      })
      })
    }







   get_tab2_details_ee()
   {


    this.transactionService.get_draft_Tab1_data(this.selectedpart_submitter_image_ee.proposalid).subscribe(tab1_data=>{


      console.log('tab1t,',tab1_data);
      this.tab1DataGet_ee=tab1_data;
      this.store_proposal_values_ee.id_proposal=this.selectedpart_submitter_image_ee.proposalid;
      this.store_proposal_values_ee.corr_protc=tab1_data.corr_protc;
     // this.store_proposal_values.lst_saved_tab=tab1_data.lst_saved_tab,
      this.store_proposal_values_ee.seasnlreq=tab1_data.seasnlreq,
      this.store_proposal_values_ee.shelf_life=tab1_data.shelf_life,
      this.store_proposal_values_ee.sp_part_no=tab1_data.sp_part_no,
      // this.store_proposal_values.submit_status=tab1_data.submit_status,
      this.store_proposal_values_ee.temp_sensitivity=tab1_data.temp_sensitivity,
      this.store_proposal_values_ee.temp_stor_spec=tab1_data.temp_stor_spec,
         //changes to update with current time stamp;
            //this.store_proposal_values_ee.time_stamp=tab1_data.timestamp,
            this.store_proposal_values_ee.time_stamp=new Date().toISOString().split('.')[0]
      //this.store_proposal_values.plantgsdbcode=tab1_data.plantgsdbcode,
      this.store_proposal_values_ee.isstampblank=tab1_data.isstampblank,


      this.store_proposal_values_ee.supgsdbcode=tab1_data.supgsdbcode;

      this.store_proposal_values_ee.supfullname=tab1_data.supfullname;
      this.store_proposal_values_ee.supaddress1=tab1_data.supaddress1;
      this.store_proposal_values_ee.supcity=tab1_data.supcity;
      this.store_proposal_values_ee.supstateprov=tab1_data.supstateprov;
      this.store_proposal_values_ee.supcountrycode=tab1_data.supcountrycode;
      this.store_proposal_values_ee.supcontactnamefirst=tab1_data.supcontactnamefirst;
      this.store_proposal_values_ee.supcontactname=tab1_data.supcontactname;
      this.store_proposal_values_ee.supcontactfunctn=tab1_data.supcontactfunctn;
      this.store_proposal_values_ee.supcontactemail=tab1_data.supcontactemail;
      this.store_proposal_values_ee.supcontactphone=tab1_data.supcontactphone;
      this.store_proposal_values_ee.supcontactfax=tab1_data.supcontactfax;
      this.store_proposal_values_ee.defaultcurr=tab1_data.defaultcurr;




    this.transactionService.loadtab2detailsifproposalexistalready_ee(this.selectedpart_submitter_image_ee.proposalid).subscribe(cont_cos_data=>
      {
        console.log(cont_cos_data);
        this.tab2data_ee=cont_cos_data


        console.log(this.tab2data_ee,'tab2222')


        this.store_proposal_values_ee.cntr_ret_type=this.tab2data_ee.cntr_ret_type
        this.store_proposal_values_ee.used_carry_ovr_pck=this.tab2data_ee.used_carry_ovr_pck,
        this.store_proposal_values_ee.cntr_base=this.tab2data_ee.cntr_base,
        this.store_proposal_values_ee.cntr_suff=this.tab2data_ee.cntr_suff,
        this.store_proposal_values_ee.cntr_desc=this.tab2data_ee.cntr_desc,
        this.store_proposal_values_ee.parts_per_cntr=this.tab2data_ee.parts_per_cntr,
        this.store_proposal_values_ee.cntr_ext_len=this.tab2data_ee.cntr_ext_len,
        this.store_proposal_values_ee.cntr_width=this.tab2data_ee.cntr_width,
        this.store_proposal_values_ee.cntr_height=this.tab2data_ee.cntr_height,
        this.store_proposal_values_ee.cntr_nsted_fld_height=this.tab2data_ee.cntr_nsted_fld_height
        this.store_proposal_values_ee.cntr_material=this.tab2data_ee.cntr_material,
        this.store_proposal_values_ee.cntr_tar_weight=this.tab2data_ee.cntr_tar_weight,
        this.store_proposal_values_ee.cntr_per_layer=this.tab2data_ee.cntr_per_layer,
        this.store_proposal_values_ee.max_layrs_shp_unt=this.tab2data_ee.max_layrs_shp_unt,
        this.store_proposal_values_ee.empty_trays_cntr_layrs=this.tab2data_ee.empty_trays_cntr_layrs,
        this.store_proposal_values_ee.max_cntr_per_shp_unt=this.tab2data_ee.max_cntr_per_shp_unt,
        this.store_proposal_values_ee.cntr_rck_colr=this.tab2data_ee.cntr_rck_colr,
        this.store_proposal_values_ee.shp_unt_base=this.tab2data_ee.shp_unt_base,
        this.store_proposal_values_ee.shp_unt_suff=this.tab2data_ee.shp_unt_suff,
        this.store_proposal_values_ee.shp_unt_desc=this.tab2data_ee.shp_unt_desc,
        this.store_proposal_values_ee.part_per_shp_unit=this.tab2data_ee.part_per_shp_unit,
        this.store_proposal_values_ee.shp_unt_ret_type=this.tab2data_ee.shp_unt_ret_type,
        this.store_proposal_values_ee.shp_unit_ext_len=this.tab2data_ee.shp_unit_ext_len,
        this.store_proposal_values_ee.shp_unt_ext_width=this.tab2data_ee.shp_unt_ext_width,
        this.store_proposal_values_ee.shp_unt_ext_height=this.tab2data_ee.shp_unt_ext_height,
        this.store_proposal_values_ee.shp_unt_nestd_fld_height=this.tab2data_ee.shp_unt_nestd_fld_height,
        this.store_proposal_values_ee.card_call=this.tab2data_ee.card_call,
        this.store_proposal_values_ee.cntr_grp_id=this.tab2data_ee.cntr_grp_id,
        this.store_proposal_values_ee.cntr_ownrshp_strat_code=this.tab2data_ee.cntr_ownrshp_strat_code,
        this.store_proposal_values_ee.cd_cntr=this.tab2data_ee.cd_cntr,
        this.store_proposal_values_ee.cd_ownrshp_strat=this.tab2data_ee.cd_ownrshp_strat;


        this.store_proposal_values_ee.cntrOwner=this.tab2data_ee.cntrowner;


        this.store_proposal_values_ee.pallettype=this.tab2data_ee.pallettype;
        this.store_proposal_values_ee.woodispmcmplnt=this.tab2data_ee.woodispmcmplnt;
        this.store_proposal_values_ee.offstmpmartprsnt=this.tab2data_ee.offstmpmartprsnt;
        this.store_proposal_values_ee.blanksperstack=this.tab2data_ee.blanksperstack;
        this.store_proposal_values_ee.stacksperpallet=this.tab2data_ee.stacksperpallet;
        this.store_proposal_values_ee.palletpintyp=this.tab2data_ee.palletpintyp;
        this.store_proposal_values_ee.iscortonimcspec=this.tab2data_ee.iscartonimcspec;
        this.store_proposal_values_ee.cortonburststrnth=this.tab2data_ee.cartonburststrnth;
        this.store_proposal_values_ee.imcwtdisc=this.tab2data_ee.imcwtdisc;

        this.store_proposal_values_ee.bct=this.tab2data_ee.bct;
        this.store_proposal_values_ee.ect=this.tab2data_ee.ect;


      if(this.tab2data_ee.cd_ownrshp_strat!=null && this.tab2data_ee.cd_ownrshp_strat!=0 && this.tab2data_ee.cntr_ownrshp_strat_code!=null && this.tab2data_ee.cntr_ownrshp_strat_code!='')
  {

  /*  this.transactionService.getcosdetails(this.tab2data.cntr_ownrshp_strat_code,this.tab2data.cd_ownrshp_strat).subscribe(cosdetails=>{
      console.log(cosdetails);
      if(cosdetails.pkg_sign_off.toLowerCase()=="required")
      {
        //condition to add after approver done to classify as Yes or no
        this.store_proposal_values.pkgsignoff="N";
      }
      if(cosdetails.sub_press_load_doc.toLowerCase()=="required")
      {
        //check condition


        this.store_proposal_values.subpresloaddoc="N";
      }





      console.log("newform",cosdetails.ownrshp_desc);



    })*/

    this.get_tab3_tab4_details_post_ee();


  }



  else{


    this.get_tab3_tab4_details_post_ee();

  }



        // check for shipping unit ret type exp or retun
        // if expend display pallet value from api
        // else dipaly pallet also returnable
        // wood complaint and oficial stamp identificaton mark value comes only for na others null

          // service to call tab 3 get details





      })

    })


   }



    get_tab3_tab4_details_post_ee()
    {
      this.transactionService.getaddedcomponentdetailsfortab3_ee(this.selectedpart_submitter_image_ee.proposalid).subscribe(complist=>{


        console.log(complist);
        this.compDataGet_ee=complist;
        let temp;
        temp=JSON.parse(JSON.stringify(complist))

        console.log(temp);
        if(temp.length!=0)
        {
          let ownershp='';
          for(let j=0;j<temp.length;j++)
          {
              console.log(temp[j].comp_ownershp);
           if(temp[j].comp_ownershp.toLowerCase()=='ford')
           {
             ownershp='F';
           }
           else if(temp[j].comp_ownershp.toLowerCase()=='supplier')
           {
            ownershp='S';
           }
           else if(temp[j].comp_ownershp.toLowerCase()=='third party')
           {
            ownershp='T';

           }

            this.store_proposal_componenets_ee.push(
              {
               id_proposal:this.selectedpart_submitter_image_ee.proposalid,
               id_comp:temp[j].id_comp,
               comp_base:temp[j].comp_base,
               comp_suffix:temp[j].comp_suffix,
               comp_desc:temp[j].comp_desc,
               comp_l:temp[j].comp_l,
               comp_w:temp[j].comp_w,
               comp_h:temp[j].comp_h,
               comp_type:temp[j].comp_ret_type,
               qt_per_min:temp[j].qt_per_min_su,
               updated_by:temp[j].upated_by,
               updated_dt:temp[j].updated_dt,
               comp_tar_wt:temp[j].comp_tare_wt,
               nestedfoldedht:temp[j].nestedfolderht,
               comp_code:temp[j].comp_code,
               comp_ownershp:temp[j].comp_ownershp,
               comp_material:temp[j].comp_material,
               tooledcomp:temp[j].tooledcomp,
               is_compulsary:temp[j].is_compulsary,
               compselctnfor:temp[j].compselctnfor,
               material_flag:temp[j].material_flag,
               typ_no:temp[j].typ_no
               });

          }

        }
        else{
          this.store_proposal_componenets_ee=[];
        }





     this.transactionService.loadtab3detailsfromAPI_ee(this.selectedpart_submitter_image_ee.proposalid).subscribe(tab3_data=>{

        console.log(tab3_data);
this.tab3detailsGet_ee=tab3_data;

        this.store_proposal_values_ee.cntr_shp_unit=tab3_data.cntr_shp_unit;
        this.store_proposal_values_ee.shp_unt_tar_wt=tab3_data.shp_unt_tar_wt;
        this.store_proposal_values_ee.shp_unt_gross_wt=tab3_data.shp_unt_gross_wt;
        this.store_proposal_values_ee.cntr_gross_wt=tab3_data.cntr_gross_wt;
        this.store_proposal_values_ee.ld_unload_inst=tab3_data.ld_unload_inst;
        this.store_proposal_values_ee.cntr_automtn_intf=tab3_data.cntr_automtn_intf;
        this.store_proposal_values_ee.transp_tested=tab3_data.transp_tested;
        this.store_proposal_values_ee.tst_reprt_num=tab3_data.tst_reprt_num;
        this.store_proposal_values_ee.mot=tab3_data.mot;
        this.store_proposal_values_ee.dangrs_good=tab3_data.dangrs_good;
        this.store_proposal_values_ee.dangrs_good_ship_detls=tab3_data.dangrs_good_ship_detls;
        this.store_proposal_values_ee.rel_level=tab3_data.rel_level;





      // inside that service

        // service to get tab4 details
        // for images append the values with the base url if value is not null or ""
        // in html add a senario when the value is so  to display empty without img tag


     this.transactionService.loadtab4details_ee(this.selectedpart_submitter_image_ee.proposalid).subscribe(tab4_data=>{


          console.log(tab4_data)
          this.tab4Get_ee=tab4_data;
          this.store_proposal_values_ee.f_extcntrdsgnphoto=tab4_data.f_extcntrdsgnphoto,
          this.store_proposal_values_ee.f_intcntrdsgnphoto=tab4_data.f_intcntrdsgnphoto;
          this.store_proposal_values_ee.f_metelidplatephoto=tab4_data.f_metelidplatephoto;
          this.store_proposal_values_ee.f_partphoto=tab4_data.f_partphoto;
          this.store_proposal_values_ee.f_shpunitphoto=tab4_data.f_shpunitphoto;
          this.store_proposal_values_ee.gen_propslcommnts=tab4_data.gen_propslcommnts;
          this.store_proposal_values_ee.int_cntrdesgncommnts=tab4_data.int_cntrdesgncommnts;
          this.store_proposal_values_ee.createmgcyexpndpckgprop=tab4_data.createmgcyexpndpckgprop;
          this.store_proposal_values_ee.agrmntexpndpackreqmnts=tab4_data.agrmntexpndpackreqmnts;
          this.store_proposal_values_ee.subpresloaddoc=tab4_data.subpresloaddoc;


         /* if(this.selectedpart_submitter_image_ee.part_status.toLowerCase()=="rejected")
          {
            this.transactionService.loadtransacmasterdetails_ee(this.selectedpart_submitter_image_ee.proposalid).subscribe(transdet_img=>{


              console.log(transdet_img);


              this.store_proposal_values_ee.chngdbase=transdet_img.chngdbase;
              this.store_proposal_values_ee.revision_hstry=transdet_img.revision_hstry;
              this.store_proposal_values_ee.revision_hstry+='|'


              this.submit_proposal_service();

            })
          }


          else{*/
            this.store_proposal_values_ee.revision_hstry='';
            this.submit_proposal_service()
         // }






        })





      })
      })
    }


    submit_proposal_service()
    {


      console.log('submit');
      console.log(this.store_proposal_values);
      console.log(this.store_proposal_componenets);
      console.log(this.store_proposal_selectedparts);


      this.store_proposal_values.apprvr_cdsid=this.curr_cdsid_ee;
      let cur_date;
      let cur_date_transform
      cur_date=new Date();
      cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
      console.log(this.user_details_ee)
      this.store_proposal_values.revision_hstry+=this.curr_cdsid_ee+'`'+this.user_details_ee.name+'`'+this.user_details_ee.email+'`Packaging Proposal Submitted`Proposal Submitted`'+cur_date_transform+'`-';

      this.oldProposalDetailsForChnageTrack=
      {
        cntrTareWt:this.tab2data.cntr_tar_weight,
        cntrNestedFldedHt:this.tab2data.cntr_nsted_fld_height,
        cntrExtLength:this.tab2data.cntr_ext_len,
        cntrExtwidth:this.tab2data.cntr_width,
        cntrExtHeight:this.tab2data.cntr_height,
        // maxLayersPerSu:this.tab2data.max_layrs_shp_unt,
        // cntrPerLayer:this.tab2data.cntr_per_layer,
        // partsPerSu:this.tab2data.part_per_shp_unit,
        maxCntrsPerSu:this.tab2data.max_cntr_per_shp_unt,
        shippingUnitBase:this.tab2data.shp_unt_base,
        shippingUnitSuffix:this.tab2data.shp_unt_suff,
        // shippingExtLen:this.tab2data.shp_unit_ext_len,
        // shippingExtWidth:this.tab2data.shp_unt_ext_width,
        // shippingExtHeight:this.tab2data.shp_unt_ext_height,
        // shippingUnitNestedFoldedht:this.tab2data.shp_unt_nestd_fld_height,
        shippingUnitReturnType:this.tab2data.shp_unt_ret_type,
        shiipingUnitTareWeight:this.tab3detailsGet.shp_unt_tar_wt,
        cntrGrossWeight:this.tab3detailsGet.cntr_gross_wt,
        shippingUnitGrossWeight:this.tab3detailsGet.shp_unt_gross_wt,
      }

      this.oldProposalDetailsForChnageTrack_ee=
      {
        cntrTareWt:this.tab2data_ee.cntr_tar_weight,
        cntrNestedFldedHt:this.tab2data_ee.cntr_nsted_fld_height,
        cntrExtLength:this.tab2data_ee.cntr_ext_len,
        cntrExtwidth:this.tab2data_ee.cntr_width,
        cntrExtHeight:this.tab2data_ee.cntr_height,
        // maxLayersPerSu:this.tab2data_ee.max_layrs_shp_unt,
        // cntrPerLayer:this.tab2data_ee.cntr_per_layer,
        // partsPerSu:this.tab2data_ee.part_per_shp_unit,
        maxCntrsPerSu:this.tab2data_ee.max_cntr_per_shp_unt,
        shippingUnitBase:this.tab2data_ee.shp_unt_base,
        shippingUnitSuffix:this.tab2data_ee.shp_unt_suff,
        // shippingExtLen:this.tab2data_ee.shp_unit_ext_len,
        // shippingExtWidth:this.tab2data_ee.shp_unt_ext_width,
        // shippingExtHeight:this.tab2data_ee.shp_unt_ext_height,
        // shippingUnitNestedFoldedht:this.tab2data_ee.shp_unt_nestd_fld_height,
        shippingUnitReturnType:this.tab2data_ee.shp_unt_ret_type,
        shiipingUnitTareWeight:this.tab3detailsGet_ee.shp_unt_tar_wt,
        cntrGrossWeight:this.tab3detailsGet_ee.cntr_gross_wt,
        shippingUnitGrossWeight:this.tab3detailsGet_ee.shp_unt_gross_wt,
      }
      /*
      this.no_of_container=this.contadmindetls.cntr_per_layer*(this.contadmindetls.max_layers_shpg_unit-tab2_data.empty_trays_cntr_layrs);
      this.containergrossweight=tab2_data.cntr_tar_weight+(tab2_data.parts_per_cntr*partweight);
      this.shippingunitgrossweight=(this.no_of_container*this.containergrossweight)+this.shippingunittareweight;
      */
      this.transactionService.editbutton_get_contr_details(this.tab2data.cntr_base,this.tab2data.cd_cntr).subscribe(cntrdetails=>{
        this.containerDetails=cntrdetails;
        console.log('container details', this.containerDetails)

        console.log(this.tab2data_ee.cd_cntr,this.tab2data_ee.cntr_base)
        this.transactionService.editbutton_get_contr_details(this.tab2data_ee.cntr_base,this.tab2data_ee.cd_cntr).subscribe(cntrdetails_ee=>{

          this.containerDetails_ee=cntrdetails_ee;
          console.log('container details EE', this.containerDetails_ee)

          this.tempCntrCompWeight=0
          this.tempSUCompWeight=0
          this.tempCntrCompWeight_ee=0
          this.tempSUCompWeight_ee=0

          for( const i of this.compDataGet)
          {
            if(i.compselctnfor.toLowerCase()=='shipping unit')
            {
              this.tempSUCompWeight+=(i.qt_per_min_su*i.comp_tare_wt);
            }
            else
            {
              this.no_of_container=this.tab2data.cntr_per_layer*(this.tab2data.max_layrs_shp_unt-this.tab2data.empty_trays_cntr_layrs);

              this.tempCntrCompWeight+=(i.qt_per_min_su *i.comp_tare_wt);
              // this.tempSUCompWeight+=(this.no_of_container*this.tempCntrCompWeight);

            }
          }

          // For EE
          for( const i of this.compDataGet_ee)
          {
            if(i.compselctnfor.toLowerCase()=='shipping unit')
            {
              this.tempSUCompWeight_ee+=(i.qt_per_min_su*i.comp_tare_wt);
            }
            else
            {
              this.no_of_container_ee=this.tab2data_ee.cntr_per_layer*(this.tab2data_ee.max_layrs_shp_unt-this.tab2data_ee.empty_trays_cntr_layrs);

              this.tempCntrCompWeight_ee+=(i.qt_per_min_su *i.comp_tare_wt);
              // this.tempSUCompWeight_ee+=(this.no_of_container_ee*this.tempCntrCompWeight_ee);

            }
          }

          var noOfContainer=this.tab2data.cntr_per_layer*(this.tab2data.max_layrs_shp_unt-this.tab2data.empty_trays_cntr_layrs);
          var partweight=0;
            for(var j of this.selectedPartsGet)
            {
              if(j.isprimary.toLowerCase() == 'yes')
              {
              partweight+=j.ngpp_part_wt;
              break;
              }
            }
          // var cntrGrossWt=this.containerDetails.cntr_tare_wt+(this.tab2data.parts_per_cntr*partweight)+this.tempCntrCompWeight;
          if(this.tab2data.cntr_ret_type != null && this.tab2data.cntr_ret_type.toLowerCase() != 'expendable')
            {
            var cntrGrossWt=this.containerDetails.cntr_tare_wt+(this.tab2data.parts_per_cntr*partweight)+this.tempCntrCompWeight;
            var suTareWt=(noOfContainer*(this.tempCntrCompWeight+this.containerDetails.cntr_tare_wt))+this.tempSUCompWeight;
            }
            else
            {
              var cntrGrossWt=this.tab2data.cntr_tar_weight+(this.tab2data.parts_per_cntr*partweight)+this.tempCntrCompWeight;
              var suTareWt=(noOfContainer*(this.tempCntrCompWeight+this.tab2data.cntr_tar_weight))+this.tempSUCompWeight;
            }
          //var suGrossWt=(noOfContainer*cntrGrossWt)+this.containerDetails.shpg_unit_tare_wt+this.tempSUCompWeight;
          var suGrossWt=(noOfContainer*cntrGrossWt)+this.tempSUCompWeight

// @ts-ignore
          cntrGrossWt=this.transactionService.roundValueToNDecimal(cntrGrossWt,3);
          suGrossWt=this.transactionService.roundValueToNDecimal(suGrossWt,3);
          suTareWt=this.transactionService.roundValueToNDecimal(suTareWt,3);
          

          const partsPerSu=  (
            this.tab2data.cntr_per_layer* (this.tab2data.max_layrs_shp_unt-this.tab2data.empty_trays_cntr_layrs)
          ) * this.tab2data.parts_per_cntr;


          console.log('no of cntr',noOfContainer);
          // @ts-ignore
            console.log(partweight,cntrGrossWt,suGrossWt,partsPerSu)

          //For EE
          var noOfContainer_ee=this.tab2data_ee.cntr_per_layer*(this.tab2data_ee.max_layrs_shp_unt-this.tab2data_ee.empty_trays_cntr_layrs);
          var partweight_ee=0;
            for(var j of this.selectedPartsGet_ee)
            {
              if(j.isprimary.toLowerCase() == 'yes')
              {
              partweight_ee+=j.ngpp_part_wt;
              break;
              }
            }
          //var cntrGrossWt_ee=this.containerDetails_ee.cntr_tare_wt+(this.tab2data_ee.parts_per_cntr*partweight_ee)+this.tempCntrCompWeight_ee;
          if(this.tab2data_ee.cntr_ret_type != null && this.tab2data_ee.cntr_ret_type.toLowerCase() != "expendable")  
            {
            var cntrGrossWt_ee=this.containerDetails_ee.cntr_tare_wt+(this.tab2data_ee.parts_per_cntr*partweight_ee)+this.tempCntrCompWeight_ee;
            var suTareWt_ee=(noOfContainer_ee*(this.tempCntrCompWeight_ee+this.containerDetails_ee.cntr_tare_wt))+this.tempSUCompWeight_ee;  
            }
            else
            {
              var cntrGrossWt_ee=this.tab2data_ee.cntr_tar_weight+(this.tab2data_ee.parts_per_cntr*partweight_ee)+this.tempCntrCompWeight_ee;
              var suTareWt_ee=(noOfContainer_ee*(this.tempCntrCompWeight_ee+this.tab2data_ee.cntr_tar_weight))+this.tempSUCompWeight_ee; 
            }

          //var suGrossWt_ee=(noOfContainer_ee*cntrGrossWt_ee)+this.containerDetails_ee.shpg_unit_tare_wt+this.tempSUCompWeight_ee;
          var suGrossWt_ee=(noOfContainer_ee*cntrGrossWt_ee)+this.tempSUCompWeight_ee;
          
          cntrGrossWt_ee=this.transactionService.roundValueToNDecimal(cntrGrossWt_ee,3);
          suGrossWt_ee=this.transactionService.roundValueToNDecimal(suGrossWt_ee,3);
          suTareWt_ee=this.transactionService.roundValueToNDecimal(suTareWt_ee,3);

          const partsPerSu_ee=  (
            this.tab2data_ee.cntr_per_layer* (this.tab2data_ee.max_layrs_shp_unt-this.tab2data_ee.empty_trays_cntr_layrs)
          ) * this.tab2data_ee.parts_per_cntr;

          console.log('no of cntr',noOfContainer_ee);
          // @ts-ignore
            console.log(partweight_ee,cntrGrossWt_ee,suGrossWt_ee,partsPerSu_ee)

          /* for( var i of this.compDataGet)
          {
            if(i.compselctnfor.toLowerCase()=="Shipping Unit")
            {
              this.tempSUCompWeight+=(i.qt_per_min_su*i.comp_tare_wt);
            }
            else
            {
              this.no_of_container=this.containerDetails.cntr_per_layer*(this.containerDetails.max_layers_shpg_unit-this.tab2data.empty_trays_cntr_layrs);

              this.tempCntrCompWeight+=(i.qt_per_min_su *i.comp_tare_wt);
              this.tempSUCompWeight+=(this.no_of_container*this.tempCntrCompWeight);

            }
          }

          //For EE

          for( var i of this.compDataGet_ee)
          {
            if(i.compselctnfor.toLowerCase()=="Shipping Unit")
            {
              this.tempSUCompWeight_ee+=(i.qt_per_min_su*i.comp_tare_wt);
            }
            else
            {
              this.no_of_container_ee=this.containerDetails_ee.cntr_per_layer*(this.containerDetails_ee.max_layers_shpg_unit-this.tab2data_ee.empty_trays_cntr_layrs);

              this.tempCntrCompWeight_ee+=(i.qt_per_min_su *i.comp_tare_wt);
              this.tempSUCompWeight_ee+=(this.no_of_container_ee*this.tempCntrCompWeight_ee);

            }
          }
 */
            let cntr_tare_wt=this.tab2data.cntr_tar_weight;
            let collapsed_ht=this.tab2data.cntr_nsted_fld_height;
            let cntr_exterior_l=this.tab2data.cntr_ext_len;
            let cntr_exterior_w=this.tab2data.cntr_width;
            let cntr_exterior_h=this.tab2data.cntr_height;
            let max_cntr_per_shpg_unit=this.tab2data.max_cntr_per_shp_unt;

            if(this.tab2data.cntr_ret_type != null && this.tab2data.cntr_ret_type.toLowerCase() != 'expendable')
            {
              cntr_tare_wt=this.containerDetails.cntr_tare_wt;
              collapsed_ht=this.containerDetails.collapsed_ht;
              cntr_exterior_l=this.containerDetails.cntr_exterior_l;
              cntr_exterior_w=this.containerDetails.cntr_exterior_w;
              cntr_exterior_h=this.containerDetails.cntr_exterior_h;
              // changed as max container per shipping unit is calculated field
             // max_cntr_per_shpg_unit=this.containerDetails.max_cntr_per_shpg_unit;
            }

            let cntr_tare_wt_ee=this.tab2data_ee.cntr_tar_weight;
            let collapsed_ht_ee=this.tab2data_ee.cntr_nsted_fld_height;
            let cntr_exterior_l_ee=this.tab2data_ee.cntr_ext_len;
            let cntr_exterior_w_ee=this.tab2data_ee.cntr_width;
            let cntr_exterior_h_ee=this.tab2data_ee.cntr_height;
            let max_cntr_per_shpg_unit_ee=this.tab2data_ee.max_cntr_per_shp_unt;

            if(this.tab2data_ee.cntr_ret_type != null && this.tab2data_ee.cntr_ret_type.toLowerCase() != 'expendable')
            {
              cntr_tare_wt_ee=this.containerDetails_ee.cntr_tare_wt;
              collapsed_ht_ee=this.containerDetails_ee.collapsed_ht;
              cntr_exterior_l_ee=this.containerDetails_ee.cntr_exterior_l;
              cntr_exterior_w_ee=this.containerDetails_ee.cntr_exterior_w;
              cntr_exterior_h_ee=this.containerDetails_ee.cntr_exterior_h;
              
              // changed as max container per shipping unit is calculated field
              //max_cntr_per_shpg_unit_ee=this.containerDetails_ee.max_cntr_per_shpg_unit;
            }

          this.newProposalDetailsForChangeTrack={
            cntrTareWt:cntr_tare_wt,
            cntrNestedFldedHt:collapsed_ht,
            cntrExtLength:cntr_exterior_l,
            cntrExtwidth:cntr_exterior_w,
            cntrExtHeight:cntr_exterior_h,
            // maxLayersPerSu:this.containerDetails.max_layers_shpg_unit,
            // cntrPerLayer:this.containerDetails.cntr_per_layer,
            // partsPerSu:partsPerSu,
            maxCntrsPerSu:max_cntr_per_shpg_unit,
            shippingUnitBase:this.containerDetails.cntr_base,
            shippingUnitSuffix:this.containerDetails.cntr_suffix,
            // shippingExtLen:this.containerDetails.shpg_unit_exterior_l,
            // shippingExtWidth:this.containerDetails.shpg_unit_exterior_w,
            // shippingExtHeight:this.containerDetails.shpg_unit_exterior_h,
            // shippingUnitNestedFoldedht:this.containerDetails.shpg_unit_nstdorflded_ht,
            shippingUnitReturnType:this.containerDetails.exp_refundable,
            shiipingUnitTareWeight:suTareWt,
              // @ts-ignore
            cntrGrossWeight:cntrGrossWt,
            shippingUnitGrossWeight:suGrossWt,
          }


            this.newProposalDetailsForChangeTrack_ee={
            cntrTareWt:cntr_tare_wt_ee,
            cntrNestedFldedHt:collapsed_ht_ee,
            cntrExtLength:cntr_exterior_l_ee,
            cntrExtwidth:cntr_exterior_w_ee,
            cntrExtHeight:cntr_exterior_h_ee,
            // maxLayersPerSu:this.containerDetails_ee.max_layers_shpg_unit,
            // cntrPerLayer:this.containerDetails_ee.cntr_per_layer,
            // partsPerSu:partsPerSu_ee,
            maxCntrsPerSu:max_cntr_per_shpg_unit_ee,
            shippingUnitBase:this.containerDetails_ee.cntr_base,
            shippingUnitSuffix:this.containerDetails_ee.cntr_suffix,
            // shippingExtLen:this.containerDetails_ee.shpg_unit_exterior_l,
            // shippingExtWidth:this.containerDetails_ee.shpg_unit_exterior_w,
            // shippingExtHeight:this.containerDetails_ee.shpg_unit_exterior_h,
            // shippingUnitNestedFoldedht:this.containerDetails_ee.shpg_unit_nstdorflded_ht,
            shippingUnitReturnType:this.containerDetails_ee.exp_refundable,
            shiipingUnitTareWeight:suTareWt_ee,
                // @ts-ignore
            cntrGrossWeight:cntrGrossWt_ee,
            shippingUnitGrossWeight:suGrossWt_ee,
          }

          console.log('old data',this.oldProposalDetailsForChnageTrack);
          console.log('new data',this.newProposalDetailsForChangeTrack);

          console.log('old data EE',this.oldProposalDetailsForChnageTrack_ee);
          console.log('new data EE',this.newProposalDetailsForChangeTrack_ee);


          if(this.tab2data.cntr_ret_type.toLowerCase() != 'expendable' && JSON.stringify(this.oldProposalDetailsForChnageTrack)!=JSON.stringify(this.newProposalDetailsForChangeTrack))
          {
            this.enableChnageDialog=true;
          }
          else if(this.tab2data.cntr_ret_type.toLowerCase() == 'expendable' && (this.oldProposalDetailsForChnageTrack.shiipingUnitTareWeight!=this.newProposalDetailsForChangeTrack.shiipingUnitTareWeight || this.oldProposalDetailsForChnageTrack.cntrGrossWeight!=this.newProposalDetailsForChangeTrack.cntrGrossWeight || this.oldProposalDetailsForChnageTrack.shippingUnitGrossWeight!=this.newProposalDetailsForChangeTrack.shippingUnitGrossWeight ))
          {
            this.enableChnageDialog=true;
          }
          else
          {
            this.enableChnageDialog=false;
          }


          // For EE
          if(this.tab2data_ee.cntr_ret_type.toLowerCase() != 'expendable' && JSON.stringify(this.oldProposalDetailsForChnageTrack_ee)!=JSON.stringify(this.newProposalDetailsForChangeTrack_ee))
          {
            this.enableChnageDialog_ee=true;
          }
          else if(this.tab2data_ee.cntr_ret_type.toLowerCase() == 'expendable' && (this.oldProposalDetailsForChnageTrack_ee.shiipingUnitTareWeight!=this.newProposalDetailsForChangeTrack_ee.shiipingUnitTareWeight || this.oldProposalDetailsForChnageTrack_ee.cntrGrossWeight!=this.newProposalDetailsForChangeTrack_ee.cntrGrossWeight || this.oldProposalDetailsForChnageTrack_ee.shippingUnitGrossWeight!=this.newProposalDetailsForChangeTrack_ee.shippingUnitGrossWeight ))
          {
            this.enableChnageDialog_ee=true;
          }
          else
          {
            this.enableChnageDialog_ee=false;
          }


          if(this.enableChnageDialog==false && this.enableChnageDialog_ee==false)
          {
            // this.checkValidationWhileSubmittingChangesForEE();
            this.submitAPICall();
          }
          else
          {
            this.loaderimgee=false;
            this.checkValidationWhileSubmittingChanges();

            this.checkValidationWhileSubmittingChangesForEE();


            console.log("test",this.cntrgrosswtInvalid,this.sutarewtInvalid,this.sugrosswtInvalid,this.partDimensionsInvalid,this.partDimensionsInvalid_ee,this.cntrgrosswtInvalid_ee,this.sutarewtInvalid_ee,this.sugrosswtInvalid_ee);
            this.reasonForNotSubmitting='';

		// Removed || this.partDimensionsInvalid || this.partDimensionsInvalid_ee
            if(this.cntrgrosswtInvalid || this.sutarewtInvalid || this.sugrosswtInvalid || this.expendable_wgts_InValid || this.cntrgrosswtInvalid_ee || this.sutarewtInvalid_ee  || this.sugrosswtInvalid_ee || this.expendable_wgts_InValid_ee 
	    || this.suExtHgtInvalid || this.suExtHgtInvalid_ee || this.suNstFldHgtInvalid || this.suNstFldHgtInvalid_ee)
            {
              this.reasonForNotSubmitting='Please Update the Proposal Accordingly and then Submit!'
            }

          }

        })

      })

    }

    expAidsWgtValidationCheck()
    {
      console.log('Inside Exp Aids Wgt Check');
      console.log('Inside Grid',this.compDataGet);
      for(const i of this.compDataGet)
      {
        if(i.compselctnfor == 'Container' && (i.comp_code > '05' && i.comp_code < '16'))
        {

          this.cntr_comp_wt = 0;
          this.cntr_comp_wt+=(i.qt_per_min_su *i.comp_tare_wt);
          this.shp_comp_wt +=( this.no_of_container*(this.cntr_comp_wt));
          console.log('Inside Container',this.shp_comp_wt);
        }
        else
        {
          if(i.compselctnfor== 'Shipping Unit' && (i.comp_code > '05' && i.comp_code < '16'))
          {
            // this.temp_shp_compwt = 0;
          this.shp_comp_wt+=(i.qt_per_min_su *i.comp_tare_wt);
          // this.temp_shp_compwt+= this.shp_comp_wt;
          console.log('Inside Ship',this.shp_comp_wt);

        }
        }
      }
    }


    expAidsWgtValidationCheck_ee()
    {
      console.log('Inside Exp Aids Wgt Check');
      console.log('Inside Grid_ee',this.compDataGet_ee);
      for(const i of this.compDataGet_ee)
      {
        console.log('Inside For - ',this.compDataGet_ee.indexOf(i));
        if(i.compselctnfor == 'Container' && (i.comp_code > '05' && i.comp_code < '16'))
        {

          this.cntr_comp_wt_ee = 0;
          this.cntr_comp_wt_ee+=(i.qt_per_min_su *i.comp_tare_wt);
          console.log('Inside Container_ee - comp',this.cntr_comp_wt_ee,i.qt_per_min_su,i.comp_tare_wt)
          this.shp_comp_wt_ee +=( this.no_of_container_ee*this.cntr_comp_wt_ee);

          console.log('Inside Container_ee',this.shp_comp_wt_ee);
        }
        else
        {
          if(i.compselctnfor== 'Shipping Unit' && (i.comp_code > '05' && i.comp_code < '16'))
          {
            // this.temp_shp_compwt_ee = 0;
          this.shp_comp_wt_ee+=(i.qt_per_min_su *i.comp_tare_wt);
          // this.temp_shp_compwt_ee+= this.shp_comp_wt_ee;
          console.log('Inside Ship_ee',this.shp_comp_wt_ee);
        }
        }
      }
    }

    updateChangesWhileSubmitting()
    {
      // this.enableChnageDialog=false;
      this.submitAPICall();
    }

    closeChangeDialog()
    {
      this.enableChnageDialog=false;

      this.enableChnageDialog_ee=false;

      this.sugrosswtInvalid=false;
      this.cntrgrosswtInvalid=false;
      this.expendable_wgts_InValid = false;

      this.cntrgrosswtInvalidReason=null;
      this.suGrossWtInvalidReason=null;
      this.expendable_wgts_InValidReason = null;

      this.partDimensionsInvalid=false;
      this.partDimensionsInvalidReason=null;

      this.partDimensionsInvalid_ee=false;
      this.partDimensionInvalidReason_ee=null;

      this.cntrgrosswtInvalid_ee=false;
      this.cntrgrosswtInvalidReason_ee=null;
      this.sugrosswtInvalid_ee=false;
      this.suGrossWtInvalidReason_ee=null;
      this.expendable_wgts_InValid_ee = false;
      this.expendable_wgts_InValidReason_ee = null;
      
        this.sutarewtInvalid=false;
      this.sutarewtInvalidReason=null;
      this.sutarewtInvalid_ee=false;
      this.sutarewtInvalidReason_ee=null;

      this.suExtHgtInvalid=false;
      this.suExtHgtInvalidReason=null;
      this.suNstFldHgtInvalid=false;
      this.suNstFldHgtInvalidReason=null;

      this.suExtHgtInvalid_ee=false;
      this.suExtHgtInvalidReason_ee=null;
      this.suNstFldHgtInvalid_ee=false;
      this.suNstFldHgtInvalidReason_ee=null;
      
      this.reasonForNotSubmitting="";
      this.updateCntrDetsReturnable = false;
      this.updateCntrDetsReturnableReason = null
    }

    checkValidationWhileSubmittingChanges()
    {
      this.expAidsWgtValidationCheck();
      if(this.newProposalDetailsForChangeTrack.shippingUnitGrossWeight<=0  || this.newProposalDetailsForChangeTrack.shippingUnitGrossWeight>999999.999)
      {
        this.sugrosswtInvalid=true;
        this.suGrossWtInvalidReason="Shipping Unit Gross Weight Should be between 0 to 999999.999";
      }
      else
      {
        this.sugrosswtInvalid=false;
         this.suGrossWtInvalidReason=null;
      }

      if(this.newProposalDetailsForChangeTrack.cntrGrossWeight<=0 || this.newProposalDetailsForChangeTrack.cntrGrossWeight>999999.999)
      {
        this.cntrgrosswtInvalid=true;
        this.cntrgrosswtInvalidReason="Container Gross Weight Should be between 0 to 999999.999";
      }
      else
      {
        this.cntrgrosswtInvalid=false;
        this.cntrgrosswtInvalidReason=null;
      }
      if(this.newProposalDetailsForChangeTrack.shiipingUnitTareWeight<=0  || this.newProposalDetailsForChangeTrack.shiipingUnitTareWeight>999999.999)
      {
        this.sutarewtInvalid=true;
        this.sutarewtInvalidReason="Shipping Unit Tare Weight Should be between 0 to 999999.999";
      }
      else
      {
        this.sutarewtInvalid=false;
         this.sutarewtInvalidReason=null;
      }

      if(this.tab2data.shp_unt_ext_height<1 || this.tab2data.shp_unt_ext_height>9999.999)
      {
        this.suExtHgtInvalid=true;
        this.suExtHgtInvalidReason="Shipping Unit Exterior Height Should be of range 1 to 9999.999";
      }
      else
      {
        this.suExtHgtInvalid=false;
        this.suExtHgtInvalidReason=null;
      }

      if(this.tab2data.shp_unt_nestd_fld_height<1 || this.tab2data.shp_unt_nestd_fld_height>99999)
      {
        this.suNstFldHgtInvalid=true;
        this.suNstFldHgtInvalidReason="Shipping Unit Nested Folded Height Should be of range 1 to 99999";
      }
      else if(this.tab2data.shp_unt_nestd_fld_height > 0 && this.tab2data.shp_unt_ext_height > 0 && this.tab2data.shp_unt_nestd_fld_height > this.tab2data.shp_unt_ext_height)
      {
        this.suNstFldHgtInvalid=false;
        this.suNstFldHgtInvalidReason="Shipping Unit Nested Folded height cannot be Greater than Shipping Unit Exterior height";
      }
      else
      {
        this.suNstFldHgtInvalid=false;
        this.suNstFldHgtInvalidReason=null;
      }
     
     
     
      if(this.shp_comp_wt<0 || this.shp_comp_wt>999.9999)
      {
        this.expendable_wgts_InValid = true;
        this.expendable_wgts_InValidReason = 'Expendable Aids Component weights are exceeding 999.9999';
      }
      else
      {
        this.expendable_wgts_InValid = false;
        this.expendable_wgts_InValidReason = null;
      }

      if(this.tab2data.cntr_ret_type.toLowerCase() == "returnable" && JSON.stringify(this.oldProposalDetailsForChnageTrack)!=JSON.stringify(this.newProposalDetailsForChangeTrack))
      {
        
        this.updateCntrDetsReturnable=true;
        this.updateCntrDetsReturnableReason = " The Container Details has been changed, go to Normal Tab2 and update Container Details";
      }
      else
      {
        this.updateCntrDetsReturnable=false;
        this.updateCntrDetsReturnableReason = null;
      }

      if(this.selectedPartsGet!=null)
      {
        for(const i of this.selectedPartsGet)
        {
          if(i.ngpp_part_l > this.newProposalDetailsForChangeTrack.cntrExtLength || i.ngpp_part_w > this.newProposalDetailsForChangeTrack.cntrExtwidth || i.ngpp_part_h > this.newProposalDetailsForChangeTrack.cntrExtHeight)
          {
            this.partDimensionsInvalid=true;
            this.partDimensionsInvalidReason="Part Dimensions is greater than Container Dimensions";
            break;
          }
        }
      }

    }

    checkValidationWhileSubmittingChangesForEE()
    {
      this.expAidsWgtValidationCheck_ee();
      console.log("Inside EE_validate",this.shp_comp_wt_ee)
      if(this.newProposalDetailsForChangeTrack_ee.shippingUnitGrossWeight<=0  || this.newProposalDetailsForChangeTrack_ee.shippingUnitGrossWeight>999999.999)
      {
        this.sugrosswtInvalid_ee=true;
        this.suGrossWtInvalidReason_ee="Shipping Unit Gross Weight In EE Proposal Should be between 0 to 999999.999";
      }
      else
      {
        this.sugrosswtInvalid_ee=false;
         this.suGrossWtInvalidReason_ee=null;
      }

      if(this.newProposalDetailsForChangeTrack_ee.cntrGrossWeight<=0 || this.newProposalDetailsForChangeTrack_ee.cntrGrossWeight>999999.999)
      {
        this.cntrgrosswtInvalid_ee=true;
        this.cntrgrosswtInvalidReason_ee="Container Gross Weight In EE Proposal Should be between 0 to 999999.999";
      }
      else
      {
        this.cntrgrosswtInvalid_ee=false;
        this.cntrgrosswtInvalidReason_ee=null;
      }
      if(this.newProposalDetailsForChangeTrack_ee.shiipingUnitTareWeight<=0  || this.newProposalDetailsForChangeTrack_ee.shiipingUnitTareWeight>999999.999)
      {
        this.sutarewtInvalid_ee=true;
        this.sutarewtInvalidReason_ee="Shipping Unit Tare Weight In EE Proposal Should be between 0 to 999999.999";
      }
      else
      {
        this.sutarewtInvalid_ee=false;
         this.sutarewtInvalidReason_ee=null;
      }
      if(this.tab2data_ee.shp_unt_ext_height<1 || this.tab2data_ee.shp_unt_ext_height>9999.999)
      {
        this.suExtHgtInvalid_ee=true;
        this.suExtHgtInvalidReason_ee="Shipping Unit Exterior Height In EE Proposal Should be of range 1 to 9999.999";
      }
      else
      {
        this.suExtHgtInvalid_ee=false;
        this.suExtHgtInvalidReason_ee=null;
      }

      if(this.tab2data_ee.shp_unt_nestd_fld_height<1 || this.tab2data_ee.shp_unt_nestd_fld_height>99999)
      {
        this.suNstFldHgtInvalid_ee=true;
        this.suNstFldHgtInvalidReason_ee="Shipping Unit Nested Folded Height In EE Proposal Should be of range 1 to 99999";
      }
      else if(this.tab2data_ee.shp_unt_nestd_fld_height > 0 && this.tab2data_ee.shp_unt_ext_height > 0 && this.tab2data_ee.shp_unt_nestd_fld_height > this.tab2data_ee.shp_unt_ext_height)
      {
        this.suNstFldHgtInvalid_ee=false;
        this.suNstFldHgtInvalidReason_ee="Shipping Unit Nested Folded height in EE Proposal cannot be Greater than Shipping Unit Exterior height";
      }
      else
      {
        this.suNstFldHgtInvalid_ee=false;
        this.suNstFldHgtInvalidReason_ee=null;
      }
      if(this.shp_comp_wt_ee<0 || this.shp_comp_wt_ee>999.9999)
      {
        this.expendable_wgts_InValid_ee = true;
        this.expendable_wgts_InValidReason_ee = 'EE - Expendable Aids Component weights are exceeding 999.9999';
      }
      else
      {
        this.expendable_wgts_InValid_ee = false;
        this.expendable_wgts_InValidReason_ee = null;
      }
      if(this.selectedPartsGet!=null)
      {
        for(const i of this.selectedPartsGet)
        {
          if(i.ngpp_part_l > this.newProposalDetailsForChangeTrack_ee.cntrExtLength || i.ngpp_part_w > this.newProposalDetailsForChangeTrack_ee.cntrExtwidth || i.ngpp_part_h > this.newProposalDetailsForChangeTrack_ee.cntrExtHeight)
          {
            this.partDimensionsInvalid_ee=true;
            this.partDimensionInvalidReason_ee="Part Dimensions is greater than EE-Container Dimensions";
            break;
          }
        }
      }
    }

    submitAPICall()
    {
      console.log('Test_EE');
      // this.expAidsWgtValidationCheck();
      this.loaderimgee=true;
      let mark_submit;
      mark_submit=0;

      this.enableNotificationDialog=false;
      this.notificationText='';
      this.enableNotificationDialog_ee=false;
      this.notificationText_ee='';
      this.notificationText_ee="";
      this.notificationTextSuNstFldHgt="";
      this.notificationTextSuHgt="";
      this.notificationTextSuNstFldHgt_ee="";
      this.notificationTextSuHgt_ee="";

      this.enablePartDimensionNotification=false;
      this.store_proposal_values.propsl_type=this.selectedpart_submitter_image_ee.propsl_type;

      if(this.enableChnageDialog || this.enableChnageDialog_ee)
      {

	//Removed && !this.partDimensionsInvalid && !this.partDimensionsInvalid_ee
        if(!this.cntrgrosswtInvalid && !this.sutarewtInvalid && !this.sugrosswtInvalid && !this.expendable_wgts_InValid && !this.suExtHgtInvalid && !this.suNstFldHgtInvalid
	&& !this.cntrgrosswtInvalid_ee && !this.sutarewtInvalid_ee && !this.sugrosswtInvalid_ee && !this.expendable_wgts_InValid_ee && !this.suExtHgtInvalid_ee && !this.suNstFldHgtInvalid_ee)
      {

        if(this.enableChnageDialog)
        {
          this.tab2DataForPost=this.tab2data;
          this.tab3DataForPost=this.tab3detailsGet;

          this.tab2DataForPost.cntr_tar_weight=this.newProposalDetailsForChangeTrack.cntrTareWt;
          this.tab2DataForPost.cntr_nsted_fld_height=this.newProposalDetailsForChangeTrack.cntrNestedFldedHt;
          this.tab2DataForPost.cntr_ext_len=this.newProposalDetailsForChangeTrack.cntrExtLength;
          this.tab2DataForPost.cntr_width=this.newProposalDetailsForChangeTrack.cntrExtwidth;
          this.tab2DataForPost.cntr_height=this.newProposalDetailsForChangeTrack.cntrExtHeight;
          // this.tab2DataForPost.max_layrs_shp_unt=this.newProposalDetailsForChangeTrack.maxLayersPerSu;
          // this.tab2DataForPost.cntr_per_layer=this.newProposalDetailsForChangeTrack.cntrPerLayer;
          // this.tab2DataForPost.part_per_shp_unit=this.newProposalDetailsForChangeTrack.partsPerSu;
          this.tab2DataForPost.max_cntr_per_shp_unt=this.newProposalDetailsForChangeTrack.maxCntrsPerSu;
          this.tab2DataForPost.shp_unt_base=this.newProposalDetailsForChangeTrack.shippingUnitBase;
          this.tab2DataForPost.shp_unt_suff=this.newProposalDetailsForChangeTrack.shippingUnitSuffix;
          // this.tab2DataForPost.shp_unit_ext_len=this.newProposalDetailsForChangeTrack.shippingExtLen;
          // this.tab2DataForPost.shp_unt_ext_width=this.newProposalDetailsForChangeTrack.shippingExtWidth;
          // this.tab2DataForPost.shp_unt_ext_height=this.newProposalDetailsForChangeTrack.shippingExtHeight;
          // this.tab2DataForPost.shp_unt_nestd_fld_height=this.newProposalDetailsForChangeTrack.shippingUnitNestedFoldedht;
          this.tab2DataForPost.shp_unt_ret_type=this.newProposalDetailsForChangeTrack.shippingUnitReturnType;

          this.tab3DataForPost.shp_unt_tar_wt=this.newProposalDetailsForChangeTrack.shiipingUnitTareWeight;
          this.tab3DataForPost.cntr_gross_wt=this.newProposalDetailsForChangeTrack.cntrGrossWeight;
          this.tab3DataForPost.shp_unt_gross_wt=this.newProposalDetailsForChangeTrack.shippingUnitGrossWeight;
          this.store_proposal_values.cntr_tar_weight=this.newProposalDetailsForChangeTrack.cntrTareWt;
          this.store_proposal_values.cntr_nsted_fld_height=this.newProposalDetailsForChangeTrack.cntrNestedFldedHt;
          this.store_proposal_values.cntr_ext_len=this.newProposalDetailsForChangeTrack.cntrExtLength;
          this.store_proposal_values.cntr_width=this.newProposalDetailsForChangeTrack.cntrExtwidth;
          this.store_proposal_values.cntr_height=this.newProposalDetailsForChangeTrack.cntrExtHeight;
          // this.store_proposal_values.max_layrs_shp_unt=this.newProposalDetailsForChangeTrack.maxLayersPerSu;
          // this.store_proposal_values.cntr_per_layer=this.newProposalDetailsForChangeTrack.cntrPerLayer;
          // this.store_proposal_values.part_per_shp_unit=this.newProposalDetailsForChangeTrack.partsPerSu;
          this.store_proposal_values.max_cntr_per_shp_unt=this.newProposalDetailsForChangeTrack.maxCntrsPerSu;
          this.store_proposal_values.shp_unt_base=this.newProposalDetailsForChangeTrack.shippingUnitBase;
          this.store_proposal_values.shp_unt_suff=this.newProposalDetailsForChangeTrack.shippingUnitSuffix;
          // this.store_proposal_values.shp_unit_ext_len=this.newProposalDetailsForChangeTrack.shippingExtLen;
          // this.store_proposal_values.shp_unt_ext_width=this.newProposalDetailsForChangeTrack.shippingExtWidth;
          // this.store_proposal_values.shp_unt_ext_height=this.newProposalDetailsForChangeTrack.shippingExtHeight;
          // this.store_proposal_values.shp_unt_nestd_fld_height=this.newProposalDetailsForChangeTrack.shippingUnitNestedFoldedht;
          this.store_proposal_values.shp_unt_ret_type=this.newProposalDetailsForChangeTrack.shippingUnitReturnType;

          this.store_proposal_values.shp_unt_tar_wt=this.newProposalDetailsForChangeTrack.shiipingUnitTareWeight;
          this.store_proposal_values.cntr_gross_wt=this.newProposalDetailsForChangeTrack.cntrGrossWeight;
          this.store_proposal_values.shp_unt_gross_wt=this.newProposalDetailsForChangeTrack.shippingUnitGrossWeight;

          this.transactionService.savetab2details(this.tab2DataForPost).subscribe(e=>{
            console.log('success',e);

            if(e.toLowerCase()=='success')
            {
              this.transactionService.savetab3details(this.tab3DataForPost).subscribe(tab3Post=>{
                if(tab3Post.toLowerCase()=='success')
                {
                  this.transactionService.submit_proposal_details(this.store_proposal_values,mark_submit).subscribe(subdet=>
                  {

                    if(subdet=='Success')
                    {
                      console.log('subdetails',this.store_proposal_values);
                      this.transactionService.submit_proposal_parts(this.store_proposal_selectedparts).subscribe(subparts=>
                      {
                        if(subparts=='Success')
                        {
                          console.log('parts save',this.store_proposal_selectedparts);
                          if(this.store_proposal_componenets.length!=0)
                          {
                            this.transactionService.submit_proposal_components(this.store_proposal_componenets).subscribe(saveattributes=>
                            {
                              const op=saveattributes;
                              console.log('successful',op);
                              if(saveattributes=='Success')
                              {
                                const message='Proposal '+this.selectedpart_submitter_image_ee.simplifiedpartno+ ' submitted successfully\'';

                                      this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'success', detail:message});
                                this.enableChnageDialog=false;
                                this.submit_proposal_service_ee()
                              }
                              else
                              {
                                this.loaderimgee=false;
                                this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Comp Not sub,Please Try Again'});
                              }

                            });
                          }
                          else
                          {
                            this.transactionService.deleteComponentsFromTxmMaster(this.store_proposal_values.id_proposal).subscribe(deleteCompStatus=>{
                              let deleteStatus=deleteCompStatus;
    
                              if(deleteStatus.toLowerCase()=="success")
                              {
              
                                var message="Proposal "+this.selectedpart_submitter_image_ee.simplifiedpartno+ " submitted successfully'";

                                          this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'success', detail:message});
                                this.enableChnageDialog=false;
                                this.submit_proposal_service_ee()
                              }
                              else{
                                this.loaderimgee=false;
                                this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Comp Not sub,Please Try Again'})  
                        
                              }

                            });
                          }
                        }
                        else
                        {
                          this.loaderimgee=false;
                          this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Parts not submitted,Please Try Again'})
                        }
                      });
                    }
                    else
                    {
                      this.loaderimgee=false;
                       this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Proposal details not submitted,Please Try Again'})
                    }
                  });
                }
                else
                {
                  this.loaderimgee=false;
                  this.messageService.add({severity:'error', summary: 'Error', detail: 'Component and Material details is not updated with changes,Please Try Again'})
                }
              });
            }
            else
            {
              this.loaderimgee=false;
              this.messageService.add({severity:'error', summary: 'Error', detail: 'COS,Container and Shipping Unit details is not updated with changes,Please Try Again'})
            }
          });
        }
      
        else
        {
              this.transactionService.submit_proposal_details(this.store_proposal_values,mark_submit).subscribe(subdet=>
                {
                
                if(subdet=="Success")
                {
                  console.log("subdetails",this.store_proposal_values);
                  this.transactionService.submit_proposal_parts(this.store_proposal_selectedparts).subscribe(subparts=>
                  {   
                    if(subparts=="Success")
                    {
                      console.log("parts save",this.store_proposal_selectedparts);
                      if(this.store_proposal_componenets.length!=0)
                      {
                        this.transactionService.submit_proposal_components(this.store_proposal_componenets).subscribe(saveattributes=>
                        {
                          let op=saveattributes;
                          console.log("successful",op);
                          if(saveattributes=="Success")
                          {
                            var message="Proposal "+this.selectedpart_submitter_image_ee.simplifiedpartno+ " submitted successfully'";
  
                                        this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'success', detail:message});
                            this.submit_proposal_service_ee()  
                          }
                          else
                          {
                            this.loaderimgee=false;
                            this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Comp Not sub,Please Try Again'});
                          }
                          
                        });
                      }
                      else
                      {
                        this.transactionService.deleteComponentsFromTxmMaster(this.store_proposal_values.id_proposal).subscribe(deleteCompStatus=>{
                          let deleteeStatus=deleteCompStatus;

                          if(deleteeStatus.toLowerCase()=="success")
                          {
                            var message="Proposal "+this.selectedpart_submitter_image_ee.simplifiedpartno+ " submitted successfully'";
      
                                            this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'success', detail:message});
                            this.submit_proposal_service_ee()
                          }
                          else
                          {
                            this.loaderimgee=false;
                            this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Comp not submited,Please Try Again'})  
                          }
                        });
                      }
                    }
                    else
                    {
                      this.loaderimgee=false;
                      this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Parts not submited,Please Try Again'})  
                    }
                  });
                }
                else
                {
                  this.loaderimgee=false;
                   this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Proposal details not submitted,Please Try Again'})
                }
              });
            
        }
      }
      else
      {
        this.loaderimgee=false;
      }
    }
      else
      {
        if(this.enableChnageDialog_ee==false && this.enableChnageDialog==false)
        {
          var enablePopUp=false;
          this.notificationText="";
          this.notificationTextSuNstFldHgt="";
          this.notificationTextSuHgt="";
          if(this.selectedPartsGet!=null)
          {
            let flag=false;
            for(const i of this.selectedPartsGet)
            {
              if(i.ngpp_part_l > this.tab2data.cntr_ext_len || i.ngpp_part_w > this.tab2data.cntr_width || i.ngpp_part_h > this.tab2data.cntr_height)
              {
                flag=true;
                break;
              }
            }

            if(flag)
            {
		        this.enablePartDimensionNotification=true;
              //enablePopUp=true;
              this.notificationText="Part Dimensions is Greater Than Container Dimensions!";
            }
          }
          if(this.tab2data.shp_unt_ext_height<1 || this.tab2data.shp_unt_ext_height>9999.999)
            {
              enablePopUp=true;
              this.notificationTextSuHgt="Shipping Unit Exterior Height Should be of range 1 to 9999.999";
            }
            if(this.tab2data.shp_unt_nestd_fld_height > 0 && this.tab2data.shp_unt_ext_height > 0 && this.tab2data.shp_unt_nestd_fld_height > this.tab2data.shp_unt_ext_height)
            {
              enablePopUp=true;
              this.notificationTextSuNstFldHgt="Shipping Unit Nested Folded height cannot be Greater than Shipping Unit Exterior height";
            }

            

          var enablePopUp_ee=false;
          this.notificationText_ee="";
          this.notificationTextSuHgt_ee="";
          this.notificationTextSuNstFldHgt_ee="";
          if(this.selectedPartsGet!=null)
          {
            var flag_ee=false;
            for(var i of this.selectedPartsGet)
            {
              if(i.ngpp_part_l > this.tab2data_ee.cntr_ext_len || i.ngpp_part_w > this.tab2data_ee.cntr_width || i.ngpp_part_h > this.tab2data_ee.cntr_height)
              {
                flag_ee=true;
                break;
              }
            }
  
            if(flag_ee)
            {
             this.enablePartDimensionNotification=true;
                //enablePopUp_ee=true;
                this.notificationText_ee="Part Dimensions is Greater Than EE-Container Dimensions!";
            }
          }
          if(this.tab2data_ee.shp_unt_ext_height<1 || this.tab2data_ee.shp_unt_ext_height>9999.999)
          {
            enablePopUp_ee=true;
            this.notificationTextSuHgt_ee="Shipping Unit Exterior Height In EE Proposal Should be of range 1 to 9999.999";
          }
          if(this.tab2data_ee.shp_unt_nestd_fld_height > 0 && this.tab2data_ee.shp_unt_ext_height > 0 && this.tab2data_ee.shp_unt_nestd_fld_height > this.tab2data_ee.shp_unt_ext_height)
          {
            enablePopUp_ee=true;
            this.notificationTextSuNstFldHgt_ee="Shipping Unit Nested Folded height In EE Proposal cannot be Greater than Shipping Unit Exterior height";
          }

          if(enablePopUp)
            {
              this.loaderimgee=false;
              this.enableNotificationDialog=true;
            }
            else
            {
              this.enableNotificationDialog=false;
              //this.notificationText="";
              this.notificationTextSuNstFldHgt="";
              this.notificationTextSuHgt="";
            }

          if(enablePopUp_ee)
          {
            this.loaderimgee=false;
            this.enableNotificationDialog_ee=true;
          }
          else
          {
            this.enableNotificationDialog_ee=false;
            //this.notificationText_ee="";
            this.notificationTextSuNstFldHgt_ee="";
            this.notificationTextSuHgt_ee="";
          }
 	        
  
        }

      if(this.enableNotificationDialog==false && this.enableNotificationDialog_ee==false && this.enablePartDimensionNotification==true)
      {
        this.loaderimgee=false;
      }

      if(this.enableNotificationDialog==false && this.enableNotificationDialog_ee==false && this.enablePartDimensionNotification==false)
      {
        this.submitspecification();
      }

    } 
     
    
    }
    
    submitspecification()
    {
      var mark_submit;
      mark_submit=0;
          if(this.enableNotificationDialog==false && this.enableNotificationDialog_ee == false)
          {
            this.transactionService.submit_proposal_details(this.store_proposal_values,mark_submit).subscribe(subdet=>
              {

              if(subdet=='Success')
              {
                console.log('subdetails',this.store_proposal_values);
                this.transactionService.submit_proposal_parts(this.store_proposal_selectedparts).subscribe(subparts=>
                {
                  if(subparts=='Success')
                  {
                    console.log('parts save',this.store_proposal_selectedparts);
                    if(this.store_proposal_componenets.length!=0)
                    {
                      this.transactionService.submit_proposal_components(this.store_proposal_componenets).subscribe(saveattributes=>
                      {
                        const op=saveattributes;
                        console.log('successful',op);
                        if(saveattributes=='Success')
                        {
                          const message='Proposal '+this.selectedpart_submitter_image_ee.simplifiedpartno+ ' submitted successfully\'';

                                      this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'success', detail:message});
                          this.submit_proposal_service_ee()
                        }
                        else
                        {
                          this.loaderimgee=false;
                          this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Comp Not sub,Please Try Again'});
                        }

                      });
                    }
                    else
                    {
                      this.transactionService.deleteComponentsFromTxmMaster(this.store_proposal_values.id_proposal).subscribe(deleteCompStatus=>{
                        let deleteestatus=deleteCompStatus;

                        if(deleteestatus.toLowerCase()=="success")
                        {
                          var message="Proposal "+this.selectedpart_submitter_image_ee.simplifiedpartno+ " submitted successfully'";

                                          this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'success', detail:message});
                          this.submit_proposal_service_ee()
                        }
                        else
                        {
                          this.loaderimgee=false;
                          this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Comp not submited,Please Try Again'})  
                        }
                      });
                    }
                  }
                  else
                  {
                    this.loaderimgee=false;
                    this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Parts not submited,Please Try Again'})
                  }
                });
              }
              else
              {
                this.loaderimgee=false;
                 this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Proposal details not submitted,Please Try Again'})
              }
            });
          }
        


    }

    okEnbaleNotification()
    {
      this.notificationText='';
      this.enableNotificationDialog=false;

      this.notificationText_ee='';
      this.enableNotificationDialog_ee=false;

      this.enablePartDimensionNotification=false;
      
      this.notificationTextSuNstFldHgt="";
      this.notificationTextSuHgt="";
      this.notificationTextSuNstFldHgt_ee="";
      this.notificationTextSuHgt_ee="";
    }

    submit_proposal_service_ee()
    {


      console.log('submit');
      console.log(this.store_proposal_values_ee);
      console.log(this.store_proposal_componenets_ee);
      console.log(this.store_proposal_selectedparts_ee);


      this.store_proposal_values_ee.apprvr_cdsid=this.curr_cdsid_ee;
      let cur_date;
      let cur_date_transform
      cur_date=new Date();
      cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
      console.log(this.user_details_ee)
      this.store_proposal_values_ee.revision_hstry+=this.curr_cdsid_ee+'`'+this.user_details_ee.name+'`'+this.user_details_ee.email+'`Packaging Proposal Submitted`Proposal Submitted`'+cur_date_transform+'`-';





      let mark_submit_ee;
      mark_submit_ee=1




      if(this.enableChnageDialog_ee)
      {
       
          this.tab2DataForPost_ee=this.tab2data_ee;
          this.tab3DataForPost_ee=this.tab3detailsGet_ee;

          this.tab2DataForPost_ee.cntr_tar_weight=this.newProposalDetailsForChangeTrack_ee.cntrTareWt;
          this.tab2DataForPost_ee.cntr_nsted_fld_height=this.newProposalDetailsForChangeTrack_ee.cntrNestedFldedHt;
          this.tab2DataForPost_ee.cntr_ext_len=this.newProposalDetailsForChangeTrack_ee.cntrExtLength;
          this.tab2DataForPost_ee.cntr_width=this.newProposalDetailsForChangeTrack_ee.cntrExtwidth;
          this.tab2DataForPost_ee.cntr_height=this.newProposalDetailsForChangeTrack_ee.cntrExtHeight;
          // this.tab2DataForPost_ee.max_layrs_shp_unt=this.newProposalDetailsForChangeTrack_ee.maxLayersPerSu;
          // this.tab2DataForPost_ee.cntr_per_layer=this.newProposalDetailsForChangeTrack_ee.cntrPerLayer;
          // this.tab2DataForPost_ee.part_per_shp_unit=this.newProposalDetailsForChangeTrack_ee.partsPerSu;
          this.tab2DataForPost_ee.max_cntr_per_shp_unt=this.newProposalDetailsForChangeTrack_ee.maxCntrsPerSu;
          this.tab2DataForPost_ee.shp_unt_base=this.newProposalDetailsForChangeTrack_ee.shippingUnitBase;
          this.tab2DataForPost_ee.shp_unt_suff=this.newProposalDetailsForChangeTrack_ee.shippingUnitSuffix;
          // this.tab2DataForPost_ee.shp_unit_ext_len=this.newProposalDetailsForChangeTrack_ee.shippingExtLen;
         // this.tab2DataForPost_ee.shp_unt_ext_width=this.newProposalDetailsForChangeTrack_ee.shippingExtWidth;
         // this.tab2DataForPost_ee.shp_unt_ext_height=this.newProposalDetailsForChangeTrack_ee.shippingExtHeight;
          // this.tab2DataForPost_ee.shp_unt_nestd_fld_height=this.newProposalDetailsForChangeTrack_ee.shippingUnitNestedFoldedht;
          this.tab2DataForPost_ee.shp_unt_ret_type=this.newProposalDetailsForChangeTrack_ee.shippingUnitReturnType;

          this.tab3DataForPost_ee.shp_unt_tar_wt=this.newProposalDetailsForChangeTrack_ee.shiipingUnitTareWeight;
          this.tab3DataForPost_ee.cntr_gross_wt=this.newProposalDetailsForChangeTrack_ee.cntrGrossWeight;
          this.tab3DataForPost_ee.shp_unt_gross_wt=this.newProposalDetailsForChangeTrack_ee.shippingUnitGrossWeight;
          this.store_proposal_values_ee.cntr_tar_weight=this.newProposalDetailsForChangeTrack_ee.cntrTareWt;
          this.store_proposal_values_ee.cntr_nsted_fld_height=this.newProposalDetailsForChangeTrack_ee.cntrNestedFldedHt;
          this.store_proposal_values_ee.cntr_ext_len=this.newProposalDetailsForChangeTrack_ee.cntrExtLength;
          this.store_proposal_values_ee.cntr_width=this.newProposalDetailsForChangeTrack_ee.cntrExtwidth;
          this.store_proposal_values_ee.cntr_height=this.newProposalDetailsForChangeTrack_ee.cntrExtHeight;
          // this.store_proposal_values_ee.max_layrs_shp_unt=this.newProposalDetailsForChangeTrack_ee.maxLayersPerSu;
         // this.store_proposal_values_ee.cntr_per_layer=this.newProposalDetailsForChangeTrack_ee.cntrPerLayer;
         // this.store_proposal_values_ee.part_per_shp_unit=this.newProposalDetailsForChangeTrack_ee.partsPerSu;
          this.store_proposal_values_ee.max_cntr_per_shp_unt=this.newProposalDetailsForChangeTrack_ee.maxCntrsPerSu;
          this.store_proposal_values_ee.shp_unt_base=this.newProposalDetailsForChangeTrack_ee.shippingUnitBase;
          this.store_proposal_values_ee.shp_unt_suff=this.newProposalDetailsForChangeTrack_ee.shippingUnitSuffix;
          // this.store_proposal_values_ee.shp_unit_ext_len=this.newProposalDetailsForChangeTrack_ee.shippingExtLen;
          // this.store_proposal_values_ee.shp_unt_ext_width=this.newProposalDetailsForChangeTrack_ee.shippingExtWidth;
          // this.store_proposal_values_ee.shp_unt_ext_height=this.newProposalDetailsForChangeTrack_ee.shippingExtHeight;
          // this.store_proposal_values_ee.shp_unt_nestd_fld_height=this.newProposalDetailsForChangeTrack_ee.shippingUnitNestedFoldedht;
          this.store_proposal_values_ee.shp_unt_ret_type=this.newProposalDetailsForChangeTrack_ee.shippingUnitReturnType;

          this.store_proposal_values_ee.shp_unt_tar_wt=this.newProposalDetailsForChangeTrack_ee.shiipingUnitTareWeight;
          this.store_proposal_values_ee.cntr_gross_wt=this.newProposalDetailsForChangeTrack_ee.cntrGrossWeight;
          this.store_proposal_values_ee.shp_unt_gross_wt=this.newProposalDetailsForChangeTrack_ee.shippingUnitGrossWeight;

          this.transactionService.savetab2details_ee(this.tab2DataForPost_ee).subscribe(e=>{
            console.log('success',e);

            if(e.toLowerCase()=='success')
            {
              this.transactionService.savetab3details_ee(this.tab3DataForPost_ee).subscribe(tab3Post=>{
                if(tab3Post.toLowerCase()=='success')
                {
                  this.transactionService.submit_proposal_details_ee(this.store_proposal_values_ee,mark_submit_ee).subscribe(subdet=>
                    {

                      if(subdet=='Success')
                      {
                        console.log('subdetails',this.store_proposal_values_ee);
                              console.log('parts save',this.store_proposal_selectedparts_ee);
                              if(this.store_proposal_componenets_ee.length!=0)
                              {
                                 this.transactionService.submit_proposal_components_ee(this.store_proposal_componenets_ee).subscribe(saveattributes=>
                                {
                                  const op=saveattributes;
                                  console.log('successful',op);
                                  if(saveattributes=='Success')
                                  {

                                    let cur_date;
                                      let cur_date_transform
                                      cur_date=new Date();
                                      cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
                                      console.log(this.user_details_ee)
                                      // this.store_proposal_values.revision_hstry+=this.curr_cdsid+"`"+this.user_details.name+"`"+this.user_details.email+"`Packaging Proposal Submitted`Proposal Submitted`"+cur_date_transform+"`-";
                                      this.revisionHistoryForPostInit_ee();
                                      this.revisionHistoryForPost_ee.userid=this.curr_cdsid_ee;
                                      this.revisionHistoryForPost_ee.comments='-';
                                      this.revisionHistoryForPost_ee.apprvractn='Proposal Submitted';
                                      this.revisionHistoryForPost_ee.email=this.user_details_ee.email;
                                      this.revisionHistoryForPost_ee.usrname=this.user_details_ee.name;
                                      this.revisionHistoryForPost_ee.evnttime=cur_date_transform;
                                      this.revisionHistoryForPost_ee.id_proposal=this.selectedpart_submitter_image_ee.proposalid;
                                      this.revisionHistoryForPost_ee.proposalstatuscurr='Packaging Proposal Submitted';

                                      console.log('revisionHistoryForPost before api call value',this.revisionHistoryForPost_ee)

                                      this.transactionService.postRevisionHistory(this.revisionHistoryForPost_ee).subscribe(postrevres=>{
                                        console.log('postRevisionHistory API o/p', postrevres)
                                        if(postrevres.toLowerCase() == 'success')
                                        {

                                        this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'success', detail:'EE-Proposal submited successfuly'});
                                        this.enableChnageDialog_ee=false;
                                        this.redirect_submitter_ee()
                                        this.loaderimgee=false;
                                        }
                                        else
                                        {
                                          this.loaderimgee=false;
                                          this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Error while updating Revision History,Please Try Again'});
                                        }
                                      })



                                  }
                                  else
                                  {
                                    this.loaderimgee=false;
                                    this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'EE-Comp Not sub,Please Try Again'});
                                  }

                              });
                            }


                            else{

                              this.transactionService.deleteComponentsFromTxmMasterEE(this.store_proposal_values.id_proposal).subscribe(deleteCompStatus=>{
                                let deleteestatus=deleteCompStatus;
        
                                if(deleteestatus.toLowerCase()=="success")
                                {
                              
                                  var message="EE-Proposal "+this.selectedpart_submitter_image_ee.simplifiedpartno+ " submitted successfully'";
                              let cur_date;
                              let cur_date_transform
                              cur_date=new Date();
                              cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
                              console.log(this.user_details_ee)
                              // this.store_proposal_values.revision_hstry+=this.curr_cdsid+"`"+this.user_details.name+"`"+this.user_details.email+"`Packaging Proposal Submitted`Proposal Submitted`"+cur_date_transform+"`-";
                              this.revisionHistoryForPostInit_ee();
                              this.revisionHistoryForPost_ee.userid=this.curr_cdsid_ee;
                              this.revisionHistoryForPost_ee.comments='-';
                              this.revisionHistoryForPost_ee.apprvractn='Proposal Submitted';
                              this.revisionHistoryForPost_ee.email=this.user_details_ee.email;
                              this.revisionHistoryForPost_ee.usrname=this.user_details_ee.name;
                              this.revisionHistoryForPost_ee.evnttime=cur_date_transform;
                              this.revisionHistoryForPost_ee.id_proposal=this.selectedpart_submitter_image_ee.proposalid;
                              this.revisionHistoryForPost_ee.proposalstatuscurr='Packaging Proposal Submitted';

                              console.log('revisionHistoryForPost before api call value',this.revisionHistoryForPost_ee)

                              this.transactionService.postRevisionHistory(this.revisionHistoryForPost_ee).subscribe(postrevres=>{
                                console.log('postRevisionHistory API o/p', postrevres)
                                if(postrevres.toLowerCase() == 'success')
                                {

                                  this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'success', detail:message});
                                    this.enableChnageDialog_ee=false;
                                    this.redirect_submitter_ee()
                                    this.loaderimgee=false;
                                }
                                else
                                {
                                  this.loaderimgee=false;
                                  this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Error while updating Revision History,Please Try Again'});
                                }
                              })
                                }
                                else
                                {
                                  this.loaderimgee=false;
                                  this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'EE-Comp details not submitted,Please Try Again'})
                               
                                }
                              });

            
                            }
                          
                      }
                      else{
                        this.loaderimgee=false;
                        this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'EE-Proposal details not submitted,Please Try Again'})
                      }
                    });
                }
                else{
                  this.loaderimgee=false;
                  this.messageService.add({severity:'error', summary: 'Error', detail: 'EE-Component and Material details is not updated with changes,Please Try Again'})

                }
              });
            }
            else
            {
              this.loaderimgee=false;
              this.messageService.add({severity:'error', summary: 'Error', detail: 'EE-COS,Container and Shipping Unit details is not updated with changes,Please Try Again'})

            }
          });
        
        
      }
      else
      {
        
          console.log("store_proposal_values",this.store_proposal_values_ee)
            this.transactionService.submit_proposal_details_ee(this.store_proposal_values_ee,mark_submit_ee).subscribe(subdet=>
              {

                if(subdet=='Success')
                {
                  console.log('subdetails',this.store_proposal_values_ee);
                        console.log('parts save',this.store_proposal_selectedparts_ee);
                        if(this.store_proposal_componenets_ee.length!=0)
                        {
                          this.transactionService.submit_proposal_components_ee(this.store_proposal_componenets_ee).subscribe(saveattributes=>
                          {
                            const op=saveattributes;
                            console.log('successful',op);
                            if(saveattributes=='Success')
                            {
                              var message="EE-Proposal "+this.selectedpart_submitter_image_ee.simplifiedpartno+ " submitted successfully'";
                              let cur_date;
                              let cur_date_transform
                              cur_date=new Date();
                              cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
                              console.log(this.user_details_ee)
                              // this.store_proposal_values.revision_hstry+=this.curr_cdsid+"`"+this.user_details.name+"`"+this.user_details.email+"`Packaging Proposal Submitted`Proposal Submitted`"+cur_date_transform+"`-";
                              this.revisionHistoryForPostInit_ee();
                              this.revisionHistoryForPost_ee.userid=this.curr_cdsid_ee;
                              this.revisionHistoryForPost_ee.comments='-';
                              this.revisionHistoryForPost_ee.apprvractn='Proposal Submitted';
                              this.revisionHistoryForPost_ee.email=this.user_details_ee.email;
                              this.revisionHistoryForPost_ee.usrname=this.user_details_ee.name;
                              this.revisionHistoryForPost_ee.evnttime=cur_date_transform;
                              this.revisionHistoryForPost_ee.id_proposal=this.selectedpart_submitter_image_ee.proposalid;
                              this.revisionHistoryForPost_ee.proposalstatuscurr='Packaging Proposal Submitted';

                              console.log('revisionHistoryForPost before api call value',this.revisionHistoryForPost_ee)

                              this.transactionService.postRevisionHistory(this.revisionHistoryForPost_ee).subscribe(postrevres=>{
                                console.log('postRevisionHistory API o/p', postrevres)
                                if(postrevres.toLowerCase() == 'success')
                                {

                                  this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'success', detail:message});
                                  this.redirect_submitter_ee()
                                  this.loaderimgee=false;
                                }
                                else
                                {
                                  this.loaderimgee=false;
                                  this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Error while updating Revision History,Please Try Again'});
                                }
                              })



                            }
                            else
                            {
                              this.loaderimgee=false;
                              this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'EE-Comp Not sub,Please Try Again'});
                            }

                        });
                      }


                      else{

                        this.transactionService.deleteComponentsFromTxmMasterEE(this.store_proposal_values.id_proposal).subscribe(deleteCompStatus=>{
                          let deleteestatus=deleteCompStatus;
  
                          if(deleteestatus.toLowerCase()=="success")
                          {
                        const message='EE-Proposal '+this.selectedpart_submitter_image_ee.simplifiedpartno+ ' submitted successfully\'';
                              let cur_date;
                              let cur_date_transform
                              cur_date=new Date();
                              cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
                              console.log(this.user_details_ee)
                              // this.store_proposal_values.revision_hstry+=this.curr_cdsid+"`"+this.user_details.name+"`"+this.user_details.email+"`Packaging Proposal Submitted`Proposal Submitted`"+cur_date_transform+"`-";
                              this.revisionHistoryForPostInit_ee();
                              this.revisionHistoryForPost_ee.userid=this.curr_cdsid_ee;
                              this.revisionHistoryForPost_ee.comments='-';
                              this.revisionHistoryForPost_ee.apprvractn='Proposal Submitted';
                              this.revisionHistoryForPost_ee.email=this.user_details_ee.email;
                              this.revisionHistoryForPost_ee.usrname=this.user_details_ee.name;
                              this.revisionHistoryForPost_ee.evnttime=cur_date_transform;
                              this.revisionHistoryForPost_ee.id_proposal=this.selectedpart_submitter_image_ee.proposalid;
                              this.revisionHistoryForPost_ee.proposalstatuscurr='Packaging Proposal Submitted';

                              console.log('revisionHistoryForPost before api call value',this.revisionHistoryForPost_ee)

                              this.transactionService.postRevisionHistory(this.revisionHistoryForPost_ee).subscribe(postrevres=>{
                                console.log('postRevisionHistory API o/p', postrevres)
                                if(postrevres.toLowerCase() == 'success')
                                {

                                  this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'success', detail:message});
                                  this.redirect_submitter_ee()
                                  this.loaderimgee=false;
                                }
                                else
                                {
                                  this.loaderimgee=false;
                                  this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Error while updating Revision History,Please Try Again'});
                                }
                              })
                          }
                          else
                          {
                            this.loaderimgee=false;
                            this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'EE-Comp details not submitted,Please Try Again'})
                          
                          }
                        });
                      }
                }
                else{
                  this.loaderimgee=false;
                  this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'EE-Proposal details not submitted,Please Try Again'})
                }
              });
          }
      
      

    }


    redirect_submitter_ee()
    {


      let reg_supp_url: string;
      reg_supp_url = this.transactionService.getregionurlvalue(this.region_ee);
      this.name_ee=this.transactionService.getregionNameInSmallLetters();
      if(this.selectedpart_submitter_image_ee.propsl_type!=null && this.selectedpart_submitter_image_ee.propsl_type.toLowerCase()=='migration')
      {
        if(this.roleid_image_ee==1)
        {
          console.log('/'+this.name_ee+'/packagingprocess/packsupplieradmin/migrationsubmitter')
          this.router.navigate(['/'+this.name_ee+'/packagingprocess/packsupplieradmin/migrationsubmitter'])
        }
        else if(this.roleid_image_ee==2)
        {
          this.router.navigate(['/'+this.name_ee+'/packagingprocess/packdesignsourceadmin/migrationsubmitter'])
        }
      }
      else // if(this.selectedpart_submitter.propsl_type!=null && this.selectedpart_submitter.propsl_type.toLowerCase()=="normal")
      {
      if(this.roleid_image_ee==1)
      {
        reg_supp_url += 'packagingprocess/packsupplieradmin/submiterdashboard';
      }
      else if ( this.roleid_image_ee==2)
      {
        reg_supp_url += 'packagingprocess/packdesignsourceadmin/submiterdashboard';
      }
      this.router.navigate([reg_supp_url]);
    }


    }


    PartPhotoImage_ee()
    {

      let url = this.baseurl_get_ee+this.imgsave_path_ee+this.image_folder_path_ee+"/PartPhoto.png";
      this.transactionService.getImageFile(url).subscribe((response: any) => {
        this.part_image_ee = URL.createObjectURL(response);
         setTimeout(()=> {
                  let url = URL.createObjectURL(response);
        const img: any = document.querySelector('#part_image_ee');
        img.src = url;
                }, 500);
      });
      console.log("image path display is "+this.part_image_ee);
    }
    InitgetImagePart_ee(id:String)
    {
      let url = this.baseurl_get_ee+this.imgsave_path_ee+id+"/PartPhoto.png";
      this.transactionService.getImageFile(url).subscribe((response: any) => {
        this.part_image_ee = URL.createObjectURL(response);
         setTimeout(()=> {
                  let url = URL.createObjectURL(response);
        const img: any = document.querySelector('#part_image_ee');
        img.src = url;
                }, 500);
      });
      this.tab4alldetails_ee.f_partphoto=this.imgsave_path_ee+id+"/PartPhoto.png";

    }
    intrCntnrPhotoImage_ee()
    {

      let url = this.baseurl_get_ee+this.imgsave_path_ee+this.image_folder_path_ee+"/intrCntnrDsgnPhoto.png";
      this.transactionService.getImageFile(url).subscribe((response: any) => {
        this.interior_image_ee = URL.createObjectURL(response);
         setTimeout(()=> {
                  let url = URL.createObjectURL(response);
        const img: any = document.querySelector('#interior_image_ee');
        img.src = url;
                }, 500);
      });
      console.log("image path display is "+this.interior_image_ee);
    }
    InitgetInteriorContainer_ee(id:String)
    {
      let url =this.baseurl_get_ee+this.imgsave_path_ee+id+"/intrCntnrDsgnPhoto.png";
      this.transactionService.getImageFile(url).subscribe((response: any) => {
        this.interior_image_ee = URL.createObjectURL(response);
         setTimeout(()=> {
                  let url = URL.createObjectURL(response);
        const img: any = document.querySelector('#interior_image_ee');
        img.src = url;
                }, 500);
      });
    this.tab4alldetails_ee.f_intcntrdsgnphoto=this.imgsave_path_ee+id+"/intrCntnrDsgnPhoto.png";
    }
    ExtrCntnrPhotoImage_ee()
    {
      let url = this.baseurl_get_ee+this.imgsave_path_ee+this.image_folder_path_ee+"/extrCntnrDsgnPhoto.png";
      this.transactionService.getImageFile(url).subscribe((response: any) => {
        this.exterior_image_ee = URL.createObjectURL(response);
         setTimeout(()=> {
                  let url = URL.createObjectURL(response);
        const img: any = document.querySelector('#exterior_image_ee');
        img.src = url;
                }, 500);
      });
      console.log("image path display is "+this.exterior_image_ee);
    }
    InitgetExtrCntnrPhotoImage_ee(id:String)
    {

      let url = this.baseurl_get_ee+this.imgsave_path_ee+id+"/extrCntnrDsgnPhoto.png";
      this.transactionService.getImageFile(url).subscribe((response: any) => {
        this.exterior_image_ee = URL.createObjectURL(response);
         setTimeout(()=> {
                  let url = URL.createObjectURL(response);
        const img: any = document.querySelector('#exterior_image_ee');
        img.src = url;
                }, 500);
      });
      console.log("image path display is "+this.exterior_image_ee);
      this.tab4alldetails_ee.f_extcntrdsgnphoto=this.imgsave_path_ee+id+"/extrCntnrDsgnPhoto.png";
    }
    LabelMetalIDCntnrPhotoImage_ee()
    {

      let url = this.baseurl_get_ee+this.imgsave_path_ee+this.image_folder_path_ee+"/labelmetalID.png";
      this.transactionService.getImageFile(url).subscribe((response: any) => {
        this.label_image_ee = URL.createObjectURL(response);
         setTimeout(()=> {
                  let url = URL.createObjectURL(response);
        const img: any = document.querySelector('#label_image_ee');
        img.src = url;
                }, 500);
      });
      console.log("image path display is "+this.label_image_ee);



    }
    InitgetLabelMetalIDPhotoImage_ee(id:String)
    {

     let url = this.baseurl_get_ee+this.imgsave_path_ee+id+"/labelmetalID.png";
      this.transactionService.getImageFile(url).subscribe((response: any) => {
        this.label_image_ee = URL.createObjectURL(response);
         setTimeout(()=> {
                  let url = URL.createObjectURL(response);
        const img: any = document.querySelector('#label_image_ee');
        img.src = url;
                }, 500);
      });
      console.log("image path display is "+this.label_image_ee);
      this.tab4alldetails_ee.f_metelidplatephoto=this.imgsave_path_ee+id+"/labelmetalID.png";
    }


    ShippingUnitPhotoImage_ee()
    {

      let url= this.baseurl_get_ee+this.imgsave_path_ee+this.image_folder_path_ee+"/shpngUntPhoto.png";
      this.transactionService.getImageFile(url).subscribe((response: any) => {
        this.shipping_image_ee = URL.createObjectURL(response);
         setTimeout(()=> {
                  let url = URL.createObjectURL(response);
        const img: any = document.querySelector('#shipping_image_ee');
        img.src = url;
                }, 500);
      });
      console.log("image path display is "+this.shipping_image_ee);
    }

    InitgetShippingUnitPhotoImage_ee(id:String)
        {
          let url = this.baseurl_get_ee+this.imgsave_path_ee+id+"/shpngUntPhoto.png";
          this.transactionService.getImageFile(url).subscribe((response: any) => {
            this.shipping_image_ee = URL.createObjectURL(response);
             setTimeout(()=> {
                  let url = URL.createObjectURL(response);
            const img: any = document.querySelector('#shipping_image_ee');
            img.src = url;
                }, 500);
          });
          console.log("image path display is "+this.shipping_image_ee);
          this.tab4alldetails_ee.f_shpunitphoto=this.imgsave_path_ee+id+"/shpngUntPhoto.png";
        }




    part_imageUplaod_ee(event, form) {
      this.disable_browse_button_part_ee=true;
      console.log('Part photo upload is working');
      for(const file of event.files) {
         this.uploadedFiles_ee.push(file);
         this.uplo_ee=file;
      }
      form.clear();
              this.image_folder_path_ee=this.tab4alldetails_ee.id_proposal.toString();

        this.transactionService.PartPhoto_postFile_ee(this.uplo_ee,this.image_folder_path_ee).subscribe((data => {
          const res=data;
          this.respdata_ee=res;
          console.log('Response',res);
             if(res == this.imgsave_path_ee+this.image_folder_path_ee+'/PartPhoto.png') {
              console.log('Upload is success '+ res);

            setTimeout( ()=>{
              console.log('works')
              this.part_image_ee='';
              }, 5000);

              setTimeout( ()=>{
                console.log('works1')
             // this.PartPhotoImage();

             this.disable_browse_button_part_ee=false;
             this.tab4alldetails_ee.f_partphoto=res// assign the return value after success
             this.save_tab4_image_ee();
             this.get_part_image_ee();
              }, 5000)
           this.PartPhoto_image_upload_dialog_ee=false;
               }
                else   {
                  this.disable_browse_button_part_ee=false;
               console.log('Upload is not success '+res);
               this.PartPhoto_image_upload_dialog_ee=false;
               this.messageService.add({severity:'error', summary: 'Error', detail: 'Part image not uploaded,Please Try Again'});

               // toast
                       }
          }));

        }

        InteriorContainer_imageUpload_ee(event, form1) {
          this.disable_browse_button_int_cntr_ee=true;
          console.log('InteriorContainer_imageUpload photo upload is working');
          for(const file of event.files) {
             this.uploadedFiles_ee.push(file);
             this.uplo_ee=file;
          }
          form1.clear();
                  this.image_folder_path_ee=this.tab4alldetails_ee.id_proposal.toString();

            this.transactionService.InteriorContainerPhoto_postFile_ee(this.uplo_ee,this.image_folder_path_ee).subscribe((data => {
              const res=data;
              this.respdata_ee=res;
              console.log('Response',res);
                 if(res == this.imgsave_path_ee+this.image_folder_path_ee+'/intrCntnrDsgnPhoto_ee.png') {

                  console.log('Upload is success '+ res);

                setTimeout( ()=>{
                  console.log('works')
                  this.interior_image_ee='';
                  }, 5000);

                  setTimeout( ()=>{
                    console.log('works1')
                  // this.intrCntnrPhotoImage();

                  this.disable_browse_button_int_cntr_ee=false;
                  this.tab4alldetails_ee.f_intcntrdsgnphoto=res// assign the return value after success ;
                  this.save_tab4_image_ee();
             this.get_interior_image_ee();
                  }, 5000)
               this.InteriorContainer_upload_dialog_ee=false;
                   }
                    else   {
                      this.disable_browse_button_int_cntr_ee=false;
                   console.log('Upload is not success '+res);
                   this.messageService.add({severity:'error', summary: 'Error', detail: 'Interior Container image not uploaded,Please Try Again'});
                   this.InteriorContainer_upload_dialog_ee=false;
                           }
              }));

            }

            ExteriorContainer_imageUpload_ee(event, form1) {
              this.disable_browse_button_ext_cntr_ee=true;
              console.log('ExteriorContainer_imageUpload photo upload is working');
              for(const file of event.files) {
                 this.uploadedFiles_ee.push(file);
                 this.uplo_ee=file;
              }
              form1.clear();
                      this.image_folder_path_ee=this.tab4alldetails_ee.id_proposal.toString();

                this.transactionService.ExteriorContainerPhoto_postFile_ee(this.uplo_ee,this.image_folder_path_ee).subscribe((data => {
                  const res=data;
                  this.respdata_ee=res;
                  console.log('Response',res);
                     if(res == this.imgsave_path_ee+this.image_folder_path_ee+'/extrCntnrDsgnPhoto_ee.png') {

                      console.log('Upload is success '+ res);

                    setTimeout( ()=>{
                      console.log('works')
                      this.exterior_image_ee='';
                      }, 5000);

                      setTimeout( ()=>{
                        console.log('works1')
                      // this.ExtrCntnrPhotoImage();

                      this.disable_browse_button_ext_cntr_ee=false;
                      this.tab4alldetails_ee.f_extcntrdsgnphoto=res// assign the return value after success
                      this.save_tab4_image_ee();
             this.get_exterior_image_ee();
                      }, 5000)
                   this.ExteriorContainer_upload_dialog_ee=false;
                       }
                        else   {
                          this.disable_browse_button_ext_cntr_ee=false;
                       console.log('Upload is not success '+res);
                       this.messageService.add({severity:'error', summary: 'Error', detail: 'Exterior Container image not uploaded,Please Try Again'});

                       this.ExteriorContainer_upload_dialog_ee=false;
                               }
                  }));

 }

 LabelMetalID_imageUpload_ee(event, form1) {
  this.disable_browse_button_label_ee=true;
  console.log('LabelMetalID_imageUpload photo upload is working');
  for(const file of event.files) {
     this.uploadedFiles_ee.push(file);
     this.uplo_ee=file;
  }
  form1.clear();
          this.image_folder_path_ee=this.tab4alldetails_ee.id_proposal.toString();

    this.transactionService.LabelMetalIDPhoto_postFile_ee(this.uplo_ee,this.image_folder_path_ee).subscribe((data => {
      const res=data;
      this.respdata_ee=res;
      console.log('Response',res);
         if(res == this.imgsave_path_ee+this.image_folder_path_ee+'/labelmetalID_ee.png') {

          console.log('Upload is success '+ res);

        setTimeout( ()=>{
          console.log('works')
          this.label_image_ee='';
          }, 5000);

          setTimeout( ()=>{
            console.log('works1')
          // this.LabelMetalIDCntnrPhotoImage();

          this.disable_browse_button_label_ee=false;
          this.tab4alldetails_ee.f_metelidplatephoto=res// assign the return value after success
          this.save_tab4_image_ee();
            this.get_label_image_ee();
           

          }, 5000)
       this.Label_MetalID_upload_dialog_ee=false;
           }
            else   {
              this.disable_browse_button_label_ee=false;
           console.log('Upload is not success '+res);

           this.messageService.add({severity:'error', summary: 'Error', detail: 'Label image not uploaded,Please Try Again'});

           this.Label_MetalID_upload_dialog_ee=false;
                   }
      }));

}

ShippingUnit_imageUpload_ee(event, form1) {
  this.disable_browse_button_shp_unt_ee=true;
  console.log('ShippingUnit_imageUpload photo upload is working');
  for(const file of event.files) {
     this.uploadedFiles_ee.push(file);
     this.uplo_ee=file;
  }
  form1.clear();
          this.image_folder_path_ee=this.tab4alldetails_ee.id_proposal.toString();

    this.transactionService.ShippingUnitPhoto_postFile_ee(this.uplo_ee,this.image_folder_path_ee).subscribe((data => {
      const res=data;
      this.respdata_ee=res;
      console.log('Response',res);
         if(res == this.imgsave_path_ee+this.image_folder_path_ee+'/shpngUntPhoto_ee.png') {

          console.log('Upload is success '+ res);

        setTimeout( ()=>{
          console.log('works')
          this.shipping_image_ee='';
          }, 5000);

          setTimeout( ()=>{
            console.log('works1')
          // this.ShippingUnitPhotoImage();
          this.disable_browse_button_shp_unt_ee=false;
          this.tab4alldetails_ee.f_shpunitphoto=res// assign the return value after success
          this.save_tab4_image_ee();
             this.get_shipping_image_ee();
          }, 5000)
       this.ShippingUnit_upload_dialog_ee=false;
           }
            else   {
              this.disable_browse_button_shp_unt_ee=false;
           console.log('Upload is not success '+res);
           this.ShippingUnit_upload_dialog_ee=false;
           this.messageService.add({severity:'error', summary: 'Error', detail: 'shipping unit image not uploaded,Please Try Again'});



                   }
      }));

}


sbupl_imageUpload_ee(event, form1) {
  this.disable_browse_button_sbupl_ee=true;
  console.log('sbupl_imageUpload photo upload is working');
  for(const file of event.files) {
     this.uploadedFiles_ee.push(file);
     this.uplo_ee=file;
  }
  form1.clear();
          this.image_folder_path_ee=this.tab4alldetails_ee.id_proposal.toString();
          this.sbupl_uploaded_ee=false;


          const path_pressload='pressloaddoc/'
          const extention=this.uplo_ee.name.split('?')[0].split('.').pop();

    this.transactionService.sbupl_postFile_ee(this.uplo_ee,this.image_folder_path_ee).subscribe((data => {
      const res=data;
      this.respdata_ee=res;
      console.log('Response',res);
         //if(res == this.imgsave_path_ee+path_pressload+this.image_folder_path_ee+'_EE_presslinedoc.'+extention) {
          if(res == path_pressload+this.image_folder_path_ee+'_EE_presslinedoc.'+extention) {

          console.log('Upload is success '+ res);


         // this.sbupl_uploaded=true;

        setTimeout( ()=>{
          console.log('works')
          this.sbu_file_ee='';
          }, 5000);

          setTimeout( ()=>{
            console.log('works1')
          // this.ShippingUnitPhotoImage();
          this.disable_browse_button_sbupl_ee=false;
         this.tab4alldetails_ee.subpresloaddoc=res// assign the return value after success
          this.save_tab4_image_ee();
          this.test_var_ee='https://www.w3schools.com/'
             this.get_sbupl_ee();
          }, 5000)
       this.sbupl_upload_dialog_ee=false;
           }
            else   {
              this.disable_browse_button_sbupl_ee=false;
           console.log('Upload is not success '+res);
           if(this.tab4alldetails_ee.subpresloaddoc!=null && this.tab4alldetails_ee.subpresloaddoc!='' )
           {
           this.sbupl_uploaded_ee=true;
           }
           this.sbupl_upload_dialog_ee=false;
           this.messageService.add({severity:'error', summary: 'Error', detail: 'sbupl not uploaded,Please Try Again'});




                   }
      }));

}



save_tab4_image_ee()

{


  console.log(this.tab4alldetails_ee);

  // service to call tab4 api
// on success
// store last saved tab
this.transactionService.savetab4details_ee(this.tab4alldetails_ee).subscribe(response=>
  {
      let msgs:any[];
      if(response=='Success')
      {

        const check=false;

  this.region_ee=this.transactionService.getregion();
  const currentab=4;
  if(currentab>this.selectedpart_submitter_image_ee.proposallastsaved_ee)
                {

                this.selectedpart_submitter_image_ee.proposallastsaved_ee=4;
                this.store_primary_part_exist_image_ee();
                console.log(this.selectedpart_submitter_image_ee);
                }





              }

              else{


                this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'EE-Image details is not saved! try again'});

              }

              })


}



// not used below both functions
      getImage_ee() {
       // alert("Image loading");
        console.log('Get image called');
        const URL=this.baseurl_get_ee+this.imgsave_path_ee+this.image_folder_path_ee+'/PartPhoto.png';
        console.log(URL);

        this.imageService.getData(URL)
          .subscribe(
            imgData => this.data_ee = imgData,
            err => console.log(err)
          );
          this.part_image_ee='ok';

      }

      InitgetImagePartPhoto_ee(proposalD:string) {

        const URL=this.baseurl_get_ee+this.imgsave_path_ee+this.image_folder_path_ee+proposalD+'/PartPhoto.png';
        console.log('image loding from '+URL);
        this.imageService.getData(URL)
          .subscribe(
            imgData => this.data_ee = imgData,
            err => console.log(err)
          );
          this.part_image_ee='ok';
      }


}
