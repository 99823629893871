/* tslint:disable */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TransactionService } from '../loginpage/Transaction.service';
import { cntrandsudetailspost, selec_prt_pro_details, submit_proposal_tab4_post, tab4details,selected_parts_submit,selected_components_submit, userdetails, changeTrackingForImprovement, compmaterialpost, containerDetailsCntrAdmin, fromdiplayvalues_all, partDetailForPost, primarypartdetails, proposalSubmissionChangeTrack, storecomponentforpost, RevHistFromAPi } from '../loginpage/transactions';
// import { PackagingproposalComponent } from '../packagingproposal/packagingproposal.component';
// import { PartsandatributesComponent } from '../partsandatributes/partsandatributes.component';
import { DatePipe } from '@angular/common';
import { ImprovementpackagingproposalComponent } from '../improvementpackagingproposal/improvementpackagingproposal.component';
import { PackagingprocessdashboardComponent } from '../packagingprocessdashboard/packagingprocessdashboard.component';
import { ImprovementtaboneComponent } from '../improvementtabone/improvementtabone.component';


import {ImageService } from '../packproposaltabfour/image.Services';
// import { PackagingprocessComponent } from '../packagingprocess/packagingprocess.component';
import { CompileShallowModuleMetadata } from '@angular/compiler';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-improvementtabfour',
  templateUrl: './improvementtabfour.component.html',
  styleUrls: ['./improvementtabfour.component.css'],
  providers:[ConfirmationService,MessageService]
})
export class ImprovementtabfourComponent implements OnInit {



    data: string;
    region:string;
    myfiles: any = [];
    uploadedFiles: any[] = [];
    uplo: File;


    image_folder_path=''


    respdata='';





    part_image=''
    interior_image='';
    exterior_image='';
    label_image='';
    shipping_image='';


    current_region_code='';


    selectedpart_submitter_image:selec_prt_pro_details={simplifiedpartno:'-',proposalid:0,bompartmasterid:0,plantgsdbcode:'',proposallastsaved:0,part_status:'',supplier_code:'',proposallastsaved_ee:0,is_ee_proposal:false,currentview_ee:false,from_draft:false,propsl_type:'',imp_part_status:''};

    id_proposal_image:number;
    noSupDialog_image=false;
    content_supp_image:string;
    noPrtDialog_image=false;
    content_selectedprt_image:string;
   contandcos_cdsid:string;
    // supp_code_image:string;
    // roleid_image:number;
    // designsource_code_image:string;

  
    noDesignsourceDialog_image:boolean=false;
  content_Designsource_image:string="";
  
  
  noroleselectedDialog:boolean=false;
    norolecontent:string="";
    tab4alldetails:tab4details={id_proposal:0,f_extcntrdsgnphoto:"",f_intcntrdsgnphoto:"",f_metelidplatephoto:"",f_partphoto:"",f_shpunitphoto:"",gen_propslcommnts:"",int_cntrdesgncommnts:"",createmgcyexpndpckgprop:"",agrmntexpndpackreqmnts:"",subpresloaddoc:""};
  
  
    baseurl_get:any;
    imgsave_path:any='';


    store_proposal_values:submit_proposal_tab4_post;
    // tab2data:cntrandsudetailspost;
    store_proposal_selectedparts:selected_parts_submit[]=[];
    store_proposal_componenets:selected_components_submit[]=[];


    containerDetails:containerDetailsCntrAdmin;
  tab1DataGet:primarypartdetails;
  selectedPartsGet:partDetailForPost[];

  tab2data:cntrandsudetailspost;
  tab2DataForPost:cntrandsudetailspost;

  compDataGet:storecomponentforpost[]
  tab3detailsGet:compmaterialpost;
  tab3DataForPost:compmaterialpost;

  tab4Get:tab4details
  trascMastrGet:fromdiplayvalues_all;

  enableChnageDialog:boolean=false;
  enableNotificationDialog:boolean=false;
  enablePartNotificationDialog:boolean=false;
  notificationText:string="";

  oldProposalDetailsForChnageTrack:proposalSubmissionChangeTrack={
    cntrTareWt:0,
    cntrNestedFldedHt:0,
    cntrExtLength:0,
    cntrExtwidth:0,
    cntrExtHeight:0,
    // maxLayersPerSu:0,
    // cntrPerLayer:0,
    // partsPerSu:0,
    maxCntrsPerSu:0,
    shippingUnitBase:'',
    shippingUnitSuffix:'',
    // shippingExtLen:0,
    // shippingExtWidth:0,
   // shippingExtHeight:0,
    // shippingUnitNestedFoldedht:0,
    shippingUnitReturnType:'',
    shiipingUnitTareWeight:0,
    cntrGrossWeight:0,
    shippingUnitGrossWeight:0,

  }
  newProposalDetailsForChangeTrack:proposalSubmissionChangeTrack={
    cntrTareWt:0,
    cntrNestedFldedHt:0,
    cntrExtLength:0,
    cntrExtwidth:0,
    cntrExtHeight:0,
    // maxLayersPerSu:0,
    // cntrPerLayer:0,
    // partsPerSu:0,
    maxCntrsPerSu:0,
    shippingUnitBase:'',
    shippingUnitSuffix:'',
    // shippingExtLen:0,
    // shippingExtWidth:0,
   // shippingExtHeight:0,
    // shippingUnitNestedFoldedht:0,
    shippingUnitReturnType:'',
    shiipingUnitTareWeight:0,
    cntrGrossWeight:0,
    shippingUnitGrossWeight:0,

  }

  sugrosswtInvalid=false;
  suGrossWtInvalidReason:string=null;

  reasonForNotSubmitting='';

  cntrgrosswtInvalid=false;
  cntrgrosswtInvalidReason:string=null;

  partDimensionsInvalid=false;
  partDimensionsInvalidReason:string=null;


  tempCntrCompWeight=0;
  tempSUCompWeight=0;
  no_of_container=0;

    PartPhoto_image_upload_dialog=false;
  InteriorContainer_upload_dialog=false;
  ExteriorContainer_upload_dialog=false;
  Label_MetalID_upload_dialog=false;
  ShippingUnit_upload_dialog=false;


  intr_dsgn_comments='';
  general_comments='';
  crt_edt_empro='';
  agre_for_empro='';


  curr_cdsid='';
    name: any;


    upload_sbupl_display=false;
    upload_sbupl_req=false;


    sbupl_uploaded=false;


    sbu_file='';


    sbu_file_path='';
    baseurl_get_sbupl=''


    sbupl_upload_dialog=false;


    test_var='';


    is_container_expendable=false;


    allow_create_ee=true;


    infoTextAllowEE='';


    enable_submit_button=true;


    user_details:userdetails={gppuserid:'',name:'',title:'',company:'',email:'',city:'',cntrycodeiso:'',createdby:'',createddt:'',designation:'',isforduser:'',isactive:'',phone:'',postalcode:'',address1:'',address2:'',state:'',lastlogin:'',country:'',fax:'',usertype:''};


    is_ee_creation_required=false;



  existChangeStatusTab4='';


  private originalTab4Details:tab4details
  private changedTab4Details:tab4details


  imp_prop_comm='';


  disable_browse_button_part = false;
  disable_browse_button_int_cntr = false;
  disable_browse_button_ext_cntr = false;
  disable_browse_button_shp_unt = false;
  disable_browse_button_label = false;
  disable_browse_button_sbupl = false;

  revisionHistoryForPost:RevHistFromAPi={
    seqno:null,
    userid:'',
    usrname:'',
    email:'',
    id_proposal:0,
    proposalstatuscurr:'',
    comments:'',
    apprvractn:'',
    evnttime:''

  }
  loaderimgimp = false;

  expendable_wgts_InValid = false;
  expendable_wgts_InValidReason: string = null;

   sutarewtInvalid:boolean=false;
  sutarewtInvalidReason:string="";

  cntr_comp_wt: number = 0;
  shp_comp_wt: number = 0;
  temp_shp_compwt: number = 0;
  // totl_wt_InValid: boolean = false;
  // totl_wt_InValidReason: string = null;
  suExtHgtInvalid:boolean=false;
  suExtHgtInvalidReason:string="";

  suNstFldHgtInvalid:boolean=false;
  suNstFldHgtInvalidReason:string="";

  notificationTextSuHgt:string="";
  notificationTextSuNstFldHgt:string="";

  updateCntrDetsReturnable: boolean = false;
  updateCntrDetsReturnableReason: string = "";

  primarypartsattributes:primarypartdetails={
    gppuserid:this.transactionService.getcdsid(),
    id_proposal:0,
    corr_protc:'',
    lst_saved_tab:0,
    seasnlreq:'',
    shelf_life:0,
    sp_part_no:'',
    submit_status:'not submitted',
    temp_sensitivity:'No',
    temp_stor_spec:'',
    timestamp:'',
    plantgsdbcode:'',
    isstampblank:'',
    supgsdbcode: '',
    supfullname: '',
     supaddress1: '',
     supcity: '',
     supstateprov: '',
      supcountrycode: '',
     supcontactnamefirst: '',
   supcontactname: '',
  supcontactfunctn: '',
    supcontactemail: '',
    supcontactphone: '',
    supcontactfax: '',
    defaultcurr:'',
    lst_saved_tab_ee:1,
    propsl_type:''


  }

  /* Added fr Supplier Profile Validation*/
  inSubmitFlowForSupplierProfileValidation:boolean=false;
  canProceedWithPropSubmission:boolean=true;// Field to enable dialog that will block user from submitting proposal if mandatry suplr details are not present
  showSupplierDetailsEmptyWarning:boolean=false; //field to show warnings in dialog that will list all non mandatory suplr profile details that are missing

  supcontactemailInvalid:boolean=false;
  supcontactemailInvalidReason=""
  defaultcurrInvalid:boolean=false;
  defaultcuuInvalidReason="";
  supcontactphoneInvalid:boolean=false;
  supcontactphoneInvalidReason="";
  supcontactfunctnInvalid:boolean=false;
  supcontactfunctnInvalidReason="";
  supcontactnamefirstInvalid:boolean=false;
  supcontactnamefirstInvalidReason=""; 
  supcontactnameInvalid:boolean=false;
  supcontactnameInvalidReason="";
  supstateprovInvalid:boolean=false;
  supstateprovInvalidReason="";
  supcountrycodeInvalid:boolean=false;
  supcountrycodeInvalidReason="";
  supfullnameInvalid:boolean=false;
  supfullnameInvalidReason="";
  supaddress1Invalid:boolean=false;
  supaddress1InvalidReason="";
  supcityInvalid:boolean=false;
  supcityInvalidReason="";
  supgsdbcodeInvalid:boolean=false;
  supgsdbcodeInvalidReason="";
  supFaxInvalid:boolean=false;
  supFaxInvalidReason="";
   /* Added fr Supplier Profile Validation*/

    constructor(private transactionService:TransactionService,private packagingproposal:ImprovementpackagingproposalComponent , private partsandattri:ImprovementtaboneComponent ,private router:Router,   private imageService: ImageService,   private messageService:MessageService,private packagingprocess:PackagingprocessdashboardComponent ,private date:DatePipe,private confirmationService:ConfirmationService,) { }

    ngOnInit(): void {
      this.region=this.transactionService.getregion();
      this.curr_cdsid=this.transactionService.getcdsid();
      this.current_region_code=this.transactionService.getregioncode(this.region);
      this.selectedpart_submitter_image = this.transactionService.getpartno_packpro(this.region);
      this.part_image="";
      this.interior_image="";
      this.exterior_image="";
      this.label_image="";
      this.shipping_image="";
      this.baseurl_get=environment.nfsBaseAPIUrl+"/downloadFile?fPath=";
      this.baseurl_get_sbupl=environment.nfsBaseAPIUrl+"/downloadNonImgFile?fPath="
      this.imgsave_path=environment.nfsBasePath;

      this.inSubmitFlowForSupplierProfileValidation=false;
      this.canProceedWithPropSubmission=true;
     // this.showEnterSupplierDetailsBeforeSubmit=false;
      this.supplierProfileInit();

      this.existChangeStatusTab4=sessionStorage.getItem('ProposalChangeStatus');
      this.reasonForNotSubmitting='';
      this.cntrgrosswtInvalid=false;
      this.sugrosswtInvalid=false;
      
      this.sutarewtInvalid=false;

      this.suExtHgtInvalid=false;
      this.suNstFldHgtInvalid=false;
   
      if(this.existChangeStatusTab4 == null || this.existChangeStatusTab4 =="null" || this.existChangeStatusTab4.trim() == "")
      {
        this.existChangeStatusTab4='unchanged';
        sessionStorage.setItem('ProposalChangeStatus','unchanged');
      }

      this.transactionService.user_details(this.curr_cdsid).subscribe(user_det=>{
        console.log(user_det);
        this.user_details=user_det;
        console.log(this.user_details);

      })
      /*if(document.getElementById("overlayimg") != null )
      {
      document.getElementById("overlayimg").style.display = "block";
      }
      if(document.getElementById("loaderimg") != null )
      {
      document.getElementById("loaderimg").style.display = "block";
      }*/
    if(this.selectedpart_submitter_image !=null)
    {
      this.transactionService.get_draft_Tab1_data(this.selectedpart_submitter_image.proposalid).subscribe(primary_details=>{
        console.log(primary_details);
        console.log(primary_details.isstampblank)


        if(primary_details.isstampblank.toLowerCase()=='yes')
        {
          this.upload_sbupl_display=true;
        }
        else{
          this.upload_sbupl_display=false;
          this.upload_sbupl_req=false;
        }


          this.transactionService.loadtab2detailsifproposalexistalready(this.selectedpart_submitter_image.proposalid).subscribe(tab2_details=>{
            console.log('tab2-det',tab2_details)
            console.log(tab2_details)


            if( tab2_details.cd_ownrshp_strat!=null && tab2_details.cd_ownrshp_strat!=0 && tab2_details.cntr_ownrshp_strat_code!=null && tab2_details.cntr_ownrshp_strat_code!='')
                {

                  this.transactionService.getcosdetails(tab2_details.cntr_ownrshp_strat_code,tab2_details.cd_ownrshp_strat).subscribe(cosdetails_tab2=>{
                    console.log(cosdetails_tab2);
                  if(cosdetails_tab2.sub_press_load_doc != null && cosdetails_tab2.sub_press_load_doc.trim() != '')
                  {
                      if(primary_details.isstampblank.toLowerCase()=='yes' && cosdetails_tab2.sub_press_load_doc.toLowerCase()=='required')
                      {
                        this.upload_sbupl_req=true;
                      }


                      else
                      {
                        this.upload_sbupl_req=false;
                      }
                  }
                  else
                  {
                    this.upload_sbupl_req=false;
                  }

                   if(cosdetails_tab2.emg_exp_propsal != null && cosdetails_tab2.emg_exp_propsal.trim() != '')
                   {
                    if(cosdetails_tab2.emg_exp_propsal.toLowerCase()=='required')
                    {
                      this.is_ee_creation_required=true;
                    }


                    else
                    {
                      this.is_ee_creation_required=false;
                    }

                   }
                   else
                   {
                    this.is_ee_creation_required=false;
                   }


                   console.log('is proposal ee',this.is_ee_creation_required)


                   if(tab2_details.cntr_ret_type!=null && tab2_details.cntr_ret_type!='' )
                   {
                     if(tab2_details.cntr_ret_type.toLowerCase()=='returnable')
                     {
                       this.is_container_expendable=false;

                     }


                     else if(tab2_details.cntr_ret_type.toLowerCase()=='expendable')
                     {
                       this.is_container_expendable=true;

                     }
                   }


                   this.tab4alldetails_init();
         this.submit_propsal_init();
         this.load_tab_details();




                  })

                }


                else
                {
                  this.upload_sbupl_req=false;


                  if(tab2_details.cntr_ret_type!=null && tab2_details.cntr_ret_type!='' )
                  {
                    if(tab2_details.cntr_ret_type.toLowerCase()=='returnable')
                    {
                      this.is_container_expendable=false;

                    }


                    else if(tab2_details.cntr_ret_type.toLowerCase()=='expendable')
                    {
                      this.is_container_expendable=true;

                    }
                  }


                  this.tab4alldetails_init();
        this.submit_propsal_init();
        this.load_tab_details();
                }




          })


        /*  this.transactionService.loadtab2detailsifproposalexistalready(this.selectedpart_submitter_image.proposalid).subscribe(tab2_details=>{
            console.log("tab2-det",tab2_details)
            console.log(tab2_details)



            if(tab2_details.cntr_ret_type!=null && tab2_details.cntr_ret_type!="" )
            {
              if(tab2_details.cntr_ret_type.toLowerCase()=="returnable")
              {
                this.is_container_expendable=false;

              }


              else if(tab2_details.cntr_ret_type.toLowerCase()=="expendable")
              {
                this.is_container_expendable=true;

              }
            }


            this.tab4alldetails_init();
            this.submit_propsal_init();


            this.load_tab_details();

          })*/






        console.log(this.upload_sbupl_display);
        console.log(this.upload_sbupl_req);
      })
    }

      else
        {
          if(this.selectedpart_submitter_image == null || this.selectedpart_submitter_image.simplifiedpartno=='-' || this.selectedpart_submitter_image.proposalid==0)
          {
            this.noPrtDialog_image = true;
            this.content_selectedprt_image = 'Please select part from Search Existing Proposal';
          }
        }


        // this.packagingproposal.savedtab=this.transactionService.getlastsavedtab(this.region);
        /*if(this.packagingproposal.savedtab==1)
          {
            for(let i of this.packagingproposal.tabpackagingproposalmenuitems.values())
            {
                  console.log(i);
                  if(i.label!='COS, Container & Shipping Unit')
                  {
                      this.packagingproposal.flag1=true;
                  }
                  else{
                      this.packagingproposal.flag1=false;
                      break;
                  }
            }

            if(this.packagingproposal.flag1)
            {
                this.packagingproposal.tabpackagingproposalmenuitems.push({label: 'COS, Container & Shipping Unit', routerLink: this.router.url, icon: 'fas fa-person-booth'});
            }
          }

          else if(this.packagingproposal.savedtab==2 )
          {
            for(let i of this.packagingproposal.tabpackagingproposalmenuitems.values())
            {
                  console.log(i);
                  if(i.label!='Component & Material')
                  {
                      this.packagingproposal.flag2=true;
                  }
                  else{
                      this.packagingproposal.flag2=false;
                      break;
                  }
            }

            if(this.packagingproposal.flag2)
            {
                this.packagingproposal.tabpackagingproposalmenuitems.push({label: 'COS, Container & Shipping Unit', routerLink:'/northamerica/proposalcreation/packproposaltabtwo', icon: 'fas fa-person-booth'});
                this.packagingproposal.tabpackagingproposalmenuitems.push({label:'Component & Material',routerLink:this.router.url, icon: 'fas fa-person-booth'});
            }
          }

          else if(this.packagingproposal.savedtab==3 )
          {
            for(let i of this.packagingproposal.tabpackagingproposalmenuitems.values())
            {
                  console.log(i);
                  if(i.label!='Images/Photos & Comments')
                  {
                      this.packagingproposal.flag3=true;
                  }
                  else{
                      this.packagingproposal.flag3=false;
                      break;
                  }
            }

            if(this.packagingproposal.flag3)
            {
              this.packagingproposal.tabpackagingproposalmenuitems.push({label: 'COS, Container & Shipping Unit', routerLink:'/northamerica/proposalcreation/packproposaltabtwo', icon: 'fas fa-person-booth'});
              this.packagingproposal.tabpackagingproposalmenuitems.push({label:'Component & Material',routerLink:'/northamerica/proposalcreation/packproposaltabthree', icon: 'fas fa-person-booth'});
              this.packagingproposal.tabpackagingproposalmenuitems.push({label:'Images/Photos & Comments',routerLink:'/northamerica/proposalcreation/packproposaltabfour', icon: 'fas fa-person-booth'});
            }
          }*/




        }

        supplierProfileInit()
      {
        this.supcontactemailInvalid=false;
        this.supcontactemailInvalidReason=""
        this.defaultcurrInvalid=false;
        this.defaultcuuInvalidReason="";
        this.supcontactphoneInvalid=false;
        this.supcontactphoneInvalidReason="";
        this.supcontactfunctnInvalid=false;
        this.supcontactfunctnInvalidReason="";
        this.supcontactnamefirstInvalid=false;
        this.supcontactnamefirstInvalidReason=""; 
        this.supcontactnameInvalid=false;
        this.supcontactnameInvalidReason="";
        this.supstateprovInvalid=false;
        this.supstateprovInvalidReason="";
        this.supcountrycodeInvalid=false;
        this.supcountrycodeInvalidReason="";
        this.supfullnameInvalid=false;
        this.supfullnameInvalidReason="";
        this.supaddress1Invalid=false;
        this.supaddress1InvalidReason="";
        this.supcityInvalid=false;
        this.supcityInvalidReason="";
        this.supgsdbcodeInvalid=false;
        this.supgsdbcodeInvalidReason="";
        this.supFaxInvalid=false;
        this.supFaxInvalidReason="";
      }




        load_tab_details()
        {
          // this.roleid_image=this.transactionService.getcurrentrole(this.region)



          /* if(this.roleid_image==null)
          {


            this.noroleselectedDialog=true;
            this.norolecontent="No role selected "
          } */
          /* if(this.roleid_image==1)
          {
          this.supp_code_image = this.transactionService.get_supplier(this.region);
          console.log(this.supp_code_image);
          } */
          /* else if( this.roleid_image==2)
          {
            this.designsource_code_image = this.transactionService.get_designsource(this.region);
            console.log(this.designsource_code_image)
          } */







         // if(this.roleid_image==1)
          // {

            if ( this.selectedpart_submitter_image != null && this.selectedpart_submitter_image.simplifiedpartno!='-' && this.selectedpart_submitter_image.proposalid!=0 )
            {

              this.id_proposal_image=this.selectedpart_submitter_image.proposalid;
              this.tab4alldetails_init();
              this.tab4alldetails.id_proposal=this.id_proposal_image;
              console.log(this.tab4alldetails.id_proposal);


              // this.InitgetImagePart(this.tab4alldetails.id_proposal.toString());
              // this.InitgetInteriorContainer(this.tab4alldetails.id_proposal.toString());
              // this.InitgetExtrCntnrPhotoImage(this.tab4alldetails.id_proposal.toString());
              // this.InitgetLabelMetalIDPhotoImage(this.tab4alldetails.id_proposal.toString());
              // this.InitgetShippingUnitPhotoImage(this.tab4alldetails.id_proposal.toString());



              if(this.selectedpart_submitter_image.proposallastsaved==3)
              {
                // below commented as ee proposal will not be allowed to create for improvement

               /* if(this.is_container_expendable==true)
                {
                  this.transactionService.get_region_details(this.current_region_code).subscribe(region_det=>{
                    console.log("tab4-region",region_det);

                    if(region_det.emg_exp_pkg_reqd_ind!=null && region_det.emg_exp_pkg_reqd_ind!="" && region_det.emg_exp_pkg_reqd_ind.toLowerCase()=="no")
                    {

                      console.log("inside if tab4")
                      this.allow_create_ee=false
                      this.infoTextAllowEE= "** Note : Not allowed to create EE proposal as Primary Proposal is Expendable, Please contact your region admin to enable the feature "
                      this.crt_edt_empro="no"

                    }


                    else
                    {

                      this.allow_create_ee=true;
                      this.infoTextAllowEE= ""



                    }



                  })
                }


                else
                {
                  this.allow_create_ee=true;
                  this.infoTextAllowEE= ""
                  if(this.is_ee_creation_required == true)
                    {
                      this.crt_edt_empro="yes"
                      this.on_create_edit_exp_change();
                    }
                    else
                    {
                      this.crt_edt_empro="no"
                    }

                }*/

                // for improvement proposal

                this.allow_create_ee=false
                this.infoTextAllowEE= '** Note : Not allowed to create EE proposal for improvement proposal '
                this.crt_edt_empro='no'
                // change update status
                this.originalTab4Details=JSON.parse(JSON.stringify(this.tab4alldetails));
                this.changedTab4Details=this.tab4alldetails;





                // document.getElementById("overlayimg").style.display = "none";
               // document.getElementById("loaderimg").style.display = "none";

              }

              if(this.selectedpart_submitter_image.proposallastsaved>=4)
              {
                console.log('this.selectedpart_submitter_contandcos',this.selectedpart_submitter_image.proposalid);
              this.loadtab4detlsifexist();
              }

             }

          /* else if (this.supp_code_image == null) {
            this.noSupDialog_image = true;
            this.content_supp_image = "Please select supplier";
           // document.getElementById("overlayimg").style.display = "none";
            //document.getElementById("loaderimg").style.display = "none";
          }


          else if (this.supp_code_image != null && (this.selectedpart_submitter_image == null || this.selectedpart_submitter_image.simplifiedpartno=="-" || this.selectedpart_submitter_image.proposalid==0)) {
            this.noPrtDialog_image = true;
            this.content_selectedprt_image = "Please select part from submitter dashboard";
            //document.getElementById("overlayimg").style.display = "none";
            //document.getElementById("loaderimg").style.display = "none";

          } */
        // }
          /* else if (this.roleid_image==2)
            {



          if (this.designsource_code_image != null && this.selectedpart_submitter_image != null && this.selectedpart_submitter_image.simplifiedpartno!="-" && this.selectedpart_submitter_image.proposalid!=0 ) {

            this.id_proposal_image=this.selectedpart_submitter_image.proposalid;
            this.tab4alldetails_init();
            this.tab4alldetails.id_proposal=this.id_proposal_image;


           //this.InitgetImagePart(this.tab4alldetails.id_proposal.toString());
            //this.InitgetInteriorContainer(this.tab4alldetails.id_proposal.toString());
            //this.InitgetExtrCntnrPhotoImage(this.tab4alldetails.id_proposal.toString());
            //this.InitgetLabelMetalIDPhotoImage(this.tab4alldetails.id_proposal.toString());
            //this.InitgetShippingUnitPhotoImage(this.tab4alldetails.id_proposal.toString());




            if(this.selectedpart_submitter_image.proposallastsaved==3)
            {
              if(this.is_container_expendable==true)
              {
                this.transactionService.get_region_details(this.region).subscribe(region_det=>{
                  console.log("tab4-region",region_det);

                  if(region_det.emg_exp_pkg_reqd_ind!=null && region_det.emg_exp_pkg_reqd_ind!="" && region_det.emg_exp_pkg_reqd_ind.toLowerCase()=="no")
                  {

                    console.log("inside if tab4")
                    this.allow_create_ee=false;
                    this.infoTextAllowEE= "** Note : Not allowed to create EE proposal as Primary Proposal is Expendable, Please contact your region admin to enable the feature "
                    this.crt_edt_empro="no"
                  }


                  else
                  {

                    this.allow_create_ee=true;
                    this.infoTextAllowEE=""

                  }



                })
              }


              else
              {
                this.allow_create_ee=true;
                this.infoTextAllowEE=""
              }
              //document.getElementById("overlayimg").style.display = "none";
              //document.getElementById("loaderimg").style.display = "none";
            }


            if(this.selectedpart_submitter_image.proposallastsaved>=4)
            {
              console.log("this.selectedpart_submitter_image",this.selectedpart_submitter_image.proposalid);
            this.loadtab4detlsifexist();



            }


          }

          else if (this.designsource_code_image == null) {
            this.noDesignsourceDialog_image = true;
            this.content_Designsource_image = "Please select Design source";
            //document.getElementById("overlayimg").style.display = "none";
            //document.getElementById("loaderimg").style.display = "none";
          }


          else if (this.designsource_code_image != null && (this.selectedpart_submitter_image == null || this.selectedpart_submitter_image.simplifiedpartno=="-" || this.selectedpart_submitter_image.proposalid==0)) {
            this.noPrtDialog_image = true;
            this.content_selectedprt_image = "Please select part from submitter dashboard";
            //document.getElementById("overlayimg").style.display = "none";
            //document.getElementById("loaderimg").style.display = "none";
          }

          }
          */


        }



  /*  ok_noroledialog()
    {
      var url
      url=this.transactionService.getregionurlvalue(this.region);
      url+="packagingprocessdashboard"
      this.router.navigate([url]);

    }




      //if no supplier present

      ok_nosupdialog() {
        console.log(this.region)
        var reg_url: string
        reg_url = this.transactionService.getregionurlvalue(this.region);
        reg_url += "packagingprocess/packsupplieradmin/packsupplier";

        this.router.navigate([reg_url]);

      }


      ok_noDesignsourcedialog() {
        console.log(this.region)
        var reg_url: string
        reg_url = this.transactionService.getregionurlvalue(this.region);
        reg_url += "packagingprocess/ppackdesignsourceadmin/packdesignsource";

        this.router.navigate([reg_url]);

      }



      go_to_supp_main_page() {
        var reg_supp_url: string;
        reg_supp_url = this.transactionService.getregionurlvalue(this.region);
        if(this.roleid_image==1)
        {
          reg_supp_url += "packagingprocess/packsupplieradmin/packsupplier";
        }
        else if ( this.roleid_image==2)
        {
          reg_supp_url += "packagingprocess/packdesignsourceadmin/packdesignsource";
        }
        this.router.navigate([reg_supp_url]);

      }
      */

      // if no part present
      ok_noprtdialog() {
        console.log(this.region)

        let reg_url_prt: string
        reg_url_prt = this.transactionService.getregionurlvalue(this.region);

        reg_url_prt += 'packagingprocessdashboard/searchexistingproposal';

        this.router.navigate([reg_url_prt]);

      }


      tab4alldetails_init()
      {
        this.tab4alldetails={
          id_proposal:0,
          f_extcntrdsgnphoto:'',
          f_intcntrdsgnphoto:'',
          f_metelidplatephoto:'',
          f_partphoto:'',
          f_shpunitphoto:'',
          gen_propslcommnts:'',
          int_cntrdesgncommnts:'',
          createmgcyexpndpckgprop:'',
          agrmntexpndpackreqmnts:'',
          subpresloaddoc:'',
        };


      }

      revisionHistoryForPostInit()
      {
        this.revisionHistoryForPost={
          seqno:null,
          userid:'',
          usrname:'',
          email:'',
          id_proposal:0,
          proposalstatuscurr:'',
          comments:'',
          apprvractn:'',
          evnttime:''
        }
      }








            loadtab4detlsifexist()
            {
              // api to get tab 4 details
              // call each image
            // by assigningurl to image


            this.transactionService.loadtab4details(this.selectedpart_submitter_image.proposalid).subscribe(tab4_api=>{


              // var output;
             this.tab4alldetails=tab4_api;

             // document.getElementById("overlayimg").style.display = "none";
             // document.getElementById("loaderimg").style.display = "none";
             console.log(tab4_api);
             console.log(this.tab4alldetails);
             this.intr_dsgn_comments=tab4_api.int_cntrdesgncommnts;
             this.general_comments=tab4_api.gen_propslcommnts;
             this.crt_edt_empro=tab4_api.createmgcyexpndpckgprop;
             this.agre_for_empro=tab4_api.agrmntexpndpackreqmnts;

              // below commented as value for ee proposal question will not be allowed to change for improvement
              /*
             if(this.is_container_expendable==true)
             {
               this.transactionService.get_region_details(this.current_region_code).subscribe(region_det=>{
                 console.log("tab4-region",region_det);

                 if(region_det.emg_exp_pkg_reqd_ind!=null && region_det.emg_exp_pkg_reqd_ind!="" && region_det.emg_exp_pkg_reqd_ind.toLowerCase()=="no")
                 {

                   console.log("inside if tab4")
                   this.allow_create_ee=false;
                   this.crt_edt_empro="no"
                   this.infoTextAllowEE= "** Note : Not allowed to create EE proposal as Primary Proposal is Expendable, Please contact your region admin to enable the feature "



                   if(tab4_api.createmgcyexpndpckgprop.toLowerCase()=="yes")
                   {
                    this.on_create_edit_exp_change();

                   }



                 }


                 else
                 {

                            this.allow_create_ee=true;
                            this.infoTextAllowEE=""

                      if(this.crt_edt_empro!=null && this.crt_edt_empro!="" && this.crt_edt_empro.toLowerCase()=="yes")
                      {
                        this.enable_submit_button=false;
                      }

                      else  if(this.crt_edt_empro!=null && this.crt_edt_empro!="" && this.crt_edt_empro.toLowerCase()=="no")
                      {
                        this.enable_submit_button=true;
                      }

                 }



               })
             }


             else
             {
                        this.allow_create_ee=true;
                        this.infoTextAllowEE=""

                      if(this.crt_edt_empro!=null && this.crt_edt_empro!="" && this.crt_edt_empro.toLowerCase()=="yes")
                      {
                        this.enable_submit_button=false;
                      }

                      else  if(this.crt_edt_empro!=null && this.crt_edt_empro!="" && this.crt_edt_empro.toLowerCase()=="no")
                      {
                        this.enable_submit_button=true;
                      }
             }*/

                    // for improvement proposal
                    this.allow_create_ee=false;


                  if(this.crt_edt_empro!=null && this.crt_edt_empro!='' && this.crt_edt_empro.toLowerCase()=='yes')
                  {
                    this.enable_submit_button=false;
                    this.infoTextAllowEE=''
                  }

                  else
                  {
                    this.enable_submit_button=true;
                    this.infoTextAllowEE='** Note : Not allowed to create EE for improvement proposal'
                  }



                // change status update
                this.originalTab4Details=JSON.parse(JSON.stringify(this.tab4alldetails));
                this.changedTab4Details=this.tab4alldetails;

  /*
             if(this.crt_edt_empro!=null && this.crt_edt_empro!="" && this.crt_edt_empro.toLowerCase()=="yes")
             {
               this.enable_submit_button=false;
             }

             else  if(this.crt_edt_empro!=null && this.crt_edt_empro!="" && this.crt_edt_empro.toLowerCase()=="no")
             {
               this.enable_submit_button=true;
             }*/

               this.get_part_image();
               this.get_exterior_image();
               this.get_interior_image();
               this.get_label_image();
               this.get_shipping_image();
               this.get_sbupl();

            })



            }


            get_part_image()
            {



              if(this.tab4alldetails.f_partphoto!='' && this.tab4alldetails.f_partphoto!=null)
              {
                let url = this.baseurl_get+this.tab4alldetails.f_partphoto;
                this.transactionService.getImageFile(url).subscribe((response: any) => {
                  this.part_image = URL.createObjectURL(response);
                  setTimeout(()=> {
                  let url = URL.createObjectURL(response);
                  const img: any = document.querySelector('#part_image');
                  img.src = url;
                }, 500);
                });
              }
              else{
                this.part_image='';
              }
            }

            get_interior_image()
            {

              if(this.tab4alldetails.f_intcntrdsgnphoto!='' && this.tab4alldetails.f_intcntrdsgnphoto!=null)
              {
              let url =this.baseurl_get+this.tab4alldetails.f_intcntrdsgnphoto;
              this.transactionService.getImageFile(url).subscribe((response: any) => {
                this.interior_image = URL.createObjectURL(response);
                setTimeout(()=> {
                  let url = URL.createObjectURL(response);
                const img: any = document.querySelector('#interior_image');
                img.src = url;
                }, 500);
              });
              }
              else{
                this.interior_image='';
              }

            }
            get_exterior_image()
            {


              if(this.tab4alldetails.f_extcntrdsgnphoto!='' && this.tab4alldetails.f_extcntrdsgnphoto!=null)
              {
              let url =this.baseurl_get+this.tab4alldetails.f_extcntrdsgnphoto;
              this.transactionService.getImageFile(url).subscribe((response: any) => {
                this.exterior_image = URL.createObjectURL(response);
                setTimeout(()=> {
                  let url = URL.createObjectURL(response);
                const img: any = document.querySelector('#exterior_image');
                img.src = url;
                }, 500);
              });
              }
              else{
                this.exterior_image='';
              }

            }
            get_label_image()
            {


              if(this.tab4alldetails.f_metelidplatephoto!='' && this.tab4alldetails.f_metelidplatephoto!=null)
              {
              let url =this.baseurl_get+this.tab4alldetails.f_metelidplatephoto;
              this.transactionService.getImageFile(url).subscribe((response: any) => {
                this.label_image = URL.createObjectURL(response);
                setTimeout(()=> {
                  let url = URL.createObjectURL(response);
                const img: any = document.querySelector('#label_image');
                img.src = url;
                }, 500);
              });
              }
              else{
                this.label_image='';
              }


              console.log(this.label_image);

            }
            get_shipping_image()
            {


              if(this.tab4alldetails.f_shpunitphoto!='' && this.tab4alldetails.f_shpunitphoto!=null)
              {
              let url =this.baseurl_get+this.tab4alldetails.f_shpunitphoto;
              this.transactionService.getImageFile(url).subscribe((response: any) => {
                this.shipping_image = URL.createObjectURL(response);
                setTimeout(()=> {
                  let url = URL.createObjectURL(response);
                const img: any = document.querySelector('#shipping_image');
                img.src = url;
                }, 500);
              });
              }
              else{
                this.shipping_image='';
              }

            }


            get_sbupl()
            {


              if(this.tab4alldetails.subpresloaddoc!='' && this.tab4alldetails.subpresloaddoc!=null)
              {
                console.log(this.tab4alldetails.subpresloaddoc)
                let sbu_arr=[];
                sbu_arr=this.tab4alldetails.subpresloaddoc.split('/');
                let len:number
                len=sbu_arr.length;
                let url =this.baseurl_get_sbupl+this.tab4alldetails.subpresloaddoc;
                this.transactionService.getPdfFile(url).subscribe((res) => {
                  this.sbu_file_path =  URL.createObjectURL(res);
                });
              this.sbu_file=sbu_arr[len-1];
              this.sbupl_uploaded=true;
              }
              else{
                this.sbu_file='';
                this.sbu_file_path='';
                this.sbupl_uploaded=false;
              }

            }




            browse_part_image()
            {


              this.PartPhoto_image_upload_dialog=true;



            }

            browse_interior_image()
            {

              this.InteriorContainer_upload_dialog=true;


            }
            browse_exterior_image()
            {

              this.ExteriorContainer_upload_dialog=true;
            }
            browse_label_image()
            {
              this.Label_MetalID_upload_dialog=true;

            }


            browse_shipping_image()
            {


              this.ShippingUnit_upload_dialog=true;

            }


            browse_sbu_pl()
            {


              this.sbupl_upload_dialog=true;

            }


            manadate_image_disable():boolean
            {

              // addded general proposal comments to the list of mandate fields
              if(this.tab4alldetails.f_extcntrdsgnphoto==null|| this.tab4alldetails.f_extcntrdsgnphoto==''||
              this.tab4alldetails.f_intcntrdsgnphoto==null|| this.tab4alldetails.f_intcntrdsgnphoto==''||
              this.tab4alldetails.f_partphoto==null|| this.tab4alldetails.f_partphoto==''||
              this.tab4alldetails.f_metelidplatephoto==null|| this.tab4alldetails.f_metelidplatephoto==''||
              this.tab4alldetails.f_shpunitphoto==null|| this.tab4alldetails.f_shpunitphoto=='' || this.crt_edt_empro==null || this.crt_edt_empro=='' )
              {
                return true;
              }

              else{
                if( this.crt_edt_empro.toLowerCase()=='no' && (this.agre_for_empro==null || this.agre_for_empro=='' || this.agre_for_empro.toLowerCase()=='no' || this.imp_prop_comm == null || this.imp_prop_comm.trim() == ''))
                {
                return true;
                }
                else
                {
                  return false
                }
              }

            }



            enable_imp_prop_comments():boolean
            {
              if(this.crt_edt_empro != null && this.crt_edt_empro.toLowerCase()=='yes')
              {
                return false;
              }
              else
              {
                return true;
              }
            }



  
  
  
          
  
  
    store_primary_part_exist_image()
    {

      if(this.current_region_code=='NA')
      {

        sessionStorage.setItem('napackpropart', JSON.stringify(this.selectedpart_submitter_image));
      }


      if(this.current_region_code=='EU')
      {
        sessionStorage.setItem('eupackpropart', JSON.stringify(this.selectedpart_submitter_image));
      }



      if(this.current_region_code=='AP')
      {
        sessionStorage.setItem('appackpropart', JSON.stringify(this.selectedpart_submitter_image));
      }



      if(this.current_region_code=='SA')
      {
        sessionStorage.setItem('sapackpropart', JSON.stringify(this.selectedpart_submitter_image));
      }
    }





    on_create_edit_exp_change()
    {
      console.log(this.crt_edt_empro);
      if(this.selectedpart_submitter_image.proposallastsaved>=4)
      {
          if(this.crt_edt_empro.toLowerCase()=='yes')
          {
            this.selectedpart_submitter_image.is_ee_proposal=true;

            this.packagingproposal.selectedpart_submitter_contandcos.is_ee_proposal=true;

            this.enable_submit_button=false;

            this.save_tab4_creat_edit();

          }

          else if(this.crt_edt_empro.toLowerCase()=='no')
          {

            this.selectedpart_submitter_image.is_ee_proposal=false;

            this.packagingproposal.selectedpart_submitter_contandcos.is_ee_proposal=false;


            this.enable_submit_button=true;


            this.save_tab4_creat_edit();

          }
      }


      else
      {


        if(this.crt_edt_empro.toLowerCase()=='yes')
          {
            this.enable_submit_button=false;
          }

          else if(this.crt_edt_empro.toLowerCase()=='no')
          {
            this.enable_submit_button=true;

          }

      }



    }



    save_tab4_creat_edit()

  {

  this.name=this.transactionService.getregionNameInSmallLetters();
    console.log(this.tab4alldetails);

    // service to call tab4 api
  // on success
  // store last saved tab


  this.tab4alldetails.createmgcyexpndpckgprop=this.crt_edt_empro;

  this.transactionService.savetab4details(this.tab4alldetails).subscribe(response=>
    {
        let msgs:any[];
        if(response=='Success')
        {
          this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary: 'success', detail: 'Image details saved successfully'});
          const check=false;

                    // change status update
                    console.log('comdet',this.originalTab4Details,this.changedTab4Details)
                    if(JSON.stringify(this.originalTab4Details) !== JSON.stringify(this.changedTab4Details))
                    {
                      // alert("value changed")
                    if(this.existChangeStatusTab4 != null && this.existChangeStatusTab4.toLowerCase()=='unchanged')
                    {
                      const setChangestatusTab4:changeTrackingForImprovement={idproposal:0,chnge_timestamp:'',status:''}
                      setChangestatusTab4.idproposal=this.selectedpart_submitter_image.proposalid
                      setChangestatusTab4.status='changed';
                    const cur_date=new Date();
                    const cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
                    setChangestatusTab4.chnge_timestamp=cur_date_transform
                      console.log('update change status ',setChangestatusTab4)
                      this.transactionService.setImprovementChangeStatus(setChangestatusTab4).subscribe(chang_stat=>{
                        console.log('change status update',chang_stat);

                        if(chang_stat.toLowerCase() == 'success')
                        {
                          sessionStorage.setItem('ProposalChangeStatus',setChangestatusTab4.status);
                          this.existChangeStatusTab4='changed';
                        }
                      })
                    }
                  }

                this.region=this.transactionService.getregion();
                const currentab=4;
                if(currentab>this.selectedpart_submitter_image.proposallastsaved)
                {

                  this.selectedpart_submitter_image.proposallastsaved=4;

                  console.log(this.selectedpart_submitter_image);
                }


                  this.store_primary_part_exist_image();
        }

        else{


          this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Image details is not saved! try again'});

        }

    })


  }





    save_tab4_close()

  {

  this.name=this.transactionService.getregionNameInSmallLetters();
    console.log(this.tab4alldetails);

    // service to call tab4 api
  // on success
  // store last saved tab

    this.tab4alldetails.int_cntrdesgncommnts=this.intr_dsgn_comments;
    this.tab4alldetails.gen_propslcommnts= this.general_comments;
      this.tab4alldetails.createmgcyexpndpckgprop=this.crt_edt_empro;
      this.tab4alldetails.agrmntexpndpackreqmnts=this.agre_for_empro;
    this.transactionService.savetab4details(this.tab4alldetails).subscribe(response=>
      {
        let msgs:any[];
        if(response=='Success')
        {
                  this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary: 'success', detail: 'Image details saved successfully'});
                  const check=false;

                  console.log('comdet',this.originalTab4Details,this.changedTab4Details)
                    if(JSON.stringify(this.originalTab4Details) !== JSON.stringify(this.changedTab4Details))
                    {
                      // change status update

                      // alert("change in data"+this.existChangeStatusTab4)
                      if(this.existChangeStatusTab4 != null && this.existChangeStatusTab4.toLowerCase()=='unchanged')
                      {
                        const setChangestatusTab4:changeTrackingForImprovement={idproposal:0,chnge_timestamp:'',status:''}
                        setChangestatusTab4.idproposal=this.selectedpart_submitter_image.proposalid
                        setChangestatusTab4.status='changed';
                      const cur_date=new Date();
                      const cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
                      setChangestatusTab4.chnge_timestamp=cur_date_transform
                        console.log('update change status ',setChangestatusTab4)
                        this.transactionService.setImprovementChangeStatus(setChangestatusTab4).subscribe(chang_stat=>{
                          console.log('change status update',chang_stat);

                          if(chang_stat.toLowerCase() == 'success')
                          {
                            sessionStorage.setItem('ProposalChangeStatus',setChangestatusTab4.status);
                            this.existChangeStatusTab4='changed';

                            if(check==false)
                            {
                              this.routeToSearchProposal()
                            }
                          }
                        })
                      }

                      else
                      {
                        if(check==false)
                        {
                          this.routeToSearchProposal()
                        }

                      }
                    }

                    else
                    {
                      if(check==false)
                      {
                        this.routeToSearchProposal()
                      }
                    }




                }

                else{


                  this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Image details is not saved! try again'});

                }

                })


  }


  routeToSearchProposal()
  {
    this.region=this.transactionService.getregion();
    const currentab=4;
    if(currentab>this.selectedpart_submitter_image.proposallastsaved)
      {

      this.selectedpart_submitter_image.proposallastsaved=4;
      this.store_primary_part_exist_image();
      console.log(this.selectedpart_submitter_image);
      }

      this.router.navigate(['/'+this.name+'/packagingprocessdashboard/searchexistingproposal'])

  }

  saveTab4_next()
  {
    this.tab4alldetails.int_cntrdesgncommnts=this.intr_dsgn_comments;
            this.tab4alldetails.gen_propslcommnts= this.general_comments;
             this.tab4alldetails.createmgcyexpndpckgprop=this.crt_edt_empro;
             this.tab4alldetails.agrmntexpndpackreqmnts=this.agre_for_empro;
             this.transactionService.savetab4details(this.tab4alldetails).subscribe(response=>
              {
                  let msgs:any[];
                  if(response=='Success')
                  {

                    const check=false;


                    console.log('comdet',this.originalTab4Details,this.changedTab4Details)
                    if(JSON.stringify(this.originalTab4Details) !== JSON.stringify(this.changedTab4Details))
                    {
                      // change status update

                      // alert("change in data"+this.existChangeStatusTab4)
                      if(this.existChangeStatusTab4 != null && this.existChangeStatusTab4.toLowerCase()=='unchanged')
                      {
                        const setChangestatusTab4:changeTrackingForImprovement={idproposal:0,chnge_timestamp:'',status:''}
                        setChangestatusTab4.idproposal=this.selectedpart_submitter_image.proposalid
                        setChangestatusTab4.status='changed';
                      const cur_date=new Date();
                      const cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
                      setChangestatusTab4.chnge_timestamp=cur_date_transform
                        console.log('update change status ',setChangestatusTab4)
                        this.transactionService.setImprovementChangeStatus(setChangestatusTab4).subscribe(chang_stat=>{
                          console.log('change status update',chang_stat);

                          if(chang_stat.toLowerCase() == 'success')
                          {
                            sessionStorage.setItem('ProposalChangeStatus',setChangestatusTab4.status);
                            this.existChangeStatusTab4='changed';

                            if(check==false)
                            {
                              this.routeToEE()
                            }
                          }
                        })
                      }

                      else
                      {
                        if(check==false)
                        {
                          this.routeToEE()
                        }

                      }
                    }

                    else
                    {
                      if(check==false)
                      {
                        this.routeToEE()
                      }
                    }



                }

              else
              {


                  this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Image details is not saved! try again'});

              }

          })



  }


  routeToEE()
  {
              this.region=this.transactionService.getregion();
              const currentab=4;

              if(currentab>this.selectedpart_submitter_image.proposallastsaved)
                  {

                  this.selectedpart_submitter_image.proposallastsaved=4;
                  this.store_primary_part_exist_image();
                  console.log('selectedpart_submitter_image',this.selectedpart_submitter_image);

                  }

                  if(this.tab4alldetails.createmgcyexpndpckgprop.toLowerCase()=='yes')
                    {
                      this.selectedpart_submitter_image.is_ee_proposal=true;

                        this.selectedpart_submitter_image.currentview_ee=true;

                    }
                    else{
                      this.selectedpart_submitter_image.is_ee_proposal=false;
                      this.selectedpart_submitter_image.currentview_ee=false;
                    }
                    this.store_primary_part_exist_image();
                    console.log('selectedpart_submitter_image-1919',this.selectedpart_submitter_image);

                    this.transactionService.loadmenuwhenclickedfromdashboard('Yes');
                    this.packagingproposal.ngOnInit();


  }


  submit_propsal_init()
  {


    this.store_proposal_values={

      gppuserid:'',
          id_proposal:0,
          corr_protc:'',
          // lst_saved_tab:0,
          seasnlreq:'',
          shelf_life:0,
          sp_part_no:'',
         // submit_status:"",
          temp_sensitivity:'',
          temp_stor_spec:'',
          time_stamp:'',
        //  plantgsdbcode:"",
          isstampblank:'',



          cntr_ret_type:'',
        used_carry_ovr_pck:'',
        cntr_base:'',
        cntr_suff:'',
        cntr_desc:'',
        parts_per_cntr:0,
        cntr_ext_len:0,
        cntr_width:0,
        cntr_height:0,
        cntr_nsted_fld_height:0,
        cntr_material:'',
        cntr_tar_weight:0,
        cntr_per_layer:0,
        max_layrs_shp_unt:0,
        empty_trays_cntr_layrs:0,
        max_cntr_per_shp_unt:0,
        cntr_rck_colr:'',
        shp_unt_base:'',
        shp_unt_suff:'',
        shp_unt_desc:'',
        part_per_shp_unit:0,
        shp_unt_ret_type:'',
        shp_unit_ext_len:0,
        shp_unt_ext_width:0,
        shp_unt_ext_height:0,
        shp_unt_nestd_fld_height:0,
        card_call:'',
        cntr_grp_id:'',
        cntr_ownrshp_strat_code:'',
        cd_cntr:0,
        cd_ownrshp_strat:0,
        pkgsignoff:'',
        subpresloaddoc:'',

        bct:0,
        ect:0,

        cntrOwner:'',


        chngdbase:'',





           cntr_shp_unit:'',
            shp_unt_tar_wt:0.000,
            shp_unt_gross_wt:0.000,
            cntr_gross_wt:0.000,
            ld_unload_inst:'',
            cntr_automtn_intf:'',
            transp_tested:'',
            tst_reprt_num:'',
            mot:'',
            dangrs_good:'',
            dangrs_good_ship_detls:'',
            rel_level:'',


            f_extcntrdsgnphoto:'',
          f_intcntrdsgnphoto:'',
          f_metelidplatephoto:'',
          f_partphoto:'',
          f_shpunitphoto:'',
          gen_propslcommnts:'',
          int_cntrdesgncommnts:'',
          createmgcyexpndpckgprop:'',
          agrmntexpndpackreqmnts:'',








      proposal_status_curr:'',
      proposal_status_prev:'',
      apprvr_cdsid:'',
      apprvr_asgn_date:this.date.transform(new Date(),"yyyy-MM-dd"),
      revision_hstry:'',
      apprvr_email_sent:'',
      manualenteredconainerbase:' ',
      pallettype:'',
      woodispmcmplnt:' ',
      offstmpmartprsnt:' ',
      supgsdbcode:'',
      supfullname:'',
      supaddress1:'',
      supcity:'',
      supstateprov:'',
      supcountrycode:'',
      supcontactnamefirst:'',
      supcontactname:'',
      supcontactfunctn:' ',
      supcontactemail:' ',
      supcontactphone:' ',
      supcontactfax:' ',
      submitdate:this.date.transform(new Date(),"yyyy-MM-dd"),
      iscortonimcspec:' ',
      cortonburststrnth:0,
      imcwtdisc:' ',
      blanksperstack:0,
      stacksperpallet:0,
      palletpintyp:'',


      isTstPckReqstd: 'No',
      isTstPckRecvd: 'NV',
      isTstPckEvaltd: 'NV',


      pressline: '',
      stmpdstackmthd: '',
      stmpwarehseloc: '',
      defaultcurr: '',
      cmmsuploadstat: '',
      hotstampcntrid: '',
      hotstampcntrcolor: '',
      hotstamppalletboxcolor: '',
      hotstamppalletbaselidcolor: '',
      hotstamidplatecolor: '',
      hotstampcolor: '',
      hotstampfontcolor: '',
      hotstampcomments: '',


      propsl_type:'Improvement',
    effectdate:this.date.transform(new Date(),"yyyy-MM-dd"),


    offstprtaprvd:'',
	is_bailmnt_prt_prsnt:''


    }

  }

  
checkIfSupplierDetailsBeforeSubmission():boolean
{
  var status=false;

  this.transactionService.get_draft_Tab1_data(this.selectedpart_submitter_image.proposalid).subscribe(tab1details=>{
    console.log("Tab1 details",tab1details);
    if(tab1details!=null)
    {  
      //this.store_proposal_values.supcontactfax=tab1_data.supcontactfax;
      if(tab1details.supcontactemail!=null && tab1details.supcontactemail.trim()!='' && 
         tab1details.defaultcurr!=null && tab1details.defaultcurr.trim()!='' && 
         tab1details.supcontactphone!=null && tab1details.supcontactphone.trim()!='' && 
         tab1details.supcontactfunctn!=null && tab1details.supcontactfunctn.trim()!='' && 
         tab1details.supcontactnamefirst!=null && tab1details.supcontactnamefirst.trim()!='' && 
         tab1details.supcontactname!=null && tab1details.supcontactname.trim()!='' && 
         tab1details.supstateprov!=null && tab1details.supstateprov.trim()!='' && 
         tab1details.supcountrycode!=null && tab1details.supcountrycode.trim()!='' && 
         tab1details.supfullname!=null && tab1details.supfullname.trim()!='' && 
         tab1details.supaddress1!=null && tab1details.supaddress1.trim()!='' && 
         tab1details.supcity!=null && tab1details.supcity.trim()!='' && 
         tab1details.supgsdbcode!=null && tab1details.supgsdbcode.trim()!='' )
        {
          status=true;
        }

        else
        {
          status=false;
        }
    }

    else
    {
      status=false;
    }

  });
  
  return status;

}

goToSupProfilePage()
{
  let reg_url: string
  reg_url = this.transactionService.getregionurlvalue(this.region);
  reg_url += 'packagingprocess/packsupplieradmin/editsupplierprofile';
  this.router.navigate([reg_url])
}

submitAfterSupplierProfileValidation()
{
  this.inSubmitFlowForSupplierProfileValidation=true;
  this.transactionService.getSupInfo(this.selectedpart_submitter_image.supplier_code).subscribe(supDetls=>{
    console.log("Supplier details",supDetls);
    if(supDetls!=null)
    {  
      //this.store_proposal_values.supcontactfax=tab1_data.supcontactfax;
      if(supDetls.contactemail==null || supDetls.contactemail.trim()=='')
        {
          this.supcontactemailInvalid=true;
          this.supcontactemailInvalidReason="Contact Email"
        }
        if(supDetls.defaultcurr==null || supDetls.defaultcurr.trim()=='')
        {
          this.defaultcurrInvalid=true;
          this.defaultcuuInvalidReason="Default Currency";
        }
        if(supDetls.contactphone==null || supDetls.contactphone.trim()=='')
        {
          this.supcontactphoneInvalid=true;
          this.supcontactphoneInvalidReason="Contact Phone";
        }
        if(supDetls.contactfunctn==null || supDetls.contactfunctn.trim()=='')
        {
          this.supcontactfunctnInvalid=true;
          this.supcontactfunctnInvalidReason="Contact Function";
        }
        if(supDetls.contactnamefirst==null || supDetls.contactnamefirst.trim()=='')
        {
          this.supcontactnamefirstInvalid=true;
          this.supcontactnamefirstInvalidReason="Name Prefix"; 
        }
        if(supDetls.contactname==null || supDetls.contactname.trim()=='')
        {
          this.supcontactnameInvalid=true;
          this.supcontactnameInvalidReason="Contact Name";
  
        }
        if(supDetls.stateprovince==null || supDetls.stateprovince.trim()=='')
        {
          this.supstateprovInvalid=true;
          this.supstateprovInvalidReason="State Province";
        }
        if(supDetls.countrycode==null || supDetls.countrycode.trim()=='')
        {
          this.supcountrycodeInvalid=true;
          this.supcountrycodeInvalidReason="Country Code";
        }
        if(supDetls.fullname==null || supDetls.fullname.trim()=='')
        {
          this.supfullnameInvalid=true;
          this.supfullnameInvalidReason="Name";
        }
        if(supDetls.address1==null || supDetls.address1.trim()=='')
        {
          this.supaddress1Invalid=true;
          this.supaddress1InvalidReason="Address";
        }
        if(supDetls.city==null || supDetls.city.trim()=='')
        {
          this.supcityInvalid=true;
          this.supcityInvalidReason="City";
        }
        if(supDetls.suppliergsdbcode==null || supDetls.suppliergsdbcode.trim()=='' )
        {
          this.supgsdbcodeInvalid=true;
          this.supgsdbcodeInvalidReason="GSDB Code";
        }
        if(supDetls.contactfax==null || supDetls.contactfax.trim()=='' )
          {
            this.supFaxInvalid=true;
            this.supFaxInvalidReason="Fax";
          }

      if(!this.supcontactnameInvalid && !this.supfullnameInvalid && !this.supcontactphoneInvalid)
      {

        // If mandatory fields like contactname , name and phone number is valid, show warning in dialog if presnt but allow them to proceed
        this.canProceedWithPropSubmission=true;

        if(this.supstateprovInvalid || this.supcontactnamefirstInvalid || this.supcontactfunctnInvalid || this.defaultcurrInvalid || this.supcontactemailInvalid || 
          this.supgsdbcodeInvalid || this.supcityInvalid || this.supaddress1Invalid || this.supcountrycodeInvalid || this.supFaxInvalid)
          
        {
          this.showSupplierDetailsEmptyWarning=true;
        }
        else
        {
          this.showSupplierDetailsEmptyWarning=false;
          //this.submitproposal(); // since no warnings and no mandatory fields missing

          this.transactionService.get_draft_Tab1_data(this.selectedpart_submitter_image.proposalid).subscribe(tab1detls=>{
            console.log("Tab1 Details",tab1detls);
            console.log("Suppler Profile",supDetls);
            this.primarypartsattributes=tab1detls;

            this.primarypartsattributes.supgsdbcode=supDetls.suppliergsdbcode;
            this.primarypartsattributes.supfullname=supDetls.fullname;
            this.primarypartsattributes.supaddress1=supDetls.address1;
            this.primarypartsattributes.supcity=supDetls.city;
            this.primarypartsattributes.supstateprov=supDetls.stateprovince;
            this.primarypartsattributes.supcountrycode=supDetls.countrycode;
            this.primarypartsattributes.supcontactnamefirst=supDetls.contactnamefirst;
            this.primarypartsattributes.supcontactname=supDetls.contactname;
            this.primarypartsattributes.supcontactfunctn=supDetls.contactfunctn;
            this.primarypartsattributes.supcontactemail=supDetls.contactemail;
            this.primarypartsattributes.supcontactphone=supDetls.contactphone;
            this.primarypartsattributes.supcontactfax=supDetls.contactfax;
            this.primarypartsattributes.defaultcurr=supDetls.defaultcurr;

            this.primarypartsattributes.timestamp=new Date().toISOString().split('.')[0];

            this.transactionService.savePrimaryPartDetails(this.primarypartsattributes).subscribe(saveattributes=>
              {
                const op=saveattributes;
                console.log('successful',op);
                if(saveattributes=='Success')
                {
                  this.submitproposal(); // since no warnings and no mandatory fields missing
                }
                else
                {
                  this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Supplier Details Not updated in Parts And Attributes Page'});
                }
              });


          });
        }
        //this.supplierProfileInit();
      }

      else
      {
         // If mandatory fields like contactname , name and phone number is not valid, dont allow them proceed with submission and show warnings also in same dialog
        this.canProceedWithPropSubmission=false;
      }
    }
    else
    {
      this.canProceedWithPropSubmission=false;
    }

  });
}

closeMandatorySupValidationDialog()
{
  this.canProceedWithPropSubmission=true;
}

   submitproposal()
      {
        this.loaderimgimp=true;
        this.inSubmitFlowForSupplierProfileValidation=false;
          this.supplierProfileInit();
        this.region=this.transactionService.getregion();
          const currentab=4;
          // service to call tab4 api
  console.log('Submit Prop');

          this.tab4alldetails.int_cntrdesgncommnts=this.intr_dsgn_comments;
            this.tab4alldetails.gen_propslcommnts= this.general_comments;
             this.tab4alldetails.createmgcyexpndpckgprop=this.crt_edt_empro;
             this.tab4alldetails.agrmntexpndpackreqmnts=this.agre_for_empro;

          this.transactionService.savetab4details(this.tab4alldetails).subscribe(response=>
            {
                let msgs:any[];
                if(response=='Success')
                {
                  this.packagingproposal.addtoast({severity:'success', summary: 'success', detail: 'Image details saved successfully'});
                  const check=false;


                  console.log('comdet',this.originalTab4Details,this.changedTab4Details)
                  if(JSON.stringify(this.originalTab4Details) !== JSON.stringify(this.changedTab4Details))
                  {
                    // change status update

                    // alert("change in data"+this.existChangeStatusTab4)
                    if(this.existChangeStatusTab4 != null && this.existChangeStatusTab4.toLowerCase()=='unchanged')
                    {
                      const setChangestatusTab4:changeTrackingForImprovement={idproposal:0,chnge_timestamp:'',status:''}
                      setChangestatusTab4.idproposal=this.selectedpart_submitter_image.proposalid
                      setChangestatusTab4.status='changed';
                    const cur_date=new Date();
                    const cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
                    setChangestatusTab4.chnge_timestamp=cur_date_transform
                      console.log('update change status ',setChangestatusTab4)
                      this.transactionService.setImprovementChangeStatus(setChangestatusTab4).subscribe(chang_stat=>{
                        console.log('change status update',chang_stat);

                        if(chang_stat.toLowerCase() == 'success')
                        {
                          sessionStorage.setItem('ProposalChangeStatus',setChangestatusTab4.status);
                          this.existChangeStatusTab4='changed';

                          if(check==false)
                          {
                            this.getDataForSubmitProposal()
                          }
                        }
                        else
                        {
                          this.loaderimgimp=false;
                          this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Error Updating improveemnt change status'});
                        }
                      })
                    }

                    else
                    {
                      if(check==false)
                      {
                        this.getDataForSubmitProposal()
                      }

                    }
                  }

                  else
                  {
                    if(check==false)
                    {
                      this.getDataForSubmitProposal()
                    }
                  }


          }

          else{

            this.loaderimgimp=false;
            this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Image details is not saved! try again'});

          }

          })



          // service to call post transaction master api

      }


      getDataForSubmitProposal()
      {

                        this.region=this.transactionService.getregion();
                        const currentab=4;
                        if(currentab>this.selectedpart_submitter_image.proposallastsaved)
                          {

                          this.selectedpart_submitter_image.proposallastsaved=4;
                          this.store_primary_part_exist_image();
                          console.log(this.selectedpart_submitter_image);
                          }





                // service to gett 1234 tab details api


                this.transactionService.get_draft_Tab1_data(this.selectedpart_submitter_image.proposalid).subscribe(tab1_data=>{

                  this.tab1DataGet=tab1_data;
                console.log('tab1t,',tab1_data);
                this.store_proposal_values.id_proposal=this.selectedpart_submitter_image.proposalid;
                this.store_proposal_values.corr_protc=tab1_data.corr_protc;
                // this.store_proposal_values.lst_saved_tab=tab1_data.lst_saved_tab,
                this.store_proposal_values.seasnlreq=tab1_data.seasnlreq,
                this.store_proposal_values.shelf_life=tab1_data.shelf_life,
                this.store_proposal_values.sp_part_no=tab1_data.sp_part_no,
                // this.store_proposal_values.submit_status=tab1_data.submit_status,
                this.store_proposal_values.temp_sensitivity=tab1_data.temp_sensitivity,
                this.store_proposal_values.temp_stor_spec=tab1_data.temp_stor_spec,
                   //changes to update with current time stamp;
                //this.store_proposal_values.time_stamp=tab1_data.timestamp,
                this.store_proposal_values.time_stamp=new Date().toISOString().split('.')[0]
                //this.store_proposal_values.plantgsdbcode=tab1_data.plantgsdbcode,
                this.store_proposal_values.isstampblank=tab1_data.isstampblank,


                this.store_proposal_values.supgsdbcode=tab1_data.supgsdbcode;

                this.store_proposal_values.supfullname=tab1_data.supfullname;
                this.store_proposal_values.supaddress1=tab1_data.supaddress1;
                this.store_proposal_values.supcity=tab1_data.supcity;
                this.store_proposal_values.supstateprov=tab1_data.supstateprov;
                this.store_proposal_values.supcountrycode=tab1_data.supcountrycode;
                this.store_proposal_values.supcontactnamefirst=tab1_data.supcontactnamefirst;
                this.store_proposal_values.supcontactname=tab1_data.supcontactname;
                this.store_proposal_values.supcontactfunctn=tab1_data.supcontactfunctn;
                this.store_proposal_values.supcontactemail=tab1_data.supcontactemail;
                this.store_proposal_values.supcontactphone=tab1_data.supcontactphone;
                this.store_proposal_values.supcontactfax=tab1_data.supcontactfax;
                this.store_proposal_values.defaultcurr=tab1_data.defaultcurr;







                this.transactionService.get_draft_selectedparts_data(this.selectedpart_submitter_image.proposalid).subscribe(selectedparts_data=>{

                console.log(selectedparts_data);
                this.selectedPartsGet=selectedparts_data;

                this.store_proposal_selectedparts=selectedparts_data;








                  this.transactionService.loadtab2detailsifproposalexistalready(this.selectedpart_submitter_image.proposalid).subscribe(cont_cos_data=>
                    {
                      console.log(cont_cos_data);
                      this.tab2data=cont_cos_data


                      console.log(this.tab2data,'tab2222')


                      this.store_proposal_values.cntr_ret_type=this.tab2data.cntr_ret_type
                      this.store_proposal_values.used_carry_ovr_pck=this.tab2data.used_carry_ovr_pck,
                      this.store_proposal_values.cntr_base=this.tab2data.cntr_base,
                      this.store_proposal_values.cntr_suff=this.tab2data.cntr_suff,
                      this.store_proposal_values.cntr_desc=this.tab2data.cntr_desc,
                      this.store_proposal_values.parts_per_cntr=this.tab2data.parts_per_cntr,
                      this.store_proposal_values.cntr_ext_len=this.tab2data.cntr_ext_len,
                      this.store_proposal_values.cntr_width=this.tab2data.cntr_width,
                      this.store_proposal_values.cntr_height=this.tab2data.cntr_height,
                      this.store_proposal_values.cntr_nsted_fld_height=this.tab2data.cntr_nsted_fld_height
                      this.store_proposal_values.cntr_material=this.tab2data.cntr_material,
                      this.store_proposal_values.cntr_tar_weight=this.tab2data.cntr_tar_weight,
                      this.store_proposal_values.cntr_per_layer=this.tab2data.cntr_per_layer,
                      this.store_proposal_values.max_layrs_shp_unt=this.tab2data.max_layrs_shp_unt,
                      this.store_proposal_values.empty_trays_cntr_layrs=this.tab2data.empty_trays_cntr_layrs,
                      this.store_proposal_values.max_cntr_per_shp_unt=this.tab2data.max_cntr_per_shp_unt,
                      this.store_proposal_values.cntr_rck_colr=this.tab2data.cntr_rck_colr,
                      this.store_proposal_values.shp_unt_base=this.tab2data.shp_unt_base,
                      this.store_proposal_values.shp_unt_suff=this.tab2data.shp_unt_suff,
                      this.store_proposal_values.shp_unt_desc=this.tab2data.shp_unt_desc,
                      this.store_proposal_values.part_per_shp_unit=this.tab2data.part_per_shp_unit,
                      this.store_proposal_values.shp_unt_ret_type=this.tab2data.shp_unt_ret_type,
                      this.store_proposal_values.shp_unit_ext_len=this.tab2data.shp_unit_ext_len,
                      this.store_proposal_values.shp_unt_ext_width=this.tab2data.shp_unt_ext_width,
                      this.store_proposal_values.shp_unt_ext_height=this.tab2data.shp_unt_ext_height,
                      this.store_proposal_values.shp_unt_nestd_fld_height=this.tab2data.shp_unt_nestd_fld_height,
                      this.store_proposal_values.card_call=this.tab2data.card_call,
                      this.store_proposal_values.cntr_grp_id=this.tab2data.cntr_grp_id,
                      this.store_proposal_values.cntr_ownrshp_strat_code=this.tab2data.cntr_ownrshp_strat_code,
                      this.store_proposal_values.cd_cntr=this.tab2data.cd_cntr,
                      this.store_proposal_values.cd_ownrshp_strat=this.tab2data.cd_ownrshp_strat;


                      this.store_proposal_values.cntrOwner=this.tab2data.cntrowner;


                      this.store_proposal_values.pallettype=this.tab2data.pallettype;
                      this.store_proposal_values.woodispmcmplnt=this.tab2data.woodispmcmplnt;
                      this.store_proposal_values.offstmpmartprsnt=this.tab2data.offstmpmartprsnt;
                      this.store_proposal_values.blanksperstack=this.tab2data.blanksperstack;
                      this.store_proposal_values.stacksperpallet=this.tab2data.stacksperpallet;
                      this.store_proposal_values.palletpintyp=this.tab2data.palletpintyp;
                      this.store_proposal_values.iscortonimcspec=this.tab2data.iscartonimcspec;
                      this.store_proposal_values.cortonburststrnth=this.tab2data.cartonburststrnth;
                      this.store_proposal_values.imcwtdisc=this.tab2data.imcwtdisc;

                      this.store_proposal_values.bct=this.tab2data.bct;
                      this.store_proposal_values.ect=this.tab2data.ect;


                    if(this.tab2data.cd_ownrshp_strat!=null && this.tab2data.cd_ownrshp_strat!=0 && this.tab2data.cntr_ownrshp_strat_code!=null && this.tab2data.cntr_ownrshp_strat_code!='')
                {

                /*  this.transactionService.getcosdetails(this.tab2data.cntr_ownrshp_strat_code,this.tab2data.cd_ownrshp_strat).subscribe(cosdetails=>{
                    console.log(cosdetails);
                    if(cosdetails.pkg_sign_off.toLowerCase()=="required")
                    {
                      //condition to add after approver done to classify as Yes or no
                      this.store_proposal_values.pkgsignoff="N";
                    }
                    if(cosdetails.sub_press_load_doc.toLowerCase()=="required")
                    {
                      //check condition


                      this.store_proposal_values.subpresloaddoc="N";
                    }





                    console.log("newform",cosdetails.ownrshp_desc);



                  })*/

                  this.get_tab3_tab4_details_post();


                }



                else{


                  this.get_tab3_tab4_details_post();

                }



                      // check for shipping unit ret type exp or retun
                      // if expend display pallet value from api
                      // else dipaly pallet also returnable
                      // wood complaint and oficial stamp identificaton mark value comes only for na others null

                        // service to call tab 3 get details





                    })






                })




                })

      }




      get_tab3_tab4_details_post()
      {
        this.transactionService.getaddedcomponentdetailsfortab3(this.selectedpart_submitter_image.proposalid).subscribe(complist=>{

          this.compDataGet=complist;
          console.log(complist);
          let temp;
          temp=JSON.parse(JSON.stringify(complist))

          console.log(temp);
          if(temp.length!=0)
          {
            let ownershp='';
            for(let j=0;j<temp.length;j++)
            {
                console.log(temp[j].comp_ownershp);
             if(temp[j].comp_ownershp.toLowerCase()=='ford')
             {
               ownershp='F';
             }
             else if(temp[j].comp_ownershp.toLowerCase()=='supplier')
             {
              ownershp='S';
             }
             else if(temp[j].comp_ownershp.toLowerCase()=='third party')
             {
              ownershp='T';

             }

              this.store_proposal_componenets.push(
                {
                 id_proposal:this.selectedpart_submitter_image.proposalid,
                 id_comp:temp[j].id_comp,
                 comp_base:temp[j].comp_base,
                 comp_suffix:temp[j].comp_suffix,
                 comp_desc:temp[j].comp_desc,
                 comp_l:temp[j].comp_l,
                 comp_w:temp[j].comp_w,
                 comp_h:temp[j].comp_h,
                 comp_type:temp[j].comp_ret_type,
                 qt_per_min:temp[j].qt_per_min_su,
                 updated_by:temp[j].upated_by,
                 updated_dt:temp[j].updated_dt,
                 comp_tar_wt:temp[j].comp_tare_wt,
                 nestedfoldedht:temp[j].nestedfolderht,
                 comp_code:temp[j].comp_code,
                 comp_ownershp:temp[j].comp_ownershp,
                 comp_material:temp[j].comp_material,
                 tooledcomp:temp[j].tooledcomp,
                 is_compulsary:temp[j].is_compulsary,
                 compselctnfor:temp[j].compselctnfor,
                 material_flag:temp[j].material_flag,
                 typ_no:temp[j].typ_no
                 // material_flag
                 });

            }

          }
          else{
            this.store_proposal_componenets=[];
          }





       this.transactionService.loadtab3detailsfromAPI(this.selectedpart_submitter_image.proposalid).subscribe(tab3_data=>{

          console.log('Tab3_data',tab3_data);
          this.tab3detailsGet=tab3_data

          this.store_proposal_values.cntr_shp_unit=tab3_data.cntr_shp_unit;
          this.store_proposal_values.shp_unt_tar_wt=tab3_data.shp_unt_tar_wt;
          this.store_proposal_values.shp_unt_gross_wt=tab3_data.shp_unt_gross_wt;
          this.store_proposal_values.cntr_gross_wt=tab3_data.cntr_gross_wt;
          this.store_proposal_values.ld_unload_inst=tab3_data.ld_unload_inst;
          this.store_proposal_values.cntr_automtn_intf=tab3_data.cntr_automtn_intf;
          this.store_proposal_values.transp_tested=tab3_data.transp_tested;
          this.store_proposal_values.tst_reprt_num=tab3_data.tst_reprt_num;
          this.store_proposal_values.mot=tab3_data.mot;
          this.store_proposal_values.dangrs_good=tab3_data.dangrs_good;
          this.store_proposal_values.dangrs_good_ship_detls=tab3_data.dangrs_good_ship_detls;
          this.store_proposal_values.rel_level=tab3_data.rel_level;





        // inside that service

          // service to get tab4 details
          // for images append the values with the base url if value is not null or ""
          // in html add a senario when the value is so  to display empty without img tag


       this.transactionService.loadtab4details(this.selectedpart_submitter_image.proposalid).subscribe(tab4_data=>{


            console.log(tab4_data)
            this.tab4Get=tab4_data;
            console.log(this.selectedpart_submitter_image)
            this.store_proposal_values.f_extcntrdsgnphoto=tab4_data.f_extcntrdsgnphoto,
            this.store_proposal_values.f_intcntrdsgnphoto=tab4_data.f_intcntrdsgnphoto;
            this.store_proposal_values.f_metelidplatephoto=tab4_data.f_metelidplatephoto;
            this.store_proposal_values.f_partphoto=tab4_data.f_partphoto;
            this.store_proposal_values.f_shpunitphoto=tab4_data.f_shpunitphoto;
            this.store_proposal_values.gen_propslcommnts=tab4_data.gen_propslcommnts;
            this.store_proposal_values.int_cntrdesgncommnts=tab4_data.int_cntrdesgncommnts;
            this.store_proposal_values.createmgcyexpndpckgprop=tab4_data.createmgcyexpndpckgprop;
            this.store_proposal_values.agrmntexpndpackreqmnts=tab4_data.agrmntexpndpackreqmnts;
            this.store_proposal_values.subpresloaddoc=tab4_data.subpresloaddoc;

             if(this.selectedpart_submitter_image.imp_part_status!=null && (this.selectedpart_submitter_image.imp_part_status.toLowerCase()=='rejected' || this.selectedpart_submitter_image.imp_part_status.toLowerCase()=='recalled'))
            {
              this.transactionService.loadtransacmasterdetails(this.selectedpart_submitter_image.proposalid).subscribe(transdet_img=>{


                console.log(transdet_img);

                this.trascMastrGet=transdet_img;
                this.store_proposal_values.chngdbase=transdet_img.chngdbase;
                this.store_proposal_values.revision_hstry=transdet_img.revision_hstry;
                this.store_proposal_values.revision_hstry+='|'


                this.submit_proposal_service();

              })
            }


            else{
              // this.store_proposal_values.revision_hstry=""; added this for improvement proposal
              this.store_proposal_values.revision_hstry='';
             // this.store_proposal_values.revision_hstry+=tab4_data.gen_propslcommnts;
             // this.store_proposal_values.revision_hstry+=this.curr_cdsid+"`"+this.user_details.name+"`"+this.user_details.email+"`Packaging Proposal Submitted`Proposal Submitted`"+cur_date_transform+"`"+tab4_data.gen_propslcommnts;

              this.submit_proposal_service()
            }






          })





        })
        })
      }




      submit_proposal_service()
      {

        console.log('submit');
        console.log(this.store_proposal_values);
        console.log(this.store_proposal_componenets);
        console.log(this.store_proposal_selectedparts);


        this.store_proposal_values.apprvr_cdsid=this.curr_cdsid;

        console.log(this.user_details)

        let cur_date;
        let cur_date_transform
        cur_date=new Date();
        cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');

        this.store_proposal_values.revision_hstry+=this.curr_cdsid+'`'+this.user_details.name+'`'+this.user_details.email+'`Packaging Proposal Submitted`Proposal Submitted`'+cur_date_transform+'`'+this.imp_prop_comm;






          if(this.tab4alldetails.createmgcyexpndpckgprop.toLowerCase()=='no')
          {


            let mark_submit;
            mark_submit=1;

            this.oldProposalDetailsForChnageTrack=
            {
              cntrTareWt:this.tab2data.cntr_tar_weight,
              cntrNestedFldedHt:this.tab2data.cntr_nsted_fld_height,
              cntrExtLength:this.tab2data.cntr_ext_len,
              cntrExtwidth:this.tab2data.cntr_width,
              cntrExtHeight:this.tab2data.cntr_height,
              // maxLayersPerSu:this.tab2data.max_layrs_shp_unt,
              // cntrPerLayer:this.tab2data.cntr_per_layer,
              // partsPerSu:this.tab2data.part_per_shp_unit,
              maxCntrsPerSu:this.tab2data.max_cntr_per_shp_unt,
              shippingUnitBase:this.tab2data.shp_unt_base,
              shippingUnitSuffix:this.tab2data.shp_unt_suff,
              // shippingExtLen:this.tab2data.shp_unit_ext_len,
              // shippingExtWidth:this.tab2data.shp_unt_ext_width,
              // shippingExtHeight:this.tab2data.shp_unt_ext_height,
              // shippingUnitNestedFoldedht:this.tab2data.shp_unt_nestd_fld_height,
              shippingUnitReturnType:this.tab2data.shp_unt_ret_type,
              shiipingUnitTareWeight:this.tab3detailsGet.shp_unt_tar_wt,
              cntrGrossWeight:this.tab3detailsGet.cntr_gross_wt,
              shippingUnitGrossWeight:this.tab3detailsGet.shp_unt_gross_wt,
            }

           /*  this.no_of_container=this.contadmindetls.cntr_per_layer*(this.contadmindetls.max_layers_shpg_unit-tab2_data.empty_trays_cntr_layrs);

            this.containergrossweight=tab2_data.cntr_tar_weight+(tab2_data.parts_per_cntr*partweight);
            this.shippingunitgrossweight=(this.no_of_container*this.containergrossweight)+this.shippingunittareweight;
   */
            this.transactionService.editbutton_get_contr_details(this.tab2data.cntr_base.trim(),this.tab2data.cd_cntr).subscribe(cntrdetails=>{
              this.containerDetails=cntrdetails;

              this.tempCntrCompWeight=0
              this.tempSUCompWeight=0

              for( const i of this.compDataGet)
              {
                if(i.compselctnfor.toLowerCase()=='shipping unit')
                {
                  this.tempSUCompWeight+=(i.qt_per_min_su*i.comp_tare_wt);
                }
                else
                {
                  this.no_of_container=this.tab2data.cntr_per_layer*(this.tab2data.max_layrs_shp_unt-this.tab2data.empty_trays_cntr_layrs);

                  this.tempCntrCompWeight+=(i.qt_per_min_su *i.comp_tare_wt);
                  // this.tempSUCompWeight+=(this.no_of_container*this.tempCntrCompWeight);

                }
              }

              const noOfContainer=this.tab2data.cntr_per_layer*(this.tab2data.max_layrs_shp_unt-this.tab2data.empty_trays_cntr_layrs);
              let partweight=0;
                for(const j of this.selectedPartsGet)
                {
                  if(j.isprimary.toLowerCase() == 'yes')
                  {
                  partweight+=j.ngpp_part_wt;
                  break
                  }
                }
                if(this.tab2data.cntr_ret_type != null && this.tab2data.cntr_ret_type.toLowerCase() != 'expendable')
                {
                var cntrGrossWt=this.containerDetails.cntr_tare_wt+(this.tab2data.parts_per_cntr*partweight)+this.tempCntrCompWeight;
            var suTareWt=(noOfContainer*(this.tempCntrCompWeight+this.containerDetails.cntr_tare_wt))+this.tempSUCompWeight;
                }
                else
                {
                  var cntrGrossWt=this.tab2data.cntr_tar_weight+(this.tab2data.parts_per_cntr*partweight)+this.tempCntrCompWeight;
              var suTareWt=(noOfContainer*(this.tempCntrCompWeight+this.tab2data.cntr_tar_weight))+this.tempSUCompWeight;
                }
           // var suGrossWt=(noOfContainer*cntrGrossWt)+this.containerDetails.shpg_unit_tare_wt+this.tempSUCompWeight;
           var suGrossWt=(noOfContainer*cntrGrossWt)+this.tempSUCompWeight;
              
              cntrGrossWt=this.transactionService.roundValueToNDecimal(cntrGrossWt,3);
              suGrossWt=this.transactionService.roundValueToNDecimal(suGrossWt,3)
            suTareWt=this.transactionService.roundValueToNDecimal(suTareWt,3);
              var partsPerSu=  (
                this.tab2data.cntr_per_layer* (this.tab2data.max_layrs_shp_unt-this.tab2data.empty_trays_cntr_layrs)
              ) * this.tab2data.parts_per_cntr;
  
  
                console.log("no of cntr",noOfContainer);
                console.log(partweight,cntrGrossWt,suGrossWt,partsPerSu)

                let cntr_tare_wt=this.tab2data.cntr_tar_weight;
                let collapsed_ht=this.tab2data.cntr_nsted_fld_height;
                let cntr_exterior_l=this.tab2data.cntr_ext_len;
                let cntr_exterior_w=this.tab2data.cntr_width;
                let cntr_exterior_h=this.tab2data.cntr_height;
                let max_cntr_per_shpg_unit=this.tab2data.max_cntr_per_shp_unt;

                if(this.tab2data.cntr_ret_type != null && this.tab2data.cntr_ret_type.toLowerCase() != 'expendable')
                {
                  cntr_tare_wt=this.containerDetails.cntr_tare_wt;
                  collapsed_ht=this.containerDetails.collapsed_ht;
                  cntr_exterior_l=this.containerDetails.cntr_exterior_l;
                  cntr_exterior_w=this.containerDetails.cntr_exterior_w;
                  cntr_exterior_h=this.containerDetails.cntr_exterior_h;
                 // changed as max container per shipping unit is calculated field
                  //max_cntr_per_shpg_unit=this.containerDetails.max_cntr_per_shpg_unit;
                }

                this.newProposalDetailsForChangeTrack={

                cntrTareWt:cntr_tare_wt,
                cntrNestedFldedHt:collapsed_ht,
                cntrExtLength:cntr_exterior_l,
                cntrExtwidth:cntr_exterior_w,
                cntrExtHeight:cntr_exterior_h,
                // maxLayersPerSu:this.containerDetails.max_layers_shpg_unit,
                // cntrPerLayer:this.containerDetails.cntr_per_layer,
                // partsPerSu:partsPerSu,
                maxCntrsPerSu:max_cntr_per_shpg_unit,
                shippingUnitBase:this.containerDetails.cntr_base,
                shippingUnitSuffix:this.containerDetails.cntr_suffix,
                // shippingExtLen:this.containerDetails.shpg_unit_exterior_l,
                // shippingExtWidth:this.containerDetails.shpg_unit_exterior_w,
                // shippingExtHeight:this.containerDetails.shpg_unit_exterior_h,
                // shippingUnitNestedFoldedht:this.containerDetails.shpg_unit_nstdorflded_ht,
                shippingUnitReturnType:this.containerDetails.exp_refundable,
              shiipingUnitTareWeight:suTareWt,
                cntrGrossWeight:cntrGrossWt,
                shippingUnitGrossWeight:suGrossWt,
              }

              console.log('old data',this.oldProposalDetailsForChnageTrack);
              console.log('new data',this.newProposalDetailsForChangeTrack);

              if(this.tab2data.cntr_ret_type.toLowerCase() != 'expendable' && JSON.stringify(this.oldProposalDetailsForChnageTrack)!=JSON.stringify(this.newProposalDetailsForChangeTrack))
              {
                this.enableChnageDialog=true;
                this.loaderimgimp=false;
              }
              else if(this.tab2data.cntr_ret_type.toLowerCase() == 'expendable' && (this.oldProposalDetailsForChnageTrack.shiipingUnitTareWeight!=this.newProposalDetailsForChangeTrack.shiipingUnitTareWeight || this.oldProposalDetailsForChnageTrack.cntrGrossWeight!=this.newProposalDetailsForChangeTrack.cntrGrossWeight || this.oldProposalDetailsForChnageTrack.shippingUnitGrossWeight!=this.newProposalDetailsForChangeTrack.shippingUnitGrossWeight ))
              {
                this.enableChnageDialog=true;
                this.loaderimgimp=false;
              }
              else
              {
                this.enableChnageDialog=false;
              }


              if(this.enableChnageDialog==false)
              {
                // this.submitAPICall();

                 // check for change status update
                // based on value ask for confirmation to procced if no changes has been made
                // and call the proposal service

                console.log('Inside 0')

                this.transactionService.getImprovementChangeStatus(this.selectedpart_submitter_image.proposalid).subscribe(changeStatus=>{
                  console.log('setChangestatusSession api o/p',changeStatus)

                  if(changeStatus != null && changeStatus.status != null && changeStatus.status.toLowerCase() == 'unchanged')
                  {
                    this.loaderimgimp=false;
                      this.confirmationService.confirm({
                        message: 'There is no improvement done, Do you still want to procced for submission of proposal',
                        header: 'Confirm',
                        icon: 'pi pi-exclamation-triangle',
                        accept: () => {
                          this.submitProposalAfterConfirmation();
                            },
                        reject: ()=>{

                        }
                    });
                  }
                  else
                  {
                    this.submitProposalAfterConfirmation();
                  }
                })
              }
              else
              {
                this.loaderimgimp=false;
                this.checkValidationWhileSubmittingChanges();
                
              console.log("test",this.cntrgrosswtInvalid,this.sugrosswtInvalid,this.sutarewtInvalid)
                this.reasonForNotSubmitting="";
                
                // || this.partDimensionsInvalid
              if(this.cntrgrosswtInvalid || this.sutarewtInvalid || this.sugrosswtInvalid || this.expendable_wgts_InValid || this.suExtHgtInvalid || this.suNstFldHgtInvalid)
                {
                  this.reasonForNotSubmitting='Please Update the Proposal Accordingly and then Submit!'
                }

              }
             //
            })


        }

      }

      updateChangesWhileSubmitting()
      {
        // this.enableChnageDialog=false;
        this.submitProposalAfterConfirmation();
      }

      closeChangeDialog()
      {
        this.enableChnageDialog=false;

        this.sugrosswtInvalid=false;
        this.cntrgrosswtInvalid=false;
        this.cntrgrosswtInvalidReason=null;
        this.cntrgrosswtInvalidReason=null;
        this.partDimensionsInvalid=false;
        this.partDimensionsInvalidReason=null;
        this.expendable_wgts_InValid=false;
        this.expendable_wgts_InValidReason=null;
        this.reasonForNotSubmitting="";
      this.sutarewtInvalid=false;
      this.sutarewtInvalidReason=null;

      this.suExtHgtInvalid=false;
      this.suExtHgtInvalidReason=null;
      this.suNstFldHgtInvalid=false;
      this.suNstFldHgtInvalidReason=null;
      this.updateCntrDetsReturnable = false;
      this.updateCntrDetsReturnableReason = null;
      }

      checkValidationWhileSubmittingChanges()
      {
        this.expAidsWgtValidationCheck();
        if(this.newProposalDetailsForChangeTrack.shippingUnitGrossWeight<=0  || this.newProposalDetailsForChangeTrack.shippingUnitGrossWeight>999999.999)
        {
          this.sugrosswtInvalid=true;
          this.suGrossWtInvalidReason="Shipping Unit Gross Weight Should be between 0 to 999999.999";
        }
        else
        {
          this.sugrosswtInvalid=false;
           this.suGrossWtInvalidReason=null;
        }
  
        if(this.newProposalDetailsForChangeTrack.cntrGrossWeight<=0 || this.newProposalDetailsForChangeTrack.cntrGrossWeight>999999.999)
        {
          this.cntrgrosswtInvalid=true;
          this.cntrgrosswtInvalidReason="Container Gross Weight Should be between 0 to 999999.999";
        }
        else
        {
          this.cntrgrosswtInvalid=false;
          this.cntrgrosswtInvalidReason=null;
        }
      if(this.newProposalDetailsForChangeTrack.shiipingUnitTareWeight<=0 || this.newProposalDetailsForChangeTrack.shiipingUnitTareWeight>999999.999)
      {
        this.sutarewtInvalid=true;
        this.sutarewtInvalidReason="Shipping Unit Tare Weight Should be between 0 to 999999.999";
      }
      else
      {
        this.sutarewtInvalid=false;
        this.sutarewtInvalidReason=null;
      }
      if(this.tab2data.shp_unt_ext_height<1 || this.tab2data.shp_unt_ext_height>999999.999)
      {
        this.suExtHgtInvalid=true;
        this.suExtHgtInvalidReason="Shipping Unit Exterior Height Should be of range 1 to 999999.999";
      }
      else
      {
        this.suExtHgtInvalid=false;
        this.suExtHgtInvalidReason=null;
      }

      if(this.tab2data.shp_unt_nestd_fld_height<1 || this.tab2data.shp_unt_nestd_fld_height>99999)
      {
        this.suNstFldHgtInvalid=true;
        this.suNstFldHgtInvalidReason="Shipping Unit Nested Folded Height Should be of range 1 to 999999.999";
      }
      else if(this.tab2data.shp_unt_nestd_fld_height > 0 && this.tab2data.shp_unt_ext_height > 0 && this.tab2data.shp_unt_nestd_fld_height > this.tab2data.shp_unt_ext_height)
      {
        this.suNstFldHgtInvalid=false;
        this.suNstFldHgtInvalidReason="Shipping Unit Nested Folded height cannot be Greater than Shipping Unit Exterior height";
      }
      else
      {
        this.suNstFldHgtInvalid=false;
        this.suNstFldHgtInvalidReason=null;
      }

        if(this.shp_comp_wt<0 || this.shp_comp_wt>999.9999)
        {
          this.expendable_wgts_InValid = true;
          this.expendable_wgts_InValidReason = 'Expendable Aids Component weights are exceeding 999.9999';
        }
        else
        {
          this.expendable_wgts_InValid = false;
          this.expendable_wgts_InValidReason = null;
        }
        if(this.tab2data.cntr_ret_type.toLowerCase() == "returnable" && JSON.stringify(this.oldProposalDetailsForChnageTrack)!=JSON.stringify(this.newProposalDetailsForChangeTrack))
        {
          
          this.updateCntrDetsReturnable=true;
          this.updateCntrDetsReturnableReason = " The Container Details has been changed, go to Tab2 and update Container Details";
        }
        else
        {
          this.updateCntrDetsReturnable=false;
          this.updateCntrDetsReturnableReason = null;
        }
        
        if(this.selectedPartsGet!=null)
        {
          for(const i of this.selectedPartsGet)
          {
            if(i.ngpp_part_l > this.newProposalDetailsForChangeTrack.cntrExtLength || i.ngpp_part_w > this.newProposalDetailsForChangeTrack.cntrExtwidth || i.ngpp_part_h > this.newProposalDetailsForChangeTrack.cntrExtHeight)
            {
              this.partDimensionsInvalid=true;
              this.partDimensionsInvalidReason="Part Dimensions is greater than Container Dimensions";
              break;
            }
          }
        }




      }


      submitProposalAfterConfirmation()
      {

        console.log('Inside submit')
        this.loaderimgimp=true;
        let mark_submit;
        mark_submit=1;

        this.enableNotificationDialog=false;
        this.enablePartNotificationDialog=false;
        this.notificationText="";

this.notificationTextSuHgt="";
        this.notificationTextSuNstFldHgt="";
        this.expAidsWgtValidationCheck();
        if(this.enableChnageDialog)
        {
          // && !this.partDimensionsInvalid
          console.log("Inside Submitprop", this.partDimensionsInvalid,this.expendable_wgts_InValid,this.sutarewtInvalid)
          if(!this.cntrgrosswtInvalid && !this.sutarewtInvalid && !this.sugrosswtInvalid && !this.expendable_wgts_InValid && !this.suExtHgtInvalid && !this.suNstFldHgtInvalid)
          {
            this.tab2DataForPost=this.tab2data;
            this.tab3DataForPost=this.tab3detailsGet;          
      
            

            this.tab2DataForPost.cntr_tar_weight=this.newProposalDetailsForChangeTrack.cntrTareWt;
            this.tab2DataForPost.cntr_nsted_fld_height=this.newProposalDetailsForChangeTrack.cntrNestedFldedHt;
            this.tab2DataForPost.cntr_ext_len=this.newProposalDetailsForChangeTrack.cntrExtLength;
            this.tab2DataForPost.cntr_width=this.newProposalDetailsForChangeTrack.cntrExtwidth;
            this.tab2DataForPost.cntr_height=this.newProposalDetailsForChangeTrack.cntrExtHeight;
            // this.tab2DataForPost.max_layrs_shp_unt=this.newProposalDetailsForChangeTrack.maxLayersPerSu;
            // this.tab2DataForPost.cntr_per_layer=this.newProposalDetailsForChangeTrack.cntrPerLayer;
            // this.tab2DataForPost.part_per_shp_unit=this.newProposalDetailsForChangeTrack.partsPerSu;
            this.tab2DataForPost.max_cntr_per_shp_unt=this.newProposalDetailsForChangeTrack.maxCntrsPerSu;
            this.tab2DataForPost.shp_unt_base=this.newProposalDetailsForChangeTrack.shippingUnitBase;
            this.tab2DataForPost.shp_unt_suff=this.newProposalDetailsForChangeTrack.shippingUnitSuffix;
            // this.tab2DataForPost.shp_unit_ext_len=this.newProposalDetailsForChangeTrack.shippingExtLen;
            // this.tab2DataForPost.shp_unt_ext_width=this.newProposalDetailsForChangeTrack.shippingExtWidth;
            // this.tab2DataForPost.shp_unt_ext_height=this.newProposalDetailsForChangeTrack.shippingExtHeight;
            // this.tab2DataForPost.shp_unt_nestd_fld_height=this.newProposalDetailsForChangeTrack.shippingUnitNestedFoldedht;
            this.tab2DataForPost.shp_unt_ret_type=this.newProposalDetailsForChangeTrack.shippingUnitReturnType;

            this.tab3DataForPost.shp_unt_tar_wt=this.newProposalDetailsForChangeTrack.shiipingUnitTareWeight;
            this.tab3DataForPost.cntr_gross_wt=this.newProposalDetailsForChangeTrack.cntrGrossWeight;
            this.tab3DataForPost.shp_unt_gross_wt=this.newProposalDetailsForChangeTrack.shippingUnitGrossWeight;

            this.store_proposal_values.cntr_tar_weight=this.newProposalDetailsForChangeTrack.cntrTareWt;
            this.store_proposal_values.cntr_nsted_fld_height=this.newProposalDetailsForChangeTrack.cntrNestedFldedHt;
            this.store_proposal_values.cntr_ext_len=this.newProposalDetailsForChangeTrack.cntrExtLength;
            this.store_proposal_values.cntr_width=this.newProposalDetailsForChangeTrack.cntrExtwidth;
            this.store_proposal_values.cntr_height=this.newProposalDetailsForChangeTrack.cntrExtHeight;
            // this.store_proposal_values.max_layrs_shp_unt=this.newProposalDetailsForChangeTrack.maxLayersPerSu;
            // this.store_proposal_values.cntr_per_layer=this.newProposalDetailsForChangeTrack.cntrPerLayer;
            // this.store_proposal_values.part_per_shp_unit=this.newProposalDetailsForChangeTrack.partsPerSu;
            this.store_proposal_values.max_cntr_per_shp_unt=this.newProposalDetailsForChangeTrack.maxCntrsPerSu;
            this.store_proposal_values.shp_unt_base=this.newProposalDetailsForChangeTrack.shippingUnitBase;
            this.store_proposal_values.shp_unt_suff=this.newProposalDetailsForChangeTrack.shippingUnitSuffix;
            // this.store_proposal_values.shp_unit_ext_len=this.newProposalDetailsForChangeTrack.shippingExtLen;
            // this.store_proposal_values.shp_unt_ext_width=this.newProposalDetailsForChangeTrack.shippingExtWidth;
            // this.store_proposal_values.shp_unt_ext_height=this.newProposalDetailsForChangeTrack.shippingExtHeight;
            // this.store_proposal_values.shp_unt_nestd_fld_height=this.newProposalDetailsForChangeTrack.shippingUnitNestedFoldedht;
            this.store_proposal_values.shp_unt_ret_type=this.newProposalDetailsForChangeTrack.shippingUnitReturnType;

            this.store_proposal_values.shp_unt_tar_wt=this.newProposalDetailsForChangeTrack.shiipingUnitTareWeight;
            this.store_proposal_values.cntr_gross_wt=this.newProposalDetailsForChangeTrack.cntrGrossWeight;
            this.store_proposal_values.shp_unt_gross_wt=this.newProposalDetailsForChangeTrack.shippingUnitGrossWeight;



            this.transactionService.savetab2details(this.tab2DataForPost).subscribe(e=>{
              console.log('success',e);

              if(e.toLowerCase()=='success')
              {
                this.transactionService.savetab3details(this.tab3DataForPost).subscribe(tab3Post=>{
                  if(tab3Post.toLowerCase()=='success')
                  {
                    this.transactionService.submit_proposal_details(this.store_proposal_values,mark_submit).subscribe(subdet=>
                      {
                        if(subdet=='Success')
                        {
                          console.log('subdetails',this.store_proposal_values);
                          this.transactionService.submit_proposal_parts(this.store_proposal_selectedparts).subscribe(subparts=>
                            {

                              if(subparts=='Success')
                              {
                                console.log('parts save',this.store_proposal_selectedparts);
                                if(this.store_proposal_componenets.length!=0)
                                {
                                  this.transactionService.submit_proposal_components(this.store_proposal_componenets).subscribe(saveattributes=>
                                  {
                                    const op=saveattributes;
                                    console.log('successful',op);
                                    if(saveattributes=='Success')
                                    {

                                      const message='Proposal '+this.selectedpart_submitter_image.simplifiedpartno+ ' submitted successfully\'';

                                      let cur_date;
                                      let cur_date_transform
                                      cur_date=new Date();
                                      cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
                                      console.log(this.user_details)
                                      // this.store_proposal_values.revision_hstry+=this.curr_cdsid+"`"+this.user_details.name+"`"+this.user_details.email+"`Packaging Proposal Submitted`Proposal Submitted`"+cur_date_transform+"`-";
                                      this.revisionHistoryForPostInit();
                                      this.revisionHistoryForPost.userid=this.curr_cdsid;
                                      this.revisionHistoryForPost.comments='-';
                                      this.revisionHistoryForPost.apprvractn='Proposal Submitted';
                                      this.revisionHistoryForPost.email=this.user_details.email;
                                      this.revisionHistoryForPost.usrname=this.user_details.name;
                                      this.revisionHistoryForPost.evnttime=cur_date_transform;
                                      this.revisionHistoryForPost.id_proposal=this.selectedpart_submitter_image.proposalid;
                                      this.revisionHistoryForPost.proposalstatuscurr='Packaging Proposal Submitted';

                                      console.log('revisionHistoryForPost before api call value',this.revisionHistoryForPost)

                                      this.transactionService.postRevisionHistory(this.revisionHistoryForPost).subscribe(postrevres=>{
                                        console.log('postRevisionHistory API o/p', postrevres)
                                        if(postrevres.toLowerCase() == 'success')
                                        {

                                          this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'success', detail:message});

                                          this.enableChnageDialog=false;
                                          // chnage the change status back to unchanged
                                          const setChangestatusTab4:changeTrackingForImprovement={idproposal:0,chnge_timestamp:'',status:''}
                                          setChangestatusTab4.idproposal=this.selectedpart_submitter_image.proposalid
                                          setChangestatusTab4.status='unchanged';
                                          const cur_date=new Date();
                                          const cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
                                          setChangestatusTab4.chnge_timestamp=cur_date_transform
                                          console.log('update change status ',setChangestatusTab4)
                                          this.transactionService.setImprovementChangeStatus(setChangestatusTab4).subscribe(chang_stat=>{
                                            console.log('change status update',chang_stat);

                                            if(chang_stat.toLowerCase() == 'success')
                                            {
                                            this.redirect_submitter()
                                            this.loaderimgimp=false;
                                            }
                                            else
                                            {
                                              this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Error while updating Improvement status'});
                                            }
                                          })
                                        }
                                        else
                                        {
                                          this.loaderimgimp=false;
                                          this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Error while updating Revision History,Please Try Again'});
                                        }
                                      })
                                    }
                                    else
                                    {
                                      this.loaderimgimp=false;
                                      this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Comp Not sub,Please Try Again'});
                                    }


                                });
                                }


                              else{

                                this.transactionService.deleteComponentsFromTxmMaster(this.store_proposal_values.id_proposal).subscribe(deleteCompStatus=>{
                                  let deleteStatus=deleteCompStatus;
        
                                  if(deleteStatus.toLowerCase()=="success")
                                  {
              
                                    //chnage the change status back to unchanged 
                                      var message="Proposal "+this.selectedpart_submitter_image.simplifiedpartno+ " submitted successfully'";
                                      var cur_date;
                                      var cur_date_transform
                                      cur_date=new Date();
                                      cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
                                      console.log(this.user_details)
                                      // this.store_proposal_values.revision_hstry+=this.curr_cdsid+"`"+this.user_details.name+"`"+this.user_details.email+"`Packaging Proposal Submitted`Proposal Submitted`"+cur_date_transform+"`-";
                                      this.revisionHistoryForPostInit();
                                      this.revisionHistoryForPost.userid=this.curr_cdsid;
                                      this.revisionHistoryForPost.comments='-';
                                      this.revisionHistoryForPost.apprvractn='Proposal Submitted';
                                      this.revisionHistoryForPost.email=this.user_details.email;
                                      this.revisionHistoryForPost.usrname=this.user_details.name;
                                      this.revisionHistoryForPost.evnttime=cur_date_transform;
                                      this.revisionHistoryForPost.id_proposal=this.selectedpart_submitter_image.proposalid;
                                      this.revisionHistoryForPost.proposalstatuscurr='Packaging Proposal Submitted';

                                      console.log('revisionHistoryForPost before api call value',this.revisionHistoryForPost)

                                      this.transactionService.postRevisionHistory(this.revisionHistoryForPost).subscribe(postrevres=>{
                                        console.log('postRevisionHistory API o/p', postrevres)
                                        if(postrevres.toLowerCase() == 'success')
                                        {

                                          this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'success', detail:message});

                                          this.enableChnageDialog=false;
                                          const setChangestatusTab4:changeTrackingForImprovement={idproposal:0,chnge_timestamp:'',status:''}
                                          setChangestatusTab4.idproposal=this.selectedpart_submitter_image.proposalid
                                          setChangestatusTab4.status='unchanged';
                                          const cur_date=new Date();
                                          const cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
                                          setChangestatusTab4.chnge_timestamp=cur_date_transform
                                          console.log('update change status ',setChangestatusTab4)
                                          this.transactionService.setImprovementChangeStatus(setChangestatusTab4).subscribe(chang_stat=>{
                                            console.log('change status update',chang_stat);

                                            if(chang_stat.toLowerCase() == 'success')
                                            {
                                              this.redirect_submitter()
                                              this.loaderimgimp=false;
                                            }
                                            else
                                            {
                                              this.loaderimgimp=false;
                                              this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Error while updating Improveemnt status'});
                                            }
                                          })
                                        }
                                        else
                                        {
                                          this.loaderimgimp=false;
                                          this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Error while updating Revision History,Please Try Again'});
                                        }
                                      })
                                    }
                                    else
                                    {
                                      this.loaderimgimp=false;
                                      this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Comp not submited,Please Try Again'})  
                                    
                                    }
                                  });
                              }
                              }
                              else
                              {
                                this.loaderimgimp=false;
                                this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Parts not submited,Please Try Again'})
                              }
                            });
                        }
                        else{
                          this.loaderimgimp=false;
                          this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Proposal details not submitted,Please Try Again'})
                        }
                      });
                  }
                  else
                  {
                    this.loaderimgimp=false;
                    this.messageService.add({severity:'error', summary: 'Error', detail: 'Component and Material details is not updated with changes,Please Try Again'})
                  }
                });
              }
              else
              {
                this.loaderimgimp=false;
                this.messageService.add({severity:'error', summary: 'Error', detail: 'COS,Container and Shipping Unit details is not updated with changes,Please Try Again'})

              }
            });
          }

          else
          {
            this.loaderimgimp=false;
          }
        }
        else
        {
          var enablePopUp=false;
          this.notificationText="";
          this.notificationTextSuHgt="";
          this.notificationTextSuNstFldHgt="";

          if(this.selectedPartsGet!=null)
          {
            let flag=false;
            for(const i of this.selectedPartsGet)
            {
              if(i.ngpp_part_l > this.tab2data.cntr_ext_len || i.ngpp_part_w > this.tab2data.cntr_width || i.ngpp_part_h > this.tab2data.cntr_height)
              {
                flag=true;
                break;
              }
            }

            if(flag)
            {
              // enablePopUp=true;
              this.enablePartNotificationDialog=true;
              this.notificationText="Part Dimensions is Greater Than Container Dimensions !";
            }
          }

          if(this.tab2data.shp_unt_ext_height<1 || this.tab2data.shp_unt_ext_height>999999.999)
          {
            enablePopUp=true;
            this.notificationTextSuHgt="Shipping Unit Exterior Height Should be of range 1 to 999999.999";
          }
          if(this.tab2data.shp_unt_nestd_fld_height > 0 && this.tab2data.shp_unt_ext_height > 0 && this.tab2data.shp_unt_nestd_fld_height > this.tab2data.shp_unt_ext_height)
          {
            enablePopUp=true;
            this.notificationTextSuNstFldHgt="Shipping Unit Nested Folded height cannot be Greater than Shipping Unit Exterior height";
          }
  
          if(enablePopUp)
          {
            this.loaderimgimp=false;
            this.enableNotificationDialog=true;
          }  
          if(enablePopUp==false && this.enablePartNotificationDialog==true)
          {
            this.loaderimgimp=false;
          }
          if(this.enableNotificationDialog!=true && this.enablePartNotificationDialog!=true)
          {
            this.submitSpecification();
          }
        }

      }
      submitSpecification()
      {
          if(this.enableNotificationDialog!=true)
          {
          var mark_submit;
        mark_submit=1;
            this.transactionService.submit_proposal_details(this.store_proposal_values,mark_submit).subscribe(subdet=>
                {
                  if(subdet=='Success')
                  {
                    console.log('subdetails',this.store_proposal_values);
                    this.transactionService.submit_proposal_parts(this.store_proposal_selectedparts).subscribe(subparts=>
                      {

                        if(subparts=='Success')
                        {
                          console.log('parts save',this.store_proposal_selectedparts);
                          if(this.store_proposal_componenets.length!=0)
                          {
                            this.transactionService.submit_proposal_components(this.store_proposal_componenets).subscribe(saveattributes=>
                            {
                              const op=saveattributes;
                              console.log('successful',op);
                              if(saveattributes=='Success')
                              {

                                const message='Proposal '+this.selectedpart_submitter_image.simplifiedpartno+ ' submitted successfully\'';
                                let cur_date;
                                let cur_date_transform
                                cur_date=new Date();
                                cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
                                console.log(this.user_details)
                                // this.store_proposal_values.revision_hstry+=this.curr_cdsid+"`"+this.user_details.name+"`"+this.user_details.email+"`Packaging Proposal Submitted`Proposal Submitted`"+cur_date_transform+"`-";
                                this.revisionHistoryForPostInit();
                                this.revisionHistoryForPost.userid=this.curr_cdsid;
                                this.revisionHistoryForPost.comments='-';
                                this.revisionHistoryForPost.apprvractn='Proposal Submitted';
                                this.revisionHistoryForPost.email=this.user_details.email;
                                this.revisionHistoryForPost.usrname=this.user_details.name;
                                this.revisionHistoryForPost.evnttime=cur_date_transform;
                                this.revisionHistoryForPost.id_proposal=this.selectedpart_submitter_image.proposalid;
                                this.revisionHistoryForPost.proposalstatuscurr='Packaging Proposal Submitted';

                                console.log('revisionHistoryForPost before api call value',this.revisionHistoryForPost)

                                this.transactionService.postRevisionHistory(this.revisionHistoryForPost).subscribe(postrevres=>{
                                  console.log('postRevisionHistory API o/p', postrevres)
                                  if(postrevres.toLowerCase() == 'success')
                                  {

                                      this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'success', detail:message});


                                // chnage the change status back to unchanged
                                const setChangestatusTab4:changeTrackingForImprovement={idproposal:0,chnge_timestamp:'',status:''}
                                setChangestatusTab4.idproposal=this.selectedpart_submitter_image.proposalid
                                setChangestatusTab4.status='unchanged';
                                const cur_date=new Date();
                                const cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
                                setChangestatusTab4.chnge_timestamp=cur_date_transform
                                console.log('update change status ',setChangestatusTab4)
                                this.transactionService.setImprovementChangeStatus(setChangestatusTab4).subscribe(chang_stat=>{
                                  console.log('change status update',chang_stat);

                                  if(chang_stat.toLowerCase() == 'success')
                                  {
                                  this.redirect_submitter()
                                  this.loaderimgimp=false;
                                  }
                                  else
                                  {
                                    this.loaderimgimp=false;
                                    this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Error while updating Improvement status'});
                                  }
                                })
                              }
                              else
                              {
                                this.loaderimgimp=false;
                                this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Error while updating Revision History,Please Try Again'});
                              }
                            })


                              }
                              else
                              {
                                this.loaderimgimp=false;
                                this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Comp Not sub,Please Try Again'});
                              }

                          });
                          }


                        else{

                          this.transactionService.deleteComponentsFromTxmMaster(this.store_proposal_values.id_proposal).subscribe(deleteCompStatus=>{
                            let deleteStatus=deleteCompStatus;
  
                            if(deleteStatus.toLowerCase()=="success")
                            {      
        
        
                              //chnage the change status back to unchanged 
                                       var message="Proposal "+this.selectedpart_submitter_image.simplifiedpartno+ " submitted successfully'";
                                      var cur_date;
                                      var cur_date_transform
                                      cur_date=new Date();
                                      cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
                                      console.log(this.user_details)
                                      // this.store_proposal_values.revision_hstry+=this.curr_cdsid+"`"+this.user_details.name+"`"+this.user_details.email+"`Packaging Proposal Submitted`Proposal Submitted`"+cur_date_transform+"`-";
                                      this.revisionHistoryForPostInit();
                                      this.revisionHistoryForPost.userid=this.curr_cdsid;
                                      this.revisionHistoryForPost.comments=this.imp_prop_comm;
                                      this.revisionHistoryForPost.apprvractn='Proposal Submitted';
                                      this.revisionHistoryForPost.email=this.user_details.email;
                                      this.revisionHistoryForPost.usrname=this.user_details.name;
                                      this.revisionHistoryForPost.evnttime=cur_date_transform;
                                      this.revisionHistoryForPost.id_proposal=this.selectedpart_submitter_image.proposalid;
                                      this.revisionHistoryForPost.proposalstatuscurr='Packaging Proposal Submitted';

                                      console.log('revisionHistoryForPost before api call value',this.revisionHistoryForPost)

                                      this.transactionService.postRevisionHistory(this.revisionHistoryForPost).subscribe(postrevres=>{
                                        console.log('postRevisionHistory API o/p', postrevres)
                                        if(postrevres.toLowerCase() == 'success')
                                        {

                                          this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'success', detail:message});

                                            const setChangestatusTab4:changeTrackingForImprovement={idproposal:0,chnge_timestamp:'',status:''}
                                            setChangestatusTab4.idproposal=this.selectedpart_submitter_image.proposalid
                                            setChangestatusTab4.status='unchanged';
                                            const cur_date=new Date();
                                            const cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
                                            setChangestatusTab4.chnge_timestamp=cur_date_transform
                                            console.log('update change status ',setChangestatusTab4)
                                            this.transactionService.setImprovementChangeStatus(setChangestatusTab4).subscribe(chang_stat=>{
                                              console.log('change status update',chang_stat);

                                              if(chang_stat.toLowerCase() == 'success')
                                              {
                                                this.redirect_submitter()
                                                this.loaderimgimp=false;
                                              }
                                              else
                                              {
                                                this.loaderimgimp=false;
                                                this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Error while updating Improvement status'});
                                              }
                                            })
                                          }
                                          else
                                          {
                                            this.loaderimgimp=false;
                                              this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Error while updating Revision History,Please Try Again'});
                                          }
                                        })
                                      }
                                      else
                                      {
                                        this.loaderimgimp=false;
                                        this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Comp not submited,Please Try Again'})  
                                      
                                      }
                                    });
                        }
                        }
                        else
                        {
                          this.loaderimgimp=false;
                          this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Parts not submited,Please Try Again'})
                        }
                      });
                  }
                  else{
                    this.loaderimgimp=false;
                    this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Proposal details not submitted,Please Try Again'})
                  }
                });
          }
        }


      expAidsWgtValidationCheck()
      {
        console.log('Inside Exp Aids Wgt Check');
        console.log('Inside Grid',this.compDataGet);
        for(const i of this.compDataGet)
        {
          if(i.compselctnfor == 'Container' && (i.comp_code > '05' || i.comp_code < '16'))
          {

            this.cntr_comp_wt = 0;
            this.cntr_comp_wt+=(i.qt_per_min_su *i.comp_tare_wt);
            this.shp_comp_wt +=( this.no_of_container*(this.cntr_comp_wt));

            console.log('Inside Container',this.shp_comp_wt);


          }
          else
          {
            if(i.compselctnfor== 'Shipping Unit' && (i.comp_code > '05' || i.comp_code < '16'))
            {
              this.temp_shp_compwt = 0;
            this.shp_comp_wt+=(i.qt_per_min_su *i.comp_tare_wt);
            // this.temp_shp_compwt+= this.shp_comp_wt;

            console.log('Inside Ship',this.shp_comp_wt);


          }
          }
        }
      }

      okEnbaleNotification()
    {
      this.notificationText='';
      this.enableNotificationDialog=false;
  
      this.notificationTextSuHgt="";
      this.notificationTextSuNstFldHgt="";
    }


      redirect_submitter()
      {


        let reg_supp_url: string;
        reg_supp_url = this.transactionService.getregionurlvalue(this.region);

          reg_supp_url += 'packagingprocessdashboard/searchexistingproposal';

        this.router.navigate([reg_supp_url]);


      }


      PartPhotoImage()
      {     
       
       let url  = this.baseurl_get+this.imgsave_path+this.image_folder_path+"/PartPhoto.png";
        this.transactionService.getImageFile(url).subscribe((response: any) => {
          this.part_image = URL.createObjectURL(response);
          setTimeout(()=> {
                  let url = URL.createObjectURL(response);
          const img: any = document.querySelector('#part_image');
          img.src = url;
                }, 500);
        });
        
        console.log("image path display is "+this.part_image);
      }
      InitgetImagePart(id:String)
      {
        let url =this.baseurl_get+this.imgsave_path+id+"/PartPhoto.png";
        this.transactionService.getImageFile(url).subscribe((response: any) => {
          this.part_image = URL.createObjectURL(response);
          setTimeout(()=> {
                  let url = URL.createObjectURL(response);
          const img: any = document.querySelector('#part_image');
          img.src = url;
                }, 500);
        });
        this.tab4alldetails.f_partphoto=this.imgsave_path+id+"/PartPhoto.png";
      
      }
      intrCntnrPhotoImage()
      {     
       
        let url = this.baseurl_get+this.imgsave_path+this.image_folder_path+"/intrCntnrDsgnPhoto.png";
        this.transactionService.getImageFile(url).subscribe((response: any) => {
          this.interior_image = URL.createObjectURL(response);
          setTimeout(()=> {
                  let url = URL.createObjectURL(response);
          const img: any = document.querySelector('#interior_image');
          img.src = url;
                }, 500);
        });
        console.log("image path display is "+this.interior_image);
      }
      InitgetInteriorContainer(id:String)
      {
        let url =this.baseurl_get+this.imgsave_path+id+"/intrCntnrDsgnPhoto.png";
        this.transactionService.getImageFile(url).subscribe((response: any) => {
          this.interior_image = URL.createObjectURL(response);
          setTimeout(()=> {
                  let url = URL.createObjectURL(response);
          const img: any = document.querySelector('#interior_image');
          img.src = url;
                }, 500);
        });
      this.tab4alldetails.f_intcntrdsgnphoto=this.imgsave_path+id+"/intrCntnrDsgnPhoto.png";
      }
      ExtrCntnrPhotoImage()
      {     
       
        let url = this.baseurl_get+this.imgsave_path+this.image_folder_path+"/extrCntnrDsgnPhoto.png";
        this.transactionService.getImageFile(url).subscribe((response: any) => {
          this.exterior_image = URL.createObjectURL(response);
          setTimeout(()=> {
                  let url = URL.createObjectURL(response);
          const img: any = document.querySelector('#exterior_image');
          img.src = url;
                }, 500);
        });
        console.log("image path display is "+this.exterior_image);
      }
      InitgetExtrCntnrPhotoImage(id:String)
      {     
       
        let url = this.baseurl_get+this.imgsave_path+id+"/extrCntnrDsgnPhoto.png";
        this.transactionService.getImageFile(url).subscribe((response: any) => {
          this.exterior_image = URL.createObjectURL(response);
          setTimeout(()=> {
                  let url = URL.createObjectURL(response);
          const img: any = document.querySelector('#exterior_image');
          img.src = url;
                }, 500);
        });
        console.log("image path display is "+this.exterior_image);
        this.tab4alldetails.f_extcntrdsgnphoto=this.imgsave_path+id+"/extrCntnrDsgnPhoto.png";
      }
      LabelMetalIDCntnrPhotoImage()
      {     
       
        let url = this.baseurl_get+this.imgsave_path+this.image_folder_path+"/labelmetalID.png";
        this.transactionService.getImageFile(url).subscribe((response: any) => {
          this.label_image = URL.createObjectURL(response);
          setTimeout(()=> {
                  let url = URL.createObjectURL(response);
          const img: any = document.querySelector('#label_image');
          img.src = url;
                }, 500);
        });
        console.log("image path display is "+this.label_image);
  
      }
      InitgetLabelMetalIDPhotoImage(id:String)
      {     
       
        let url = this.baseurl_get+this.imgsave_path+id+"/labelmetalID.png";
        this.transactionService.getImageFile(url).subscribe((response: any) => {
          this.label_image = URL.createObjectURL(response);
          setTimeout(()=> {
                  let url = URL.createObjectURL(response);
          const img: any = document.querySelector('#label_image');
          img.src = url;
                }, 500);
        });
        console.log("image path display is "+this.label_image);
        this.tab4alldetails.f_metelidplatephoto=this.imgsave_path+id+"/labelmetalID.png";
      }


      ShippingUnitPhotoImage()
      {     
       
        let url = this.baseurl_get+this.imgsave_path+this.image_folder_path+"/shpngUntPhoto.png";
        this.transactionService.getImageFile(url).subscribe((response: any) => {
          this.shipping_image = URL.createObjectURL(response);
          setTimeout(()=> {
                  let url = URL.createObjectURL(response);
          const img: any = document.querySelector('#shipping_image');
          img.src = url;
                }, 500);
        });
        console.log("image path display is "+this.shipping_image);
      }

      InitgetShippingUnitPhotoImage(id:String)
          {     
           
            let url = this.baseurl_get+this.imgsave_path+id+"/shpngUntPhoto.png";
            this.transactionService.getImageFile(url).subscribe((response: any) => {
              this.shipping_image = URL.createObjectURL(response);
              setTimeout(()=> {
                  let url = URL.createObjectURL(response);
              const img: any = document.querySelector('#shipping_image');
              img.src = url;
                }, 500);
            });
            console.log("image path display is "+this.shipping_image);
  
  
            this.tab4alldetails.f_shpunitphoto=this.imgsave_path+id+"/shpngUntPhoto.png";
          }




      part_imageUplaod(event, form) {
        this.disable_browse_button_part=true;
        console.log('Part photo upload is working');
        for(const file of event.files) {
           this.uploadedFiles.push(file);
           this.uplo=file;
        }
        form.clear();
                this.image_folder_path=this.tab4alldetails.id_proposal.toString();

          this.transactionService.PartPhoto_postFile(this.uplo,this.image_folder_path).subscribe((data => {
            const res=data;
            this.respdata=res;
            console.log('Response',res);
               if(res == this.imgsave_path+this.image_folder_path+'/PartPhoto.png') {
                console.log('Upload is success '+ res);

              setTimeout( ()=>{
                console.log('works')
                this.part_image='';
                }, 5000);

                setTimeout( ()=>{
                  console.log('works1')
               // this.PartPhotoImage();

               this.disable_browse_button_part=false;
               this.tab4alldetails.f_partphoto=res// assign the return value after success
               this.save_tab4_image();
               this.get_part_image();
                }, 5000)
             this.PartPhoto_image_upload_dialog=false;
                 }
                  else   {
                    this.disable_browse_button_part=false;
                 console.log('Upload is not success '+res);
                 this.PartPhoto_image_upload_dialog=false;
                 this.messageService.add({severity:'error', summary: 'Error', detail: 'Part image not uploaded,Please Try Again'});

                 // toast
                         }
            }));

          }

          InteriorContainer_imageUpload(event, form1) {
            this.disable_browse_button_int_cntr=true;
            console.log('InteriorContainer_imageUpload photo upload is working');
            for(const file of event.files) {
               this.uploadedFiles.push(file);
               this.uplo=file;
            }
            form1.clear();
                    this.image_folder_path=this.tab4alldetails.id_proposal.toString();

              this.transactionService.InteriorContainerPhoto_postFile(this.uplo,this.image_folder_path).subscribe((data => {
                const res=data;
                this.respdata=res;
                console.log('Response',res);
                   if(res == this.imgsave_path+this.image_folder_path+'/intrCntnrDsgnPhoto.png') {

                    console.log('Upload is success '+ res);

                  setTimeout( ()=>{
                    console.log('works')
                    this.interior_image='';
                    }, 5000);

                    setTimeout( ()=>{
                      console.log('works1')
                    // this.intrCntnrPhotoImage();

                    this.disable_browse_button_int_cntr=false;
                    this.tab4alldetails.f_intcntrdsgnphoto=res// assign the return value after success ;
                    this.save_tab4_image();
               this.get_interior_image();
                    }, 5000)
                 this.InteriorContainer_upload_dialog=false;
                     }
                      else   {
                        this.disable_browse_button_int_cntr=false;
                     console.log('Upload is not success '+res);
                     this.messageService.add({severity:'error', summary: 'Error', detail: 'Interior Container image not uploaded,Please Try Again'});
                     this.InteriorContainer_upload_dialog=false;
                             }
                }));

              }

              ExteriorContainer_imageUpload(event, form1) {
                this.disable_browse_button_ext_cntr=true;
                console.log('ExteriorContainer_imageUpload photo upload is working');
                for(const file of event.files) {
                   this.uploadedFiles.push(file);
                   this.uplo=file;
                }
                form1.clear();
                        this.image_folder_path=this.tab4alldetails.id_proposal.toString();

                  this.transactionService.ExteriorContainerPhoto_postFile(this.uplo,this.image_folder_path).subscribe((data => {
                    const res=data;
                    this.respdata=res;
                    console.log('Response',res);
                       if(res == this.imgsave_path+this.image_folder_path+'/extrCntnrDsgnPhoto.png') {

                        console.log('Upload is success '+ res);

                      setTimeout( ()=>{
                        console.log('works')
                        this.exterior_image='';
                        }, 5000);

                        setTimeout( ()=>{
                          console.log('works1')
                        // this.ExtrCntnrPhotoImage();

                        this.disable_browse_button_ext_cntr=false;
                        this.tab4alldetails.f_extcntrdsgnphoto=res// assign the return value after success
                        this.save_tab4_image();
               this.get_exterior_image();
                        }, 5000)
                     this.ExteriorContainer_upload_dialog=false;
                         }
                          else   {
                            this.disable_browse_button_ext_cntr=false;
                         console.log('Upload is not success '+res);
                         this.messageService.add({severity:'error', summary: 'Error', detail: 'Exterior Container image not uploaded,Please Try Again'});

                         this.ExteriorContainer_upload_dialog=false;
                                 }
                    }));

   }

   LabelMetalID_imageUpload(event, form1) {
    this.disable_browse_button_label=true;
    console.log('LabelMetalID_imageUpload photo upload is working');
    for(const file of event.files) {
       this.uploadedFiles.push(file);
       this.uplo=file;
    }
    form1.clear();
            this.image_folder_path=this.tab4alldetails.id_proposal.toString();

      this.transactionService.LabelMetalIDPhoto_postFile(this.uplo,this.image_folder_path).subscribe((data => {
        const res=data;
        this.respdata=res;
        console.log('Response',res);
           if(res == this.imgsave_path+this.image_folder_path+'/labelmetalID.png') {

            console.log('Upload is success '+ res);

          setTimeout( ()=>{
            console.log('works')
            this.label_image='';
            }, 5000);

            setTimeout( ()=>{
              console.log('works1')
            // this.LabelMetalIDCntnrPhotoImage();

            this.disable_browse_button_label=false;
            this.tab4alldetails.f_metelidplatephoto=res// assign the return value after success
            this.save_tab4_image();
              this.get_label_image();

            }, 5000)
         this.Label_MetalID_upload_dialog=false;
             }
              else   {
                this.disable_browse_button_label=false;
             console.log('Upload is not success '+res);

             this.messageService.add({severity:'error', summary: 'Error', detail: 'Label image not uploaded,Please Try Again'});

             this.Label_MetalID_upload_dialog=false;
                     }
        }));

  }

  ShippingUnit_imageUpload(event, form1) {
    this.disable_browse_button_shp_unt=true;
    console.log('ShippingUnit_imageUpload photo upload is working');
    for(const file of event.files) {
       this.uploadedFiles.push(file);
       this.uplo=file;
    }
    form1.clear();
            this.image_folder_path=this.tab4alldetails.id_proposal.toString();

      this.transactionService.ShippingUnitPhoto_postFile(this.uplo,this.image_folder_path).subscribe((data => {
        const res=data;
        this.respdata=res;
        console.log('Response',res);
           if(res == this.imgsave_path+this.image_folder_path+'/shpngUntPhoto.png') {

            console.log('Upload is success '+ res);

          setTimeout( ()=>{
            console.log('works')
            this.shipping_image='';
            }, 5000);

            setTimeout( ()=>{
              console.log('works1')
            // this.ShippingUnitPhotoImage();
            this.disable_browse_button_shp_unt=false;
            this.tab4alldetails.f_shpunitphoto=res// assign the return value after success
            this.save_tab4_image();
               this.get_shipping_image();
            }, 5000)
         this.ShippingUnit_upload_dialog=false;
             }
              else   {
                this.disable_browse_button_shp_unt=false;
             console.log('Upload is not success '+res);
             this.ShippingUnit_upload_dialog=false;
             this.messageService.add({severity:'error', summary: 'Error', detail: 'shipping unit image not uploaded,Please Try Again'});



                     }
        }));

  }


  sbupl_imageUpload(event, form1) {
    this.disable_browse_button_sbupl=true;
    console.log('sbupl_imageUpload photo upload is working');
    for(const file of event.files) {
       this.uploadedFiles.push(file);
       this.uplo=file;
    }
    form1.clear();
            this.image_folder_path=this.tab4alldetails.id_proposal.toString();
            this.sbupl_uploaded=false;


            const path_pressload='pressloaddoc/'
            const extention=this.uplo.name.split('?')[0].split('.').pop();

      this.transactionService.sbupl_postFile(this.uplo,this.image_folder_path).subscribe((data => {
        const res=data;
        this.respdata=res;
        console.log('Response',res);
           //if(res == this.imgsave_path+path_pressload+this.image_folder_path+'_presslinedoc.'+extention) {
            if(res == this.image_folder_path+'_presslinedoc.'+extention) {

            console.log('Upload is success '+ res);


           // this.sbupl_uploaded=true;

          setTimeout( ()=>{
            console.log('works')
            this.sbu_file='';
            }, 5000);

            setTimeout( ()=>{
              console.log('works1')
            // this.ShippingUnitPhotoImage();
            this.disable_browse_button_sbupl=false;
           this.tab4alldetails.subpresloaddoc=res// assign the return value after success
            this.save_tab4_image();
            this.test_var='https://www.w3schools.com/'
               this.get_sbupl();
            }, 5000)
         this.sbupl_upload_dialog=false;
             }
              else   {
                this.disable_browse_button_sbupl=false;
             console.log('Upload is not success '+res);
             if(this.tab4alldetails.subpresloaddoc!=null && this.tab4alldetails.subpresloaddoc!='' )
             {
             this.sbupl_uploaded=true;
             }
             this.sbupl_upload_dialog=false;
             this.messageService.add({severity:'error', summary: 'Error', detail: 'sbupl not uploaded,Please Try Again'});




                     }
        }));

  }



  save_tab4_image()

  {


    console.log(this.tab4alldetails);

    // service to call tab4 api
  // on success
  // store last saved tab

  this.tab4alldetails.createmgcyexpndpckgprop=this.crt_edt_empro;
  this.transactionService.savetab4details(this.tab4alldetails).subscribe(response=>
    {
        let msgs:any[];
        if(response=='Success')
        {

              const check=false;
              // change status update
              console.log('comdet',this.originalTab4Details,this.changedTab4Details)
              /*if(JSON.stringify(this.originalTab4Details) !== JSON.stringify(this.changedTab4Details))
              {*/
                // alert("value changed")
              if(this.existChangeStatusTab4 != null && this.existChangeStatusTab4.toLowerCase()=='unchanged')
              {

                const setChangestatusTab4:changeTrackingForImprovement={idproposal:0,chnge_timestamp:'',status:''}
                setChangestatusTab4.idproposal=this.selectedpart_submitter_image.proposalid
                setChangestatusTab4.status='changed';
              const cur_date=new Date();
              const cur_date_transform=this.date.transform(cur_date, 'yyyy-MM-dd HH:mm:ss');
              setChangestatusTab4.chnge_timestamp=cur_date_transform
                console.log('update change status ',setChangestatusTab4)
                this.transactionService.setImprovementChangeStatus(setChangestatusTab4).subscribe(chang_stat=>{
                  console.log('change status update',chang_stat);

                  if(chang_stat.toLowerCase() == 'success')
                  {
                    sessionStorage.setItem('ProposalChangeStatus',setChangestatusTab4.status);
                    this.existChangeStatusTab4='changed';
                  }
                })
              }
            // }
            this.region=this.transactionService.getregion();
            const currentab=4;
            if(currentab>this.selectedpart_submitter_image.proposallastsaved)
                  {

                  this.selectedpart_submitter_image.proposallastsaved=4;
                  this.store_primary_part_exist_image();
                  console.log(this.selectedpart_submitter_image);
                  }




                }

                else{


                  this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Image details is not saved! try again'});

                }

                })


  }




  // not used below both functions
        getImage() {
          // alert("Image loading");
          console.log('Get image called');
          const URL=this.baseurl_get+this.imgsave_path+this.image_folder_path+'/PartPhoto.png';
          console.log(URL);

          this.imageService.getData(URL)
            .subscribe(
              imgData => this.data = imgData,
              err => console.log(err)
            );
            this.part_image='ok';

        }

        InitgetImagePartPhoto(proposalD:string) {

          const URL=this.baseurl_get+this.imgsave_path+this.image_folder_path+proposalD+'/PartPhoto.png';
          console.log('image loding from '+URL);
          this.imageService.getData(URL)
            .subscribe(
              imgData => this.data = imgData,
              err => console.log(err)
            );
            this.part_image='ok';
        }
      
  
  

}
