/* tslint:disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng';
import { MessageService } from 'primeng/api';
import { runInThisContext } from 'vm';
import { threadId } from 'worker_threads';
import { TransactionService } from '../loginpage/Transaction.service';
import {  ActivePlants, corrosionProtectionForGet, partDetailForPost, partDetailForRequiredFields, partDetailsForGet, primarypartdetails,  programsbasedonplants, regiondetails, selec_prt_pro_details, shelfLifeForList, subgrid, subpltlist, subprglist, supplierdetails, useraccessrole } from '../loginpage/transactions';
import { PackagingprocessComponent } from '../packagingprocess/packagingprocess.component';
import { PackagingproposalComponent } from '../packagingproposal/packagingproposal.component';
import { SubmiterdashboardComponent } from '../submiterdashboard/submiterdashboard.component';


@Component({
  selector: 'app-partsandatributes',
  templateUrl: './partsandatributes.component.html',
  styleUrls: ['./partsandatributes.component.css'],
  providers:[MessageService]
})
export class PartsandatributesComponent implements OnInit {


    @ViewChild("dt4", { static: false }) public dt4: Table;
  

    onClick() {
        this.dt4.reset();
    }

    tab2menuitem: any;
    tab2: string;
    name: string;
    region: string;
    regionforroute:string;
    flag: boolean;

    /* field added for proposal creation menubar*/
    savedtabfortesting:number;
    tab1route:string;
    tab2route:string;
    tab3route:string;
    tab4route:string;

    /* field added for proposal creation menubar ends*/

    primarypartsbomid='';
    id_proposal:number;
    editingdetails=0;
    primarypartgridforpost:partDetailForPost;
    primarypartsgrid:partDetailForPost[]=[];
    primarypartsattributes:primarypartdetails;
    primarypartgridforget:partDetailsForGet;

    secondarypartsgridforget:partDetailsForGet[] = [];
    secondarypartsbomid='';
    secondarypartreceivedfromgrid1:subgrid[]=[];

 // secondary parts validation
    secondarypartgridforpostAPI:partDetailForPost[]=[];
      secondarypartsgrid:partDetailForRequiredFields[]=[];
    secondarypartgridforpost:partDetailForRequiredFields[]=[];

    partsdetailsbeforeediting:partDetailForPost;
    enablegrid4=false;

    clonedParts: {[s:string]:partDetailForPost;}={};
    cols1:any;
    cols2:any;

    weight_status=[{label:'Actual',value:'Actual'},{label:'Estimate',value:'Estimate'}];
    manufacturing_location=[{label:'AP',value:'AP'},{label:'NA',value:'NA'},{label:'SA',value:'SA'},{label:'EU',value:'EU'}];
    // corrosion_protection=[{prot:"Oiling"},{prot:"N/A"},{prot:"Paper"},{prot:"Other"},{prot:"VCI"},{prot:"Silica-Gel (Desiccant)"}];
    // corrosion_protection=[{label:"Oiling",value:"Oiling"},{label:"N/A",value:"N/A"},{label:"Paper",value:"Paper"},{label:"Other",value:"Other"},{label:"VCI",value:"VCI"},{label:"Silica-Gel (Desiccant)",value:"Silica-Gel (Desiccant)"}];
    corrosion_protection:corrosionProtectionForGet[]=[];
    selected_corrosion_protection:corrosionProtectionForGet={cd_corsn_prot_type:0,corsn_prot_type:'',active_stat:'',active_stat_display:false,updated_by:'',updated_dt:''};

    shelfLifeList:shelfLifeForList[] = [];
    selectedShelfLife:shelfLifeForList={label:'NA',value:0};
    

    disableProgDrp_prt = true;
    current_region_prt = ' ';
    current_region_code_prt: string;
    prtUserAccess: useraccessrole[] = [];

    plantCodeList_prt: subpltlist[] = [];
    selectedplantlist_prt: subpltlist[] = [];


    progList_prt: subprglist[] = [];
    selectedproglist_prt: subprglist[] = [];


    subAllPlants_prt:ActivePlants[]=[];
    progListOnPlt_prt:programsbasedonplants[]=[];

    enablesave = true;

    isStampingBlank='No'


    edit_measure_part='lbs/inch';


    /*
      statusList:substatuslist[]=[];
      selectedstatuslist:substatuslist[]=[];

      trdcmpyList:subtrdcmpylist[]=[];
      selectedtrdcmpylist:subtrdcmpylist[]=[];
      */

    selectedpartprefix_prt = '';
    selectedpartbase_prt = '';
    selectedpartsuffix_prt = '';

    part_prefix_prt = '';
    part_base_prt = '';
    part_suffix_prt = '';



    plantcode_prt = '';
    programcode_prt = '';


    noSupDialog_prt = false;
    noPrtDialog_prt = false;
    content_selectedprt_prt = '';
    content_supp_prt = '';
    // part selected from submitter dashboard
    // selectedpart_submitter: string = "";

    selectedpart_submitter:selec_prt_pro_details={simplifiedpartno:'-',proposalid:0,bompartmasterid:0,plantgsdbcode:'',proposallastsaved:0,part_status:'',supplier_code:'',proposallastsaved_ee:0,is_ee_proposal:false,currentview_ee:false,from_draft:false,propsl_type:'',imp_part_status:''};
    prtPrgUserAccess: useraccessrole[] = [];


    region_details_prt:regiondetails;


    disablePlantDrp_prt=false;

    // not used
    selectedsimpliedpartno = '';


    // all parts data for the user
    allPartgridList: subgrid[] = [];

    getPartgridList: subgrid[] = []; // grid 1 data for user to add secondary parts
    selectedPartgridList: subgrid[] = [];// grid 2 data display primary and seconday parts selected
    choosedGetPartGrid: subgrid[] = []; // selected parts in grid 1 before clicking  add
    choosedSelectPartGrid: subgrid[] = [];// selected parts in grid 2 before clicking remove

    // all parts for user when filter is selcted
    partgridList: subgrid[] = [];


    //  primaryPartgridDetails:[]=[];
    // additionalPartgridDetails:


    getpart_grid_text = '';
    getpart_present_table = false;



    public getpart_cols: any[];
    public selectpart_cols: any[];

    prt_cdsid: string;
    prt_roleid:number;


    unitom = '';

    supp_code_prt: string;
    designsource_code_prt:string;


     noDesignsourceDialog_prt=false;
    content_designsource_prt=''

    noroleselectedDialog=false;
    norolecontent=''


    popup1=false;


    selected_supp_details:supplierdetails;


    disable_add_button = false;

    originalTab1Secondarygrid:partDetailForPost[]=[];
    pripartlenInvalid=false;
    pripartlenInvalidReason=null;
    pripartwidInvalid=false;
    pripartwidInvalidReason=null;
    priparthgtInvalid=false;
    priparthgtInvalidReason=null;
    pripartwgtInvalid=false;
    pripartwgtInvalidReason=null;
    corrosionProtectionInvalid=false;
    corrosionProtectionInvalidReason=null;

    shelfLifeInvalid:boolean=false;
    shelfLifeInvalidReason:string=null;

    gridListFromAPI:subgrid[]=[];
    constructor(private packagingproposal:PackagingproposalComponent, private packagingprocess:PackagingprocessComponent, private submitterdashboard: SubmiterdashboardComponent, private router: Router, private transactionService: TransactionService, private messageService:MessageService) { }

  ngOnInit(): void {
    /* code added for packaging proposal menubar*/

    this.regionforroute=this.transactionService.getregion();
    this.tab1route=this.transactionService.getregionurlvalue(this.regionforroute)+'packagingprocess/proposalcreation/partsandattri';
    this.tab2route=this.transactionService.getregionurlvalue(this.regionforroute)+'packagingprocess/proposalcreation/packproposaltabtwo';
    this.tab3route=this.transactionService.getregionurlvalue(this.regionforroute)+'packagingprocess/proposalcreation/packproposaltabthree';
    this.tab4route=this.transactionService.getregionurlvalue(this.regionforroute)+'packagingprocess/proposalcreation/packproposaltabfour';




  console.log('parts and attribute initialised',this.packagingproposal.savedtab);
    if(this.packagingproposal.savedtab==1)
        {
          for(const i of this.packagingproposal.tabpackagingproposalmenuitems.values())
          {
                console.log(i);
                if(i.label!='COS, Container & Shipping Unit')
                {
                    console.log('entered check condition');
                    this.packagingproposal.flag1=true;
                }
                else{
                    this.packagingproposal.flag1=false;
                    break;
                }
          }

          if(this.packagingproposal.flag1)
          {
            this.packagingproposal.tabpackagingproposalmenuitems.push({label: 'COS, Container & Shipping Unit', routerLink:this.tab2route, icon: 'fas fa-cubes'});  }
        }

        else if(this.packagingproposal.savedtab==2 )
        {
          for(const i of this.packagingproposal.tabpackagingproposalmenuitems.values())
          {
                console.log(i);
                if(i.label!='Component & Material')
                {
                    this.packagingproposal.flag2=true;
                }
                else{
                    this.packagingproposal.flag2=false;
                    break;
                }
          }

          if(this.packagingproposal.flag2)
          {
            this.packagingproposal.tabpackagingproposalmenuitems.push({label: 'COS, Container & Shipping Unit', routerLink:this.tab2route, icon: 'fas fa-cubes'});
            this.packagingproposal.tabpackagingproposalmenuitems.push({label:'Component & Material',routerLink:this.tab3route, icon: 'fas fa-person-booth'});
          }
        }

        else if(this.packagingproposal.savedtab==3 || this.packagingproposal.savedtab==4)
        {
          for(const i of this.packagingproposal.tabpackagingproposalmenuitems.values())
          {
                console.log(i);
                if(i.label!='Images/Photos & Comments')
                {
                    this.packagingproposal.flag3=true;
                }
                else{
                    this.packagingproposal.flag3=false;
                    break;
                }
          }

          if(this.packagingproposal.flag3)
          {
            this.packagingproposal.tabpackagingproposalmenuitems.push({label: 'COS, Container & Shipping Unit', routerLink:this.tab2route, icon: 'fas fa-cubes'});
             this.packagingproposal.tabpackagingproposalmenuitems.push({label:'Component & Material',routerLink:this.tab3route, icon: 'fas fa-person-booth'});
             this.packagingproposal.tabpackagingproposalmenuitems.push({label:'Images/Photos & Comments',routerLink:this.tab4route, icon: 'pi pi-images'});
          }


        }



    /* code added for packaging proposal menubar ends*/



    this.primarypartsdetailsinit();
    this.onRowEditInit(this.primarypartgridforpost);
    this.partsValidation();
    this.corrosionProtectionInvalid=false;
    this.corrosionProtectionInvalidReason=null;
    this.shelfLifeInvalid=false;
    this.shelfLifeInvalidReason=null;









   /* this.transactionService.getProposalId().subscribe(a=>
      {
        console.log("proposalid",a);
        this.id_proposal=Number(JSON.stringify(a));
        console.log("check",this.id_proposal);
      });*/




      this.primarypartsattributes={
        gppuserid:this.transactionService.getcdsid(),
        id_proposal:this.id_proposal,
        corr_protc:'',
        lst_saved_tab:0,
        seasnlreq:'',
        shelf_life:0,
        sp_part_no:'',
        submit_status:'not submitted',
        temp_sensitivity:'No',
        temp_stor_spec:'',
        timestamp:'',
        plantgsdbcode:'',
        isstampblank:'',
        supgsdbcode: '',
        supfullname: '',
         supaddress1: '',
         supcity: '',
         supstateprov: '',
          supcountrycode: '',
         supcontactnamefirst: '',
       supcontactname: '',
      supcontactfunctn: '',
        supcontactemail: '',
        supcontactphone: '',
        supcontactfax: '',
        defaultcurr:'',
        lst_saved_tab_ee:1,
        propsl_type:''


      }
      this.transactionService.getActiveCorrosionProtectionType().subscribe(activeCorrosionList=>{
        console.log('corrosion protection list from api',activeCorrosionList);
        this.corrosion_protection=[];
        this.corrosion_protection=activeCorrosionList;
        if(this.corrosion_protection != null && this.corrosion_protection.length != 0)
        {
          this.selected_corrosion_protection={cd_corsn_prot_type:0,corsn_prot_type:"",active_stat:"",active_stat_display:false,updated_by:"",updated_dt:""};
          //this.selected_corrosion_protection=this.corrosion_protection[0]
        }
    })

  
    this.shelfLifeOptions();
    this.prt_cdsid = this.transactionService.getcdsid();
    this.current_region_prt = this.transactionService.getregion();
    this.prt_roleid=this.transactionService.getcurrentrole(this.current_region_prt)


   if(this.prt_roleid==null )
    {


      this.noroleselectedDialog=true;
      this.norolecontent='No role selected '
    }


    if(this.prt_roleid==1)
    {
    this.supp_code_prt = this.transactionService.get_supplier(this.current_region_prt);
    console.log(this.supp_code_prt);
    }

    else if(this.prt_roleid==2)
    {
      this.designsource_code_prt=this.transactionService.get_designsource(this.current_region_prt);
    }
    this.current_region_code_prt = this.transactionService.getregioncode(this.current_region_prt);
  //  this.transactionService.storepage(this.current_region_prt, "packagingprocess/proposalcreation/partsandattri");
    // this.selectedpart_submitter=this.submitterdashboard.pushdata();
    this.selectedpart_submitter = this.transactionService.getpartno_packpro(this.current_region_prt);
    console.log('suppop',this.selectedpart_submitter)


    console.log('UOMCURR',this.current_region_code_prt,this.current_region_prt);

    this.transactionService.get_region_details(this.current_region_code_prt).subscribe(measure=>{

      this.unitom = measure.uom;
      console.log('UOMREG', this.unitom);

      if(this.unitom=='E')
    {
      this.edit_measure_part='lbs/inch';

      console.log('UOMlb',this.unitom,this.edit_measure_part);
    }
    else
    {
      this.edit_measure_part='kg/cm';
      console.log('UOMlb',this.unitom,this.edit_measure_part);
    }
    })

    // if supplier code and part number is present load the page and get user wise plant and program data
if(this.prt_roleid==1)
{
    if (this.supp_code_prt != null && this.selectedpart_submitter != null && this.selectedpart_submitter.simplifiedpartno!='-' && this.selectedpart_submitter.proposalid!=0 ) {

      this.id_proposal=this.selectedpart_submitter.proposalid;
      this.get_supp_details();
      this.list_plantcode();



    }

    else if (this.supp_code_prt == null) {
      this.noSupDialog_prt = true;
      this.content_supp_prt = 'Please select supplier';
    }


    else if (this.supp_code_prt != null && (this.selectedpart_submitter == null || this.selectedpart_submitter.simplifiedpartno=='-' || this.selectedpart_submitter.proposalid==0)) {
      this.noPrtDialog_prt = true;
      this.content_selectedprt_prt = 'Please select part from submitter dashboard';
    }

  }


  else if( this.prt_roleid==2)
  {


    if (this.designsource_code_prt != null && this.selectedpart_submitter != null && this.selectedpart_submitter.simplifiedpartno!='-' && this.selectedpart_submitter.proposalid!=0 && this.selectedpart_submitter.plantgsdbcode!='') {

      this.id_proposal=this.selectedpart_submitter.proposalid;
      this.get_supp_details();
      this.list_plantcode();



    }

    else if (this.designsource_code_prt == null) {
      this.noDesignsourceDialog_prt = true;
      this.content_designsource_prt = 'Please select Designsource';
    }


    else if (this.designsource_code_prt != null && (this.selectedpart_submitter == null || this.selectedpart_submitter.simplifiedpartno=='-' || this.selectedpart_submitter.proposalid==0)) {
      this.noPrtDialog_prt = true;
      this.content_selectedprt_prt = 'Please select part from submitter dashboard';
    }

  }


}


ok_noroledialog()
  {
    let url
    url=this.transactionService.getregionurlvalue(this.current_region_prt);
    url+='packagingprocessdashboard'
    this.router.navigate([url]);

  }


  partsValidation()
  {
    this.pripartlenInvalid=false;
    this.pripartlenInvalidReason=null;
    this.pripartwidInvalid=false;
    this.pripartwidInvalidReason=null;
    this.priparthgtInvalid=false;
    this.priparthgtInvalidReason=null;
    this.pripartwgtInvalid=false;
    this.pripartwgtInvalidReason=null;
  }


  // if no supplier present

  ok_nosupdialog() {
    console.log(this.current_region_prt)
    let reg_url: string
    reg_url = this.transactionService.getregionurlvalue(this.current_region_prt);
    reg_url += 'packagingprocess/packsupplieradmin/packsupplier';

    this.router.navigate([reg_url]);

  }


  ok_noDesignsourcedialog() {
    console.log(this.current_region_prt)
    let reg_url: string
    reg_url = this.transactionService.getregionurlvalue(this.current_region_prt);
    reg_url += 'packagingprocess/packdesignsourceadmin/packdesignsource';

    this.router.navigate([reg_url]);

  }


  go_to_supp_main_page() {
    let reg_supp_url: string;
    reg_supp_url = this.transactionService.getregionurlvalue(this.current_region_prt);
    if(this.prt_roleid==1)
    {
    reg_supp_url += 'packagingprocess/packsupplieradmin/packsupplier';
    }
    else if(this.prt_roleid==2)
    {
      reg_supp_url += 'packagingprocess/packdesignsourceadmin/packdesignsource';
    }
    this.router.navigate([reg_supp_url]);


  }

  // if no part present
  ok_noprtdialog() {
    console.log(this.current_region_prt)

    let reg_url_prt: string
    reg_url_prt = this.transactionService.getregionurlvalue(this.current_region_prt);
    this.name=this.transactionService.getregionNameInSmallLetters();
    const proposalFor=sessionStorage.getItem('proposaltypefor');
    if(proposalFor!=null && proposalFor != 'null' && proposalFor.toLowerCase()=='migration')
    {
      if(this.prt_roleid==1)
      {
        this.router.navigate(['/'+this.name+'/packagingprocess/packsupplieradmin/migrationsubmitter'])
      }
      else if(this.prt_roleid==2)
      {
        this.router.navigate(['/'+this.name+'/packagingprocess/packdesignsourceadmin/migrationsubmitter'])
      }
    }
    else // if(this.selectedpart_submitter.propsl_type!=null && this.selectedpart_submitter.propsl_type.toLowerCase()=="normal")
    {
    if(this.prt_roleid==1)
    {
    reg_url_prt += 'packagingprocess/packsupplieradmin/submiterdashboard';
    }
    else if(this.prt_roleid==2){
      reg_url_prt += 'packagingprocess/packdesignsourceadmin/submiterdashboard';
    }

    this.router.navigate([reg_url_prt]);

  }
}


  // partdataList() {

  // }



  exist()
  {
    this.popup1=true;
  }


  get_supp_details()
  {
    console.log(this.selectedpart_submitter);
    const is_design_src='N'
    if(this.selectedpart_submitter.supplier_code!=null && this.selectedpart_submitter.supplier_code!='')
    {
      this.transactionService.getsuppdet(this.selectedpart_submitter.supplier_code,is_design_src).subscribe(suppdet=>{

        console.log('supplier details',suppdet);
        this.selected_supp_details=suppdet
      })
    }
  }



  // only first time call to load user wise all plant and program data and get the part values by sending all parameters as all
  list_plantcode() {
    console.log('get plant list')


    const sub_allplants=[]
  this.transactionService.getactiveplantslist(this.current_region_code_prt).subscribe(sub_allplants=>{
      console.log('sub_useracces',sub_allplants);
     let position:number;
       this.subAllPlants_prt=sub_allplants;
       this.plantCodeList_prt=[];
       this.progList_prt=[];



       // this.plantCodeList.push({plantcode:"all",plant:"[ALL]"});


       if(this.subAllPlants_prt.length!=0)
       {
       for(let temp=0;temp<this.subAllPlants_prt.length;temp++)
       {
         console.log(this.subAllPlants_prt[temp]);
         let plt_name:string;
         plt_name=this.subAllPlants_prt[temp].plantGSDBCode+'-'+this.subAllPlants_prt[temp].plant_name;

          this.plantCodeList_prt.push({plantcode:this.subAllPlants_prt[temp].plantGSDBCode,plant:plt_name})

       }
      }


       console.log(this.plantCodeList_prt);







     // service to get trading company
     // assign slected trading company full list of trading companies;






 // this.selectedproglist=this.progList;
 // this.selectedcoslist=this.cosList;

 // this.selectedplantlist=this.plantCodeList;
 // this.disableProgDrp=false;






// ---------------------------------------------------------------------------------------------------------------------
// in future do gor global admin if its used
 /*   this.transactionService.getUserAccessRole(this.prt_cdsid).subscribe(prt_useraccess => {
      console.log("sub_useracces", prt_useraccess);
      var position: number;
      this.prtUserAccess = prt_useraccess;
      this.plantCodeList_prt = [];
      this.progList_prt = [];


      for (var temp = 0; temp < this.prtUserAccess.length; temp++) {


        if (this.prtUserAccess[temp].cd_region == this.current_region_code_prt) {
          if (this.prtUserAccess[temp].role_id == this.prt_roleid) {
            var plt_name_prt: string;
            plt_name_prt = this.prtUserAccess[temp].plantgsdbcode + "-" + this.prtUserAccess[temp].plant_name;

            this.plantCodeList_prt.push({ plantcode: this.prtUserAccess[temp].plantgsdbcode, plant: plt_name_prt })


            if (this.prtUserAccess[temp].program_name != null) {

              var prg_arr_prt = [];
              prg_arr_prt = this.prtUserAccess[temp].program_name.split(',');

              console.log(prg_arr_prt)
              for (var prg = 0; prg < prg_arr_prt.length; prg++) {
                var present_prg=false;
                for(var kprg=0;kprg<this.progList_prt.length;kprg++)
                {
                       if(prg_arr_prt[prg]==this.progList_prt[kprg].programcode)
                        {
                             present_prg=true;
                                                break;
                        }
                 }


              if(present_prg==false)
                      {
                        this.progList_prt.push({programcode:prg_arr_prt[prg]})
                          }

              }


              console.log("proglist", this.progList_prt);
            }


          }
        }


      }*/
// -------------------------------------------------------------------------------------------------------------------------------------
      // this.selectedproglist=this.progList;
      // this.selectedcoslist=this.cosList;

      // this.selectedplantlist=this.plantCodeList;
      // this.disableProgDrp=false;


      this.selectedproglist_prt = [];

      this.selectedplantlist_prt = [];
      this.disableProgDrp_prt = true;



      // this.apply_filter();

      // to get all parts for that supplier and user wise plant and program


      this.transactionService.get_region_details( this.current_region_code_prt).subscribe((regdtl_prt => {

        this.region_details_prt=regdtl_prt;
        console.log('Region Details',this.region_details_prt);


        let plantcode_parameter='';


        if(this.region_details_prt.limit_prts_recvd_plt.toLowerCase()=='yes')
        {


          let plant_present=false;
          for(let tplt=0;tplt<this.plantCodeList_prt.length;tplt++)
          {
            if(this.plantCodeList_prt[tplt].plantcode==this.selectedpart_submitter.plantgsdbcode)
            {
              plant_present=true;
              // this.selectedplantlist_prt.push(this.plantCodeList_prt[tplt]);
              this.selectedplantlist_prt=this.selectedplantlist_prt.concat(this.plantCodeList_prt[tplt]);
              break

            }
          }


          if(plant_present==false)
          {
            this.selectedplantlist_prt.push({ plantcode: this.selectedpart_submitter.plantgsdbcode, plant:this.selectedpart_submitter.plantgsdbcode })
          }


          this.disablePlantDrp_prt=true;

          plantcode_parameter=this.selectedplantlist_prt[0].plantcode;

          console.log('qo/p',this.selectedplantlist_prt);

          this.onchange_plant();

        }
        else if(this.region_details_prt.limit_prts_recvd_plt.toLowerCase()=='no')
        {


          plantcode_parameter='all'
          this.disablePlantDrp_prt=false;

        }

        if(this.region_details_prt.allow_multi_prt_prop_ind != null && this.region_details_prt.allow_multi_prt_prop_ind.toLowerCase()=='one')
        {
          this.disable_add_button=true;
        }

        else
        {
          this.disable_add_button=false;
        }




       /* if(document.getElementById("overlayprt") != null )
        {
        document.getElementById("overlayprt").style.display = "block";
        }
        if(document.getElementById("loaderprt") != null )
        {
        document.getElementById("loaderprt").style.display = "block";
        }  */

if(this.prt_roleid==1)
{

  if(this.selectedpart_submitter.propsl_type != null && this.selectedpart_submitter.propsl_type.toLowerCase() == 'migration')
  {
    this.transactionService.getsubmittergrid_migration(plantcode_parameter, 'all', 'all', 'New Part', 'all', 'all', 'all', 'all', this.selectedpart_submitter.supplier_code, this.prt_cdsid, 1, this.current_region_code_prt).subscribe((prtgridlist => {


      this.gridListFromAPI=[]
      this.gridListFromAPI=prtgridlist
      this.loadGridDataFromAPI();

        }))
  }
  else
  {
    this.transactionService.getsubmittergrid(plantcode_parameter, 'all', 'all', 'New Part', 'all', 'all', 'all', 'all', this.selectedpart_submitter.supplier_code, this.prt_cdsid, 1, this.current_region_code_prt).subscribe((prtgridlist => {

      this.gridListFromAPI=[]
      this.gridListFromAPI=prtgridlist
      this.loadGridDataFromAPI();

        }))
  }
  // var grid_display_part_status="";


    }

    else if(this.prt_roleid==2)
    {

      // service to getdata
      if(this.selectedpart_submitter.propsl_type != null && this.selectedpart_submitter.propsl_type.toLowerCase() == 'migration')
      {
        console.log(this.designsource_code_prt);
          this.transactionService.getsubmittergrid_designsource_migration(plantcode_parameter,'all','all','New Part','all','all','all','all',this.selectedpart_submitter.supplier_code,'all',this.designsource_code_prt,this.prt_cdsid,2,this.current_region_code_prt).subscribe((prtgridlist => {

            this.gridListFromAPI=[]
            this.gridListFromAPI=prtgridlist
            this.loadGridDataFromAPI();

          }))
      }
      else
      {


          console.log(this.designsource_code_prt);
          this.transactionService.getsubmittergrid_designsource(plantcode_parameter,'all','all','New Part','all','all','all','all',this.selectedpart_submitter.supplier_code,'all',this.designsource_code_prt,this.prt_cdsid,2,this.current_region_code_prt).subscribe((prtgridlist => {

            this.gridListFromAPI=[]
            this.gridListFromAPI=prtgridlist
            this.loadGridDataFromAPI();

          }))
      }

    }

  }
  ))


    })

  }


  loadGridDataFromAPI()
  {
    let grid_display_part_status='';
    this.allPartgridList =[];

    const offsitesupplierid=sessionStorage.getItem('offsitesupplierid')
    if(this.gridListFromAPI != null && this.gridListFromAPI.length != 0)
    {
      if(offsitesupplierid == null || offsitesupplierid == 'null')
      {
        for(const prt of this.gridListFromAPI)
        {
          if(prt.offsitesupplierid == null)
          {
            this.allPartgridList.push(prt)
          }
        }
      }
      else
      {
        for(const prt of this.gridListFromAPI)
        {
          if(prt.offsitesupplierid != null && prt.offsitesupplierid.trim().toLowerCase() == offsitesupplierid.trim().toLowerCase())
          {
            this.allPartgridList.push(prt)
          }
        }
      }
    }


  if(this.allPartgridList!=null && this.allPartgridList.length!=0)
  {
    for(let i=0;i<this.allPartgridList.length;i++)
    {

grid_display_part_status='';
grid_display_part_status+=this.allPartgridList[i].ngpp_part_status;

if(this.allPartgridList[i].isstatusurgent!=null && this.allPartgridList[i].isstatusurgent.toLowerCase()=='y')
{
grid_display_part_status+=' (Urgent)'
}
if(this.allPartgridList[i].isdeliquent!=null && this.allPartgridList[i].isdeliquent.toLowerCase()=='y')
{
grid_display_part_status+=' (Delinquent)'
}


this.allPartgridList[i].displaypartstatus=grid_display_part_status;

    }
  }


  console.log('List parts grid',this.allPartgridList);




  if(this.selectedpart_submitter.proposallastsaved>=1)
{

this.get_exist_proposal_details_tab1()

}


else
{
this.getprimarypart();
}
  }








  getprimarypart()
  {

    console.log(this.allPartgridList)


    this.selectedPartgridList = [];
    this.getPartgridList = [];


    // one time done to check for the primary part from the list
    // if found push it to selected parts array else push other parts to get array where user can select the secondary/additional parts


    // disable parameter- flase for primary - to disbale checkbox



    if (this.allPartgridList.length != 0 && this.allPartgridList != null) {
      this.selectedPartgridList = []


      console.log(this.allPartgridList)
      for (let i = 0; i < this.allPartgridList.length; i++) {
        if (this.allPartgridList[i].partnumbercommonized == this.selectedpart_submitter.simplifiedpartno && this.allPartgridList[i].bompartmasterid==this.selectedpart_submitter.bompartmasterid) {
          // console.log("inside")
          // console.log(i);
          // console.log(this.allPartgridList[i]);
          // console.log(this.selectedPartgridList)
          this.selectedPartgridList.push(this.allPartgridList[i]);
          // console.log(this.selectedPartgridList)
          this.selectedPartgridList[0].disable = true;

        }

        else {
          this.getPartgridList.push(this.allPartgridList[i]);
        }
      }


      console.log(this.getPartgridList);
      console.log(this.selectedPartgridList)

      this.selectpart_cols = [
        { field: 'partnumbercommonized', header: 'Simplified Part Number' },
        { field: 'plantgsdbcode', header: 'Plant' },
        { field: 'programname', header: 'Program(s)' },
        { field: 'suppliergsdbcode', header:'Supplier GSDB'},
        { field: 'parteffectiveindate', header: 'Effective In Dates' },

      ];



      this.getpart_cols = [
        { field: 'partnumbercommonized', header: 'Simplified Part Number' },
        { field: 'plantgsdbcode', header: 'Plant' },
        { field: 'programname', header: 'Program(s)' },
        { field: 'suppliergsdbcode', header:'Supplier GSDB'},
        { field: 'parteffectiveindate', header: 'Effective In Dates' },

      ];


     /* document.getElementById("overlayprt").style.display = "none";
      document.getElementById("loaderprt").style.display = "none";*/


      console.log('selectedpartsgridlist', this.selectedPartgridList);
      // to push selectedPartgridList to grid 3
      // get details for primary part
      if(this.selectedPartgridList != null && this.selectedPartgridList.length != 0)
      {
      this.loadprimarypart(this.selectedPartgridList);
      }


    }









  }


  get_exist_proposal_details_tab1()
    {


      console.log(this.selectedpart_submitter.proposalid);
      this.primarypartsgrid=[];
      this.selectedPartgridList=[];
      this.secondarypartsgrid=[];
      this.secondarypartgridforpost=[];




      this.transactionService.get_draft_Tab1_data(this.selectedpart_submitter.proposalid).subscribe(tab1_data=>{



        console.log('tab1data',tab1_data);


        this.transactionService.get_draft_selectedparts_data(this.selectedpart_submitter.proposalid).subscribe(selectedparts_data=>{


          console.log(selectedparts_data);


          const grid2_parts:subgrid[]=[];
          const grid1_parts:subgrid[]=[];



          this.primarypartsattributes=tab1_data;

          this.selected_corrosion_protection={cd_corsn_prot_type:0,corsn_prot_type:'',active_stat:'',active_stat_display:false,updated_by:'',updated_dt:''}
          // To Populate existing proposal details.
            if(this.shelfLifeList!= null && this.shelfLifeList.length!= 0)
            {
              var shelflifeIndex
              shelflifeIndex=this.shelfLifeList.findIndex(shelf=>shelf.value == tab1_data.shelf_life);
              console.log("Shelflife in getexistingproposal", this.shelfLifeList, shelflifeIndex, tab1_data.shelf_life)
              if(shelflifeIndex != -1)
              {
                this.selectedShelfLife =this.shelfLifeList[shelflifeIndex];
              }
              else if(tab1_data.shelf_life != null)
              {
                this.selectedShelfLife={label:"NA",value:0}
              }
            }
            else if(tab1_data.shelf_life != null)
            {
              this.selectedShelfLife={label:"NA",value:0}
            }
  
              console.log("shelflife selected value", this.selectedShelfLife);

          if(this.corrosion_protection != null && this.corrosion_protection.length != 0)
          {
            let corrIndex
            corrIndex=this.corrosion_protection.findIndex(cor=>cor.corsn_prot_type.toLowerCase() == tab1_data.corr_protc.toLowerCase())
            if(corrIndex != -1)
            {
              this.selected_corrosion_protection=this.corrosion_protection[corrIndex];
            }
            else if(tab1_data.corr_protc != null)
            {
              this.selected_corrosion_protection={cd_corsn_prot_type:0,corsn_prot_type:'',active_stat:'',active_stat_display:false,updated_by:'',updated_dt:''}
            }

          }

          else if(tab1_data.corr_protc != null)
          {
            this.selected_corrosion_protection={cd_corsn_prot_type:0,corsn_prot_type:'',active_stat:'',active_stat_display:false,updated_by:'',updated_dt:''}
          }
          console.log('corrosionprotection selected value', this.selected_corrosion_protection);

          this.isStampingBlank=tab1_data.isstampblank;



          for(let ki=0;ki<selectedparts_data.length;ki++)
          {
            if(selectedparts_data[ki].isprimary.toLowerCase()=='yes')
            {


              this.primarypartsgrid.push(selectedparts_data[ki]);
              this.primarypartgridforpost=selectedparts_data[ki];


              grid1_parts.push({
                bompartmasterid:selectedparts_data[ki].bompartmasterid,
                cntrsuggestedownershpstrat:selectedparts_data[ki].cntrsuggestedownershpstrat,
              disable:true,
              // dummy as no status stored in draft tab 1
              ngpp_part_status:tab1_data.submit_status,
              part_pre_bas_suf:selectedparts_data[ki].partprefix+'-'+selectedparts_data[ki].partbase+'-'+selectedparts_data[ki].partsuffix,
              partbase:selectedparts_data[ki].partbase,
              partcontrol:selectedparts_data[ki].partcontrol,
              partdesc:selectedparts_data[ki].partdesc,
              parteffectiveindate:selectedparts_data[ki].parteffectiveindate,
              partnumbercommonized:selectedparts_data[ki].partnumbercommonized,
              partprefix:selectedparts_data[ki].partprefix,
              partsuffix:selectedparts_data[ki].partsuffix,
              plantgsdbcode:selectedparts_data[ki].plantgsdbcode,
              plantname:selectedparts_data[ki].plantname,
              programname:selectedparts_data[ki].programname,
              suppliercountrycodeISO:selectedparts_data[ki].suppliercountrycodeiso,
              suppliergsdbcode:selectedparts_data[ki].suppliergsdbcode,
              tradingcogsdbcode:selectedparts_data[ki].tradingcogsdbcode,
              // as value req simple hardcoded
              ngpp_part_status_name:'1',
              non_submited:true,
              displaypartstatus:tab1_data.submit_status,
              isdeliquent:'',
              isstatusurgent:'',
              deliquentondate:'',
              urgentondate:'',
              imp_ngpp_part_status:'',
              offsitesupplierid:selectedparts_data[ki].offsitesupplierid
              })





            }

            else{

              // secondary parts validation
              this.secondarypartgridforpost.push({
                bompartmasterid: selectedparts_data[ki].bompartmasterid,
                buyercode: selectedparts_data[ki].buyercode,
                id_proposal: selectedparts_data[ki].id_proposal,
              plantgsdbcode: selectedparts_data[ki].plantgsdbcode,
                supplierid: selectedparts_data[ki].supplierid,
                  cd_region: selectedparts_data[ki].cd_region,
                partsubmitergsdbcodeid: selectedparts_data[ki].partsubmitergsdbcodeid,
                partintrobomuploadbatchid:selectedparts_data[ki].partintrobomuploadbatchid,
                lastseenonbomuploadbatchid:selectedparts_data[ki].lastseenonbomuploadbatchid,
                partintrodate: selectedparts_data[ki].partintrodate,
                plantname: selectedparts_data[ki].plantname,
                programname: selectedparts_data[ki].programname,
                partprefix: selectedparts_data[ki].partprefix,
                partbase: selectedparts_data[ki].partbase,
                partsuffix:selectedparts_data[ki].partsuffix,
                partcontrol: selectedparts_data[ki].partcontrol,
                cpsccode: selectedparts_data[ki].cpsccode,
                partdesc: selectedparts_data[ki].partdesc,
                partstatus: selectedparts_data[ki].partstatus,
                parttype: selectedparts_data[ki].parttype,
                controlitem: selectedparts_data[ki].controlitem,
                commonalitycode: selectedparts_data[ki].commonalitycode,
                partaddedfrom: selectedparts_data[ki].partaddedfrom,
                form1271requested: selectedparts_data[ki].form1271requested,
                cntrsuggestedownershpstrat: selectedparts_data[ki].cntrsuggestedownershpstrat,
                offsitesupplierid: selectedparts_data[ki].offsitesupplierid,
                containerdesignsourceid: selectedparts_data[ki].containerdesignsourceid,
                transtimeindays: selectedparts_data[ki].transtimeindays,
                parteffectiveindate: selectedparts_data[ki].parteffectiveindate,
                s4pincode: selectedparts_data[ki].s4pincode,
                parteffectiveoutdate: selectedparts_data[ki].parteffectiveoutdate,
                s4poutcode: selectedparts_data[ki].s4poutcode,
                carlinecode: selectedparts_data[ki].carlinecode,
                ptotype: selectedparts_data[ki].ptotype,
                cmmspackagingconfirmcode: selectedparts_data[ki].cmmspackagingconfirmcode,
                cmmscontainerbase: selectedparts_data[ki].cmmscontainerbase,
                cmmscontainersuffix: selectedparts_data[ki].cmmscontainersuffix,
                cmmscomponent1base: selectedparts_data[ki].cmmscomponent1base,
                cmmscomponent1suffix: selectedparts_data[ki].cmmscomponent1suffix,
                cmmscomponent2base: selectedparts_data[ki].cmmscomponent2base,
                cmmscomponent2suffix: selectedparts_data[ki].cmmscomponent2suffix,
                cmmscontainerpieses: selectedparts_data[ki].cmmscontainerpieses,
                cmmscontainerextl: selectedparts_data[ki].cmmscontainerextl,
                cmmscontainerextw: selectedparts_data[ki].cmmscontainerextw,
                cmmscontainerexth: selectedparts_data[ki].cmmscontainerexth,
                cmmscontainergrosswt:selectedparts_data[ki].cmmscontainergrosswt,
                cmmscontainersperlayer: selectedparts_data[ki].cmmscontainersperlayer,
                cmmslayerspershipunit: selectedparts_data[ki].cmmslayerspershipunit,
                cmmsshipunitnumpieses: selectedparts_data[ki].cmmsshipunitnumpieses,
                cmmsshipunitextl: selectedparts_data[ki].cmmsshipunitextl,
                cmmsshipunitextw: selectedparts_data[ki].cmmsshipunitextw,
                cmmsshipunitexth: selectedparts_data[ki].cmmsshipunitexth,
                cmmsshipunitgrosswt: selectedparts_data[ki].cmmsshipunitgrosswt,
                cmmspackagingreleaselevel: selectedparts_data[ki].cmmspackagingreleaselevel,
                partnumbercommonized: selectedparts_data[ki].partnumbercommonized,
                tradingcogsdbcode: selectedparts_data[ki].tradingcogsdbcode,
                ismissingpart: selectedparts_data[ki].ismissingpart,
                suppliercountrycodeiso: selectedparts_data[ki].suppliercountrycodeiso,
                suppliergsdbcode: selectedparts_data[ki].suppliergsdbcode,
                isprimary: selectedparts_data[ki].isprimary,
                selorder: selectedparts_data[ki].selorder,
                txntimestamp: selectedparts_data[ki].txntimestamp,
                ngpp_part_l: selectedparts_data[ki].ngpp_part_l,
                ngpp_part_w: selectedparts_data[ki].ngpp_part_w,
                ngpp_part_h: selectedparts_data[ki].ngpp_part_h,
                ngpp_part_wt: selectedparts_data[ki].ngpp_part_wt,
                ngpp_part_wt_status: selectedparts_data[ki].ngpp_part_wt_status,
                ngpp_ref_part_no: selectedparts_data[ki].ngpp_ref_part_no,
                ngpp_manf_loc: selectedparts_data[ki].ngpp_manf_loc,
                partid: selectedparts_data[ki].partid,
                ngpp_part_h_invalid:false,
                ngpp_part_l_invalid:false,
                ngpp_part_w_invalid:false,
                ngpp_part_wt_invalid:false,
                ngpp_part_h_invalid_reasn:'',
                ngpp_part_l_invalid_reasn:'',
                ngpp_part_w_invalid_reasn:'',
                ngpp_part_wt_invalid_reasn:''
              })
              this.secondarypartsgrid.push({
                bompartmasterid: selectedparts_data[ki].bompartmasterid,
                buyercode: selectedparts_data[ki].buyercode,
                id_proposal: selectedparts_data[ki].id_proposal,
              plantgsdbcode: selectedparts_data[ki].plantgsdbcode,
                supplierid: selectedparts_data[ki].supplierid,
                  cd_region: selectedparts_data[ki].cd_region,
                partsubmitergsdbcodeid: selectedparts_data[ki].partsubmitergsdbcodeid,
                partintrobomuploadbatchid:selectedparts_data[ki].partintrobomuploadbatchid,
                lastseenonbomuploadbatchid:selectedparts_data[ki].lastseenonbomuploadbatchid,
                partintrodate: selectedparts_data[ki].partintrodate,
                plantname: selectedparts_data[ki].plantname,
                programname: selectedparts_data[ki].programname,
                partprefix: selectedparts_data[ki].partprefix,
                partbase: selectedparts_data[ki].partbase,
                partsuffix:selectedparts_data[ki].partsuffix,
                partcontrol: selectedparts_data[ki].partcontrol,
                cpsccode: selectedparts_data[ki].cpsccode,
                partdesc: selectedparts_data[ki].partdesc,
                partstatus: selectedparts_data[ki].partstatus,
                parttype: selectedparts_data[ki].parttype,
                controlitem: selectedparts_data[ki].controlitem,
                commonalitycode: selectedparts_data[ki].commonalitycode,
                partaddedfrom: selectedparts_data[ki].partaddedfrom,
                form1271requested: selectedparts_data[ki].form1271requested,
                cntrsuggestedownershpstrat: selectedparts_data[ki].cntrsuggestedownershpstrat,
                offsitesupplierid: selectedparts_data[ki].offsitesupplierid,
                containerdesignsourceid: selectedparts_data[ki].containerdesignsourceid,
                transtimeindays: selectedparts_data[ki].transtimeindays,
                parteffectiveindate: selectedparts_data[ki].parteffectiveindate,
                s4pincode: selectedparts_data[ki].s4pincode,
                parteffectiveoutdate: selectedparts_data[ki].parteffectiveoutdate,
                s4poutcode: selectedparts_data[ki].s4poutcode,
                carlinecode: selectedparts_data[ki].carlinecode,
                ptotype: selectedparts_data[ki].ptotype,
                cmmspackagingconfirmcode: selectedparts_data[ki].cmmspackagingconfirmcode,
                cmmscontainerbase: selectedparts_data[ki].cmmscontainerbase,
                cmmscontainersuffix: selectedparts_data[ki].cmmscontainersuffix,
                cmmscomponent1base: selectedparts_data[ki].cmmscomponent1base,
                cmmscomponent1suffix: selectedparts_data[ki].cmmscomponent1suffix,
                cmmscomponent2base: selectedparts_data[ki].cmmscomponent2base,
                cmmscomponent2suffix: selectedparts_data[ki].cmmscomponent2suffix,
                cmmscontainerpieses: selectedparts_data[ki].cmmscontainerpieses,
                cmmscontainerextl: selectedparts_data[ki].cmmscontainerextl,
                cmmscontainerextw: selectedparts_data[ki].cmmscontainerextw,
                cmmscontainerexth: selectedparts_data[ki].cmmscontainerexth,
                cmmscontainergrosswt:selectedparts_data[ki].cmmscontainergrosswt,
                cmmscontainersperlayer: selectedparts_data[ki].cmmscontainersperlayer,
                cmmslayerspershipunit: selectedparts_data[ki].cmmslayerspershipunit,
                cmmsshipunitnumpieses: selectedparts_data[ki].cmmsshipunitnumpieses,
                cmmsshipunitextl: selectedparts_data[ki].cmmsshipunitextl,
                cmmsshipunitextw: selectedparts_data[ki].cmmsshipunitextw,
                cmmsshipunitexth: selectedparts_data[ki].cmmsshipunitexth,
                cmmsshipunitgrosswt: selectedparts_data[ki].cmmsshipunitgrosswt,
                cmmspackagingreleaselevel: selectedparts_data[ki].cmmspackagingreleaselevel,
                partnumbercommonized: selectedparts_data[ki].partnumbercommonized,
                tradingcogsdbcode: selectedparts_data[ki].tradingcogsdbcode,
                ismissingpart: selectedparts_data[ki].ismissingpart,
                suppliercountrycodeiso: selectedparts_data[ki].suppliercountrycodeiso,
                suppliergsdbcode: selectedparts_data[ki].suppliergsdbcode,
                isprimary: selectedparts_data[ki].isprimary,
                selorder: selectedparts_data[ki].selorder,
                txntimestamp: selectedparts_data[ki].txntimestamp,
                ngpp_part_l: selectedparts_data[ki].ngpp_part_l,
                ngpp_part_w: selectedparts_data[ki].ngpp_part_w,
                ngpp_part_h: selectedparts_data[ki].ngpp_part_h,
                ngpp_part_wt: selectedparts_data[ki].ngpp_part_wt,
                ngpp_part_wt_status: selectedparts_data[ki].ngpp_part_wt_status,
                ngpp_ref_part_no: selectedparts_data[ki].ngpp_ref_part_no,
                ngpp_manf_loc: selectedparts_data[ki].ngpp_manf_loc,
                partid: selectedparts_data[ki].partid,
                ngpp_part_h_invalid:false,
                ngpp_part_l_invalid:false,
                ngpp_part_w_invalid:false,
                ngpp_part_wt_invalid:false,
                ngpp_part_h_invalid_reasn:'',
                ngpp_part_l_invalid_reasn:'',
                ngpp_part_w_invalid_reasn:'',
                ngpp_part_wt_invalid_reasn:''
              })
              // this.secondarypartgridforpost.push(selectedparts_data[ki]);
              // this.secondarypartsgrid.push(selectedparts_data[ki]);


              grid2_parts.push({
                bompartmasterid:selectedparts_data[ki].bompartmasterid,
                cntrsuggestedownershpstrat:selectedparts_data[ki].cntrsuggestedownershpstrat,
              disable:false,
              ngpp_part_status:tab1_data.submit_status,
              part_pre_bas_suf:selectedparts_data[ki].partprefix+'-'+selectedparts_data[ki].partbase+'-'+selectedparts_data[ki].partsuffix,
              partbase:selectedparts_data[ki].partbase,
              partcontrol:selectedparts_data[ki].partcontrol,
              partdesc:selectedparts_data[ki].partdesc,
              parteffectiveindate:selectedparts_data[ki].parteffectiveindate,
              partnumbercommonized:selectedparts_data[ki].partnumbercommonized,
              partprefix:selectedparts_data[ki].partprefix,
              partsuffix:selectedparts_data[ki].partsuffix,
              plantgsdbcode:selectedparts_data[ki].plantgsdbcode,
              plantname:selectedparts_data[ki].plantname,
              programname:selectedparts_data[ki].programname,
              suppliercountrycodeISO:selectedparts_data[ki].suppliercountrycodeiso,
              suppliergsdbcode:selectedparts_data[ki].suppliergsdbcode,
              tradingcogsdbcode:selectedparts_data[ki].tradingcogsdbcode,
              ngpp_part_status_name:'1',
              non_submited:true,
              displaypartstatus:tab1_data.submit_status,
              isdeliquent:'',
              isstatusurgent:'',
              deliquentondate:'',
              urgentondate:'',
              imp_ngpp_part_status:'',
              offsitesupplierid:selectedparts_data[ki].offsitesupplierid
              })





            }
          }
          this.originalTab1Secondarygrid=JSON.parse(JSON.stringify(this.secondarypartsgrid));

          console.log(grid2_parts);
          console.log(grid1_parts)


          if(grid1_parts.length != 0)
          {
            this.selectedPartgridList.push(grid1_parts[0]);
          }
          this.selectedPartgridList.push(...grid2_parts);


          this.getPartgridList=this.allPartgridList


          console.log(this.selectedPartgridList);
          console.log(this.secondarypartsgrid)


          if(this.secondarypartsgrid.length!=0)
          {
            this.enablegrid4=true;
          }


          else{
            this.enablegrid4=false
          }


          this.on_change_isStampingBlank();


          this.selectpart_cols = [
            { field: 'partnumbercommonized', header: 'Simplified Part Number' },
            { field: 'plantgsdbcode', header: 'Plant' },
            { field: 'programname', header: 'Program(s)' },
            { field: 'suppliergsdbcode', header:'Supplier GSDB'},
            { field: 'parteffectiveindate', header: 'Effective In Dates' },

          ];



          this.getpart_cols = [
            { field: 'partnumbercommonized', header: 'Simplified Part Number' },
            { field: 'plantgsdbcode', header: 'Plant' },
            { field: 'programname', header: 'Program(s)' },
            { field: 'suppliergsdbcode', header:'Supplier GSDB'},
            { field: 'parteffectiveindate', header: 'Effective In Dates' },

          ];


         /* document.getElementById("overlayprt").style.display = "none";
          document.getElementById("loaderprt").style.display = "none";
*/




        })

      })





    }





  //   on_change_measure_part()
  //   {

  // console.log("onchange measure");
  //     console.log(this.edit_measure_part);

      // if(this.unitom == "E")
      // {
      //   this.edit_measure_part = "lbs/inch";
      // }
      // else
      // {
      //   this.edit_measure_part = "kg/cm";
      // }

  //     // if(this.edit_measure_part=="lbs/inch")
  //     // {
  //     //   console.log(this.primarypartsgrid);
  //     //   console.log(this.secondarypartsgrid);


  //     //   if(this.primarypartsgrid[0].ngpp_part_l!=null)
  //     //   {
  //     //     this.primarypartsgrid[0].ngpp_part_l=Math.round((0.393701*this.primarypartsgrid[0].ngpp_part_l)*1000)/1000;

  //     //   }
  //     //   if(this.primarypartsgrid[0].ngpp_part_w!=null)
  //     //   {
  //     //     this.primarypartsgrid[0].ngpp_part_w=Math.round((0.393701*this.primarypartsgrid[0].ngpp_part_w)*1000)/1000;

  //     //   }
  //     //   if(this.primarypartsgrid[0].ngpp_part_h!=null)
  //     //   {
  //     //     this.primarypartsgrid[0].ngpp_part_h=Math.round((0.393701*this.primarypartsgrid[0].ngpp_part_h)*1000)/1000;

  //     //   }
  //     //   if(this.primarypartsgrid[0].ngpp_part_wt!=null)
  //     //   {
  //     //     this.primarypartsgrid[0].ngpp_part_wt=Math.round((2.20462*this.primarypartsgrid[0].ngpp_part_wt)*1000)/1000;

  //     //   }



  //     //   if(this.secondarypartsgrid.length!=0)
  //     //   {


  //     //     for(var sec=0;sec<this.secondarypartsgrid.length;sec++)
  //     //     {


  //     //       if(this.secondarypartsgrid[sec].ngpp_part_l!=null)
  //     //       {
  //     //         this.secondarypartsgrid[sec].ngpp_part_l=Math.round((0.393701*this.secondarypartsgrid[sec].ngpp_part_l)*1000)/1000;

  //     //       }
  //     //       if(this.secondarypartsgrid[sec].ngpp_part_w!=null)
  //     //       {
  //     //         this.secondarypartsgrid[sec].ngpp_part_w=Math.round((0.393701*this.secondarypartsgrid[sec].ngpp_part_w)*1000)/1000;

  //     //       }
  //     //       if(this.secondarypartsgrid[sec].ngpp_part_h!=null)
  //     //       {
  //     //         this.secondarypartsgrid[sec].ngpp_part_h=Math.round((0.393701*this.secondarypartsgrid[sec].ngpp_part_h)*1000)/1000;

  //     //       }
  //     //       if(this.secondarypartsgrid[sec].ngpp_part_wt!=null)
  //     //       {
  //     //         this.secondarypartsgrid[sec].ngpp_part_wt=Math.round((2.20462*this.secondarypartsgrid[sec].ngpp_part_wt)*1000)/1000;

  //     //       }


  //     //     }

  //     //   }

  //     // }
  //     // else if(this.edit_measure_part=="kg/cm")
  //     // {


  //     //   console.log(this.primarypartsgrid);
  //     //   console.log(this.secondarypartsgrid);


  //     //   if(this.primarypartsgrid[0].ngpp_part_l!=null)
  //     //   {
  //     //     this.primarypartsgrid[0].ngpp_part_l=Math.round((2.54*this.primarypartsgrid[0].ngpp_part_l)*1000)/1000;

  //     //   }
  //     //   if(this.primarypartsgrid[0].ngpp_part_w!=null)
  //     //   {
  //     //     this.primarypartsgrid[0].ngpp_part_w=Math.round((2.54*this.primarypartsgrid[0].ngpp_part_w)*1000)/1000;

  //     //   }
  //     //   if(this.primarypartsgrid[0].ngpp_part_h!=null)
  //     //   {
  //     //     this.primarypartsgrid[0].ngpp_part_h=Math.round((2.54*this.primarypartsgrid[0].ngpp_part_h)*1000)/1000;

  //     //   }
  //     //   if(this.primarypartsgrid[0].ngpp_part_wt!=null)
  //     //   {
  //     //     this.primarypartsgrid[0].ngpp_part_wt=Math.round((0.453592*this.primarypartsgrid[0].ngpp_part_wt)*1000)/1000;

  //     //   }



  //     //   if(this.secondarypartsgrid.length!=0)
  //     //   {


  //     //     for(var sec=0;sec<this.secondarypartsgrid.length;sec++)
  //     //     {


  //     //       if(this.secondarypartsgrid[sec].ngpp_part_l!=null)
  //     //       {
  //     //         this.secondarypartsgrid[sec].ngpp_part_l=Math.round((2.54*this.secondarypartsgrid[sec].ngpp_part_l)*1000)/1000;

  //     //       }
  //     //       if(this.secondarypartsgrid[sec].ngpp_part_w!=null)
  //     //       {
  //     //         this.secondarypartsgrid[sec].ngpp_part_w=Math.round((2.54*this.secondarypartsgrid[sec].ngpp_part_w)*1000)/1000;

  //     //       }
  //     //       if(this.secondarypartsgrid[sec].ngpp_part_h!=null)
  //     //       {
  //     //         this.secondarypartsgrid[sec].ngpp_part_h=Math.round((2.54*this.secondarypartsgrid[sec].ngpp_part_h)*1000)/1000;

  //     //       }
  //     //       if(this.secondarypartsgrid[sec].ngpp_part_wt!=null)
  //     //       {
  //     //         this.secondarypartsgrid[sec].ngpp_part_wt=Math.round((0.453592*this.secondarypartsgrid[sec].ngpp_part_wt)*1000)/1000;

  //     //       }


  //     //     }

  //     //   }


  //     // }

  //   }

  
  shelfLifeOptions(){
    this.shelfLifeList = [];


      for (let i = 0; i <= 36; i++) {
        this.shelfLifeList.push({ label: i.toString(), value: i });
      }
  
      // Change the first element's label to 'NA'
      if (this.shelfLifeList.length > 0) {
        this.shelfLifeList[0].label = 'NA';
      }
    console.log("Shelflife List", this.shelfLifeList);
  }

  corrosionProtectionValidation()
  {
    if(this.selected_corrosion_protection==null || this.selected_corrosion_protection.corsn_prot_type.trim()=="")
    {
      this.corrosionProtectionInvalid=true;
      this.corrosionProtectionInvalidReason="Required";
    }
    else
    {
      this.corrosionProtectionInvalid=false;
      this.corrosionProtectionInvalidReason=null;
    }
  }

  shelfLifeValidation()
  {
    if(this.primarypartsattributes.shelf_life==null)
    {
      this.shelfLifeInvalid=true;
      this.shelfLifeInvalidReason="Required";
    }
    else
    {
      this.shelfLifeInvalid=false;
      this.shelfLifeInvalidReason=null;
    }
  }

  pripartlenValidation()
  {
    const pripart_l = String(this.primarypartgridforpost.ngpp_part_l).split('.');
    if(!String(this.primarypartgridforpost.ngpp_part_l).includes('.'))
    {
      if(this.primarypartgridforpost.ngpp_part_l==0 || this.primarypartgridforpost.ngpp_part_l==null)
      {
        this.pripartlenInvalid = true;
        this.pripartlenInvalidReason = 'Required';
        console.log('LENPART');
      }
      else if(this.primarypartgridforpost.ngpp_part_l<=0 || this.primarypartgridforpost.ngpp_part_l>999.9999)
      {
        this.pripartlenInvalid = true;
        this.pripartlenInvalidReason = 'Should be between 0 to 999.9999';
      }
      else if( String(this.primarypartgridforpost.ngpp_part_l).length>8)
      {
        this.pripartwidInvalid = true;
        this.pripartwidInvalidReason = 'Max Length Of Characters can be 8';
      }
      else
      {
        this.pripartlenInvalid = false;
        this.pripartlenInvalidReason = null;
      }
    }
    else if(String(this.primarypartgridforpost.ngpp_part_l).includes('.'))
    {
        if(String(pripart_l[0]).length<0 || String(pripart_l[0]).length>3)
        {
          this.pripartlenInvalid=true;
          this.pripartlenInvalidReason='Should be between 0 to 999.9999';
        }
        else if(this.primarypartgridforpost.ngpp_part_l<=0 || this.primarypartgridforpost.ngpp_part_l>999.9999)
        {
          this.pripartlenInvalid=true;
          this.pripartlenInvalidReason='Should be between 0 to 999.9999';
        }
        else if(String(pripart_l[1]).length<0 || String(pripart_l[1]).length>4)
        {
          this.pripartlenInvalid=true;
          this.pripartlenInvalidReason='Max Length Of decimal values can be 4';
        }
        else
        {
          this.pripartlenInvalid = false;
          this.pripartlenInvalidReason = null;
        }
    }
    else
    {
      this.pripartlenInvalid = false;
      this.pripartlenInvalidReason = null;
    }

    }

     pripartwidValidation()
     {
      const pripart_w = String(this.primarypartgridforpost.ngpp_part_w).split('.');
      if(!String(this.primarypartgridforpost.ngpp_part_w).includes('.'))
      {
      if(this.primarypartgridforpost.ngpp_part_w==0 || this.primarypartgridforpost.ngpp_part_w==null)
      {
        this.pripartwidInvalid = true;
        this.pripartwidInvalidReason = 'Required';
        console.log('WIDPART');
      }
      else if(this.primarypartgridforpost.ngpp_part_w<=0 || this.primarypartgridforpost.ngpp_part_w > 999.9999)
      {
        this.pripartwidInvalid = true;
        this.pripartwidInvalidReason = 'Should be between 0 to 999.9999';
        console.log('WIDPART');
      }
      else if( String(this.primarypartgridforpost.ngpp_part_w).length>8)
      {
        this.pripartwidInvalid = true;
        this.pripartwidInvalidReason = 'Max Length Of Characters can be 8';
      }
      else
      {
        this.pripartwidInvalid = false;
        this.pripartwidInvalidReason = null;
        console.log('WIDPART2');
      }
      }
      else if(String(this.primarypartgridforpost.ngpp_part_w).includes('.'))
    {
        if(String(pripart_w[0]).length<0 || String(pripart_w[0]).length>3)
        {
          this.pripartwidInvalid=true;
          this.pripartwidInvalidReason='Should be between 0 to 999.9999';
        }
        else if(this.primarypartgridforpost.ngpp_part_w<=0 || this.primarypartgridforpost.ngpp_part_w>999.9999)
        {
          this.pripartwidInvalid=true;
          this.pripartwidInvalidReason='Should be between 0 to 999.9999';
        }
        else if(String(pripart_w[1]).length<0 || String(pripart_w[1]).length>4)
        {
          this.pripartwidInvalid=true;
          this.pripartwidInvalidReason='Max Length Of decimal values can be 4';
        }
        else
        {
          this.pripartwidInvalid = false;
          this.pripartwidInvalidReason = null;
        }
    }
    else
    {
      this.pripartwidInvalid = false;
      this.pripartwidInvalidReason = null;
    }
     }
    priparthgtValidation()
    {
      const pripart_h = String(this.primarypartgridforpost.ngpp_part_h).split('.');
      if(!String(this.primarypartgridforpost.ngpp_part_h).includes('.'))
      {
      if(this.primarypartgridforpost.ngpp_part_h==0 || this.primarypartgridforpost.ngpp_part_h==null)
      {
        this.priparthgtInvalid = true;
        this.priparthgtInvalidReason = 'Required';
        console.log('HGTPART');
      }
      else if(this.primarypartgridforpost.ngpp_part_h<=0 || this.primarypartgridforpost.ngpp_part_h>999.9999)
      {
        this.priparthgtInvalid = true;
        this.priparthgtInvalidReason = 'Should be between 0 to 999.9999';
        console.log('HGTPART');
      }
      else if( String(this.primarypartgridforpost.ngpp_part_h).length>8)
      {
        this.priparthgtInvalid = true;
        this.priparthgtInvalidReason = 'Max Length Of Characters can be 8';
      }
      else
      {
        this.priparthgtInvalid = false;
        this.priparthgtInvalidReason = null;
        console.log('HGTPART2');
      }
    }
    else if(String(this.primarypartgridforpost.ngpp_part_h).includes('.'))
    {
        if(String(pripart_h[0]).length<0 || String(pripart_h[0]).length>3)
        {
          this.priparthgtInvalid=true;
          this.priparthgtInvalidReason='Should be between 0 to 999.9999';
        }
        else if(this.primarypartgridforpost.ngpp_part_h<=0 || this.primarypartgridforpost.ngpp_part_h>999.9999)
        {
          this.priparthgtInvalid=true;
          this.priparthgtInvalidReason='Should be between 0 to 999.9999';
        }
        else if(String(pripart_h[1]).length<0 || String(pripart_h[1]).length>4)
        {
          this.priparthgtInvalid=true;
          this.priparthgtInvalidReason='Max Length Of decimal values can be 4';
        }
        else
        {
          this.priparthgtInvalid = false;
          this.priparthgtInvalidReason = null;
        }
    }
    else
    {
      this.priparthgtInvalid = false;
      this.priparthgtInvalidReason = null;
    }
     }
     pripartwgtValidation()
     {
      const pripart_wgt = String(this.primarypartgridforpost.ngpp_part_wt).split('.');
      if(!String(this.primarypartgridforpost.ngpp_part_wt).includes('.'))
      {
      if(this.primarypartgridforpost.ngpp_part_wt==0 || this.primarypartgridforpost.ngpp_part_wt==null)
      {
        this.pripartwgtInvalid = true;
        this.pripartwgtInvalidReason = 'Required';
        console.log('WGTPART');
      }
      else if(this.primarypartgridforpost.ngpp_part_wt<=0 || this.primarypartgridforpost.ngpp_part_wt>9999.99999)
      {
        this.pripartwgtInvalid = true;
        this.pripartwgtInvalidReason = 'Should be between 0 to 9999.99999';
      }
      else if( String(this.primarypartgridforpost.ngpp_part_wt).length>11)
      {
        this.pripartwgtInvalid = true;
        this.pripartwgtInvalidReason = 'Max Length Of Characters can be 10';
      }
      else
      {
        this.pripartwgtInvalid = false;
        this.pripartwgtInvalidReason = null;
      }
    }
    else if(String(this.primarypartgridforpost.ngpp_part_wt).includes('.'))
    {
        if(String(pripart_wgt[0]).length<0 || String(pripart_wgt[0]).length>4)
        {
          this.pripartwgtInvalid=true;
          this.pripartwgtInvalidReason='Should be between 0 to 9999.99999';
        }
        else if(this.primarypartgridforpost.ngpp_part_wt<=0 || this.primarypartgridforpost.ngpp_part_wt>9999.99999)
        {
          this.pripartwgtInvalid=true;
          this.pripartwgtInvalidReason='Should be between 0 to 9999.99999';
        }
        else if(String(pripart_wgt[1]).length<0 || String(pripart_wgt[1]).length>5)
        {
          this.pripartwgtInvalid=true;
          this.pripartwgtInvalidReason='Max Length Of decimal values can be 5';
        }
        else
        {
          this.pripartwgtInvalid = false;
          this.pripartwgtInvalidReason = null;
        }
    }
    else
    {
      this.pripartwgtInvalid = false;
      this.pripartwgtInvalid = null;
    }
     }
// secondary parts validation

    secpartlenValidation(product: any)
    {
      // for(var i =0;i<this.secondarypartgridforpost.length;i++)
      // {
      const secpart_l = String(product.ngpp_part_l).split('.');
      if(!String(product.ngpp_part_l).includes('.'))
      {
          if(product.ngpp_part_l==0 || product.ngpp_part_l==null)
        {

          product.ngpp_part_l_invalid = true;
          product.ngpp_part_l_invalid_reasn = 'Required';
          this.enablesave = false;

        }
        else if(product.ngpp_part_l<=0 || product.ngpp_part_l>999.9999)
        {
          product.ngpp_part_l_invalid = true;
          product.ngpp_part_l_invalid_reasn = 'Should be between 0 to 999.9999';
          this.enablesave = false;
        }
        else if( String(product.ngpp_part_l).length>8)
        {
          product.ngpp_part_l_invalid = true;
          product.ngpp_part_l_invalid_reasn = 'Max Length Of Characters can be 8';
          this.enablesave = false;
        }
        else
        {
          product.ngpp_part_l_invalid = false;
          product.ngpp_part_l_invalid_reasn = null;
          this.enablesave = true;
        }
      }
      else if(String(product.ngpp_part_l).includes('.'))
      {
          if(String(secpart_l[0]).length<0 || String(secpart_l[0]).length>3)
          {
            product.ngpp_part_l_invalid=true;
            product.ngpp_part_l_invalid_reasn ='Should be of between 0 to 999.9999';
            this.enablesave = false;
          }
          else if(product.ngpp_part_l<=0 || product.ngpp_part_l>999.9999)
          {
            product.ngpp_part_l_invalid=true;
            product.ngpp_part_l_invalid_reasn ='Should be between 0 to 999.9999';
            this.enablesave = false;
          }
          else if(String(secpart_l[1]).length<0 || String(secpart_l[1]).length>4)
          {
            product.ngpp_part_l_invalid=true;
            product.ngpp_part_l_invalid_reasn ='Max Length Of decimal values can be 4';
            this.enablesave = false;
          }
          else
          {
            product.ngpp_part_l_invalid = false;
            product.ngpp_part_l_invalid_reasn  = null;
            this.enablesave = true;
          }
      }
      else
      {
        product.ngpp_part_l_invalid = false;
        product.ngpp_part_l_invalid_reasn = null;
        this.enablesave = true;
      }
      // }
  }

    secpartwidValidation(product: any)
     {
      const secpart_w = String(product.ngpp_part_w).split('.');
      if(!String(product.ngpp_part_w).includes('.'))
      {
        if(product.ngpp_part_w == 0 || product.ngpp_part_w == null)
        {

          product.ngpp_part_w_invalid = true;
          product.ngpp_part_w_invalid_reasn = 'Required';
          this.enablesave = false;

        }
        else if(product.ngpp_part_w<=0 || product.ngpp_part_w>999.9999)
        {
          product.ngpp_part_w_invalid = true;
          product.ngpp_part_w_invalid_reasn = 'Should be between 0 to 999.9999';
          this.enablesave = false;
        }
        else if( String(product.ngpp_part_w).length>8)
        {
          product.ngpp_part_w_invalid = true;
          product.ngpp_part_w_invalid_reasn = 'Max Length Of Characters can be 8';
          this.enablesave = false;
        }
        else
        {
          product.ngpp_part_w_invalid = false;
          product.ngpp_part_w_invalid_reasn = null;
          this.enablesave = true;
        }
      }
      else if(String(product.ngpp_part_w).includes('.'))
      {
          if(String(secpart_w[0]).length<0 || String(secpart_w[0]).length>3)
          {
            product.ngpp_part_w_invalid=true;
            product.ngpp_part_w_invalid_reasn ='Should be between 0 to 999.9999';
            this.enablesave = false;
          }
          else if(product.ngpp_part_w<=0 || product.ngpp_part_w>999.9999)
          {
            product.ngpp_part_w_invalid=true;
            product.ngpp_part_w_invalid_reasn ='Should be between 0 to 999.9999';
            this.enablesave = false;
          }
          else if(String(secpart_w[1]).length<0 || String(secpart_w[1]).length>4)
          {
            product.ngpp_part_w_invalid=true;
            product.ngpp_part_w_invalid_reasn ='Max Length Of decimal values can be 4';
            this.enablesave = false;
          }
          else
          {
            product.ngpp_part_w_invalid = false;
            product.ngpp_part_w_invalid_reasn  = null;
            this.enablesave = true;
          }
      }
    else
    {
      product.ngpp_part_w_invalid = false;
      product.ngpp_part_w_invalid_reasn = null;
      this.enablesave = true;
    }
    }


    secparthgtValidation(product:any)
     {
      const secpart_h = String(product.ngpp_part_h).split('.');
      if(!String(product.ngpp_part_h).includes('.'))
      {
        if(product.ngpp_part_h == 0 || product.ngpp_part_h == null)
        {
          product.ngpp_part_h_invalid = true;
          product.ngpp_part_h_invalid_reasn = 'Required';
          this.enablesave = false;
        }
        else if(product.ngpp_part_h<=0 || product.ngpp_part_h>999.9999)
        {
          product.ngpp_part_h_invalid = true;
          product.ngpp_part_h_invalid_reasn = 'Should be between 0 to 999.9999';
          this.enablesave = false;
        }
        else if( String(product.ngpp_part_h).length>8)
        {
          product.ngpp_part_h_invalid = true;
          product.ngpp_part_h_invalid_reasn = 'Max Length Of Characters can be 8';
          this.enablesave = false;
        }
        else
        {
          product.ngpp_part_h_invalid = false;
          product.ngpp_part_h_invalid_reasn = null;
          this.enablesave = true;
        }
      }
      else if(String(product.ngpp_part_h).includes('.'))
      {
          if(String(secpart_h[0]).length<0 || String(secpart_h[0]).length>3)
          {
            product.ngpp_part_h_invalid=true;
            product.ngpp_part_h_invalid_reasn ='Should be between 0 to 999.9999';
            this.enablesave = false;
          }
          else if(product.ngpp_part_h<=0 || product.ngpp_part_h>999.9999)
          {
            product.ngpp_part_h_invalid=true;
            product.ngpp_part_h_invalid_reasn ='Should be between 0 to 999.9999';
            this.enablesave = false;
          }
          else if(String(secpart_h[1]).length<0 || String(secpart_h[1]).length>4)
          {
            product.ngpp_part_h_invalid=true;
            product.ngpp_part_h_invalid_reasn ='Max Length Of decimal values can be 4';
            this.enablesave = false;
          }
          else
          {
            product.ngpp_part_h_invalid = false;
            product.ngpp_part_h_invalid_reasn  = null;
            this.enablesave = true;
          }
      }
    else
    {
      product.ngpp_part_h_invalid = false;
      product.ngpp_part_h_invalid_reasn = null;
      this.enablesave = true;
    }
    }
    secpartwgtValidation(product:any)
     {
      const secpart_wt = String(product.ngpp_part_wt).split('.');
      if(!String(product.ngpp_part_wt).includes('.'))
      {
        if(product.ngpp_part_wt == 0 || product.ngpp_part_wt == null)
        {

          product.ngpp_part_wt_invalid = true;
          product.ngpp_part_wt_invalid_reasn = 'Required';
          this.enablesave = false;

        }
        else if(product.ngpp_part_wt <=0 || product.ngpp_part_wt >9999.99999)
        {
          product.ngpp_part_wt_invalid = true;
          product.ngpp_part_wt_invalid_reasn = 'Should be between 0 to 9999.99999';
          this.enablesave = false;

        }
        else if( String(product.ngpp_part_wt).length>11)
        {
          product.ngpp_part_wt_invalid = true;
          product.ngpp_part_wt_invalid_reasn = 'Max Length Of Characters can be 10';
          this.enablesave = false;
        }
        else
        {
          product.ngpp_part_wt_invalid = false;
          product.ngpp_part_wt_invalid_reasn = null;
        }
      }
      else if(String(product.ngpp_part_wt).includes('.'))
      {
          if(String(secpart_wt[0]).length<0 || String(secpart_wt[0]).length>4)
          {
            product.ngpp_part_wt_invalid=true;
            product.ngpp_part_wt_invalid_reasn ='Should be between 0 to 9999.99999';
            this.enablesave = false;
          }
          else if(product.ngpp_part_wt<=0 || product.ngpp_part_wt>9999.99999)
          {
            product.ngpp_part_wt_invalid=true;
            product.ngpp_part_wt_invalid_reasn ='Should be between 0 to 9999.99999';
            this.enablesave = false;
          }
          else if(String(secpart_wt[1]).length<0 || String(secpart_wt[1]).length>5)
          {
            product.ngpp_part_wt_invalid=true;
            product.ngpp_part_wt_invalid_reasn ='Max Length Of decimal values can be 5';
            this.enablesave = false;
          }
          else
          {
            product.ngpp_part_wt_invalid = false;
            product.ngpp_part_wt_invalid_reasn  = null;
            this.enablesave = true;
          }
      }
    else
    {
      product.ngpp_part_wt_invalid = false;
      product.ngpp_part_wt_invalid_reasn = null;
      this.enablesave = true;
    }

     }



  on_change_isStampingBlank ()
    {


      console.log(this.isStampingBlank)


      if(this.isStampingBlank=='No')
      {


      this.cols1 = [
        { field: 'partnumbercommonized', header:'Primary Part Number'},
        {  field: 'ngpp_part_l', header:'Length'},
        { field: 'ngpp_part_w', header:'Width'},
        { field: 'ngpp_part_h', header:'Height'},
        { field: 'ngpp_part_wt', header:'Weight'},
        { field: 'ngpp_part_wt_status', header:'Weight Status'},
        { field: 'ngpp_ref_part_no', header:'Ref Part No'},
        { field: 'ngpp_manf_loc', header:'Manufacturing Location'}
      ];


      this.cols2 = [
        { field: 'partnumbercommonized', header:'Additional Part Number(s)'},
        { field: 'ngpp_part_l', header:'Length'},
        { field: 'ngpp_part_w', header:'Width'},
        { field: 'ngpp_part_h', header:'Height'},
        { field: 'ngpp_part_wt', header:'Weight'},
        { field: 'ngpp_part_wt_status', header:'Weight Status'},
        { field: 'ngpp_ref_part_no', header:'Ref Part No'},
        { field: 'ngpp_manf_loc', header:'Manufacturing Location'}
      ];

    }


    else{


      this.cols1 = [
        { field: 'partnumbercommonized', header:'Primary Part Number'},
        {  field: 'ngpp_part_l', header:'Pitch'},
        { field: 'ngpp_part_w', header:'Width'},
        { field: 'ngpp_part_h', header:'Thickness'},
        { field: 'ngpp_part_wt', header:'Weight'},
        { field: 'ngpp_part_wt_status', header:'Weight Status'},
        { field: 'ngpp_ref_part_no', header:'Ref Part No'},
        { field: 'ngpp_manf_loc', header:'Manufacturing Location'}
      ];


      this.cols2 = [
        { field: 'partnumbercommonized', header:'Additional Part Number(s)'},
        { field: 'ngpp_part_l', header:'Pitch'},
        { field: 'ngpp_part_w', header:'Width'},
        { field: 'ngpp_part_h', header:'Thickness'},
        { field: 'ngpp_part_wt', header:'Weight'},
        { field: 'ngpp_part_wt_status', header:'Weight Status'},
        { field: 'ngpp_ref_part_no', header:'Ref Part No'},
        { field: 'ngpp_manf_loc', header:'Manufacturing Location'}
      ];




    }
    }

  loadprimarypart(primaryparts:subgrid[])
  {
    this.primarypartsbomid=primaryparts[0].bompartmasterid.toString();
    console.log('primarypartsbomid',this.primarypartsbomid);
    this.loadprimarypartgrid();

  }

  primarypartsdetailsinit()
  {
    this.primarypartgridforpost=
    {
      id_proposal:0,
      bompartmasterid:0,
      plantgsdbcode:'',
      supplierid:'',
      cd_region:'',
      partsubmitergsdbcodeid:'',
      partintrobomuploadbatchid:0,
      lastseenonbomuploadbatchid:0,
      partintrodate:'',
      plantname:'',
      programname:'',
      partprefix:'',
      partbase:'',
      partsuffix:'',
      partcontrol:'',
      cpsccode:'',
      partdesc:'',
      partstatus:'',
      parttype:'',
      controlitem:'',
      commonalitycode:'',
      partaddedfrom:'',
      form1271requested:'',
      cntrsuggestedownershpstrat:'',
      offsitesupplierid:'',
      containerdesignsourceid:'',
      buyercode:'',
      transtimeindays:0,
      parteffectiveindate:'',
      s4pincode:'',
      parteffectiveoutdate:'',
      s4poutcode:'',
      carlinecode:'',
      ptotype:'',
      cmmspackagingconfirmcode:'',
      cmmscontainerbase:'',
      cmmscontainersuffix:'',
      cmmscomponent1base:'',
      cmmscomponent1suffix:'',
      cmmscomponent2base:'',
      cmmscomponent2suffix:'',
      cmmscontainerpieses:0,
      cmmscontainerextl:0,
      cmmscontainerextw:0,
      cmmscontainerexth:0,
      cmmscontainergrosswt:0,
      cmmscontainersperlayer:0,
      cmmslayerspershipunit:0,
      cmmsshipunitnumpieses:0,
      cmmsshipunitextl:0,
      cmmsshipunitextw:0,
      cmmsshipunitexth:0,
      cmmsshipunitgrosswt:0,
      cmmspackagingreleaselevel:'',
      partnumbercommonized:'',
      tradingcogsdbcode:'',
      ismissingpart:'',
      suppliercountrycodeiso:'',
      suppliergsdbcode:'',
      isprimary:'',
      selorder:0,
      txntimestamp:'',
      ngpp_part_l:0,
      ngpp_part_w:0,
      ngpp_part_h:0,
      ngpp_part_wt:0,
      ngpp_part_wt_status:'',
      ngpp_ref_part_no:'',
      ngpp_manf_loc:'',
      partid:''



  };
  }

  loadprimarypartgrid()
  {




    this.transactionService.getSelectedPartsDetails(this.primarypartsbomid).subscribe(ret=>{
      console.log(ret);
      this.primarypartgridforget=ret[0];
      // this.primarypartgridforpost=JSON.parse(JSON.stringify(this.primarypartgridforget))
      this.primarypartgridforpost.id_proposal=this.id_proposal;

      this.primarypartgridforpost.bompartmasterid=this.primarypartgridforget.bompartmasterid;
      console.log('printing');
      this.primarypartgridforpost.plantgsdbcode=this.primarypartgridforget.plantgsdbcode;
      this.primarypartgridforpost.supplierid=this.primarypartgridforget.supplierid;
      this.primarypartgridforpost.cd_region=this.primarypartgridforget.cd_region;
      this.primarypartgridforpost.partsubmitergsdbcodeid=this.primarypartgridforget.partsubmitergsdbcodeid;
      this.primarypartgridforpost.partintrobomuploadbatchid=this.primarypartgridforget.partintrobomuploadbatchid;
      this.primarypartgridforpost.lastseenonbomuploadbatchid=this.primarypartgridforget.lastseenonbomuploadbatchid;
      this.primarypartgridforpost.partintrodate=this.primarypartgridforget.partintrodate;
      this.primarypartgridforpost.plantname=this.primarypartgridforget.plantname;
      this.primarypartgridforpost.programname=this.primarypartgridforget.programname;
      this.primarypartgridforpost.partprefix=this.primarypartgridforget.partprefix;
      this.primarypartgridforpost.partbase=this.primarypartgridforget.partbase;
      this.primarypartgridforpost.partsuffix=this.primarypartgridforget.partsuffix;
      this.primarypartgridforpost.partcontrol=this.primarypartgridforget.partcontrol;
      this.primarypartgridforpost.cpsccode=this.primarypartgridforget.cpsccode;
      this.primarypartgridforpost.partdesc=this.primarypartgridforget.partdesc;
      this.primarypartgridforpost.partstatus=this.primarypartgridforget.partstatus;
      this.primarypartgridforpost.parttype=this.primarypartgridforget.parttype;
      this.primarypartgridforpost.controlitem=this.primarypartgridforget.controlitem;
      this.primarypartgridforpost.commonalitycode=this.primarypartgridforget.commonalitycode;
      this.primarypartgridforpost.partaddedfrom=this.primarypartgridforget.partaddedfrom;
      this.primarypartgridforpost.form1271requested=this.primarypartgridforget.form1271requested;
      this.primarypartgridforpost.cntrsuggestedownershpstrat=this.primarypartgridforget.cntrsuggestedownershpstrat;
      this.primarypartgridforpost.offsitesupplierid=this.primarypartgridforget.offsitesupplierid;
      this.primarypartgridforpost.containerdesignsourceid=this.primarypartgridforget.containerdesignsourceid;
      this.primarypartgridforpost.buyercode=this.primarypartgridforget.buyercode;
      this.primarypartgridforpost.transtimeindays=this.primarypartgridforget.transtimeindays;
      this.primarypartgridforpost.parteffectiveindate=this.primarypartgridforget.parteffectiveindate;
      this.primarypartgridforpost.s4pincode=this.primarypartgridforget.s4pincode;
      this.primarypartgridforpost.parteffectiveoutdate=this.primarypartgridforget.parteffectiveoutdate;
      this.primarypartgridforpost.s4poutcode=this.primarypartgridforget.s4poutcode;
      this.primarypartgridforpost.carlinecode=this.primarypartgridforget.carlinecode;
      this.primarypartgridforpost.ptotype=this.primarypartgridforget.ptotype;
      this.primarypartgridforpost.cmmspackagingconfirmcode=this.primarypartgridforget.cmmspackagingconfirmcode;
      this.primarypartgridforpost.cmmscontainerbase=this.primarypartgridforget.cmmscontainerbase;
      this.primarypartgridforpost.cmmscontainersuffix=this.primarypartgridforget.cmmscontainersuffix;
      this.primarypartgridforpost.cmmscomponent1base=this.primarypartgridforget.cmmscomponent1base;
      this.primarypartgridforpost.cmmscomponent1suffix=this.primarypartgridforget.cmmscomponent1suffix;
      this.primarypartgridforpost.cmmscomponent2base=this.primarypartgridforget.cmmscomponent2base;
      this.primarypartgridforpost.cmmscomponent2suffix=this.primarypartgridforget.cmmscomponent2suffix;
      this.primarypartgridforpost.cmmscontainerpieses=this.primarypartgridforget.cmmscontainerpieses;
      this.primarypartgridforpost.cmmscontainerextl=this.primarypartgridforget.cmmscontainerextl;
      this.primarypartgridforpost.cmmscontainerextw=this.primarypartgridforget.cmmscontainerextw;
      this.primarypartgridforpost.cmmscontainerexth=this.primarypartgridforget.cmmsshipunitexth;
      this.primarypartgridforpost.cmmscontainergrosswt=this.primarypartgridforget.cmmscontainergrosswt;
      this.primarypartgridforpost.cmmscontainersperlayer=this.primarypartgridforget.cmmscontainersperlayer;
      this.primarypartgridforpost.cmmslayerspershipunit=this.primarypartgridforget.cmmslayerspershipunit;
      this.primarypartgridforpost.cmmsshipunitnumpieses=this.primarypartgridforget.cmmsshipunitnumpieses;
      this.primarypartgridforpost.cmmsshipunitextl=this.primarypartgridforget.cmmsshipunitextl;
      this.primarypartgridforpost.cmmsshipunitextw=this.primarypartgridforget.cmmsshipunitextw;
      this.primarypartgridforpost.cmmsshipunitexth=this.primarypartgridforget.cmmsshipunitexth;
      this.primarypartgridforpost.cmmsshipunitgrosswt=this.primarypartgridforget.cmmsshipunitgrosswt;
      this.primarypartgridforpost.cmmspackagingreleaselevel=this.primarypartgridforget.cmmspackagingreleaselevel;
      this.primarypartgridforpost.partnumbercommonized=this.primarypartgridforget.partnumbercommonized;
      this.primarypartgridforpost.tradingcogsdbcode=this.primarypartgridforget.tradingcogsdbcode;
      this.primarypartgridforpost.ismissingpart=this.primarypartgridforget.ismissingpart;
      this.primarypartgridforpost.suppliercountrycodeiso=this.primarypartgridforget.suppliercountrycodeiso;
      this.primarypartgridforpost.suppliergsdbcode=this.primarypartgridforget.suppliergsdbcode;
      this.primarypartgridforpost.isprimary='yes';
      this.primarypartgridforpost.selorder=0;
      this.primarypartgridforpost.txntimestamp=new Date().toISOString().split('.')[0];
      this.primarypartgridforpost.ngpp_part_l=0;
      this.primarypartgridforpost.ngpp_part_w=0;
      this.primarypartgridforpost.ngpp_part_h=0;
      this.primarypartgridforpost.ngpp_part_wt=0;
      this.primarypartgridforpost.ngpp_part_wt_status='';
      this.primarypartgridforpost.ngpp_ref_part_no='';
      this.primarypartgridforpost.ngpp_manf_loc='';
      this.primarypartgridforpost.partid=this.primarypartgridforget.partid;


this.on_change_isStampingBlank();



    });


    this.originalTab1Secondarygrid=JSON.parse(JSON.stringify(this.secondarypartsgrid));

  this.primarypartsgrid.push(this.primarypartgridforpost);
  console.log('this.primarypartsgrid',this.primarypartsgrid);

  this.loadprimarypartdetails(this.id_proposal);
  }


  loadprimarypartdetails(proposal_id:number)
  {
    console.log('--------',this.primarypartgridforpost.partnumbercommonized);
      this.primarypartsattributes={
        id_proposal:proposal_id,
        temp_sensitivity:'No',
        corr_protc:'',
        seasnlreq:'',
        "shelf_life":+this.selectedShelfLife.label,
        sp_part_no:this.selectedPartgridList[0].partnumbercommonized,
        temp_stor_spec:'',
       gppuserid:this.transactionService.getcdsid(),
        lst_saved_tab:0,
        submit_status:'not submitted',
        timestamp:new Date().toISOString().split('.')[0],
        plantgsdbcode:this.selectedpart_submitter.plantgsdbcode,
        isstampblank:this.isStampingBlank,
        supgsdbcode: '',
        supfullname: '',
         supaddress1: '',
         supcity: '',
         supstateprov: '',
          supcountrycode: '',
         supcontactnamefirst: '',
       supcontactname: '',
      supcontactfunctn: '',
        supcontactemail: '',
        supcontactphone: '',
        supcontactfax: '',
        defaultcurr:'',
        lst_saved_tab_ee:1,
        propsl_type:''
    }
    console.log('in primarypart details',this.primarypartsattributes);
  }

  onRowEditInit(product: partDetailForPost) {
    this.editingdetails++;
    this.partsdetailsbeforeediting=product;
    this.clonedParts[product.partnumbercommonized] = {...product};
    console.log('In row edit init', this.clonedParts);
}

onRowEditSave(product: partDetailForPost)
{

    this.editingdetails--;
    console.log('modified values',product);
    console.log('part attributes has been modified');
    if(this.primarypartsgrid.includes(product))
    {
      console.log('primarypartsgrid',this.primarypartsgrid);
      console.log('primarypartgridforpost',this.primarypartgridforpost)
      delete this.clonedParts[product.partnumbercommonized];

      this.messageService.add({severity:'success', summary: 'Success', detail:'Part Details Updated'});

    }
    // else
    // {
    //   console.log("secndarypartsgridmodified");
    //   console.log("secondarypartgridforpost",this.secondarypartgridforpost)
    //   console.log("secondarypartsgrid",this.secondarypartsgrid);

    //   delete this.clonedParts[product.partnumbercommonized];
    //   this.messageService.add({severity:'success', summary: 'Success', detail:'Part Details Updated'});

    // }

}

onRowEditCancel(product: partDetailForPost, index: string)
{
    this.editingdetails--;
    if(this.primarypartsgrid.includes(product))
    {
        this.primarypartsgrid[index]=this.clonedParts[product.partnumbercommonized];
    }
    else
    {
        this.secondarypartgridforpost[index]=this.clonedParts[product.partnumbercommonized];
        this.secondarypartsgrid[index]=this.clonedParts[product.partnumbercommonized];
    }

    this.messageService.add({severity:'error', summary: 'Cancelled', detail:'Edit has been cancelled'});

}









  // while plant code changes get the user wise supplier wise plant wise program code list

  onchange_plant() {


    console.log(this.selectedplantlist_prt);

    if (this.selectedplantlist_prt.length != 0) {
      this.disableProgDrp_prt = false;


      let plantcodes_selected_commasep_prt='';
  plantcodes_selected_commasep_prt=this.selectedplantlist_prt[0].plantcode;



  if(this.selectedplantlist_prt.length>1)
  {
  for(let iconv=1;iconv<this.selectedplantlist_prt.length;iconv++)
  {

    plantcodes_selected_commasep_prt+=',';
    plantcodes_selected_commasep_prt+=this.selectedplantlist_prt[iconv].plantcode;

  }
}


console.log(plantcodes_selected_commasep_prt);

const prog_output=[];
this.transactionService.getprogmlistbasedonplantgsdb(plantcodes_selected_commasep_prt,this.current_region_code_prt).subscribe(prog_output=>{

  console.log(prog_output);
  this.progListOnPlt_prt=prog_output
  this.progList_prt=[];
  if(this.progListOnPlt_prt.length!=0)
  {
    let prog_plt='';
    for(let prg=0; prg<this.progListOnPlt_prt.length;prg++)
    {


      let present_prg=false;
      prog_plt='';
      for(let kprg=0;kprg<this.progList_prt.length;kprg++)
      {
             if(this.progListOnPlt_prt[prg].programname.toLowerCase()==this.progList_prt[kprg].programcode.toLowerCase() )
              {

               this.progList_prt[kprg].programdisplay= this.progList_prt[kprg].programdisplay+',('+this.progListOnPlt_prt[prg].plantgsdbcode+')';

                   present_prg=true;
                                      break;
              }
       }


    if(present_prg==false)
            {
              prog_plt=this.progListOnPlt_prt[prg].programname+'('+this.progListOnPlt_prt[prg].plantgsdbcode+')';

      this.progList_prt.push({programcode: this.progListOnPlt_prt[prg].programname,programdisplay:prog_plt})

                }



    }

  }


  console.log(this.selectedproglist_prt);


  if(this.progList_prt.length!=0 && this.selectedproglist_prt.length!=0)
{
  const selprgarr=[];
  console.log(this.selectedproglist_prt);
  console.log(this.progList_prt)
  const prog_plt='';
          for(let iprg=0;iprg<this.progList_prt.length;iprg++)
          {
            console.log(this.progList_prt[iprg])
          for(let jprg=0;jprg<this.selectedproglist_prt.length;jprg++)
          {
            console.log(this.selectedproglist_prt[jprg])

            if(this.progList_prt[iprg].programcode.toLowerCase()==this.selectedproglist_prt[jprg].programcode.toLowerCase())
            {
              this.selectedproglist_prt[jprg].programdisplay=this.progList_prt[iprg].programdisplay
              selprgarr.push(this.selectedproglist_prt[jprg]);
              console.log(selprgarr)
              break;
            }
          }

          }




          this.selectedproglist_prt=selprgarr;

          console.log(this.selectedproglist_prt)
}


else if(this.progList_prt.length==0)
{
  this.selectedproglist_prt=[];
}



})


// ----------------------------------------------------------------------------------------------------------------
// in future do gor global admin if its used
   /*   this.transactionService.getUserAccessRole(this.prt_cdsid).subscribe(prt_prguseraccess => {
        console.log("prt_useracces", prt_prguseraccess);
        var position: number;
        this.prtPrgUserAccess = prt_prguseraccess;
        this.progList_prt = [];


        console.log(this.selectedplantlist_prt)

        for (var iplt = 0; iplt < this.selectedplantlist_prt.length; iplt++) {
          for (var prg_temp = 0; prg_temp < this.prtPrgUserAccess.length; prg_temp++) {


            if (this.prtUserAccess[prg_temp].cd_region == this.current_region_code_prt) {
              if (this.prtPrgUserAccess[prg_temp].role_id == 1 && this.prtPrgUserAccess[prg_temp].plantgsdbcode == this.selectedplantlist_prt[iplt].plantcode) {


                var prg_arr_prt = [];
                if (this.prtPrgUserAccess[prg_temp].program_name != null) {
                  prg_arr_prt = this.prtPrgUserAccess[prg_temp].program_name.split(',');
                  for (var prg = 0; prg < prg_arr_prt.length; prg++) {
                    var present_prg=false;
                    for(var kprg=0;kprg<this.progList_prt.length;kprg++)
                              {
                           if(prg_arr_prt[prg]==this.progList_prt[kprg].programcode)
                            {
                                 present_prg=true;
                                                    break;
                            }
                     }


                  if(present_prg==false)
                          {
                            this.progList_prt.push({programcode:prg_arr_prt[prg]})
                              }

                  }


                  console.log("proglist", this.progList_prt);
                }












              }
            }

          }


          console.log(this.selectedproglist_prt);



        }

        if (this.progList_prt.length != 0 && this.selectedproglist_prt.length != 0) {
          var selprgarr_prt = [];
          console.log(this.selectedproglist_prt);
          console.log(this.progList_prt)
          for (var iprg = 0; iprg < this.progList_prt.length; iprg++) {

            console.log(this.progList_prt[iprg])
            for (var jprg = 0; jprg < this.selectedproglist_prt.length; jprg++) {
              console.log(this.selectedproglist_prt[jprg])

              if (this.progList_prt[iprg].programcode == this.selectedproglist_prt[jprg].programcode) {
                selprgarr_prt.push(this.selectedproglist_prt[jprg]);
                console.log(selprgarr_prt)
                break;
              }
            }

          }




          this.selectedproglist_prt = selprgarr_prt;

          console.log(this.selectedproglist_prt)
        }


        else if (this.progList_prt.length == 0) {
          this.selectedproglist_prt = [];
        }




      })*/
// -----------------------------------------------------------------------------------------------------------------------------
      // service

      // this.disableProgDrp=true;



    }


    else {
      this.disableProgDrp_prt = true;
      this.selectedplantlist_prt = [];
      this.selectedproglist_prt = [];


    }

  }


  // while user selectes the filter values and apply filter to get parts based on filter


  // if no part is selected or all part selected parameter is "all"
  // else the the selected values as comma seperated values

  apply_filter() {

    this.getpart_grid_text = '';
    console.log(this.supp_code_prt);
    console.log(this.selectedpartprefix_prt);
    console.log(this.selectedpartbase_prt);
    console.log(this.selectedpartsuffix_prt);
    console.log(this.selectedplantlist_prt);
    if (this.selectedpartprefix_prt != null && this.selectedpartprefix_prt != '') {
      this.part_prefix_prt = this.selectedpartprefix_prt

    }
    else {
      this.part_prefix_prt = 'all'
    }

    if (this.selectedpartbase_prt != null && this.selectedpartbase_prt != '') {
      this.part_base_prt = this.selectedpartbase_prt

    }
    else {
      this.part_base_prt = 'all'
    }



    if (this.selectedpartsuffix_prt != null && this.selectedpartsuffix_prt != '') {
      this.part_suffix_prt = this.selectedpartsuffix_prt

    }
    else {
      this.part_suffix_prt = 'all'
    }


    if (this.selectedplantlist_prt.length != 0) {

      console.log('inside')
      if (this.selectedplantlist_prt.length == this.plantCodeList_prt.length) {
        this.plantcode_prt = 'all';
      }

      else {

        this.plantcode_prt = '';
        let plt_count_prt = 0;
        for (let p_plt = 0; p_plt < this.selectedplantlist_prt.length; p_plt++) {
          this.plantcode_prt += this.selectedplantlist_prt[p_plt].plantcode
          plt_count_prt += 1;
          if (plt_count_prt != this.selectedplantlist_prt.length) {
            this.plantcode_prt += ',';
          }
        }



      }

      console.log(this.plantcode_prt);

    }

    else {

      this.plantcode_prt = 'all';

    }




    // program

    if (this.selectedproglist_prt.length != 0) {
      if (this.selectedproglist_prt.length == this.progList_prt.length) {
        this.programcode_prt = 'all';
      }

      else {

        this.programcode_prt = '';
        let prg_count_prt = 0;
        for (let p_prg = 0; p_prg < this.selectedproglist_prt.length; p_prg++) {
          this.programcode_prt += this.selectedproglist_prt[p_prg].programcode
          prg_count_prt += 1;
          if (prg_count_prt != this.selectedproglist_prt.length) {
            this.programcode_prt += ',';
          }
        }



      }

      console.log(this.programcode_prt);

    }

    else {
      this.programcode_prt = 'all';

    }



    // cos

    console.log(this.getPartgridList);
    console.log(this.selectedPartgridList);


    if(this.prt_roleid==1)
    {



    console.log(this.plantcode_prt, this.programcode_prt, this.part_prefix_prt, this.part_base_prt, this.part_suffix_prt, this.supp_code_prt)

    if(this.selectedpart_submitter.propsl_type != null && this.selectedpart_submitter.propsl_type.toLowerCase() == 'migration')
    {
        this.transactionService.getsubmittergrid_migration(this.plantcode_prt, this.programcode_prt, 'all', 'New Part', 'all', this.part_prefix_prt, this.part_base_prt, this.part_suffix_prt, this.selectedpart_submitter.supplier_code, this.prt_cdsid, 1, this.current_region_code_prt).subscribe((subgridlist => {
          // this.partgridList = subgridlist
          this.gridListFromAPI=[]
          this.gridListFromAPI=subgridlist
          this.loadGridListFromAPIForApplyFilter();
          // this.suppcode=[];
          /* if(this.suppget.length==0)
          {
            this.valueback=true;
          }
          else{
          this.valueback=false;
          }*/

    }

        ))
    }
    else
    {
      this.transactionService.getsubmittergrid(this.plantcode_prt, this.programcode_prt, 'all', 'New Part', 'all', this.part_prefix_prt, this.part_base_prt, this.part_suffix_prt, this.selectedpart_submitter.supplier_code, this.prt_cdsid, 1, this.current_region_code_prt).subscribe((subgridlist => {
        // this.partgridList = subgridlist
        this.gridListFromAPI=[]
        this.gridListFromAPI=subgridlist
        this.loadGridListFromAPIForApplyFilter();
        // this.suppcode=[];
        /* if(this.suppget.length==0)
        {
          this.valueback=true;
        }
        else{
        this.valueback=false;
        }*/

      }

      ))

    }


  }


  else if(this.prt_roleid==2)
  {
    // service get all data similar to submitter dashboard
    // supplier code="all"
    // supplier country="all" send design source
    if(this.selectedpart_submitter.propsl_type != null && this.selectedpart_submitter.propsl_type.toLowerCase() == 'migration')
    {
    this.transactionService.getsubmittergrid_designsource_migration(this.plantcode_prt,this.programcode_prt,'all','New Part','all',this.part_prefix_prt,this.part_base_prt,this.part_suffix_prt,this.selectedpart_submitter.supplier_code,'all',this.designsource_code_prt,this.prt_cdsid,2,this.current_region_code_prt).subscribe((subgridlist => {
    // this.partgridList = subgridlist
    this.gridListFromAPI=[]
    this.gridListFromAPI=subgridlist
    this.loadGridListFromAPIForApplyFilter();
      }))
    }
    else
    {
      this.transactionService.getsubmittergrid_designsource(this.plantcode_prt,this.programcode_prt,'all','New Part','all',this.part_prefix_prt,this.part_base_prt,this.part_suffix_prt,this.selectedpart_submitter.supplier_code,'all',this.designsource_code_prt,this.prt_cdsid,2,this.current_region_code_prt).subscribe((subgridlist => {
        // this.partgridList = subgridlist
        this.gridListFromAPI=[]
        this.gridListFromAPI=subgridlist
        this.loadGridListFromAPIForApplyFilter();
          }))
    }
  }







    // service
  }


  loadGridListFromAPIForApplyFilter()
  {
    this.partgridList =[];
    let grid_display_part_status='';
    const offsitesupplierid=sessionStorage.getItem('offsitesupplierid')
    if(this.gridListFromAPI != null && this.gridListFromAPI.length != 0)
    {
      if(offsitesupplierid == null || offsitesupplierid == 'null')
      {
        for(const prt of this.gridListFromAPI)
        {
          if(prt.offsitesupplierid == null)
          {
            this.partgridList.push(prt)
          }
        }
      }
      else
      {
        for(const prt of this.gridListFromAPI)
        {
          if(prt.offsitesupplierid != null && prt.offsitesupplierid.trim().toLowerCase() == offsitesupplierid.trim().toLowerCase())
          {
            this.partgridList.push(prt)
          }
        }
      }
    }


    if(this.partgridList!=null && this.partgridList.length!=0)
      {
        for(let i=0;i<this.partgridList.length;i++)
        {

  grid_display_part_status='';
  grid_display_part_status+=this.partgridList[i].ngpp_part_status;

  if(this.partgridList[i].isstatusurgent!=null && this.partgridList[i].isstatusurgent.toLowerCase()=='y')
  {
    grid_display_part_status+=' (Urgent)'
  }
  if(this.partgridList[i].isdeliquent!=null && this.partgridList[i].isdeliquent.toLowerCase()=='y')
  {
    grid_display_part_status+=' (Delinquent)'
  }


  this.partgridList[i].displaypartstatus=grid_display_part_status;

        }
      }


this.applyfilter_load_table1data();
  }




  applyfilter_load_table1data()
  {
    this.getPartgridList = [];
    console.log('successful', this.partgridList);


    // after we get part get data , we have to check if the data is present in selcted data as secondary
    // if present remove the data from get data
    // else push is to get data
    if (this.partgridList.length != 0 && this.partgridList != null) {

      this.getPartgridList = [];
      if (this.selectedPartgridList.length != 0) {
        for (let ki = 0; ki < this.partgridList.length; ki++) {
          let prt_present = false
          for (let kj = 0; kj < this.selectedPartgridList.length; kj++) {
            if (this.selectedPartgridList[kj].bompartmasterid == this.partgridList[ki].bompartmasterid) {
              prt_present = true;
              break;
            }
          }


          if (prt_present == false) {

            this.getPartgridList.push(this.partgridList[ki]);

          }
        }
      }


      else {
        this.getPartgridList = this.partgridList
      }


      this.getpart_grid_text = '';
      this.getpart_present_table = true;

      this.getpart_cols = [
        { field: 'partnumbercommonized', header: 'Simplified Part Number' },
        { field: 'plantgsdbcode', header: 'Plant' },
        { field: 'programname', header: 'Program(s)' },
        { field: 'suppliergsdbcode', header:'Supplier GSDB'},
        { field: 'parteffectiveindate', header: 'Effective In Dates' },

      ];
    }


    else {
      this.getpart_present_table = false;
      this.getpart_cols = [];
      this.getpart_grid_text = 'No Match Found'
    }


  }






  // while clicking add after selecting parts from get data
  // to remove selected parts from get data
  // to add data to select grid/data

  addSelectedParts() {


    if (this.choosedGetPartGrid.length != 0) {
      console.log(this.choosedGetPartGrid);
      this.enablegrid4=true;

      let tempGetPartList: subgrid[] = [];
      if (this.choosedGetPartGrid.length == this.getPartgridList.length) {
        this.getPartgridList = [];

      }
      else if (this.choosedGetPartGrid.length != this.getPartgridList.length) {
        tempGetPartList = this.getPartgridList;
        this.getPartgridList = [];

        for (let mi = 0; mi < tempGetPartList.length; mi++) {

          let temp_present_part_value = false;

          for (let mj = 0; mj < this.choosedGetPartGrid.length; mj++) {


            if (tempGetPartList[mi].bompartmasterid == this.choosedGetPartGrid[mj].bompartmasterid) {
              temp_present_part_value = true;
              break;
            }

          }


          if (temp_present_part_value == false) {
            this.getPartgridList.push(tempGetPartList[mi]);
          }
        }

      }

      console.log(this.selectedPartgridList)




      for (let ni = 0; ni < this.choosedGetPartGrid.length; ni++) {
        console.log(this.selectedPartgridList)


        this.selectedPartgridList.push(this.choosedGetPartGrid[ni]);
        let select_prt_index;
        select_prt_index = this.selectedPartgridList.length - 1;
        console.log(select_prt_index)
        this.selectedPartgridList[select_prt_index].disable = false;

      }
      // push choosedGetPartGrid to grid4
      // call get details
      console.log('choosedgetpartgrid', this.choosedGetPartGrid);
      this.loadsecondaryparts(this.primarypartgridforpost.id_proposal,this.choosedGetPartGrid);



      this.choosedGetPartGrid = [];

    }
  }
  loadsecondaryparts(proposal_id:number,grid4values:any)
  {
      this.secondarypartreceivedfromgrid1=grid4values;
      console.log('secondarypartreceivedfromgrid1',this.secondarypartreceivedfromgrid1);

      let sec_part='';
      for(let part=0;part<this.secondarypartreceivedfromgrid1.length;part++)
      {
        sec_part+=JSON.parse(JSON.stringify(this.secondarypartreceivedfromgrid1[part])).bompartmasterid;

        if(part<this.secondarypartreceivedfromgrid1.length-1)
        {
          sec_part+=',';
        }
      }
      this.secondarypartsbomid=sec_part;

      console.log('secondarypartbomid',this.secondarypartsbomid);

      this.transactionService.getSelectedPartsDetails(this.secondarypartsbomid).subscribe(value=>{
        console.log('getsec_partsfromapi',value);
        this.secondarypartsgridforget=value;
        console.log('secondarypartsgridforget',this.secondarypartsgridforget);
        this.loadsecondarypartsforpost(proposal_id);
      });

  }

  loadsecondarypartsforpost(proposalid:number)
  {
    for(let i=this.secondarypartgridforpost.length ,j=0;j<this.secondarypartsgridforget.length && this.secondarypartsgridforget.length>0;i++,j++)
        {

        this.secondarypartgridforpost[i]=JSON.parse(JSON.stringify(this.secondarypartsgridforget[j]));
         this.secondarypartgridforpost[i].id_proposal=proposalid;
          this.secondarypartgridforpost[i].isprimary='no';
          this.secondarypartgridforpost[i].selorder=i+1;
          this.secondarypartgridforpost[i].txntimestamp=new Date().toISOString().split('.')[0];
          this.secondarypartgridforpost[i].ngpp_part_l=0;
          this.secondarypartgridforpost[i].ngpp_part_w=0;
          this.secondarypartgridforpost[i].ngpp_part_h=0;
          this.secondarypartgridforpost[i].ngpp_part_wt=0;
          this.secondarypartgridforpost[i].ngpp_part_wt_status='';
          this.secondarypartgridforpost[i].ngpp_ref_part_no='';
          this.secondarypartgridforpost[i].ngpp_manf_loc='';
        }
        console.log('this.secondarypartgridforpost',this.secondarypartgridforpost);
   this.on_change_isStampingBlank();

        this.secondarypartsgrid=[...this.secondarypartgridforpost];
  }






  // while clicking remove button
  // to remove data from selcected parts
  // to add parts to get parts list , removed parts should be added in the first of get data
  removeSelectedParts() {


    console.log(this.choosedSelectPartGrid)

    if (this.choosedSelectPartGrid.length != 0) {
      let tempSelectPartGrid: subgrid[] = [];
      tempSelectPartGrid = this.selectedPartgridList;
      this.selectedPartgridList = [];
      this.selectedPartgridList.push(tempSelectPartGrid[0]);
      this.selectedPartgridList[0].disable = true;

      console.log(this.selectedPartgridList);


      for (let oi = 1; oi < tempSelectPartGrid.length; oi++) {
        let temp_present_select_part_value = false;
        for (let oj = 0; oj < this.choosedSelectPartGrid.length; oj++) {

          if (tempSelectPartGrid[oi].bompartmasterid == this.choosedSelectPartGrid[oj].bompartmasterid) {
            temp_present_select_part_value = true;
            break;
          }

        }
        if (temp_present_select_part_value == false) {
          this.selectedPartgridList.push(tempSelectPartGrid[oi]);

        }
      }


      console.log(this.getPartgridList);


      let sort_temp_getpartgridlist: subgrid[] = [];
      sort_temp_getpartgridlist = this.getPartgridList;
      this.getPartgridList = [];


      for (let pi = 0; pi < this.choosedSelectPartGrid.length; pi++) {
        this.getPartgridList.push(this.choosedSelectPartGrid[pi]);
      }





      for (let pj = 0; pj < sort_temp_getpartgridlist.length; pj++) {
        this.getPartgridList.push(sort_temp_getpartgridlist[pj]);
      }

      // code to remove partnumbers(choosedSelectpartGrid) in db by calling API


      // write logic to remove the partnumbers(choosedSelectPartGrid) from grid4
      let idtoberemovedwithcommaseperated='';
      let partInDbRemoved = false;
      let iter=0;
      for(const partstoberemoved of this.choosedSelectPartGrid)
      {
        let index;

        let removalflag=false;
        const partremoval=partstoberemoved.bompartmasterid;
        if(iter<this.choosedSelectPartGrid.length)
        {
          idtoberemovedwithcommaseperated+=partremoval;
          iter=iter+1;
          if(iter<=this.choosedSelectPartGrid.length-1)
          {
              idtoberemovedwithcommaseperated+=',';
          }
          if(partremoval==this.selectedpart_submitter.bompartmasterid)
          {
            removalflag=true;
          }
        }



        let removingIdSelOrder:number;
        for(const valu of this.secondarypartgridforpost)
        {
          if(valu.bompartmasterid == partremoval)
          {

            index=this.secondarypartgridforpost.findIndex(a=>a==valu);

            removingIdSelOrder=-1
            if(index != -1 )
            {
              removingIdSelOrder=this.secondarypartgridforpost[index].selorder;
            }

            this.secondarypartgridforpost.splice(this.secondarypartgridforpost.findIndex(a=>a==valu),1);

            // this.loadsecondarypartsforpost(this.id_proposal);

            console.log(index);
            // console.log(this.secondarypartgridforpost[index],this.secondarypartgridforpost[index].selorder);
            // console.log(this.secondarypartgridforpost[index].selorder-1);

            for(let i=0 ; i<=this.secondarypartgridforpost.length-1;i++)
              {
                if(this.secondarypartgridforpost[i].selorder > removingIdSelOrder)
                {
                this.secondarypartgridforpost[i].selorder=this.secondarypartgridforpost[i].selorder-1;
                }
              }

            console.log('finalvalues',this.secondarypartgridforpost);
         }
        }
        if(this.secondarypartgridforpost.length==0)
        {
          this.enablegrid4=false;
        }

        if(!partInDbRemoved)
        {
            if(this.originalTab1Secondarygrid != null && this.originalTab1Secondarygrid.length != 0)
            {
             const indexPart = this.originalTab1Secondarygrid.findIndex(ele => ele.bompartmasterid == partremoval)
             if(indexPart != -1)
             {
               partInDbRemoved = true;
             }
            }
        }

      }
      this.secondarypartgridforpost=this.secondarypartgridforpost;
      this.secondarypartsgrid=[...this.secondarypartgridforpost];
      console.log('after removal of secondary parts',this.secondarypartgridforpost);
      console.log('idtoremovedwithcommaseperated',idtoberemovedwithcommaseperated);
      if(partInDbRemoved)
      {
          if(idtoberemovedwithcommaseperated != null && idtoberemovedwithcommaseperated.trim() != '')
          {
              // @ts-ignore
          this.removeParts(idtoberemovedwithcommaseperated,removalflag);
          }
     }

      this.choosedSelectPartGrid = [];

    }





  }

  removeParts(removalids:any,flag:boolean)
  {
    console.log('removeParts')
    this.transactionService.removeParts(removalids,this.selectedpart_submitter.proposalid).subscribe(remo=>{
      console.log('removePartsop',remo)
      if(remo=='Success')
      {
        console.log('Success',remo);




        this.transactionService.get_draft_selectedparts_data(this.selectedpart_submitter.proposalid).subscribe(selectedparts_data=>{


          console.log(selectedparts_data);
          // this.secondarypartgridforpost
          const secondarypartsAfterOrderChange:partDetailForPost[]=[]

          for(let ki=0;ki<selectedparts_data.length;ki++)
          {
            if(selectedparts_data[ki].isprimary.toLowerCase()!='yes')
            {
              const findSecPartIndex=this.secondarypartsgrid.findIndex(ele => ele.bompartmasterid == selectedparts_data[ki].bompartmasterid)
              if(findSecPartIndex != -1)
              {
                selectedparts_data[ki].selorder = this.secondarypartsgrid[findSecPartIndex].selorder;
                secondarypartsAfterOrderChange.push(selectedparts_data[ki]);
              }
            }
          }
          console.log(this.secondarypartgridforpost,this.secondarypartsgrid);
          console.log('secondarypartsAfterOrderChange',secondarypartsAfterOrderChange)
          if(secondarypartsAfterOrderChange != null && secondarypartsAfterOrderChange.length != 0)
          {
          this.transactionService.savePartDetails(secondarypartsAfterOrderChange).subscribe(saveattributes=>
            {
              const op=saveattributes;
              console.log('successful',op);
              if(saveattributes=='Success')
              {
                console.log('success while uploading sel order')
              }
              else
              {
                console.log('error while uploading sel order')
              }
            })
          }



        })

          /*this.transactionService.getproposalid().subscribe(sub_proposalid=>{


            console.log(sub_proposalid)
            this.selectedpart_submitter.proposalid=sub_proposalid;
            this.selectedpart_submitter.proposallastsaved=0;
            this.store_primary_part_exist();

            this.primarypartsgrid=[];
            this.primarypartsdetailsinit();

            this.secondarypartsgrid=[];
            this.secondarypartsgridforget=[];
            this.secondarypartgridforpost=[];
            this.enablegrid4=false;

            this.ngOnInit();
            this.packagingproposal.savedtab=0;
            this.packagingproposal.tabpackagingproposalmenuitems=[];
            this.packagingproposal.loadmenubar();


          });*/



        // this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:'Successfully deleted part from db'});
      }
      else{
        this.packagingproposal.addtoast({severity:'error', summary:'Error', detail:'Error In removing additional part'});
      }
    })
  }


  isCopyPartsEnable():boolean
  {


    if(this.primarypartsgrid.length!=0 && this.secondarypartsgrid.length !=0)
    {
      return true;
    }


    else{
      return false;
    }


  }



copyPrimaryToSecondaryParts()
{
  if(this.secondarypartsgrid.length!=0)
  {
    console.log('inside copy parts ',this.primarypartsgrid)
    console.log(this.secondarypartsgrid);


    for(let icopy=0;icopy<this.secondarypartsgrid.length;icopy++)
    {


      this.secondarypartsgrid[icopy].ngpp_part_l=this.primarypartsgrid[0].ngpp_part_l;
      this.secondarypartsgrid[icopy].ngpp_part_w=this.primarypartsgrid[0].ngpp_part_w;
      this.secondarypartsgrid[icopy].ngpp_part_h=this.primarypartsgrid[0].ngpp_part_h;
      this.secondarypartsgrid[icopy].ngpp_part_wt=this.primarypartsgrid[0].ngpp_part_wt;
      this.secondarypartsgrid[icopy].ngpp_part_wt_status=this.primarypartsgrid[0].ngpp_part_wt_status;
      this.secondarypartsgrid[icopy].ngpp_ref_part_no=this.primarypartsgrid[0].ngpp_ref_part_no;
      this.secondarypartsgrid[icopy].ngpp_manf_loc=this.primarypartsgrid[0].ngpp_manf_loc;



    }
    this.messageService.add({severity:'success', summary:'Success', detail:'Successfully copied values'});
  }

}


store_primary_part_exist()
{

    if(this.current_region_code_prt=='NA')
    {

      sessionStorage.setItem('napackpropart', JSON.stringify(this.selectedpart_submitter));
    }


    if(this.current_region_code_prt=='EU')
    {
      sessionStorage.setItem('eupackpropart', JSON.stringify(this.selectedpart_submitter));
    }



    if(this.current_region_code_prt=='AP')
    {
      sessionStorage.setItem('appackpropart', JSON.stringify(this.selectedpart_submitter));
    }



    if(this.current_region_code_prt=='SA')
    {
      sessionStorage.setItem('sapackpropart', JSON.stringify(this.selectedpart_submitter));
    }
}







  saveThisAndEnableOtherForms()
  {
    this.region = this.transactionService.getregion();

    // this.primarypartsattributes.corr_protc=this.primarypartsattributes.corr_protc.prot;
    // console.log("this.primarypartsattributes.corr_protc",this.primarypartsattributes.corr_protc);
    if(this.primarypartsattributes.lst_saved_tab < 1)
    {
      this.primarypartsattributes.lst_saved_tab=1;
    }

    this.primarypartsattributes.submit_status='No';
    this.primarypartsattributes.isstampblank=this.isStampingBlank;
    this.primarypartsattributes.timestamp=new Date().toISOString().split('.')[0];
    this.primarypartsattributes.corr_protc=this.selected_corrosion_protection.corsn_prot_type;
    /// changed
    this.primarypartsattributes.propsl_type=this.selectedpart_submitter.propsl_type;

    this.primarypartsattributes.supgsdbcode=this.selected_supp_details.supgsdbcode
    this.primarypartsattributes.supfullname=this.selected_supp_details.fullname;
    this.primarypartsattributes.supaddress1=this.selected_supp_details.address1;
    this.primarypartsattributes.supcity=this.selected_supp_details.city;
    this.primarypartsattributes.supstateprov=this.selected_supp_details.stateprovince;
    this.primarypartsattributes.supcountrycode=this.selected_supp_details.countrycode;
    this.primarypartsattributes.supcontactnamefirst=this.selected_supp_details.contactnamefirst;
    this.primarypartsattributes.supcontactname=this.selected_supp_details.contactname;
    this.primarypartsattributes.supcontactfunctn=this.selected_supp_details.contactfunctn;
    this.primarypartsattributes.supcontactemail=this.selected_supp_details.contactemail;
    this.primarypartsattributes.supcontactphone=this.selected_supp_details.contactphone;
    this.primarypartsattributes.supcontactfax=this.selected_supp_details.contactfax;
    this.primarypartsattributes.defaultcurr=this.selected_supp_details.defaultcurr;
    this.primarypartsattributes.shelf_life = this.selectedShelfLife.value;
    


    console.log('primarypart attributes',this.primarypartsattributes);
    console.log('primaryparts',this.primarypartsgrid);
    console.log('secondaryparts',this.secondarypartsgrid);


    if(this.unitom == 'E')
      {
        this.edit_measure_part = 'lbs/inch';
      }
      else
      {
        this.edit_measure_part = 'kg/cm';
      }

    console.log(this.edit_measure_part);
    // if(this.edit_measure_part=="kg/cm")
    //   {
    //     console.log(this.primarypartsgrid);
    //     console.log(this.secondarypartsgrid);


    //     if(this.primarypartsgrid[0].ngpp_part_l!=null)
    //     {
    //       this.primarypartsgrid[0].ngpp_part_l=Math.round((0.393701*this.primarypartsgrid[0].ngpp_part_l)*1000)/1000;

    //     }
    //     if(this.primarypartsgrid[0].ngpp_part_w!=null)
    //     {
    //       this.primarypartsgrid[0].ngpp_part_w=Math.round((0.393701*this.primarypartsgrid[0].ngpp_part_w)*1000)/1000;

    //     }
    //     if(this.primarypartsgrid[0].ngpp_part_h!=null)
    //     {
    //       this.primarypartsgrid[0].ngpp_part_h=Math.round((0.393701*this.primarypartsgrid[0].ngpp_part_h)*1000)/1000;

    //     }
    //     if(this.primarypartsgrid[0].ngpp_part_wt!=null)
    //     {
    //       this.primarypartsgrid[0].ngpp_part_wt=Math.round((2.20462*this.primarypartsgrid[0].ngpp_part_wt)*1000)/1000;

    //     }



    //     if(this.secondarypartsgrid.length!=0)
    //     {


    //       for(var sec=0;sec<this.secondarypartsgrid.length;sec++)
    //       {


    //         if(this.secondarypartsgrid[sec].ngpp_part_l!=null)
    //         {
    //           this.secondarypartsgrid[sec].ngpp_part_l=Math.round((0.393701*this.secondarypartsgrid[sec].ngpp_part_l)*1000)/1000;

    //         }
    //         if(this.secondarypartsgrid[sec].ngpp_part_w!=null)
    //         {
    //           this.secondarypartsgrid[sec].ngpp_part_w=Math.round((0.393701*this.secondarypartsgrid[sec].ngpp_part_w)*1000)/1000;

    //         }
    //         if(this.secondarypartsgrid[sec].ngpp_part_h!=null)
    //         {
    //           this.secondarypartsgrid[sec].ngpp_part_h=Math.round((0.393701*this.secondarypartsgrid[sec].ngpp_part_h)*1000)/1000;

    //         }
    //         if(this.secondarypartsgrid[sec].ngpp_part_wt!=null)
    //         {
    //           this.secondarypartsgrid[sec].ngpp_part_wt=Math.round((2.20462*this.secondarypartsgrid[sec].ngpp_part_wt)*1000)/1000;

    //         }


    //       }

    //     }

    //   }

    console.log('primarypart attributes',this.primarypartsattributes);
    console.log('primaryparts',this.primarypartsgrid);
    console.log('secondaryparts',this.secondarypartsgrid);

    this.pripartlenValidation();
    this.pripartwidValidation();
    this.priparthgtValidation();
    this.pripartwgtValidation();
    this.corrosionProtectionValidation();
    this.shelfLifeValidation()
   
   
//secondary parts validation 
  this.enablesave = true;
    for(let i=0;i<this.secondarypartsgrid.length;i++)
    {
      console.log('SECOPIP',this.secondarypartsgrid[i]);

      this.secpartlenValidation(this.secondarypartsgrid[i]);
      this.secpartwidValidation(this.secondarypartsgrid[i]);
      this.secparthgtValidation(this.secondarypartsgrid[i]);
      this.secpartwgtValidation(this.secondarypartsgrid[i]);

      if(this.enablesave == true)
    {
      if(this.secondarypartsgrid[i].ngpp_part_l_invalid || this.secondarypartsgrid[i].ngpp_part_w_invalid
        || this.secondarypartsgrid[i].ngpp_part_h_invalid || this.secondarypartsgrid[i].ngpp_part_wt_invalid)
      {
        this.enablesave = false;
      }
    }

    //   if(this.secondarypartsgrid[i].ngpp_part_l == 0 || this.secondarypartsgrid[i].ngpp_part_l == null)
    // {
    //   // this.secondarypartgridforpost[i]=this.secondarypartsgrid[i];
    //   this.secondarypartsgrid[i].ngpp_part_l_invalid = true;
    //   this.secondarypartsgrid[i].ngpp_part_l_invalid_reasn = "Required";
    //   this.enablesave = false;
    // }

    // if(this.secondarypartsgrid[i].ngpp_part_w == 0 || this.secondarypartsgrid[i].ngpp_part_w == null)
    // {
    //   this.secondarypartsgrid[i].ngpp_part_w_invalid = true;
    //   this.secondarypartsgrid[i].ngpp_part_w_invalid_reasn = "Required";
    //   this.enablesave = false;
    // }
    // if(this.secondarypartsgrid[i].ngpp_part_h == 0 || this.secondarypartsgrid[i].ngpp_part_h == null)
    // {
    //   this.secondarypartsgrid[i].ngpp_part_h_invalid = true;
    //   this.secondarypartsgrid[i].ngpp_part_h_invalid_reasn = "Required";
    //   this.enablesave = false;
    // }
    // if(this.secondarypartsgrid[i].ngpp_part_wt == 0 || this.secondarypartsgrid[i].ngpp_part_wt == null)
    // {
    //   this.secondarypartsgrid[i].ngpp_part_wt_invalid = true;
    //   this.secondarypartsgrid[i].ngpp_part_wt_invalid_reasn = "Required";
    //   this.enablesave = false;
    // }

    }

    // }
    // enablesave

    if(!this.pripartlenInvalid && !this.pripartwidInvalid && !this.priparthgtInvalid && !this.pripartwgtInvalid && this.enablesave && !this.corrosionProtectionInvalid && !this.shelfLifeInvalid)
    {
      // secondary parts validation
      this.secondarypartgridforpostAPI=[];
      if(this.secondarypartsgrid.length != 0)
      {
        for(let i=0;i<this.secondarypartsgrid.length;i++)
        {
          console.log('SECHECK',this.secondarypartsgrid)
        this.secondarypartgridforpostAPI.push({
            bompartmasterid: this.secondarypartsgrid[i].bompartmasterid,
            buyercode: this.secondarypartsgrid[i].buyercode,
            id_proposal: this.secondarypartsgrid[i].id_proposal,
          plantgsdbcode: this.secondarypartsgrid[i].plantgsdbcode,
            supplierid: this.secondarypartsgrid[i].supplierid,
              cd_region: this.secondarypartsgrid[i].cd_region,
            partsubmitergsdbcodeid: this.secondarypartsgrid[i].partsubmitergsdbcodeid,
            partintrobomuploadbatchid:this.secondarypartsgrid[i].partintrobomuploadbatchid,
            lastseenonbomuploadbatchid:this.secondarypartsgrid[i].lastseenonbomuploadbatchid,
            partintrodate: this.secondarypartsgrid[i].partintrodate,
            plantname: this.secondarypartsgrid[i].plantname,
            programname: this.secondarypartsgrid[i].programname,
            partprefix: this.secondarypartsgrid[i].partprefix,
            partbase: this.secondarypartsgrid[i].partbase,
            partsuffix:this.secondarypartsgrid[i].partsuffix,
            partcontrol: this.secondarypartsgrid[i].partcontrol,
            cpsccode: this.secondarypartsgrid[i].cpsccode,
            partdesc: this.secondarypartsgrid[i].partdesc,
            partstatus: this.secondarypartsgrid[i].partstatus,
            parttype: this.secondarypartsgrid[i].parttype,
            controlitem: this.secondarypartsgrid[i].controlitem,
            commonalitycode: this.secondarypartsgrid[i].commonalitycode,
            partaddedfrom: this.secondarypartsgrid[i].partaddedfrom,
            form1271requested: this.secondarypartsgrid[i].form1271requested,
            cntrsuggestedownershpstrat: this.secondarypartsgrid[i].cntrsuggestedownershpstrat,
            offsitesupplierid: this.secondarypartsgrid[i].offsitesupplierid,
            containerdesignsourceid: this.secondarypartsgrid[i].containerdesignsourceid,
            transtimeindays: this.secondarypartsgrid[i].transtimeindays,
            parteffectiveindate: this.secondarypartsgrid[i].parteffectiveindate,
            s4pincode: this.secondarypartsgrid[i].s4pincode,
            parteffectiveoutdate: this.secondarypartsgrid[i].parteffectiveoutdate,
            s4poutcode: this.secondarypartsgrid[i].s4poutcode,
            carlinecode: this.secondarypartsgrid[i].carlinecode,
            ptotype: this.secondarypartsgrid[i].ptotype,
            cmmspackagingconfirmcode: this.secondarypartsgrid[i].cmmspackagingconfirmcode,
            cmmscontainerbase: this.secondarypartsgrid[i].cmmscontainerbase,
            cmmscontainersuffix: this.secondarypartsgrid[i].cmmscontainersuffix,
            cmmscomponent1base: this.secondarypartsgrid[i].cmmscomponent1base,
            cmmscomponent1suffix: this.secondarypartsgrid[i].cmmscomponent1suffix,
            cmmscomponent2base: this.secondarypartsgrid[i].cmmscomponent2base,
            cmmscomponent2suffix: this.secondarypartsgrid[i].cmmscomponent2suffix,
            cmmscontainerpieses: this.secondarypartsgrid[i].cmmscontainerpieses,
            cmmscontainerextl: this.secondarypartsgrid[i].cmmscontainerextl,
            cmmscontainerextw: this.secondarypartsgrid[i].cmmscontainerextw,
            cmmscontainerexth: this.secondarypartsgrid[i].cmmscontainerexth,
            cmmscontainergrosswt:this.secondarypartsgrid[i].cmmscontainergrosswt,
            cmmscontainersperlayer: this.secondarypartsgrid[i].cmmscontainersperlayer,
            cmmslayerspershipunit: this.secondarypartsgrid[i].cmmslayerspershipunit,
            cmmsshipunitnumpieses: this.secondarypartsgrid[i].cmmsshipunitnumpieses,
            cmmsshipunitextl: this.secondarypartsgrid[i].cmmsshipunitextl,
            cmmsshipunitextw: this.secondarypartsgrid[i].cmmsshipunitextw,
            cmmsshipunitexth: this.secondarypartsgrid[i].cmmsshipunitexth,
            cmmsshipunitgrosswt: this.secondarypartsgrid[i].cmmsshipunitgrosswt,
            cmmspackagingreleaselevel: this.secondarypartsgrid[i].cmmspackagingreleaselevel,
            partnumbercommonized: this.secondarypartsgrid[i].partnumbercommonized,
            tradingcogsdbcode: this.secondarypartsgrid[i].tradingcogsdbcode,
            ismissingpart: this.secondarypartsgrid[i].ismissingpart,
            suppliercountrycodeiso: this.secondarypartsgrid[i].suppliercountrycodeiso,
            suppliergsdbcode: this.secondarypartsgrid[i].suppliergsdbcode,
            isprimary: this.secondarypartsgrid[i].isprimary,
            selorder: this.secondarypartsgrid[i].selorder,
            txntimestamp: this.secondarypartsgrid[i].txntimestamp,
            ngpp_part_l: this.secondarypartsgrid[i].ngpp_part_l,
            ngpp_part_w: this.secondarypartsgrid[i].ngpp_part_w,
            ngpp_part_h: this.secondarypartsgrid[i].ngpp_part_h,
            ngpp_part_wt: this.secondarypartsgrid[i].ngpp_part_wt,
            ngpp_part_wt_status: this.secondarypartsgrid[i].ngpp_part_wt_status,
            ngpp_ref_part_no: this.secondarypartsgrid[i].ngpp_ref_part_no,
            ngpp_manf_loc: this.secondarypartsgrid[i].ngpp_manf_loc,
            partid: this.secondarypartsgrid[i].partid
          })
        }
      }
      console.log('secondarypartgridforpostAPI',this.secondarypartgridforpostAPI)

      // if(!this.enablesave)
      // {
      this.transactionService.savePrimaryPartDetails(this.primarypartsattributes).subscribe(saveattributes=>
      {
        const op=saveattributes;
        console.log('successful',op);
        if(saveattributes=='Success')
        {
          console.log('primarypart attributes in save',this.primarypartsattributes);
          // this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:'primary part and attributes Added Successfully'});

          this.transactionService.savePartDetails(this.primarypartsgrid).subscribe(getprimary=>
            {
              let check=getprimary;
              if(check='Success')
              {
                console.log('primarypart in save',this.primarypartsgrid);
                // this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:'primary part  Added Successfully'});
                this.transactionService.savePartDetails(this.secondarypartgridforpostAPI).subscribe(saveattributes=>
                  {
                    const op=saveattributes;
                    console.log('successful',op);
                    if(saveattributes=='Success')
                    {
                      console.log('secondary attributes in save',this.secondarypartgridforpostAPI);
                      const currentab=1;
                      if(this.selectedpart_submitter.proposallastsaved<currentab)
                      {
                      this.selectedpart_submitter.proposallastsaved=1;
                      this.selectedpart_submitter.part_status='UnSubmitted';
                      this.selectedpart_submitter.proposallastsaved_ee=1;
                      this.store_primary_part_exist()
                      }

                      this.packagingproposal.addtoast({severity:'success', summary:'success', detail:'Parts and Attributes Saved Successfully'});
                      this.navigatetonexttab();
                    }
                    else
                    {
                      this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Additional Parts Not Added,Please Try Again'});
                      // if(this.edit_measure_part=="kg/cm")
                      // {


                      //   console.log(this.primarypartsgrid);
                      //   console.log(this.secondarypartsgrid);


                      //   if(this.primarypartsgrid[0].ngpp_part_l!=null)
                      //   {
                      //     this.primarypartsgrid[0].ngpp_part_l=Math.round((2.54*this.primarypartsgrid[0].ngpp_part_l)*1000)/1000;

                      //   }
                      //   if(this.primarypartsgrid[0].ngpp_part_w!=null)
                      //   {
                      //     this.primarypartsgrid[0].ngpp_part_w=Math.round((2.54*this.primarypartsgrid[0].ngpp_part_w)*1000)/1000;

                      //   }
                      //   if(this.primarypartsgrid[0].ngpp_part_h!=null)
                      //   {
                      //     this.primarypartsgrid[0].ngpp_part_h=Math.round((2.54*this.primarypartsgrid[0].ngpp_part_h)*1000)/1000;

                      //   }
                      //   if(this.primarypartsgrid[0].ngpp_part_wt!=null)
                      //   {
                      //     this.primarypartsgrid[0].ngpp_part_wt=Math.round((0.453592*this.primarypartsgrid[0].ngpp_part_wt)*1000)/1000;

                      //   }



                      //   if(this.secondarypartsgrid.length!=0)
                      //   {


                      //     for(var sec=0;sec<this.secondarypartsgrid.length;sec++)
                      //     {


                      //       if(this.secondarypartsgrid[sec].ngpp_part_l!=null)
                      //       {
                      //         this.secondarypartsgrid[sec].ngpp_part_l=Math.round((2.54*this.secondarypartsgrid[sec].ngpp_part_l)*1000)/1000;

                      //       }
                      //       if(this.secondarypartsgrid[sec].ngpp_part_w!=null)
                      //       {
                      //         this.secondarypartsgrid[sec].ngpp_part_w=Math.round((2.54*this.secondarypartsgrid[sec].ngpp_part_w)*1000)/1000;

                      //       }
                      //       if(this.secondarypartsgrid[sec].ngpp_part_h!=null)
                      //       {
                      //         this.secondarypartsgrid[sec].ngpp_part_h=Math.round((2.54*this.secondarypartsgrid[sec].ngpp_part_h)*1000)/1000;

                      //       }
                      //       if(this.secondarypartsgrid[sec].ngpp_part_wt!=null)
                      //       {
                      //         this.secondarypartsgrid[sec].ngpp_part_wt=Math.round((0.453592*this.secondarypartsgrid[sec].ngpp_part_wt)*1000)/1000;

                      //       }


                      //     }

                      //   }


                      // }
                    }

                });
              }
              else
              {
                this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Primary Part  Not Added,Please Try Again'})
                // if(this.edit_measure_part=="kg/cm")
                // {


                //   console.log(this.primarypartsgrid);
                //   console.log(this.secondarypartsgrid);


                //   if(this.primarypartsgrid[0].ngpp_part_l!=null)
                //   {
                //     this.primarypartsgrid[0].ngpp_part_l=Math.round((2.54*this.primarypartsgrid[0].ngpp_part_l)*1000)/1000;

                //   }
                //   if(this.primarypartsgrid[0].ngpp_part_w!=null)
                //   {
                //     this.primarypartsgrid[0].ngpp_part_w=Math.round((2.54*this.primarypartsgrid[0].ngpp_part_w)*1000)/1000;

                //   }
                //   if(this.primarypartsgrid[0].ngpp_part_h!=null)
                //   {
                //     this.primarypartsgrid[0].ngpp_part_h=Math.round((2.54*this.primarypartsgrid[0].ngpp_part_h)*1000)/1000;

                //   }
                //   if(this.primarypartsgrid[0].ngpp_part_wt!=null)
                //   {
                //     this.primarypartsgrid[0].ngpp_part_wt=Math.round((0.453592*this.primarypartsgrid[0].ngpp_part_wt)*1000)/1000;

                //   }



                //   if(this.secondarypartsgrid.length!=0)
                //   {


                //     for(var sec=0;sec<this.secondarypartsgrid.length;sec++)
                //     {


                //       if(this.secondarypartsgrid[sec].ngpp_part_l!=null)
                //       {
                //         this.secondarypartsgrid[sec].ngpp_part_l=Math.round((2.54*this.secondarypartsgrid[sec].ngpp_part_l)*1000)/1000;

                //       }
                //       if(this.secondarypartsgrid[sec].ngpp_part_w!=null)
                //       {
                //         this.secondarypartsgrid[sec].ngpp_part_w=Math.round((2.54*this.secondarypartsgrid[sec].ngpp_part_w)*1000)/1000;

                //       }
                //       if(this.secondarypartsgrid[sec].ngpp_part_h!=null)
                //       {
                //         this.secondarypartsgrid[sec].ngpp_part_h=Math.round((2.54*this.secondarypartsgrid[sec].ngpp_part_h)*1000)/1000;

                //       }
                //       if(this.secondarypartsgrid[sec].ngpp_part_wt!=null)
                //       {
                //         this.secondarypartsgrid[sec].ngpp_part_wt=Math.round((0.453592*this.secondarypartsgrid[sec].ngpp_part_wt)*1000)/1000;

                //       }


                //     }

                //   }


                // }
              }
            });
        }
        else{
          this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Primary Part Attributes Not Added,Please Try Again'})
        }
      });
      // }
    }

}

navigatetonexttab()
{
   /* code added for packaging proposal menu creation */

   this.name=this.transactionService.getregionNameInSmallLetters();
   this.tab2='/'+this.name+'/packagingprocess/proposalcreation/packproposaltabtwo';
   const currentab=1;
   if(currentab>this.packagingproposal.savedtab)
   {
     this.packagingproposal.savedtab=currentab;
     console.log('saved tab set as 1');
     // this.transactionService.storelastsavedtab(this.packagingproposal.savedtab,this.region);
     // this.transactionService.storepage(this.region,'packagingprocess/proposalcreation/partsandattri');
   }

   this.tab2menuitem={label: 'COS, Container & Shipping Unit', routerLink: this.tab2, icon: 'fas fa-cubes'};
   for(const i of this.packagingproposal.tabpackagingproposalmenuitems.values())
   {
       console.log(i);
       if(i.label!='COS, Container & Shipping Unit')
       {
           this.flag=true;
       }
       else{
           this.flag=false;
           break;
       }
   }

   if(this.flag)
   {
     this.packagingproposal.tabpackagingproposalmenuitems.push(this.tab2menuitem);
   }

   this.packagingproposal.setActiveItemDynamically(this.tab2,this.tab2menuitem);



 /* code added for packaging proposal menu creation ends */

}

SaveTab()
{
  this.region = this.transactionService.getregion();
  this.name=this.transactionService.getregionNameInSmallLetters();

    if(this.primarypartsattributes.lst_saved_tab < 1)
    {
      this.primarypartsattributes.lst_saved_tab = 1;
    }
    this.primarypartsattributes.submit_status='No';
    this.primarypartsattributes.isstampblank=this.isStampingBlank;
    this.primarypartsattributes.timestamp=new Date().toISOString().split('.')[0];
    this.primarypartsattributes.corr_protc=this.selected_corrosion_protection.corsn_prot_type;
    // changed
    this.primarypartsattributes.propsl_type=this.selectedpart_submitter.propsl_type;


    this.primarypartsattributes.supgsdbcode=this.selected_supp_details.supgsdbcode
    this.primarypartsattributes.supfullname=this.selected_supp_details.fullname;
    this.primarypartsattributes.supaddress1=this.selected_supp_details.address1;
    this.primarypartsattributes.supcity=this.selected_supp_details.city;
    this.primarypartsattributes.supstateprov=this.selected_supp_details.stateprovince;
    this.primarypartsattributes.supcountrycode=this.selected_supp_details.countrycode;
    this.primarypartsattributes.supcontactnamefirst=this.selected_supp_details.contactnamefirst;
    this.primarypartsattributes.supcontactname=this.selected_supp_details.contactname;
    this.primarypartsattributes.supcontactfunctn=this.selected_supp_details.contactfunctn;
    this.primarypartsattributes.supcontactemail=this.selected_supp_details.contactemail;
    this.primarypartsattributes.supcontactphone=this.selected_supp_details.contactphone;
    this.primarypartsattributes.supcontactfax=this.selected_supp_details.contactfax;
    this.primarypartsattributes.defaultcurr=this.selected_supp_details.defaultcurr;
    this.primarypartsattributes.shelf_life = this.selectedShelfLife.value;


    console.log(this.edit_measure_part);
    // if(this.edit_measure_part=="kg/cm")
    //   {
    //     console.log(this.primarypartsgrid);
    //     console.log(this.secondarypartsgrid);

    //     if(this.primarypartsgrid[0].ngpp_part_l!=null)
    //     {
    //       this.primarypartsgrid[0].ngpp_part_l=Math.round((0.393701*this.primarypartsgrid[0].ngpp_part_l)*1000)/1000;

    //     }
    //     if(this.primarypartsgrid[0].ngpp_part_w!=null)
    //     {
    //       this.primarypartsgrid[0].ngpp_part_w=Math.round((0.393701*this.primarypartsgrid[0].ngpp_part_w)*1000)/1000;

    //     }
    //     if(this.primarypartsgrid[0].ngpp_part_h!=null)
    //     {
    //       this.primarypartsgrid[0].ngpp_part_h=Math.round((0.393701*this.primarypartsgrid[0].ngpp_part_h)*1000)/1000;

    //     }
    //     if(this.primarypartsgrid[0].ngpp_part_wt!=null)
    //     {
    //       this.primarypartsgrid[0].ngpp_part_wt=Math.round((2.20462*this.primarypartsgrid[0].ngpp_part_wt)*1000)/1000;

    //     }

    //     if(this.secondarypartsgrid.length!=0)
    //     {
    //       for(var sec=0;sec<this.secondarypartsgrid.length;sec++)
    //       {
    //         if(this.secondarypartsgrid[sec].ngpp_part_l!=null)
    //         {
    //           this.secondarypartsgrid[sec].ngpp_part_l=Math.round((0.393701*this.secondarypartsgrid[sec].ngpp_part_l)*1000)/1000;
    //         }
    //         if(this.secondarypartsgrid[sec].ngpp_part_w!=null)
    //         {
    //           this.secondarypartsgrid[sec].ngpp_part_w=Math.round((0.393701*this.secondarypartsgrid[sec].ngpp_part_w)*1000)/1000;
    //         }
    //         if(this.secondarypartsgrid[sec].ngpp_part_h!=null)
    //         {
    //           this.secondarypartsgrid[sec].ngpp_part_h=Math.round((0.393701*this.secondarypartsgrid[sec].ngpp_part_h)*1000)/1000;
    //         }
    //         if(this.secondarypartsgrid[sec].ngpp_part_wt!=null)
    //         {
    //           this.secondarypartsgrid[sec].ngpp_part_wt=Math.round((2.20462*this.secondarypartsgrid[sec].ngpp_part_wt)*1000)/1000;
    //         }
    //       }
    //     }
    //   }
    console.log('primarypart attributes',this.primarypartsattributes);
    console.log('primaryparts',this.primarypartsgrid);
    console.log('secondaryparts',this.secondarypartsgrid);

    this.pripartlenValidation();
    this.pripartwidValidation();
    this.priparthgtValidation();
    this.pripartwgtValidation();
    this.corrosionProtectionValidation();
    this.shelfLifeValidation();

    // secondary parts validation
  this.enablesave = true;
  for(let i=0;i<this.secondarypartsgrid.length;i++)
  {

    this.secpartlenValidation(this.secondarypartsgrid[i]);
    this.secpartwidValidation(this.secondarypartsgrid[i]);
    this.secparthgtValidation(this.secondarypartsgrid[i]);
    this.secpartwgtValidation(this.secondarypartsgrid[i]);

    if(this.enablesave == true)
    {
      if(this.secondarypartsgrid[i].ngpp_part_l_invalid || this.secondarypartsgrid[i].ngpp_part_w_invalid
        || this.secondarypartsgrid[i].ngpp_part_h_invalid || this.secondarypartsgrid[i].ngpp_part_wt_invalid)
      {
        this.enablesave = false;
      }
    }

  //   if(this.secondarypartsgrid[i].ngpp_part_l == 0 || this.secondarypartsgrid[i].ngpp_part_l == null)
  // {
  //   // this.secondarypartgridforpost[i]=this.secondarypartsgrid[i];
  //   this.secondarypartsgrid[i].ngpp_part_l_invalid = true;
  //   this.secondarypartsgrid[i].ngpp_part_l_invalid_reasn = "Required";
  //   this.enablesave = false;
  // }
  // if(this.secondarypartsgrid[i].ngpp_part_w == 0 || this.secondarypartsgrid[i].ngpp_part_w == null)
  // {
  //   this.secondarypartsgrid[i].ngpp_part_w_invalid = true;
  //   this.secondarypartsgrid[i].ngpp_part_w_invalid_reasn = "Required";
  //   this.enablesave = false;
  // }
  // if(this.secondarypartsgrid[i].ngpp_part_h == 0 || this.secondarypartsgrid[i].ngpp_part_h == null)
  // {
  //   this.secondarypartsgrid[i].ngpp_part_h_invalid = true;
  //   this.secondarypartsgrid[i].ngpp_part_h_invalid_reasn = "Required";
  //   this.enablesave = false;
  // }
  // if(this.secondarypartsgrid[i].ngpp_part_wt == 0 || this.secondarypartsgrid[i].ngpp_part_wt == null)
  // {
  //   this.secondarypartsgrid[i].ngpp_part_wt_invalid = true;
  //   this.secondarypartsgrid[i].ngpp_part_wt_invalid_reasn = "Required";
  //   this.enablesave = false;
  // }

  }

  // }


  console.log('secondarypartgridforpostAPI',this.secondarypartgridforpostAPI)

  if(!this.pripartlenInvalid && !this.pripartwidInvalid && !this.priparthgtInvalid && !this.pripartwgtInvalid && this.enablesave && !this.corrosionProtectionInvalid && !this.shelfLifeInvalid)
  {
    // secondary parts validation
    this.secondarypartgridforpostAPI=[];
    if(this.secondarypartsgrid.length != 0)
    {
      for(let i=0;i<this.secondarypartsgrid.length;i++)
      {
        console.log('SECHECK',this.secondarypartsgrid)
      this.secondarypartgridforpostAPI.push({
          bompartmasterid: this.secondarypartsgrid[i].bompartmasterid,
          buyercode: this.secondarypartsgrid[i].buyercode,
          id_proposal: this.secondarypartsgrid[i].id_proposal,
        plantgsdbcode: this.secondarypartsgrid[i].plantgsdbcode,
          supplierid: this.secondarypartsgrid[i].supplierid,
            cd_region: this.secondarypartsgrid[i].cd_region,
          partsubmitergsdbcodeid: this.secondarypartsgrid[i].partsubmitergsdbcodeid,
          partintrobomuploadbatchid:this.secondarypartsgrid[i].partintrobomuploadbatchid,
          lastseenonbomuploadbatchid:this.secondarypartsgrid[i].lastseenonbomuploadbatchid,
          partintrodate: this.secondarypartsgrid[i].partintrodate,
          plantname: this.secondarypartsgrid[i].plantname,
          programname: this.secondarypartsgrid[i].programname,
          partprefix: this.secondarypartsgrid[i].partprefix,
          partbase: this.secondarypartsgrid[i].partbase,
          partsuffix:this.secondarypartsgrid[i].partsuffix,
          partcontrol: this.secondarypartsgrid[i].partcontrol,
          cpsccode: this.secondarypartsgrid[i].cpsccode,
          partdesc: this.secondarypartsgrid[i].partdesc,
          partstatus: this.secondarypartsgrid[i].partstatus,
          parttype: this.secondarypartsgrid[i].parttype,
          controlitem: this.secondarypartsgrid[i].controlitem,
          commonalitycode: this.secondarypartsgrid[i].commonalitycode,
          partaddedfrom: this.secondarypartsgrid[i].partaddedfrom,
          form1271requested: this.secondarypartsgrid[i].form1271requested,
          cntrsuggestedownershpstrat: this.secondarypartsgrid[i].cntrsuggestedownershpstrat,
          offsitesupplierid: this.secondarypartsgrid[i].offsitesupplierid,
          containerdesignsourceid: this.secondarypartsgrid[i].containerdesignsourceid,
          transtimeindays: this.secondarypartsgrid[i].transtimeindays,
          parteffectiveindate: this.secondarypartsgrid[i].parteffectiveindate,
          s4pincode: this.secondarypartsgrid[i].s4pincode,
          parteffectiveoutdate: this.secondarypartsgrid[i].parteffectiveoutdate,
          s4poutcode: this.secondarypartsgrid[i].s4poutcode,
          carlinecode: this.secondarypartsgrid[i].carlinecode,
          ptotype: this.secondarypartsgrid[i].ptotype,
          cmmspackagingconfirmcode: this.secondarypartsgrid[i].cmmspackagingconfirmcode,
          cmmscontainerbase: this.secondarypartsgrid[i].cmmscontainerbase,
          cmmscontainersuffix: this.secondarypartsgrid[i].cmmscontainersuffix,
          cmmscomponent1base: this.secondarypartsgrid[i].cmmscomponent1base,
          cmmscomponent1suffix: this.secondarypartsgrid[i].cmmscomponent1suffix,
          cmmscomponent2base: this.secondarypartsgrid[i].cmmscomponent2base,
          cmmscomponent2suffix: this.secondarypartsgrid[i].cmmscomponent2suffix,
          cmmscontainerpieses: this.secondarypartsgrid[i].cmmscontainerpieses,
          cmmscontainerextl: this.secondarypartsgrid[i].cmmscontainerextl,
          cmmscontainerextw: this.secondarypartsgrid[i].cmmscontainerextw,
          cmmscontainerexth: this.secondarypartsgrid[i].cmmscontainerexth,
          cmmscontainergrosswt:this.secondarypartsgrid[i].cmmscontainergrosswt,
          cmmscontainersperlayer: this.secondarypartsgrid[i].cmmscontainersperlayer,
          cmmslayerspershipunit: this.secondarypartsgrid[i].cmmslayerspershipunit,
          cmmsshipunitnumpieses: this.secondarypartsgrid[i].cmmsshipunitnumpieses,
          cmmsshipunitextl: this.secondarypartsgrid[i].cmmsshipunitextl,
          cmmsshipunitextw: this.secondarypartsgrid[i].cmmsshipunitextw,
          cmmsshipunitexth: this.secondarypartsgrid[i].cmmsshipunitexth,
          cmmsshipunitgrosswt: this.secondarypartsgrid[i].cmmsshipunitgrosswt,
          cmmspackagingreleaselevel: this.secondarypartsgrid[i].cmmspackagingreleaselevel,
          partnumbercommonized: this.secondarypartsgrid[i].partnumbercommonized,
          tradingcogsdbcode: this.secondarypartsgrid[i].tradingcogsdbcode,
          ismissingpart: this.secondarypartsgrid[i].ismissingpart,
          suppliercountrycodeiso: this.secondarypartsgrid[i].suppliercountrycodeiso,
          suppliergsdbcode: this.secondarypartsgrid[i].suppliergsdbcode,
          isprimary: this.secondarypartsgrid[i].isprimary,
          selorder: this.secondarypartsgrid[i].selorder,
          txntimestamp: this.secondarypartsgrid[i].txntimestamp,
          ngpp_part_l: this.secondarypartsgrid[i].ngpp_part_l,
          ngpp_part_w: this.secondarypartsgrid[i].ngpp_part_w,
          ngpp_part_h: this.secondarypartsgrid[i].ngpp_part_h,
          ngpp_part_wt: this.secondarypartsgrid[i].ngpp_part_wt,
          ngpp_part_wt_status: this.secondarypartsgrid[i].ngpp_part_wt_status,
          ngpp_ref_part_no: this.secondarypartsgrid[i].ngpp_ref_part_no,
          ngpp_manf_loc: this.secondarypartsgrid[i].ngpp_manf_loc,
          partid: this.secondarypartsgrid[i].partid
        })
      }
    }
    console.log('secondarypartgridforpostAPI',this.secondarypartgridforpostAPI)

    this.transactionService.savePrimaryPartDetails(this.primarypartsattributes).subscribe(saveandcloseattributes=>
      {
        const opsc=saveandcloseattributes;
        console.log('successful',opsc);
        if(saveandcloseattributes=='Success')
        {
          console.log('primarypart attributes in save',this.primarypartsattributes);
          // this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:'primary part and attributes Added Successfully'});

          this.transactionService.savePartDetails(this.primarypartsgrid).subscribe(getprimaryinsaveclose=>
            {
              let checkinsaveclose=getprimaryinsaveclose;
              if(checkinsaveclose='Success')
              {
                console.log('primarypart  in save',this.primarypartsgrid);
                // this.packagingproposal.addtoast({severity:'success', summary:'Success', detail:'primary part  Added Successfully'});

                this.transactionService.savePartDetails(this.secondarypartgridforpostAPI).subscribe(saveattributesinsaveandclose=>
                  {
                    const opsavclos=saveattributesinsaveandclose;
                    console.log('successful',opsavclos);
                    if(saveattributesinsaveandclose=='Success')
                    {
                      console.log('secondary attributes in save',this.secondarypartgridforpostAPI);
                      const currentab=1;
                      if(this.selectedpart_submitter.proposallastsaved<currentab)
                      {
                      this.selectedpart_submitter.proposallastsaved=1;
                      this.selectedpart_submitter.part_status='UnSubmitted';
                      this.selectedpart_submitter.proposallastsaved_ee=1;

                      this.store_primary_part_exist()
                      }

                      this.packagingprocess.addtoastinpackagingprocess({severity:'success', summary:'success', detail:'Parts And Attributes Details Saved Successfully'});

                      if(this.selectedpart_submitter.propsl_type!=null && this.selectedpart_submitter.propsl_type.toLowerCase()=='migration')
                      {
                        if(this.prt_roleid==1)
                        {
                          this.router.navigate(['/'+this.name+'/packagingprocess/packsupplieradmin/migrationsubmitter'])
                        }
                        else if(this.prt_roleid==2)
                        {
                          this.router.navigate(['/'+this.name+'/packagingprocess/packdesignsourceadmin/migrationsubmitter'])
                        }
                      }
                      else // if(this.selectedpart_submitter.propsl_type!=null && this.selectedpart_submitter.propsl_type.toLowerCase()=="normal")
                      {
                        if(this.prt_roleid==1)
                        {
                          this.router.navigate(['/'+this.name+'/packagingprocess/packsupplieradmin/submiterdashboard'])
                        }
                        else if(this.prt_roleid==2)
                        {
                          this.router.navigate(['/'+this.name+'/packagingprocess/packdesignsourceadmin/submiterdashboard'])
                        }
                      }

                    }
                    else
                    {
                      this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Additional Parts Not Added,Please Try Again'});
                      // if(this.edit_measure_part=="kg/cm")
                      // {


                      //   console.log(this.primarypartsgrid);
                      //   console.log(this.secondarypartsgrid);


                      //   if(this.primarypartsgrid[0].ngpp_part_l!=null)
                      //   {
                      //     this.primarypartsgrid[0].ngpp_part_l=Math.round((2.54*this.primarypartsgrid[0].ngpp_part_l)*1000)/1000;

                      //   }
                      //   if(this.primarypartsgrid[0].ngpp_part_w!=null)
                      //   {
                      //     this.primarypartsgrid[0].ngpp_part_w=Math.round((2.54*this.primarypartsgrid[0].ngpp_part_w)*1000)/1000;

                      //   }
                      //   if(this.primarypartsgrid[0].ngpp_part_h!=null)
                      //   {
                      //     this.primarypartsgrid[0].ngpp_part_h=Math.round((2.54*this.primarypartsgrid[0].ngpp_part_h)*1000)/1000;

                      //   }
                      //   if(this.primarypartsgrid[0].ngpp_part_wt!=null)
                      //   {
                      //     this.primarypartsgrid[0].ngpp_part_wt=Math.round((0.453592*this.primarypartsgrid[0].ngpp_part_wt)*1000)/1000;

                      //   }



                      //   if(this.secondarypartsgrid.length!=0)
                      //   {


                      //     for(var sec=0;sec<this.secondarypartsgrid.length;sec++)
                      //     {


                      //       if(this.secondarypartsgrid[sec].ngpp_part_l!=null)
                      //       {
                      //         this.secondarypartsgrid[sec].ngpp_part_l=Math.round((2.54*this.secondarypartsgrid[sec].ngpp_part_l)*1000)/1000;

                      //       }
                      //       if(this.secondarypartsgrid[sec].ngpp_part_w!=null)
                      //       {
                      //         this.secondarypartsgrid[sec].ngpp_part_w=Math.round((2.54*this.secondarypartsgrid[sec].ngpp_part_w)*1000)/1000;

                      //       }
                      //       if(this.secondarypartsgrid[sec].ngpp_part_h!=null)
                      //       {
                      //         this.secondarypartsgrid[sec].ngpp_part_h=Math.round((2.54*this.secondarypartsgrid[sec].ngpp_part_h)*1000)/1000;

                      //       }
                      //       if(this.secondarypartsgrid[sec].ngpp_part_wt!=null)
                      //       {
                      //         this.secondarypartsgrid[sec].ngpp_part_wt=Math.round((0.453592*this.secondarypartsgrid[sec].ngpp_part_wt)*1000)/1000;

                      //       }


                      //     }

                      //   }


                      // }
                    }

                });
              }
              else
              {
                this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Primary Part  Not Added,Please Try Again'})
                // if(this.edit_measure_part=="kg/cm")
                // {


                //   console.log(this.primarypartsgrid);
                //   console.log(this.secondarypartsgrid);


                //   if(this.primarypartsgrid[0].ngpp_part_l!=null)
                //   {
                //     this.primarypartsgrid[0].ngpp_part_l=Math.round((2.54*this.primarypartsgrid[0].ngpp_part_l)*1000)/1000;

                //   }
                //   if(this.primarypartsgrid[0].ngpp_part_w!=null)
                //   {
                //     this.primarypartsgrid[0].ngpp_part_w=Math.round((2.54*this.primarypartsgrid[0].ngpp_part_w)*1000)/1000;

                //   }
                //   if(this.primarypartsgrid[0].ngpp_part_h!=null)
                //   {
                //     this.primarypartsgrid[0].ngpp_part_h=Math.round((2.54*this.primarypartsgrid[0].ngpp_part_h)*1000)/1000;

                //   }
                //   if(this.primarypartsgrid[0].ngpp_part_wt!=null)
                //   {
                //     this.primarypartsgrid[0].ngpp_part_wt=Math.round((0.453592*this.primarypartsgrid[0].ngpp_part_wt)*1000)/1000;

                //   }



                //   if(this.secondarypartsgrid.length!=0)
                //   {


                //     for(var sec=0;sec<this.secondarypartsgrid.length;sec++)
                //     {


                //       if(this.secondarypartsgrid[sec].ngpp_part_l!=null)
                //       {
                //         this.secondarypartsgrid[sec].ngpp_part_l=Math.round((2.54*this.secondarypartsgrid[sec].ngpp_part_l)*1000)/1000;

                //       }
                //       if(this.secondarypartsgrid[sec].ngpp_part_w!=null)
                //       {
                //         this.secondarypartsgrid[sec].ngpp_part_w=Math.round((2.54*this.secondarypartsgrid[sec].ngpp_part_w)*1000)/1000;

                //       }
                //       if(this.secondarypartsgrid[sec].ngpp_part_h!=null)
                //       {
                //         this.secondarypartsgrid[sec].ngpp_part_h=Math.round((2.54*this.secondarypartsgrid[sec].ngpp_part_h)*1000)/1000;

                //       }
                //       if(this.secondarypartsgrid[sec].ngpp_part_wt!=null)
                //       {
                //         this.secondarypartsgrid[sec].ngpp_part_wt=Math.round((0.453592*this.secondarypartsgrid[sec].ngpp_part_wt)*1000)/1000;

                //       }


                //     }

                //   }


                // }
              }
            });
        }
        else{
          this.packagingproposal.addtoast({severity:'error', summary: 'Error', detail: 'Primary Part Attributes Not Added,Please Try Again'})
        }
      });
}
}
}
